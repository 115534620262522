import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { languages } from "./languages";
import { I18nextProvider } from "react-i18next";
import "./i18n";
import HomeApp from "./App";
//boostrap:
import "bootstrap/dist/css/bootstrap.min.css";

//css
import "./index.css";
import { Suspense } from "react";
//========================================redux================================================
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
//======================================reducers======================================
import cartReducer, { getTotals } from "./redux/cartSlice";
import shipmentStepperReducer from "./redux/shipmentStepperSlice";
import productsReducer, { productsFetch } from "./redux/shipmentProductsSlice";
import { productsApi } from "./redux/shipmentProductsApi";
import getNotificationReducer from "./redux/notificationSlice";
//======================================admin Reducers======================================
import adminCartReducer, { adminGetTotals } from "./redux/Admin/adminCartSlice";
import adminShipmentStepperReducer from "./redux/Admin/adminShipmentStepperSlice";
import PreLoader from "./Components/PreLoader/PreLoader";

//========================Translations=============================

import translationEn from "./locales/EN/translation.json";
import translationAr from "./locales/AR/translation.json";
import translationFr from "./locales/FR/translation.json";
import translationDe from "./locales/DE/translation.json";
import translationDA from "./locales/DA/translation.json";
import translationFa from "./locales/FA/translation.json";
import translationCn from "./locales/ZH/translation.json";
import translationTr from "./locales/TR/translation.json";
import translationRu from "./locales/RU/translation.json";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const store = configureStore({
  reducer: {
    products: productsReducer,
    cart: cartReducer,
    adminCart: adminCartReducer,
    adminShipmentStepper: adminShipmentStepperReducer,
    shipmentStepper: shipmentStepperReducer,
    notifications: getNotificationReducer,
    [productsApi.reducerPath]: productsApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(productsApi.middleware),
});
store.dispatch(productsFetch());
store.dispatch(getTotals());
store.dispatch(adminGetTotals());
//=============================Translation Options============================================

const resources = {
  en: {
    translation: translationEn,
  },
  ar: {
    translation: translationAr,
  },
  fr: {
    translation: translationFr,
  },
  ru: {
    translation: translationRu,
  },
  zh: {
    translation: translationCn,
  },
  tr: {
    translation: translationTr,
  },
  ir: {
    translation: translationFa,
  },
  da: {
    translation: translationDA,
  },
  de: {
    translation: translationDe,
  },
};

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    react: {
      useSuspense: false,
      wait: true,
    },
    supportedLngs: ["en", "ar", "fr", "ru", "de", "cn", "tr", "dn", "ir"],
    fallbackLng: "en",
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["localStorage", "cookie"],
    },
    debug: true,
    whitelist: languages,
    nsSeperator: false,
    keySeperator: false,
    backend: {
      loadPath: "../src/locales/{{lng}}/translation.json",
      crossDomain: true,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
const queryClient = new QueryClient();
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<PreLoader />}>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <HomeApp />
            </BrowserRouter>
          </QueryClientProvider>
        </I18nextProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
