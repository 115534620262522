import { useEffect, useState } from "react";
//css
import "./ShipmentMethodCard.css";
//bootstrap
import { Form } from "react-bootstrap";
//images
import ShipmentMethod from "../../../../assets/Payments/shipmentMethod.png";
import ImportCountry from "../../../../assets/Payments/ImportCountry.png";
import DeliveryPlace from "../../../../assets/Payments/deliveryPlace.png";
import Plain from "../../../../assets/Payments/plain.png";
import Shipment from "../../../../assets/Payments/shipment.png";
import TruckImg from "../../../../assets/Payments/truck.png";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  addDeliveryMethod,
  addImportCountryId,
  addImportStateId,
  addContainerId,
  addContainer,
} from "../../../../redux/shipmentStepperSlice";
//translation
import { useTranslation } from "react-i18next";

const ShipmentMethodCard = ({ method }) => {
  const state = useSelector((state) => state.shipmentStepper);
  const dispatch = useDispatch();
  const [isShown, setIsShown] = useState(true);
  const [shipmentMethod] = useState(method);
  const [importCountries, setImportCountries] = useState([]);
  const [importCountryId, setImportCountryId] = useState("");
  const [importStates, setImportStates] = useState([]);
  const [importStateId, setImportStateId] = useState("");
  const [stateContainers, setStateContainers] = useState([]);
  const [containerType, setContainerType] = useState("");
  const [DeliveryMethods, setDeliveryMethods] = useState([]);
  const [DeliveryMethodId, setDeliveryMethodId] = useState("");
  const [choosenContainerId, setChoosenContainerId] = useState("");
  const [docLang, setDocLang] = useState(document.querySelector("html").lang);
  const { t } = useTranslation();
  useEffect(() => {
    setDocLang(document.querySelector("html").lang);
  }, [t]);
  //===========================Add Container TO Redux===============================
  useEffect(() => {
    stateContainers.forEach((container) => {
      if (container._id === state.containerId) {
        localStorage.setItem("container", JSON.stringify(container));
        dispatch(addContainer(container));
      }
    });
  }, [choosenContainerId, dispatch, stateContainers, state.containerId]);
  //===========================Handle Container Change=========================
  useEffect(() => {
    dispatch(addContainerId(choosenContainerId));
  }, [choosenContainerId, dispatch]);
  //===========================Enable And Disable Next Button===============================
  useEffect(() => {
    if (
      state.exportCountryId &&
      state.containerId &&
      state.deliveryMethod &&
      state.importCountryId &&
      state.importStateId
    ) {
      document.getElementById("next-button").disabled = false;
    } else {
      document.getElementById("next-button").disabled = true;
    }
  }, [state]);
  //=========================handleCountryChange==============================
  useEffect(() => {
    dispatch(addImportCountryId(importCountryId));
    importCountries.forEach((country) => {
      if (country._id === importCountryId) {
        setImportStates(country.states);
      }
    });
  }, [dispatch, importCountries, importCountryId]);
  //=========================handleStateChange==============================

  useEffect(() => {
    dispatch(addImportStateId(importStateId));
    importStates?.forEach((state) => {
      if (state._id === importStateId) {
        setStateContainers(state.shippingContainers);
      }
    });
  }, [dispatch, importStateId, importStates]);

  //===========================Handle Delivery Method=========================
  useEffect(() => {
    dispatch(addDeliveryMethod(DeliveryMethodId));
  }, [DeliveryMethodId, dispatch]);

  //===========================Show and hide fright info=========================
  const showHideFreightInfo = () => {
    setIsShown((current) => current);
  };
  //============================getAllImportCountries============================

  useEffect(() => {
    const getAllImportCountries = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/import-country/?exportCountryId=${localStorage.getItem(
          "exportCountryId"
        )}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setImportCountries([]);
        console.log(body);
      } else {
        setImportCountries(body);
        console.log(body);
      }
    };
    getAllImportCountries();
  }, []);
  //================================getDeliveryMethod=======================

  useEffect(() => {
    const getDeliveryMethod = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/delivery-method/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setDeliveryMethods([]);
        console.log(body);
      } else {
        setDeliveryMethods(body);
      }
    };
    getDeliveryMethod();
  }, []);
  // console.log("Waaaaaa " + choosenContainerId);
  console.log(state);
  return (
    <>
      {shipmentMethod.length > 0
        ? shipmentMethod.map((method) => {
            return (
              <>
                {
                  //=================================================================Choose Country And State==============================================================
                  //=================================================================Choose Country And State==============================================================
                  //=================================================================Choose Country And State==============================================================
                  //=================================================================Choose Country And State==============================================================
                  //=================================================================Choose Country And State==============================================================
                  method === "importCountry" ? (
                    <section className="importCountry">
                      <div className="importCountry-header">
                        <img src={ImportCountry} alt="importCountryImg" />
                        <span>
                          {t(
                            `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.ImportCountry`
                          )}
                        </span>
                      </div>
                      <div className="importCountry-body-dropdown">
                        <Form.Select
                          id="importCountry-dropdown"
                          name="countryId"
                          value={state.importCountryId}
                          onChange={(e) => {
                            setImportCountryId(e.target.value);
                          }}
                        >
                          <option>
                            {t(
                              `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.SelectCountry`
                            )}
                          </option>
                          {importCountries.map((country, key) => {
                            return (
                              <option
                                name={country.countryName.en}
                                key={key}
                                value={country._id}
                              >
                                {country.countryName[docLang]
                                  ? country.countryName[docLang]
                                  : country.countryName.en}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Select
                          name="stateId"
                          id="importState-dropdown"
                          value={state.importStateId}
                          onChange={(e) => {
                            setImportStateId(e.target.value);
                          }}
                        >
                          <label for="company">
                            {t(
                              `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.StateName`
                            )}
                          </label>
                          <option>
                            {t(
                              `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.SelectState`
                            )}
                          </option>
                          {importStates.map((state, key) => {
                            return (
                              <option key={key} value={state._id}>
                                {state.stateName[docLang]
                                  ? state.stateName[docLang]
                                  : state.stateName.en}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    </section>
                  ) : //=================================================================Choose Container==============================================================
                  //=================================================================Choose Container==============================================================
                  //=================================================================Choose Container==============================================================
                  //=================================================================Choose Container==============================================================

                  method === "shipmentMethod" ? (
                    <section className="shipmentMethodCard">
                      <div className="shipmentMethodCard-header">
                        <img src={ShipmentMethod} alt="shipmentMethod" />
                        <span>
                          {t(
                            `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.ShipmentMethod`
                          )}
                        </span>
                      </div>
                      <div className="shipmentMethodCard-body">
                        <div className="shipmentMethodCard-body-buttons">
                          <button
                            className="shipmentMethodCard-btn"
                            onClick={() => {
                              showHideFreightInfo();
                              setContainerType("air");
                            }}
                          >
                            <img src={Plain} alt="plain" />
                            <span>
                              {t(
                                `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.AirFreight`
                              )}
                            </span>
                          </button>
                          <button
                            className="shipmentMethodCard-btn"
                            onClick={() => {
                              showHideFreightInfo();
                              setContainerType("sea");
                            }}
                          >
                            <img src={Shipment} alt="shipment" />
                            <span>
                              {t(
                                `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.SeaFreight`
                              )}
                            </span>
                          </button>
                          <button
                            className="shipmentMethodCard-btn"
                            onClick={() => {
                              showHideFreightInfo();
                              setContainerType("land");
                            }}
                          >
                            <img src={TruckImg} alt="Truck" />
                            <span>
                              {t(
                                `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.LandShipping`
                              )}
                            </span>
                          </button>
                        </div>
                        <div>
                          <h6>
                            {t(
                              `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.ContainerInfo`
                            )}
                          </h6>
                          <p>
                            {t(
                              `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.ContainerInfoNote`
                            )}
                          </p>
                          {/* ========================Display Container Values========================= */}
                          {/* ========================Display Container Values========================= */}
                          {/* ========================Display Container Values========================= */}
                          {/* ========================Display Container Values========================= */}
                          {/* ========================Display Container Values========================= */}
                          {/* ========================Display Container Values========================= */}
                          {isShown && containerType === "air" ? (
                            <h4>
                              {t(
                                `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.AirFreightWarn`
                              )}
                            </h4>
                          ) : isShown && containerType === "sea" ? (
                            <Form.Select
                              id="container-dropdown"
                              name="seaContainers"
                              onChange={(e) => {
                                setChoosenContainerId(e.target.value);
                              }}
                            >
                              <option>
                                {t(
                                  `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.SelectSeaContainer`
                                )}
                              </option>
                              {stateContainers.map((container, index) => {
                                return container.container.containerType ===
                                  "sea" ? (
                                  <option
                                    key={index}
                                    value={container._id}
                                    dir="ltr"
                                  >
                                    {` ${container.container.usualName} === ${
                                      container.container.isFridge
                                        ? "Frozen"
                                        : "Not Frozen"
                                    }`}
                                  </option>
                                ) : null;
                              })}
                            </Form.Select>
                          ) : isShown && containerType === "land" ? (
                            <Form.Select
                              id="container-dropdown"
                              name="landContainers"
                              onChange={(e) => {
                                setChoosenContainerId(e.target.value);
                              }}
                            >
                              <option>
                                {t(
                                  `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.SelectLandContainer`
                                )}
                              </option>
                              {stateContainers.map((container, index) => {
                                return container.container.containerType ===
                                  "land" ? (
                                  <option
                                    key={index}
                                    value={container._id}
                                    style={{ width: "100%" }}
                                  >
                                    {`${container.container.containerType}   ${
                                      container.container.isFridge
                                        ? "Fridge"
                                        : "Not Fridge"
                                    } ${container.container.maxWeight}ton ${
                                      container.container.maxVolume
                                    }`}
                                  </option>
                                ) : null;
                              })}
                            </Form.Select>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  ) : //===============================================================Choose Delivery Method============================================
                  //===============================================================Choose Delivery Method============================================
                  //===============================================================Choose Delivery Method============================================
                  //===============================================================Choose Delivery Method============================================
                  //===============================================================Choose Delivery Method============================================
                  method === "deliveryMethod" ? (
                    <section className="deliveryMethod">
                      <div className="deliveryMethod-header">
                        <img src={DeliveryPlace} alt="deliveryMethod" />
                        <span>
                          {t(
                            `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.DeliveryMethod`
                          )}
                        </span>
                      </div>
                      <div className="deliveryMethod-body">
                        <Form.Select
                          id="deliveryMethod-dropdown"
                          title="Delivery Method"
                          onChange={(e) => {
                            setDeliveryMethodId(e.target.value);
                          }}
                        >
                          <option>
                            {t(
                              `Components.ShipmentComponents.ShipmentStepper.ShipmentMethodCard.SelectDeliveryMethod`
                            )}
                          </option>
                          {DeliveryMethods.map((method, index) => {
                            return (
                              <option key={index} value={method._id}>
                                {method.methodName}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    </section>
                  ) : null
                }
              </>
            );
          })
        : null}
    </>
  );
};

export default ShipmentMethodCard;
