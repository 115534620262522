import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Row, Form, Container, Modal } from "react-bootstrap";
import "./CrudProducts.css";
import { useMutation, useQuery } from '@tanstack/react-query';
import ApiInstances from '../../Api/axiso';
import toast from 'react-hot-toast';
import { useDrop } from 'react-dnd';
const TableRow = ({ item, moveRow }) => {
    const [, dragRef] = useDrag({
        type: 'row',
        item: { id: item._id },
    });

    const [, dropRef] = useDrop({
        accept: 'row',
        hover: (draggedItem) => {
            if (draggedItem.id !== item._id) {
                moveRow(draggedItem.id, item._id);
            }
        },
    });

    return (
        <tr ref={(node) => dragRef(dropRef(node))}>
            <td style={{ width: "70px" }}>
                <img
                    src={`https://www.api.setalkel.co${item.img}`}
                    style={{ cursor: 'pointer', height: "70px", width: "70px" }}
                    alt={item.cat.en}
                />
            </td>
            <td>{item.subCat.en}</td>
            <td>{item.peiceQty.usualWeight}</td>
            <td>{item.countryOrigin ? item.countryOrigin : "No Country Origin"}</td>
            <td>{item.materialProperty ? item.materialProperty : "No Material Property"}</td>
        </tr>
    );
};

const CardItem = ({ item, moveRow }) => {
    const [, dragRef] = useDrag({
        type: 'row',
        item: { id: item._id },
    });

    const [, dropRef] = useDrop({
        accept: 'row',
        hover: (draggedItem) => {
            if (draggedItem.id !== item._id) {
                moveRow(draggedItem.id, item._id);
            }
        },
    });

    return (
        <div ref={(node) => dragRef(dropRef(node))} className="card-item">
            <img src={`https://www.api.setalkel.co${item.img}`} alt={item.cat.en} style={{ width: "100px", height: "100px" }} />
            <div>{item.subCat.en}</div>
            <div>{item.peiceQty.usualWeight ? `${item.peiceQty.usualWeight}` : "No Price Available"}</div>
        </div>
    );
};

const ProductTableDrag = () => {
    const [categories, setCategories] = useState([]);
    const [CatFilterID, setCatFilterID] = useState("");
    const [categoryProducts, setCategoryProducts] = useState([]);
    const [savedOrders, setSavedOrders] = useState({});
    const [viewType, setViewType] = useState("table");

    useEffect(() => {
        const fetchCategories = async () => {
            var requestOptions = {
                method: "GET",
                redirect: "follow",
            };

            const response = await fetch(
                "https://www.api.setalkel.co/product/cat/",
                requestOptions
            );
            const body = await response.json();

            if (!response.ok) {
                setCategories([]);
            } else {
                setCategories(body);
            }
        };
        fetchCategories();
    }, []);
    const queryResult = useQuery({
        queryKey: ["get-pro-filter", CatFilterID],
        queryFn: async () => {
            const { data } = await ApiInstances.get(`https://www.api.setalkel.co/product/?cat=${CatFilterID}`)
            return data;
        },
        enabled: !!CatFilterID
    });

    const filterProductsByCategoryID = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        try {
            const response = await fetch(`https://www.api.setalkel.co/product/?cat=${CatFilterID}`, requestOptions);
            const body = await response.json();
            if (response.ok) {
                const productsWithOrder = body.data.map((product, index) => ({
                    ...product,
                    order: index + 1,
                }));
                setCategoryProducts(productsWithOrder);
            } else {
                console.error('Error fetching products:', body);
                setCategoryProducts([]);
            }
        } catch (error) {
            console.error('Network error:', error);
            setCategoryProducts([]);
        }
    };

    const moveRow = (draggedId, hoverId) => {
        const draggedIndex = categoryProducts.findIndex((item) => item._id === draggedId);
        const hoverIndex = categoryProducts.findIndex((item) => item._id === hoverId);

        if (draggedIndex !== -1 && hoverIndex !== -1) {
            const newProducts = [...categoryProducts];
            const [draggedItem] = newProducts.splice(draggedIndex, 1);
            newProducts.splice(hoverIndex, 0, draggedItem);
            setCategoryProducts(newProducts);
        }
    };


    useEffect(() => {
        const savedOrder = JSON.parse(localStorage.getItem('categoryProductsOrder'));
        if (savedOrder) {
            setSavedOrders(savedOrder);
        }
    }, []);

    useEffect(() => {
        CatFilterID && filterProductsByCategoryID()
    }, [CatFilterID]);
    const { mutate } = useMutation({
        mutationFn: async (res) => {
            const { data } = await ApiInstances.post(`/product/update-order`, res)
            return data;
        },
        onSuccess: () => {
            toast.success("Sorting Successfully");
            filterProductsByCategoryID()
        },

    });
    const handleSort = () => {
        const productIds = categoryProducts.map(obj => obj._id);
        mutate({ productIds: productIds })
    }
    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{ display: "flex", gap: "10px" }}>
                <Form.Group className="filter-category">
                    <Form.Label>Filter Category</Form.Label>
                    <Form.Select
                        id="category-list"
                        name="catFilterId"
                        value={CatFilterID}
                        onChange={(e) => setCatFilterID(e.target.value)}
                    >
                        <option>Please Choose A Category</option>
                        {categories.map((category) => (
                            <option key={category._id} value={category._id}>{category.en}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group style={{
                    marginTop: "23px"
                }} className="view-type">
                    <Form.Label>View Type</Form.Label>
                    <Form.Select
                        id="view-type"
                        name="viewType"
                        value={viewType}
                        onChange={(e) => setViewType(e.target.value)}
                    >
                        <option value="table">Sequential</option>
                        <option value="grid">Grid</option>
                    </Form.Select>
                </Form.Group>
                <Button
                    variant="warning"
                    style={{ height: "fit-content", margin: "0", marginTop: "50px" }}
                    onClick={() => handleSort()}>Sorting</Button>

            </div>
            {categoryProducts.length === 0 ? (
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        color: "black",
                        fontSize: "16px"
                    }}
                ><p>Please Select Category</p></div>
            ) : viewType === "table" ? (
                <Table className='tabel-drag'>
                    <thead>
                        <tr>
                            <th style={{ width: "70px" }}>Image</th>
                            <th>Product</th>
                            <th>Usual Weight</th>
                            <th>Country Origin</th>
                            <th>Material Property</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categoryProducts.map((item) => (
                            <TableRow key={item._id} item={item} moveRow={moveRow} />
                        ))}
                    </tbody>
                </Table>
            ) : (
                <div className="card-grid">
                {categoryProducts.map((item) => (
                    <CardItem key={item._id} item={item} moveRow={moveRow} />
                ))}
            </div>
            )}
        </DndProvider>
    );
};

export default ProductTableDrag;
