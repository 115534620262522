import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
//bootstrap
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
//semantic UI
//css
import DataTable from "react-data-table-component";
import "./CROurActivities.css";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import UnVerifiedAgentsApi from "../../Api/UnVerifiedAgentsApi";
import toast from "react-hot-toast";
import { FaDownload } from "react-icons/fa";
const CROurActivities = () => {
  const [activities, setActivities] = useState([]);
  const [activityId, setActivityId] = useState('');
  const [generateExcelFile, setGenerateExecelFile] = useState()
  const [deletedProducts, setDeletedProducts] = useState({
    ids: [],
  });

  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleClose = () => setDeleteModalShow(false);
  const handleShow = () => setDeleteModalShow(true);

  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);

  //=======================Handle Imput Desc=========================



  //=======================Fetch Activities===============

  useEffect(() => {
    const fetchActivities = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      const response = await fetch(
        "https://www.api.setalkel.co/our-activities",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setActivities([]);
      } else {
        setActivities(body);
      }
    };
    fetchActivities();
  }, []);

  //============================Delete Activity=============================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/our-activities/${activityId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          toast.success("item deleted");
          handleClose()
        }
      })
      .catch((error) => {
        toast.error(error.response);
      });
  };
  //============================Delete Mutliple Product=====================

  const deleteProducts = (e, contactID) => {
    if (e.target.checked) {
      if (!deletedProducts.ids.includes(contactID)) {
        setDeletedProducts({
          ...deletedProducts,
          ids: [...deletedProducts.ids, contactID],
        });
        console.log(deletedProducts);
      }
    } else {
      setDeletedProducts({
        ids: deletedProducts.ids.filter((item) => item !== contactID),
      });
    }
  };
  const deleteMultipleActivities = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/our-activities/collection",
      requestOptions
    );

    if (!response.ok) {
      toast.error("There Is An Error ");
      console.log(response);
    } else {
      toast.success("Activities Have Been Deleted !!");
      handleMultipleClose()
    }
  };
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  const columns = [
    {
      name: "Image",
      selector: (row) => (
        <div className="position-relative">
          <img
            src={"https://www.api.setalkel.co" + row.img}
            alt="productImg"
            style={{
              height: "100px",
              width: "100px",
            }}
          />
          <FaDownload onClick={() => getFile(row.img)} style={{
            color: "red",
            fontSize: "15px",
            position: "absolute",
            top: "0",
            right: "0",
            cursor:"pointer"

          }} />

        </div>
      ),
    },
    {
      name: "Name En",
      selector: (row) => <span>{row.title.en}</span>,
    },
    {
      name: "Name Ar",
      selector: (row) => <span>{row.title.ar}</span>,
    },
    {
      name: "Desc",
      selector: (row) => (
        <Form.Control className="textarea" as="textarea" disabled row={10} cols={10} width="10rem">
          {row.desc.en}
        </Form.Control>
      ),
    },
    {
      name: "Location",
      selector: (row) => <span>{row.loc.en}</span>,
    },
    {
      name: "Date",
      selector: (row) => <span>{row.date.slice(0, 10)}</span>,
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/crouractivities/${row._id}`}>
          <Button type="button" variant="dark">
            Update
          </Button>
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button variant="danger" onClick={() => {
            handleShow()
            setActivityId(row._id)
          }}>
            Delete
          </Button>
        </div>
      ),
    },
    {
      name: "   Add Sub Activity",
      selector: (row) => (
        <div>
          <Button variant="warning" onClick={() => {
            navigate("sub-activity")
          }}>
          Add Sub Activity
          </Button>
        </div>
      ),
    },
    {
      name: "M-Delete",
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteProducts(e, row._id)}
        />
      ),
    },
  ];
  const { data: Excel } = UnVerifiedAgentsApi.GenerateExelFileQueryForActivites()
  console.log(Excel)
  const getFileExcel = async () => {

    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co${generateExcelFile.pathFile}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${generateExcelFile.pathFile}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log("Error:", error);
    }
  }
  const navigate = useNavigate()
  return (
    <section className="cms-ourActivities">
      <Row>
        <Col md={12}>
          <div className="crud-title">
            <h1>Our Activities</h1>
          </div>
        </Col>
      </Row>
      <Row>

      </Row>
      <div className="d-flex">
        {!generateExcelFile ? <Button variant="warning" id="verify-btn" onClick={() => setGenerateExecelFile(Excel)}>
          Export Excel for Activities
        </Button> : <Button variant="success" id="verify-btn" onClick={getFileExcel}>
          Download Excel  File
        </Button>}
        <Button variant="danger" id="verify-btn" >
              Import Excel
            </Button>
        <Button variant="warning" id="verify-btn" onClick={() => navigate("/dashboard/crouractivities/add-activities")}>
          Add Activities
        </Button>
      </div>
      <Row>
        <DataTable
          fixedHeader
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={activities}
          customStyles={customStyles}
        />
      </Row>
      <div>
        {deletedProducts.ids.length > 0 ? (
          <>
            <Button
              id="multipleDelete"
              variant="outline-danger"
              onClick={handleMultipleShow}
            >
              Multiple Delete
            </Button>

            <Modal
              size="lg"
              show={MultipleDeleteModalShow}
              onHide={() => setMultipleDeleteModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Are You Sure You Want To Delete All Those
                  Activities
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleMultipleClose}
                >
                  Close
                </Button>
                <Button
                  variant="danger"
                  onClick={deleteMultipleActivities}
                >
                  Delete Activities
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : null}
        <Modal show={DeleteModalShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Are You Sure You Want To Delete This Activity
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="danger" onClick={() => onDelete()}>
              Delete Activity
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </section>
  );
};

export default CROurActivities;
