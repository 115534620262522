import React, { useEffect, useState } from "react";
import "./CRPrices.css";
import { Button, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { customStyles } from "../../../constants/DataTableCustomeStyles";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ApiInstances from "../../../Api/axiso";

const CRPrices = () => {
  const [agents, setAgents] = useState([]);
  const [agentID, setAgentID] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryID, setCategoryID] = useState("");
  const [CategoryMetaData, setCategoryMetaData] = useState("");
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [AgentProductsPrices, setAgentProductsPrices] = useState([]);
  const [generatedExcelFile, setGeneratedExcelFile] = useState({});

  //=========================Handle Product Price=========================
  const handleProductPrice = (value, productId) => {
    if (value === "") {
      const index = orderProducts.findIndex((order) => order.product === productId);
      if (index !== -1) {
        orderProducts.splice(index, 1); // حذف العنصر المطابق لـ productId
      }
      return;
    }
    if (value > 0) {
      let flag = false;
      orderProducts.forEach((order) => {
        if (order.product === productId) {
          flag = true;
          order.price = parseFloat(value);
        }
      });
      if (!flag) {
        orderProducts.unshift({
          product: productId,
          price: isNaN(parseFloat(value)) ? "" : parseFloat(value),
        });
      } else if (flag && value === "") {

        // console.log(flag)

      }

    } else {
      toast.error("Product Price Cannot Be Negative or 0");
    }
  };

  //================================generateExcelFile=========================
  const generatePricesExcel = async () => {
    if (agentID) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/agent/export-prices/${agentID}`,
        requestOptions
      );
      const body = await response.json();
      // console.log(body);
      if (!response.ok) {
        console.log(body);
        setGeneratedExcelFile({});
        alert("Prices Excel File Not Generated");
      } else {
        setGeneratedExcelFile(body);
        // console.log(body)
      }
    } else {
      alert("Please Choose An Agent First");
    }
  };

  //======================download File ===============================================
  const getFile = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co${generatedExcelFile.pathFile}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${generatedExcelFile.pathFile}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      // console.log("Error:", error);
    }
  };

  //=========================Fetch Agents=========================
  useEffect(() => {
    const fetchAgents = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/agent/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setAgents([]);
        // console.log(body);
      } else {
        setAgents(body);
      }
    };
    fetchAgents();
  }, []);

  useEffect(() => {
    const fethcCategories = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/cat/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setCategories([]);
        // console.log(body);
      } else {
        setCategories(body);
      }
    };
    fethcCategories();
  }, []);

  //=========================Get Agent Products Prices==============================
  useEffect(() => {
    const fetchAgentProductsPrices = async () => {
      if (agentID && categoryID) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `https://www.api.setalkel.co/agent/productPrice/${agentID}/${categoryID}`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          setAgentProductsPrices([]);
        } else {
          setAgentProductsPrices(body.data);
        }
      }
    };
    fetchAgentProductsPrices();
  }, [agentID, categoryID]);
  const [success, setSuccess] = useState(false)
  //====================Post Products Prices====================================
  const PostAgentProductsPrices = async () => {
    if (!agentID) {
      toast.error("Please Select Agent")
      return
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw;
    for (let i = 0; i < orderProducts.length; i++) {
      const orderProduct = orderProducts[i];
      if (orderProduct.price === "") {
        AgentProductsPrices.forEach((item) => {
          if (orderProduct.product === item.product._id) {
            toast.success(
              `${item.product.subCat.en} Weight : ${item.product.peiceQty.qty}-${item.product.peiceQty.unit} was entered with no price`
            );
          }
        });
        orderProducts.splice(i, 1);
        i--;
      }
    }
    if (orderProducts.length > 0) {

      raw = JSON.stringify(orderProducts);
      // console.log(raw);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const response = await fetch(
        `https://www.api.setalkel.co/agent/productPrice/${agentID}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setOrderProducts([]);
        // console.log(body);
        toast.error("There Is Somthing Wrong");
        setSuccess(false)
      } else {
        toast.success("Prices Has Been Added");
        setSuccess(true)
        queryClient.refetchQueries(["get-price"])
        const inputFields = document.querySelectorAll("#price");
        inputFields.forEach((field) => {
          field.value = "";
        });
        setOrderProducts([]);
        // console.log(orderProducts)
      }
    } else {
      toast.error("Prices Array Length is not bigger than 0");
    }
  };
  const queryClient = useQueryClient()
  const GetPriceQuery = (categoryID) => {
    const queryResult = useQuery({
      queryKey: ["get-price", categoryID],
      queryFn: async () => {
        const { data } = await ApiInstances.get(`https://www.api.setalkel.co/product/?cat=${categoryID}`)
        return data;
      },
      enabled: !!categoryID
    });
    return queryResult;
  };
  const { data: ImportPrice } = GetPriceQuery(categoryID)
  //  useEffect(() => {
  //     const filterProductsByCategoryID = async () => {
  //       var requestOptions = {
  //         method: "GET",
  //         redirect: "follow",
  //       };

  //       const response = await fetch(
  //         `https://www.api.setalkel.co/product/?cat=${categoryID}`,
  //         requestOptions
  //       );
  //       const body = await response.json();
  //       if (!response.ok) {
  //         setCategoryProducts([]);
  //         console.log(body);
  //       } else {
  //         setCategoryProducts(body.data);
  //         setCategoryMetaData(body._metadata.count);
  //       }
  //     };

  //     if (categoryID) {
  //       filterProductsByCategoryID();
  //     }
  //   }, [categoryID, success === true]);

  //=======================SORTING=======================
  const handleSortingChange = (e) => {
    if (e.target.checked) {
      if (e.target.id === "A-Z") {
        const strAscending = [...categoryProducts].sort((a, b) =>
          a.subCat.en > b.subCat.en ? 1 : -1
        );
        setCategoryProducts(strAscending);
      } else if (e.target.id === "Z-A") {
        const strDescending = [...categoryProducts].sort((a, b) =>
          a.subCat.en > b.subCat.en ? -1 : 1
        );
        setCategoryProducts(strDescending);
      }
    }
  };

  //===============SORTING===========================
  const handleWeightSorting = (e) => {
    if (e.target.checked) {
      if (e.target.id === "1-100") {
        const numAscending = [...categoryProducts].sort(
          (a, b) =>
            a.peiceQty.grossWeightForPeice - b.peiceQty.grossWeightForPeice
        );
        setCategoryProducts(numAscending);
      } else if (e.target.id === "100-1") {
        const numDescending = [...categoryProducts].sort(
          (a, b) =>
            b.peiceQty.grossWeightForPeice - a.peiceQty.grossWeightForPeice
        );
        setCategoryProducts(numDescending);
      }
    }
  };

  //=================SORTING===============
  const handleMatriealSorting = (e) => {
    if (e.target.checked) {
      if (e.target.id === "dried") {
        const driedData = async () => {
          var requestOptions = {
            method: "GET",
            redirect: "follow",
          };

          const response = await fetch(
            `https://www.api.setalkel.co/product/?cat=${categoryID}&materialProperty=dried`,
            requestOptions
          );
          const body = await response.json();
          setCategoryProducts(body.data);
        };
        driedData();
      } else if (e.target.id === "mix") {
        const mixData = async () => {
          var requestOptions = {
            method: "GET",
            redirect: "follow",
          };

          const response = await fetch(
            `https://www.api.setalkel.co/product/?cat=${categoryID}&materialProperty=mix`,
            requestOptions
          );
          const body = await response.json();
          // console.log(body);
          setCategoryProducts(body.data);
        };
        mixData();
      } else if (e.target.id === "frozen") {
        const frozenData = async () => {
          var requestOptions = {
            method: "GET",
            redirect: "follow",
          };

          const response = await fetch(
            `https://www.api.setalkel.co/product/?cat=${categoryID}&materialProperty=frozen`,
            requestOptions
          );
          const body = await response.json();
          // console.log(body);
          setCategoryProducts(body.data);
        };
        frozenData();
      }
    }
  };
  //====================================END  SORTING========================================

  const columns = [
    {
      name: "Image",
      selector: (row) => (
        <span>
          <img
            src={"https://www.api.setalkel.co" + row.img}
            alt="productImg"
            style={{
              height: "70px",
              width: "70px",
            }}
          />
        </span>
      ),
    },
    { name: "Category", selector: (row) => <span>{row.cat.en}</span> },
    { name: "Product-Name", width: 300, selector: (row) => <span>{row.subCat.en}</span> },
    {
      name: "Usual-Weight",
      selector: (row) => <span>{row.peiceQty.usualWeight}</span>,
    },
    {
      name: "Price",
      selector: (row) => (
        <span>
          {AgentProductsPrices?.map((item) => {
            let num = parseFloat(item.price);
            return row._id === item.product._id ? num.toFixed(2) + "$" : "";
          })}
        </span>
      ),
    },
    {
      name: "Country-Origin",
      selector: (row) => (
        <span>{row.countryOrigin ? row.countryOrigin : "No Country Origin"}</span>
      ),
    },
    {
      name: "Material-Property",
      selector: (row) => (
        <span>{row.countryOrigin ? row.materialProperty : "No Material Property"}</span>
      ),
    },
    {
      name: "Enter Price :",
      selector: (row) => (
        <Form.Control
          id="price"
          type="number"
          name="price"
          lang="en"
          placeholder=" $ "
          min={1}
          onChange={(e) => {
            handleProductPrice(e.target.value, row._id);
          }}
        />
      ),
    },
  ];

  return (
    <section className="cms-create-prices">
      <Row>
        <div className="crud-title">
          <h1>Agents Products Prices</h1>
        </div>
      </Row>
      <Row>
        <div className="post-products-prices">
          <h4>Please Enter Agent Prices For His Products</h4>
          <Form>
            <div className="filter">
              <Form.Group>
                <Form.Label>Please Select Agent</Form.Label>
                <Form.Select
                  name="agentID"
                  value={agentID}
                  onChange={(e) => setAgentID(e.target.value)}
                >
                  <option>Please Select Agent</option>
                  {
                    agents.length > 0 ? agents.map((agent, index) => {
                      return (
                        <option key={index} value={agent._id}>
                          {agent.name}
                        </option>
                      );
                    })
                      : null
                  }
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Label>Please Select Category</Form.Label>
                <Form.Select
                  name="categoryID"
                  value={categoryID}
                  onChange={(e) => setCategoryID(e.target.value)}
                >
                  <option>Please Select Category</option>
                  {
                    categories.length > 0 ? categories.map((category, index) => {
                      return (
                        <option key={index} value={category._id}>
                          {category.en}
                        </option>
                      );
                    })
                      : null
                  }
                </Form.Select>
              </Form.Group>
              <div>
                <h4>Alphabetically Sorting</h4>
                <Form.Check
                  type="radio"
                  name="sort"
                  id="A-Z"
                  label={<span>Sorting From A-Z</span>}
                  onChange={(e) => handleSortingChange(e)}
                />
                <Form.Check
                  type="radio"
                  name="sort"
                  id="Z-A"
                  label={<span>Sorting From Z-A</span>}
                  onChange={(e) => handleSortingChange(e)}
                />
              </div>
              <div>
                <h4>Weight Sorting</h4>
                <Form.Check
                  type="radio"
                  name="sort-weight"
                  id="1-100"
                  label={<span>Sorting From 1-100</span>}
                  onChange={(e) => handleWeightSorting(e)}
                />
                <Form.Check
                  type="radio"
                  name="sort-weight"
                  id="100-1"
                  label={<span>Sorting From 100-1</span>}
                  onChange={(e) => handleWeightSorting(e)}
                />
              </div>
              <div>
                <h4>Matrial Property Sorting</h4>
                <Form.Check
                  type="radio"
                  name="sort-matrial-property"
                  id="dried"
                  label={<span>Dried</span>}
                  onChange={(e) => handleMatriealSorting(e)}
                />
                <Form.Check
                  type="radio"
                  name="sort-matrial-property"
                  id="mix"
                  label={<span>MIX</span>}
                  onChange={(e) => handleMatriealSorting(e)}
                />
                <Form.Check
                  type="radio"
                  name="sort-matrial-property"
                  id="frozen"
                  label={<span>Frozen</span>}
                  onChange={(e) => handleMatriealSorting(e)}
                />
              </div>
            </div>

          </Form>

          <div className="list-info">
            <span>{`Total Number Of Products In This Category : ${ImportPrice?._metadata?.count ? ImportPrice?._metadata?.count : 0}`}</span>
          </div>

          <div className="d-flex">
            {generatedExcelFile.pathFile ? (
              <Button variant="success" onClick={() => getFile()}>
                Download Agent Prices As Excel
              </Button>
            ) : (
              <Button onClick={() => generatePricesExcel()}>
                Generate Agent Prices As Excel
              </Button>
            )}
             <Button variant="danger" id="verify-btn" >
              Import Excel
            </Button>
            <Button variant="outline-warning" onClick={PostAgentProductsPrices}>
              Post Agent Products Prices
            </Button>
          </div>

          <DataTable
            fixedHeader
            responsive
            subHeaderWrap
            highlightOnHover
            columns={columns}
            customStyles={customStyles}
            data={ImportPrice?.data}
          />


        </div>
      </Row>
    </section>
  );
};

export default CRPrices;
