const sidebarNav = [
  {
    link: "/",
    section: "home",
    text: "Home",
  },
  {
    link: "/dashboard/cragents",
    section: "agents",
    text: "Visitors",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/address",
        section: "Shipment",
        textLink: "Potential visitors",
        sublinks2: [
          {
            id: 1,
            link: "/dashboard/websitevisitors",
            section: "Shipment",
            text: "Website visitors",
          },
          {
            id: 1,
            link: "/dashboard/applicationvisitors",
            section: "Shipment",
            text: "Application visitors",
          }
        ]
      },
      {
        id: 1,
        link: "/dashboard/address",
        section: "Shipment",
        textLink: "Actual visitors",
        sublinks2: [
          {
            id: 1,
            link: "/dashboard/cragents",
            section: "agents",
            text: "Create Agent",
          },
          {
            id: 2,
            link: "/dashboard/verifiedAgents",
            section: "agents",
            text: "Verify Agent",
          },
          {
            id: 2,
            link: "/dashboard/unverifiedAgents",
            section: "agents",
            text: "UnVerify Agent",
          },
        ]
      },

    ],
  },
  {
    link: "/dashboard/addaddress",
    section: "aboutUs",
    text: "Address",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/address",
        section: "Shipment",
        text: "Address",
      },
      {
        id: 1,
        link: "/dashboard/addaddress",
        section: "Shipment",
        text: "Add Address",
      },

    ]
  },

  {
    link: "/dashboard/craboutUs",
    section: "aboutUs",
    text: "About Us",
  },
  {
    link: "/dashboard/crproducts",
    section: "products",

    text: "Products",
  },
  {
    link: "/dashboard/crcategories",
    section: "products",
    text: "Sorting",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/crcategories/drag",
        section: "Shipment",
        text: "Categories",
      },
      {
        id: 1,
        link: "/dashboard/crproducts/drag",
        section: "Products",
        text: "Products",
      },
      {
        id: 1,
        link: "/dashboard/new/drag",
        section: "Products",
        text: "Our New",
      },
      {
        id: 1,
        link: "/dashboard/verifiedAgents/drag",
        section: "Products",
        text: "Verified Agents",
      },
      {
        id: 1,
        link: "/dashboard/unverifiedAgents/drag",
        section: "Products",
        text: " Un Verified Agents",
      },
      {
        id: 1,
        link: "/dashboard/crouractivities/drag",
        section: "Products",
        text: "Activities",
      },
      {
        id: 1,
        link: "/dashboard/bannerimages/drag",
        section: "Products",
        text: "Banner",
      },
      {
        id: 1,
        link: "/dashboard/crbites/drag",
        section: "Products",
        text: "Bites",
      },
      {
        id: 1,
        link: "/dashboard/craboutUs/drag",
        section: "Products",
        text: "About Us",
      },
      {
        id: 1,
        link: "/dashboard/crpartners/drag",
        section: "Products",
        text: "Partners",
      },
      {
        id: 1,
        link: "/dashboard/croffers/drag",
        section: "Products",
        text: "Offers",
      },
      {
        id: 1,
        link: "/dashboard/crexportcountries/drag",
        section: "Products",
        text: "Export Countries",
      },
      {
        id: 1,
        link: "/dashboard/crimportcountries/drag",
        section: "Products",
        text: "Import Countries",
      },
    ]
  },
  {
    link: "/dashboard/crcategories",
    section: "products",
    text: "Categories",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/crcategories",
        section: "Shipment",
        text: "Categories WepSite",
      },
      {
        id: 1,
        link: "/dashboard/crcategoriesalameen",
        section: "Shipment",
        text: "Categories AlAmeen",
      },
      {
        id: 1,
        link: "/dashboard/crcategoryorganizationCoding",
        section: "Shipment",
        text: "Category GSI",
      },
    ]
  },
  {
    link: "/dashboard/crcontactus",
    section: "products",

    text: "Contact Us",
  },
  {
    link: "/dashboard/crbites",
    section: "products",
    text: "Bites",
  },
  {
    link: "/dashboard/crpartners",
    section: "products",

    text: "Partners",
  },
  {
    link: "/dashboard/crouractivities",
    section: "products",

    text: "Activities",
  },
  {
    link: "/dashboard/crprices",
    section: "products",

    text: "Prices",
  },
  {
    link: "/dashboard/crprices3",
    section: "products",
    text: "Prices",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/crprices3",
        section: "Shipment",
        text: "Price names",
      },
      {
        id: 1,
        link: "/dashboard/crprices2",
        section: "Shipment",
        text: "Prices",
      },
      {
        id: 1,
        link: "/dashboard/agentprice",
        section: "Shipment",
        text: "Agent Price",
      },
    ]
  },
  {
    link: "/dashboard/croffers",
    section: "products",

    text: "Offers",
  },

  {
    link: "/dashboard/crcontainers",
    section: "products",

    text: "Containers",
  },
  {
    link: "/dashboard/suggestionshipments",
    section: "products",
    text: "Shipments",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/suggestionshipments",
        section: "Shipment",
        text: "Suggestion Shipments",
      },
      {
        id: 2,
        link: "/dashboard/waitingshippingshipment",
        section: "Shipment",
        text: "Waiting Shipping Shipment",
        sublinks2: [
          {
            link: "/dashboard/uploadshipmentfiles",
            section: "uploadshipmentfiles",
            text: "Upload Shipment Files",
          },
          {
            link: "/dashboard/shippingterms",
            section: "shipmentterms",

            text: "Agent Shipping Terms",
          },
        ]
      },
      {
        id: 3,
        link: "/dashboard/waitingpaymentshipment",
        section: "Shipment",
        text: "Waiting Payment Shipment",
      },
      {
        id: 4,
        link: "/dashboard/finishshipment",
        section: "Shipment",
        text: "Finish Shipment",
      },
      {
        id: 5,
        link: "/dashboard/freezedshipments",
        section: "Shipment",
        text: "Freezed Shipments",
      },
    ],
  },
  {
    link: "/dashboard/crexportcountries",
    section: "countires",
    text: "Countries",
    sublinks: [
      {
        id: 1,
        path2: "/dashboard/crexportcountries",
        section: "Countries",
        textLink: "Export Countries",
        sublinks2: [
          {
            id: 1,
            link: "/dashboard/crexportcountriescategoriesandproducts",
            section: "Countries",
            text: "Export Countries Products & Categories",
          },
          {
            id: 2,
            link: "/dashboard/exportcountriesstatescontainers",
            section: "Countries",
            text: "Export States Containers",
          },
        ],
      },

      {
        id: 3,
        path2: "/dashboard/crimportcountries",
        section: "Countries",
        textLink: "Import Countries",
        sublinks2: [
          {
            id: 1,
            link: "/dashboard/crimportcountriesstate",
            section: "Countries",
            text: "Add Import Country State",
          },
          {
            id: 2,
            link: "/dashboard/crimportcountrycontainer",
            section: "Countries",
            text: "Add Import Country State Container",
          },
          {
            id: 3,
            link: "/dashboard/crimportcountryagents",
            section: "Countries",
            text: "Add Import Country State Agents",
          },
        ],
      },
    ],
  },

  {
    link: "/dashboard/crdeliverymethod",
    section: "DeliveryMethod",

    text: "Delivery Methods",
  },
  {
    link: "/dashboard/bannerimages",
    section: "BannerImages",

    text: "Banner Images",
  },
  {
    link: "/dashboard/ratings",
    section: "ratings",
    text: "Ratings",
  },
  {
    link: "/dashboard/paymentaccount",
    section: "paymentaccount",
    text: "Payment Account",
  },
  {
    link: "/dashboard/cradmins",
    section: "products",
    text: "Admins",
  },
  {
    link: "/dashboard/app-version",
    section: "products",
    text: "App Version",
  },
  {
    link: "/dashboard/pallet",
    section: "products",
    text: "Pallet",
  },
  {
    link: "/dashboard/advertisements",
    section: "products",
    text: "Advertisements",
  },
  {
    link: "/dashboard/promotionalmaterials",
    section: "products",
    text: "PromotionalMaterials",
  },
  {
    link: "/dashboard/upload-video",
    section: "products",
    text: "Upload Video",
  },
  {
    link: "/dashboard/events",
    section: "products",
    text: "ُEvents",
  },

  {
    link: "/dashboard/session-admin",
    section: "Sessions",
    text: "Sessions",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/session-admin",
        section: "Shipment",
        text: "SessionsAdmin",
      },
      {
        id: 1,
        link: "/dashboard/session-agent",
        section: "Shipment",
        text: "SessionsAgent",
      },
    ]
  },

];

export default sidebarNav;
