import React, { useEffect, useState } from "react";
//boostrap
import { Button, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";

//components
import Notification from "../../../Components/Notification/Notification";
import { customStyles } from "../../constants/DataTableCustomeStyles";
const UploadShipmentFiles = () => {
  const [shipments, setShipments] = useState([]);
  const [shipmentId, setShipmentId] = useState("");

  const [TelexRelaseShow, setTelexRelaseShow] = useState(false);

  const handleCloseTelexRelease = () => setTelexRelaseShow(false);
  const handleShowTelexRelease = () => setTelexRelaseShow(true);

  const [telexRelease, setTelexRelease] = useState({
    telexRelease: null,
  });
  const [uploadFilesModalShow, setUploadFilesModalShow] = React.useState(false);
  const handleFilesClose = () => setUploadFilesModalShow(false);
  const handleFilesShow = () => setUploadFilesModalShow(true);
  const [FilesShipmentId, setFilesShipmentId] = useState("");
  const [shippingPolicy, setShippingPolicy] = useState({
    shippingPolicy: null,
  });
  const [finalInvoice, setFinalInvoice] = useState({
    finalInvoice: null,
  });
  const [packingList, setPackingList] = useState({
    packingList: null,
  });
  const [healthCertificate, setHealthCertificate] = useState({
    healthCertificate: null,
  });
  const handleTelexRelease = (e) => {
    setTelexRelease({
      telexRelease: e.target.files[0],
    });
  };
  //=======================Shipping Policy Handeling===============
  const handleShippingPolicyChange = (e) => {
    setShippingPolicy({
      shippingPolicy: e.target.files[0],
    });
  };
  //=======================finalInvoice Handeling===============
  const handleFinalInvoiceChange = (e) => {
    setFinalInvoice({
      finalInvoice: e.target.files[0],
    });
  };
  //=======================packingList Handeling===============

  const handlePackingListChange = (e) => {
    setPackingList({
      packingList: e.target.files[0],
    });
  };
  //=======================Health Certificate Handeling===============

  const handleHealthCertificateChange = (e) => {
    setHealthCertificate({
      healthCertificate: e.target.files[0],
    });
  };
  //==========================Upload Telex Release=======================
  const uploadTelexRelease = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    const formdata = new FormData();
    formdata.append(
      "file",
      telexRelease.telexRelease,
      telexRelease.telexRelease.name
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/telex-release/${shipmentId}`,
      requestOptions
    );
    if (response.ok) {
      alert("Telex Release Has Been Uploaded");
    } else {
      alert("Telex Release NOT UPLOADED");
      console.log(response);
    }
  };
  //============================ Get all shipments=====================

  useEffect(() => {
    const getAllShipments = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/shipment/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setShipments([]);
        console.log(body);
      } else {
        console.log(body);
        setShipments(body);
      }
    };
    getAllShipments();
  }, []);

  //========================Upload Shiping policy============
  const uploadShippingPolicy = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );
      const formdata = new FormData();
      formdata.append(
        "file",
        shippingPolicy.shippingPolicy,
        shippingPolicy.shippingPolicy.name
      );
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      const FileResponse = await fetch(
        `https://www.api.setalkel.co/shipment/shipping-policy/${FilesShipmentId}`,
        requestOptions
      );
      if (FileResponse.ok) {
        alert("Shipping Policy Has Been Uploaded");
      } else {
        alert("Shipping Policy NOT UPLOADED");
        console.log(FileResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //========================Upload Final Invoice=====================
  const uploadFinalInvoice = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    const formdata = new FormData();
    formdata.append(
      "file",
      finalInvoice.finalInvoice,
      finalInvoice.finalInvoice.name
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/final-invoice/${FilesShipmentId}`,
      requestOptions
    );
    if (response.ok) {
      alert("Final Invoice Has Been Uploaded");
    } else {
      alert("Final Invoice NOT UPLOADED");
      console.log(response);
    }
  };
  //========================Upload packingList=======================
  const uploadPackingList = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    const formdata = new FormData();
    formdata.append(
      "file",
      packingList.packingList,
      packingList.packingList.name
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/packing-list/${FilesShipmentId}`,
      requestOptions
    );
    if (response.ok) {
      alert("Packing List Has Been Uploaded");
    } else {
      alert("Packing List NOT UPLOADED");
      console.log(response);
    }
  };
  //========================Upload healthCertificate=======================
  const uploadHealthCertificate = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    const formdata = new FormData();
    formdata.append(
      "file",
      healthCertificate.healthCertificate,
      healthCertificate.healthCertificate.name
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/health-certificate/${FilesShipmentId}`,
      requestOptions
    );
    if (response.ok) {
      alert("Health Certificate Has Been Uploaded");
    } else {
      alert("Health Certificate NOT UPLOADED");
      console.log(response);
    }
  };
  //=================================================================================================
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Status",
      selector: (row) => <span>{row.status}</span>,
    },
    {
      name: "Container Details",
      selector: (row) => (
        <div>
          <ul className="p-0">
            <li>{`Type : ${row.containerInfo.container.containerType}`}</li>
            <li>
              {`Fridge : ${
                row.containerInfo.container.isFridge ? "Fridge" : "Not Fridge"
              }`}
            </li>
            <li>{`Max Vol : ${row.containerInfo.container.maxVolume}`}</li>
            <li>{`Max Weight: ${row.containerInfo.container.maxWeight}`}</li>
            <li>{`Max Weight State : ${row.containerInfo.maxWeightInState}`}</li>
          </ul>
        </div>
      ),
    },
    {
      name: "Agent Info",
      selector: (row) => (
        <span>
          <span>{row.agent.name}</span>
          <br />
          <span>{row.agent.email}</span>
          <br />
          <span>{row.agent.mobilePhone}</span>
          <br />
          <span>{row.agent.companyName}</span>
        </span>
      ),
    },
    {
      name: "Export Country",
      selector: (row) => (
        <span>
          {`Country Code : ${row.exportCountry.countryCode}`}
          <br />
          {`Country Name : ${row.exportCountry.countryName.en}`}
        </span>
      ),
    },
    {
      name: "Import Country",
      selector: (row) => (
        <span>
          {`Country Code : ${row.importCountry.countryCode}`}
          <br />
          {`Country Name : ${row.importCountry.countryName.en}`}
        </span>
      ),
    },
    {
      name: "Import State",
      selector: (row) => <span>{`${row.state.stateName.en}`}</span>,
    },
    //     {
    //       name: "Shipment Suggestion Files",
    //       selector: (row) => <div>
    //  {row.adminFiles.map((file, index) => {
    //                           return (
    //                             <Button
    //                               key={index}
    //                               variant="outline-warning"
    //                               onClick={() => getFile(file)}
    //                             >
    //                               {file.slice(16)}
    //                             </Button>
    //                           );
    //                         })}
    //       </div>,
    //     },
    {
      name: "Upload Telex Relase",
      selector: (row) => (
        <div>
          <Button
            variant="warning"
            onClick={() => {
              handleShowTelexRelease();
              setShipmentId(row._id);
            }}
          >
            Upload Telex Relase
          </Button>
          <Modal show={TelexRelaseShow} onHide={handleCloseTelexRelease}>
            <Modal.Header closeButton>
              <Modal.Title>Telex Release</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Telex Release</Form.Label>
                  <Form.Control
                    type="file"
                    name="telexRelease"
                    onChange={handleTelexRelease}
                    autoFocus
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseTelexRelease}>
                Close
              </Button>
              <Button
                variant="warning"
                onClick={async () => {
                  await uploadTelexRelease();
                  window.location.reload();
                }}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
    {
      name: "Upload Shipment Files",
      selector: (row) => (
        <div>

            <>
              <Button
                variant="warning"
                onClick={() => {
                  handleFilesShow();
                  setFilesShipmentId(row._id);
                }}
              >
                Upload Files
              </Button>

            
            </>
          
        </div>
      ),
    },
  ];
  return (
    <section className="waiting-payment-shipping">
      <Notification />
      <Modal show={uploadFilesModalShow} onHide={handleFilesClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Upload Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Shipping Policy</Form.Label>
                      <Form.Control
                        type="file"
                        name="shippingPolicy"
                        onChange={handleShippingPolicyChange}
                        placeholder="Shipping Policy"
                        autoFocus
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Final Invoice</Form.Label>
                      <Form.Control
                        type="file"
                        name="shippingPolicy"
                        onChange={handleFinalInvoiceChange}
                        placeholder="Final Invoice"
                        autoFocus
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Packing List</Form.Label>
                      <Form.Control
                        type="file"
                        name="shippingPolicy"
                        onChange={handlePackingListChange}
                        placeholder="Packing List"
                        autoFocus
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Health Certificate</Form.Label>
                      <Form.Control
                        type="file"
                        name="healthCertificate"
                        onChange={handleHealthCertificateChange}
                        placeholder="Shipping Policy"
                        autoFocus
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFilesClose}>
                    Close
                  </Button>
                  <Button
                    variant="warning"
                    onClick={async () => {
                      if (shippingPolicy.shippingPolicy) {
                        await uploadShippingPolicy();
                      }
                      if (finalInvoice.finalInvoice) {
                        await uploadFinalInvoice();
                      }
                      if (packingList.packingList) {
                        await uploadPackingList();
                      }
                      if (healthCertificate.healthCertificate) {
                        await uploadHealthCertificate();
                      }
                    }}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
      <Row>
        <h2>All Shipments</h2>
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={shipments}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
};

export default UploadShipmentFiles;
