import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//css
import "./CartCard.css";
//bootstrap
import { Col, Row } from "react-bootstrap";
//icons
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
//images
// import TrashImg from "../../assets/Payments/trash.png";
//redux
import {
  addToCart,
  decreaseCart,
  removeFromCart,
  addAmountToCart,
} from "../../redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//URL
const BaseURL = "https://www.api.setalkel.co";
const CartCard = ({ cartItem, shipmentOrder, setShipmentOrder }) => {
  const [docLang, setDocLang] = useState(document.querySelector("html").lang);
  const { t } = useTranslation();
  useEffect(() => {
    setDocLang(document.querySelector("html").lang);
  }, [t]);
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  //=======================Check Over Load============================
  // useEffect(() => {
  //   const addQuantityBtn = document.getElementById("addQuantity");
  //   cart.shipmentInRange === true && addQuantityBtn.disabled;
  // }, []);
  //=======================add Amount Handle============================

  const handleChange = (e, cartItem) => {
    if (e.target.value > 0) {
      dispatch(
        addAmountToCart({
          amount: parseInt(e.target.value),
          cartItem: cartItem,
        })
      );
    }
  };
  //=============================Add To Cart=================================
  const handleAddToCart = (cartItem) => {
    dispatch(addToCart(cartItem));
  };
  //=============================Decrease From Cart=================================

  const handleDecreaseCart = (cartItem) => {
    dispatch(decreaseCart(cartItem));
  };
  //=============================Remove From Cart=================================

  const handleRemoveFromCart = (cartItem) => {
    dispatch(removeFromCart(cartItem));
  };
  //=============================Text Area Cart Card=================================

  const handleTextAreaChange = (e, cartItem) => {
    const tempShipmentOrder = [...shipmentOrder.order.items];
    let index = tempShipmentOrder.findIndex(
      (item) => item.product === cartItem.product
    );
    if (index !== -1) {
      tempShipmentOrder[index] = {
        ...tempShipmentOrder[index],
        note: e.target.value,
      };
    }
    setShipmentOrder({
      ...shipmentOrder,
      order: { ...shipmentOrder.order, items: tempShipmentOrder },
    });
  };

  return (
    <article className="cart-card" key={cartItem._id}>
      <div className="cart-card-section2">
        <div className="cart-card-dropdown">
          <button className="dropbtn">...</button>
          <div className="cart-card-dropdown-content">
            <Link
              to="#"
              style={{ color: "rgba(255, 127, 82, 1)" }}
              onClick={() => handleRemoveFromCart(cartItem)}
            >
              {t(`Components.cartCard.Delete`)}
            </Link>
          </div>
        </div>
        <img src={BaseURL + cartItem.img} alt="" />
      </div>
      <div className="cart-card-section1">
        <div className="cart-card-h1">
          <div className="cart-card-title">
            <div className="cart-card-title-info">
              <h5>
                {cartItem.subCat[docLang]
                  ? cartItem.subCat[docLang]
                  : cartItem.subCat.en}
              </h5>
              <p>
                {cartItem.subCat[docLang]
                  ? cartItem.subCat[docLang]
                  : cartItem.subCat.en}
              </p>
            </div>
            <div className="cart-card-title-totals">
              <span>{t(`Components.cartCard.SubTotalPrice`)}</span>
              <span>{`${
                cartItem.productPrice * cartItem.cartQuantity
                  ? (cartItem.productPrice * cartItem.cartQuantity).toFixed(2)
                  : 0
              } $`}</span>
            </div>
          </div>
          <hr id="product-card-hr" />
          <div className="cart-card-details">
            <div className="info-data">
              <div>
                <Row>
                  <Col>{t(`Components.cartCard.PieceWeight`)}</Col>
                  <Col>{`${
                    cartItem.peiceQty.grossWeightForPeice
                      ? cartItem.peiceQty.grossWeightForPeice
                      : 0
                  } ${cartItem.peiceQty.unit}`}</Col>
                </Row>
                <Row>
                  <Col>{t(`Components.cartCard.GrossWeight`)}</Col>
                  <Col>{`${
                    cartItem.packageGrossWeight
                      ? cartItem.packageGrossWeight
                      : 0
                  }`}</Col>
                </Row>
                <Row>
                  <Col>{t(`Components.cartCard.BoxVolume`)}</Col>
                  <Col>{cartItem.volume ? cartItem.volume : 0}</Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col>{t(`Components.cartCard.BoxPrice`)}</Col>
                  <Col>
                    {cartItem.productPrice
                      ? (cartItem.productPrice * cartItem.countInBox).toFixed(2)
                      : 0}
                  </Col>
                </Row>
                <Row>
                  <Col>{t(`Components.cartCard.CountInBox`)}</Col>
                  <Col>{cartItem.countInBox ? cartItem.countInBox : 0}</Col>
                </Row>
                <Row>
                  <Col>{t(`Components.cartCard.ProductPrice`)}</Col>
                  <Col>{`${
                    cartItem.productPrice ? cartItem.productPrice : 0
                  } $`}</Col>
                </Row>
              </div>
            </div>
            <div className="cart-card-qty">
              <p>{t(`Components.cartCard.PackageQuantity`)}</p>
              <div className="btn-group">
                <button
                  onClick={() => handleAddToCart(cartItem)}
                  id="addQuantity"
                >
                  <AiOutlinePlus />
                </button>
                <input
                  type="text"
                  name="quantity"
                  id="quantity"
                  placeholder="QTY"
                  maxLength="4"
                  value={cartItem.cartQuantity}
                  onChange={(e) => handleChange(e, cartItem)}
                />
                <button
                  onClick={() => handleDecreaseCart(cartItem)}
                  id="subtracktQuantity"
                >
                  <AiOutlineMinus />
                </button>
              </div>
            </div>
          </div>
        </div>
        {cart.isEditing === true ? (
          <div className="card-note">
            <textarea
              placeholder={t(`Components.cartCard.UpdateNote`)}
              onChange={(e) => handleTextAreaChange(e, cartItem)}
            />
          </div>
        ) : null}
      </div>
    </article>
  );
};

export default CartCard;
