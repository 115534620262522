import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//boostrap
import { Form, FormGroup } from "react-bootstrap";
//css
import "./AgentVerificationForm.css";
//images
import Person from "../../assets/Payments/person.png";
//components
import PopUp from "./AgentVerificationPopUp/PopUp";
//translation
import { useTranslation } from "react-i18next";
const AgentInfoForm = () => {
  const [companyName, setCompanyName] = useState("");
  const [files] = useState([]);
  const [fileSize, setFileSize] = useState(true);
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  const [fileUploadResponse, setFileUploadResponse] = useState(null);
  const navigate = useNavigate();
  const backToHome = () => {
    navigate("/");
  };
  const { t } = useTranslation();
  //=======================Verification Files Handeling===============
  const handleVerifyFilesChange = (e) => {
    files.push(e.target.files);
  };
  //========================Post Verification Files===========
  const PostVerificationFiles = async (e) => {
    if (files.length >= 4) {
      e.preventDefault();
      setFileSize(true);
      setFileUploadProgress(true);
      setFileUploadResponse(null);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("token")}`
      );
      var formdata = new FormData();
      for (let i = 0; i < files.length; i++) {
        if (files[i][0].size > 81920) {
          setFileSize(false);
          setFileUploadProgress(false);
          setFileUploadResponse(null);
          return;
        }

        formdata.append(`files`, files[i][0], files[i][0].name);
      }
      formdata.append("companyNameVerification", companyName);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      const response = await fetch(
        "https://www.api.setalkel.co/agent/verification",
        requestOptions
      );
      // const isJson = response.headers
      //   .get("content-type")
      //   ?.includes("application/json");
      // const data = isJson && (await response.json());
      if (!response.ok) {
        // const error = (data && data.message) || response.status;
        // setFileUploadResponse(data.message);
        // alert(t(`Components.AgentVerificationForm.ErrorMsg`) + "<= 8Mb");
        // setFileUploadProgress(false);
        // return Promise.reject(error);
      } else {
        // setFileUploadResponse(data.message);
        alert(t(`Components.AgentVerificationForm.ThanksMsg`));
        window.location.reload();
        navigate("/");
      }
    } else {
      alert(t(`Components.AgentVerificationForm.AllFilesMsg`));
    }
  };

  return (
    <article className="agent-info">
      <PopUp />
      <h3>
        {t(`Components.AgentVerificationForm.Title`)}
        <img src={Person} alt={"person"} />
      </h3>
      <p>{t(`Components.AgentVerificationForm.Desc`)}</p>
      <div className="info-form">
        <Form>
          <div>
            <FormGroup>
              <Form.Label htmlFor="companyName">
                {t(`Components.AgentVerificationForm.CompanyName`)}
                <Form.Control
                  type="text"
                  name="companyName"
                  id="companyName"
                  placeholder={t(
                    `Components.AgentVerificationForm.CompanyName`
                  )}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </Form.Label>
            </FormGroup>
            <FormGroup>
              <Form.Label>
                {t(`Components.AgentVerificationForm.CommercialRegister`)}
                <Form.Control
                  type="file"
                  name="files"
                  onChange={handleVerifyFilesChange}
                  placeholder={t(
                    `Components.AgentVerificationForm.CommercialRegister`
                  )}
                  required
                />
              </Form.Label>
            </FormGroup>
            <FormGroup>
              <Form.Label>
                {t(`Components.AgentVerificationForm.Practicelicense`)}
                <Form.Control
                  type="file"
                  name="files"
                  onChange={handleVerifyFilesChange}
                  placeholder={t(
                    `Components.AgentVerificationForm.Practicelicense`
                  )}
                  required
                />
              </Form.Label>
            </FormGroup>
          </div>
          <div>
            <FormGroup>
              <Form.Label>
                {t(`Components.AgentVerificationForm.CompanyLogo`)}
                <Form.Control
                  type="file"
                  name="files"
                  onChange={handleVerifyFilesChange}
                  placeholder={t(
                    `Components.AgentVerificationForm.CompanyLogo`
                  )}
                  required
                />
              </Form.Label>
            </FormGroup>

            <FormGroup>
              <Form.Label>
                {t(`Components.AgentVerificationForm.Companyownerdocuments`)}
                <Form.Control
                  type="file"
                  name="files"
                  onChange={handleVerifyFilesChange}
                  placeholder={t(
                    `Components.AgentVerificationForm.Companyownerdocuments`
                  )}
                  required
                />
              </Form.Label>
            </FormGroup>
          </div>
        </Form>
      </div>
      <div className="btn-group">
        <button type="button" onClick={PostVerificationFiles}>
          {t(`Components.AgentVerificationForm.Save&ContinueBtn`)}
        </button>
        <button onClick={() => backToHome()}>
          {t(`Components.AgentVerificationForm.CloseBtn`)}
        </button>
        {!fileSize && (
          <p style={{ color: "red" }}>
            {t(`Components.AgentVerificationForm.Filesizeexceedednote`) +
              " 8Mb"}
          </p>
        )}
        {fileUploadProgress && (
          <p style={{ color: "red" }}>
            {t(`Components.AgentVerificationForm.UploadingFiles`)}
          </p>
        )}
        {fileUploadResponse != null && (
          <p style={{ color: "green" }}>{fileUploadResponse}</p>
        )}
      </div>
    </article>
  );
};

export default AgentInfoForm;
