export const ARRAYOFCountries = [

  { label: "Afghanistan", value: "Afghanistan" },
  { label: "land Islands", value: "land Islands" },
  { label: "Albania", value: "Albania" },
  { label: "Algeria", value: "Algeria" },
  { label: "American Samoa", value: "American Samoa" },
  { label: "AndorrA", value: "AndorrA" },
  { label: "Angola", value: "Angola" },
  { label: "Anguilla", value: "Anguilla" },
  { label: "Antarctica", value: "Antarctica" },
  { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  { label: "Argentina", value: "Argentina" },
  { label: "Armenia", value: "Armenia" },
  { label: "Aruba", value: "Aruba" },
  { label: "Australia", value: "Australia" },
  { label: "Austria", value: "Austria" },
  { label: "Azerbaijan", value: "Azerbaijan" },
  { label: "Bahamas", value: "Bahamas" },
  { label: "Bahrain", value: "Bahrain" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Barbados", value: "Barbados" },
  { label: "Belarus", value: "Belarus" },
  { label: "Belgium", value: "Belgium" },
  { label: "Belize", value: "Belize" },
  { label: "Benin", value: "Benin" },
  { label: "Bermuda", value: "Bermuda" },
  { label: "Bhutan", value: "Bhutan" },
  { label: "Bolivia", value: "Bolivia" },
  { label: "Bosnia and Herzegovina", value: "BosniaandHerzegovina" },
  { label: "Botswana", value: "Botswana" },
  { label: "Bouvet Island", value: "Bouvet Island" },
  { label: "Brazil", value: "Brazil" },


  {
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
  },
  { label: "Brunei Darussalam", value: "Brunei Darussalam" },
  { label: "Bulgaria", value: "Bulgaria" },
  { label: "Burkina Faso", value: "Burkina" },
  { label: "Burundi", value: "Burundi" },
  { label: "Cambodia", value: "Cambodia" },
  { label: "Cameroon", value: "Cameroon" },
  { label: "Canada", value: "Canada" },
  { label: "Cape Verde", value: "Cape Verde" },
  { label: "Cayman Islands", value: "Cayman Islands" },
  { label: "Central African Republic", value: "Central African Republic" },
  { label: "Chad", value: "Chad" },
  { label: "Chile", value: "Chile" },
  { label: "China", value: "China" },
  { label: "Christmas Island", value: "Christmas Island" },
  { label: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
  { label: "Colombia", value: "Colombia" },
  { label: "Comoros", value: "Comoros" },
  { label: "Congo", value: "Congo" },
  {
    label: "Congo, The Democratic Republic of the",
    value: "Congo, The Democratic Republic of the",
  },
  { label: "Cook Islands", value: "Cook Islands" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Cote D'Ivoire", value: "Cote D'Ivoire" },
  { label: "Croatia", value: "Croatia" },
  { label: "Cuba", value: "Cuba" },
  { label: "Cyprus", value: "Cyprus" },
  { label: "Czech Republic", value: "Czech Republic" },
  { label: "Denmark", value: "Denmark" },
  { label: "Djibouti", value: "Djibouti" },
  { label: "Dominica", value: "Dominica" },
  { label: "Dominican Republic", value: "Dominican Republic" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Egypt", value: "Egypt" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Equatorial Guinea", value: "Equatorial Guinea" },
  { label: "Eritrea", value: "Eritrea" },
  { label: "Estonia", value: "Estonia" },
  { label: "Ethiopia", value: "Ethiopia" },
  { label: "European Union", value: "European Union" },







  {
    label: "Falkland Islands (Malvinas)",
    value: "Falkland Islands (Malvinas)",
  },
  { label: "Faroe Islands", value: "Faroe Islands" },
  { label: "Fiji", value: "Fiji" },
  { label: "Finland", value: "Finland" },
  { label: "France", value: "France" },
  { label: "French Guiana", value: "French Guiana" },
  { label: "French Polynesia", value: "French Polynesia" },
  {
    label: "French Southern Territories",
    value: "French Southern Territories",
  },
  { label: "Gabon", value: "Gabon" },
  { label: "Gambia", value: "Gambia" },
  { label: "Georgia", value: "Georgia" },
  { label: "Germany", value: "Germany" },
  { label: "Ghana", value: "Ghana" },
  { label: "Gibraltar", value: "Gibraltar" },
  { label: "Greece", value: "Greece" },
  { label: "Greenland", value: "Greenland" },
  { label: "Grenada", value: "Grenada" },
  { label: "Guadeloupe", value: "Guadeloupe" },
  { label: "Guam", value: "Guam" },
  { label: "Guatemala", value: "Guatemala" },
  { label: "Guernsey", value: "Guernsey" },
  { label: "Guinea", value: "Guinea" },
  { label: "Guinea-Bissau", value: "Guinea-Bissau" },
  { label: "Guyana", value: "Guyana" },
  { label: "Haiti", value: "Haiti" },
  {
    label: "Heard Island and Mcdonald Islands",
    value: "Heard Island and Mcdonald Islands",
  },
  {
    label: "Holy See (Vatican City State)",
    value: "Holy See (Vatican City State)",
  },
  { label: "Honduras", value: "Honduras" },
  { label: "Hong Kong", value: "Hong Kong" },
  { label: "Hungary", value: "Hungary" },
  { label: "Iceland", value: "Iceland" },
  { label: "India", value: "India" },
  { label: "Indonesia", value: "Indonesia" },
  { label: "Iran, Islamic Republic Of", value: "Iran, Islamic Republic Of" },
  { label: "Iraq", value: "Iraq" },
  { label: "Ireland", value: "Ireland" },
  { label: "Isle of Man", value: "Isle of Man" },
  { label: "Italy", value: "Italy" },
  { label: "Jamaica", value: "Jamaica" },
  { label: "Japan", value: "Japan" },
  { label: "Jersey", value: "Jersey" },
  { label: "Jordan", value: "Jordan" },
  { label: "Kazakhstan", value: "Kazakhstan" },
  { label: "Kenya", value: "Kenya" },
  { label: "Kiribati", value: "Kiribati" },
  {
    label: "Korea, Democratic People'S Republic of",
    value: "Korea, Democratic People'S Republic of",
  },
  { label: "Korea, Republic of", value: "Korea, Republic of" },
  { label: "Kuwait", value: "Kuwait" },
  { label: "Kyrgyzstan", value: "Kyrgyzstan" },
  {
    label: "Lao People'S Democratic Republic",
    value: "Lao People'S Democratic Republic",
  },
  { label: "Latvia", value: "Latvia" },
  { label: "Lebanon", value: "Lebanon" },
  { label: "Lesotho", value: "LLesothoS" },
  { label: "Liberia", value: "Liberia" },
  { label: "Libyan Arab Jamahiriya", value: "Libyan Arab Jamahiriya" },
  { label: "Liechtenstein", value: "Liechtenstein" },
  { label: "Lithuania", value: "Lithuania" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Macao", value: "Macao" },
  {
    label: "Macedonia, The Former Yugoslav Republic of",
    value: "Macedonia, The Former Yugoslav Republic of",
  },
  { label: "Madagascar", value: "Madagascar" },
  { label: "Malawi", value: "Malawi" },
  { label: "Malaysia", value: "Malaysia" },
  { label: "Maldives", value: "Maldives" },
  { label: "Mali", value: "Mali" },
  { label: "Malta", value: "Malta" },
  { label: "Marshall Islands", value: "Marshall Islands" },
  { label: "Martinique", value: "Martinique" },
  { label: "Mauritania", value: "Mauritania" },
  { label: "Mauritius", value: "Mauritius" },
  { label: "Mayotte", value: "Mayotte" },
  { label: "Mexico", value: "Mexico" },
  {
    label: "Micronesia, Federated States of",
    value: "Micronesia, Federated States of",
  },
  { label: "Moldova, Republic of", value: "Moldova, Republic of" },
  { label: "Monaco", value: "Monaco" },
  { label: "Mongolia", value: "Mongolia" },
  { label: "Montenegro", value: "Montenegro" },
  { label: "Montserrat", value: "Montserrat" },
  { label: "Morocco", value: "Morocco" },
  { label: "Mozambique", value: "Mozambique" },
  { label: "Myanmar", value: "Myanmar" },
  { label: "Namibia", value: "Namibia" },
  { label: "Nauru", value: "Nauru" },
  { label: "Nepal", value: "Nepal" },
  { label: "Netherlands", value: "Netherlands" },
  { label: "Netherlands Antilles", value: "Netherlands Antilles" },
  { label: "New Caledonia", value: "New Caledonia" },
  { label: "New Zealand", value: "New Zealand" },
  { label: "Nicaragua", value: "Nicaragua" },
  { label: "Niger", value: "Niger" },
  { label: "Nigeria", value: "Nigeria" },
  { label: "Niue", value: "Niue" },
  { label: "Norfolk Island", value: "Norfolk Island" },
  { label: "Northern Mariana Islands", value: "Northern Mariana Islands" },
  { label: "Norway", value: "Norway" },
  { label: "Oman", value: "Oman" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Palau", value: "Palau" },




  {
    label: "Palestinian Territory, Occupied",
    value: "Palestinian Territory, Occupied",
  },
  { label: "Panama", value: "Panama" },
  { label: "Papua New Guinea", value: "Papua New Guinea" },
  { label: "Paraguay", value: "Paraguay" },
  { label: "Peru", value: "Peru" },
  { label: "Philippines", value: "Philippines" },
  { label: "Pitcairn", value: "Pitcairn" },
  { label: "Poland", value: "Poland" },
  { label: "Portugal", value: "Portugal" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Qatar", value: "Qatar" },
  { label: "Reunion", value: "Reunion" },
  { label: "Romania", value: "Romania" },
  { label: "Russian Federation", value: "Russian Federation" },
  { label: "RWANDA", value: "RWANDA" },
  { label: "Saint Helena", value: "Saint Helena" },
  { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { label: "Saint Lucia", value: "Saint Lucia" },
  { label: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },



  { label: "Samoa", value: "Samoa" },
  { label: "San Marino", value: "San Marino" },
  { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "Senegal", value: "Senegal" },
  { label: "Serbia", value: "Serbia" },
  { label: "Seychelles", value: "Seychelles" },
  { label: "Sierra Leone", value: "Sierra Leone" },
  { label: "Singapore", value: "Singapore" },
  { label: "Slovakia", value: "Slovakia" },
  { label: "Slovenia", value: "Slovenia" },
  { label: "Solomon Islands", value: "Solomon Islands" },
  { label: "Somalia", value: "Somalia" },
  { label: "South Africa", value: "South Africa" },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands",
  },
  { label: "Spain", value: "Spain" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "Sudan", value: "Sudan" },
  { label: "Surilabel", value: "Surilabel" },
  { label: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
  { label: "Swaziland", value: "Swaziland" },
  { label: "Sweden", value: "Sweden" },
  { label: "Switzerland", value: "Switzerland" },
  { label: "Syria", value: "Syria" },
  { label: "Taiwan, Province of China", value: "Taiwan, Province of China" },
  { label: "Tajikistan", value: "Tajikistan" },
  { label: "Tanzania, United Republic of", value: "Tanzania" },
  { label: "Thailand", value: "Thailand" },
  { label: "Timor-Leste", value: "Timor-Leste" },
  { label: "Togo", value: "Togo" },
  { label: "Tokelau", value: "Tokelau" },
  { label: "Tonga", value: "Tonga" },
  { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  { label: "Tunisia", value: "Tunisia" },
  { label: "Turkey", value: "Turkey" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  { label: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
  { label: "Tuvalu", value: "Tuvalu" },
  { label: "Uganda", value: "Uganda" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "United Arab Emirates", value: "United Arab Emirates" },
  { label: "United Kingdom", value: "United Kingdom" },
  { label: "United States", value: "United States" },
  {
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands",
  },
  { label: "Uruguay", value: "Uruguay" },
  { label: "Uzbekistan", value: "Uzbekistan" },
  { label: "Vanuatu", value: "Vanuatu" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Viet Nam", value: "Viet Nam" },
  { label: "Virgin Islands, British", value: "Virgin Islands, British" },
  { label: "Virgin Islands, U.S.", value: "Virgin Islands, U.S." },
  { label: "Wallis and Futuna", value: "Wallis and Futuna" },
  { label: "Western Sahara", value: "Western Sahara" },
  { label: "Yemen", value: "Yemen" },
  { label: "Zambia", value: "Zambia" },
  { label: "Zimbabwe", value: "Zimbabwe" },
];




export const ARRAYOFCountriesAR = [

  {
    label: "فلسطين ",
    value: " فلسطين ",
  },
  { label: "بنما", value: "بنما" },
  { label: "بابوا غينيا الجديدة", value: "بابوا غينيا الجديدة" },
  { label: "باراغواي", value: "باراغواي" },
  { label: "بيرو", value: "بيرو" },
  { label: "الفلبين", value: "الفلبين" },
  { label: "بيتكيرن", value: "بيتكيرن" },
  { label: "بولندا", value: "بولندا" },
  { label: "البرتغال", value: "البرتغال" },
  { label: "بورتوريكو", value: "بورتوريكو" },
  { label: "قطر", value: "قطر" },
  { label: "لا ريونيون", value: "لا ريونيون" },
  { label: "رومانيا", value: "رومانيا" },
  { label: "الاتحاد الروسي", value: "الاتحاد الروسي" },
  { label: "رواندا", value: "رواندا" },
  { label: "سانت هيلينا", value: "سانت هيلينا" },
  { label: "سانت كيتس ونيفس", value: "سانت كيتس ونيفس" },
  { label: "سانت لوسيا", value: "سانت لوسيا" },
  { label: "سانت بيير وميكلون", value: "سانت بيير وميكلون" },
  {
    label: "سانت فنسنت وجزر غرينادين",
    value: "سانت فنسنت وجزر غرينادين",
  },
  {
    label: "إقليم المحيط الهندي البريطاني",
    value: "إقليم المحيط الهندي البريطاني",
  },
  { label: "بروناي دار السلام", value: "بروناي دار السلام" },
  { label: "بلغاريا", value: "بلغاريا" },
  { label: "بوركينا فاسو", value: "بوركينا فاسو" },
  { label: "بوروندي", value: "بوروندي" },
  { label: "كمبوديا", value: "كمبوديا" },
  { label: "الكاميرون", value: "الكاميرون" },
  { label: "كندا", value: "كندا" },
  { label: "الرأس الأخضر", value: "الرأس الأخضر" },
  { label: "جزر كايمان", value: "جزر كايمان" },
  { label: "جمهورية أفريقيا الوسطى", value: "جمهورية أفريقيا الوسطى" },
  { label: "تشاد", value: "تشاد" },
  { label: "تشيلي", value: "تشيلي" },
  { label: "الصين", value: "الصين" },
  { label: "جزيرة الكريسماس", value: "جزيرة الكريسماس" },
  { label: "جزر كوكوس (كيلينغ)", value: "جزر كوكوس (كيلينغ)" },
  { label: "كولومبيا", value: "كولومبيا" },
  { label: "جزر القمر", value: "جزر القمر" },
  { label: "الكونغو", value: "الكونغو" },
  {
    label: "جمهورية الكونغو الديمقراطية",
    value: "جمهورية الكونغو الديمقراطية",
  },
  { label: "جزر كوك", value: "جزر كوك" },
  { label: "كوستاريكا", value: "كوستاريكا" },
  { label: "ساحل العاج", value: "ساحل العاج" },
  { label: "كرواتيا", value: "كرواتيا" },
  { label: "كوبا", value: "كوبا" },
  { label: "قبرص", value: "قبرص" },
  { label: "جمهورية التشيك", value: "جمهورية التشيك" },
  { label: "الدنمارك", value: "الدنمارك" },
  { label: "جيبوتي", value: "جيبوتي" },
  { label: "دومينيكا", value: "دومينيكا" },
  { label: "جمهورية الدومينيكان", value: "جمهورية الدومينيكان" },
  { label: "الإكوادور", value: "الإكوادور" },
  { label: "مصر", value: "مصر" },
  { label: "السلفادور", value: "السلفادور" },
  { label: "غينيا الاستوائية", value: "غينيا الاستوائية" },
  { label: "إريتريا", value: "إريتريا" },
  { label: "إستونيا", value: "إستونيا" },
  { label: "إثيوبيا", value: "إثيوبيا" },
  { label: "توفالو", value: "توفالو" },
  { label: "أوغندا", value: "أوغندا" },
  { label: "أوكرانيا", value: "أوكرانيا" },
  { label: "الإمارات العربية المتحدة", value: "الإمارات العربية المتحدة" },
  { label: "المملكة المتحدة", value: "المملكة المتحدة" },
  { label: "الولايات المتحدة", value: "الولايات المتحدة" },
  {
    label: "جزر الولايات المتحدة الصغيرة النائية",
    value: "جزر الولايات المتحدة الصغيرة النائية",
  },
  { label: "أوروغواي", value: "أوروغواي" },
  { label: "أوزبكستان", value: "أوزبكستان" },
  { label: "فانواتو", value: "فانواتو" },
  { label: "فنزويلا", value: "فنزويلا" },
  { label: "فيتنام", value: "فيتنام" },
  { label: "جزر العذراء البريطانية", value: "جزر العذراء البريطانية" },
  { label: "جزر العذراء الأمريكية", value: "جزر العذراء الأمريكية" },
  { label: "واليس وفوتونا", value: "واليس وفوتونا" },
  { label: "الصحراء الغربية", value: "الصحراء الغربية" },
  { label: "اليمن", value: "اليمن" },
  { label: "زامبيا", value: "زامبيا" },
  { label: "زيمبابوي", value: "زيمبابوي" },
  { label: "ساموا", value: "ساموا" },
  { label: "سان مارينو", value: "سان مارينو" },
  { label: "ساو تومي وبرينسيبي", value: "ساو تومي وبرينسيبي" },
  { label: "المملكة العربية السعودية", value: "المملكة العربية السعودية" },
  { label: "السنغال", value: "السنغال" },
  { label: "صربيا", value: "صربيا" },
  { label: "سيشيل", value: "سيشيل" },
  { label: "سيراليون", value: "سيراليون" },
  { label: "سنغافورة", value: "سنغافورة" },
  { label: "سلوفاكيا", value: "سلوفاكيا" },
  { label: "سلوفينيا", value: "سلوفينيا" },
  { label: "جزر سليمان", value: "جزر سليمان" },
  { label: "الصومال", value: "الصومال" },
  { label: "جنوب أفريقيا", value: "جنوب أفريقيا" },
  { label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية", value: "جورجيا الجنوبية وجزر ساندويتش الجنوبية" },
  { label: "إسبانيا", value: "إسبانيا" },
  { label: "سريلانكا", value: "سريلانكا" },
  { label: "السودان", value: "السودان" },
  { label: "سورينام", value: "سورينام" },
  { label: "سفالبارد وجان ماين", value: "سفالبارد وجان ماين" },
  { label: "سوازيلاند", value: "سوازيلاند" },
  { label: "السويد", value: "السويد" },
  { label: "سويسرا", value: "سويسرا" },
  { label: "سوريا", value: "سوريا" },
  { label: "تايوان، جمهورية الصين", value: "تايوان، جمهورية الصين" },
  { label: "طاجيكستان", value: "طاجيكستان" },
  { label: "تنزانيا، جمهورية المتحدة لل", value: "تنزانيا" },
  { label: "تايلاند", value: "تايلاند" },
  { label: "تيمور الشرقية", value: "تيمور الشرقية" },
  { label: "توغو", value: "توغو" },
  { label: "توكيلاو", value: "توكيلاو" },
  { label: "تونغا", value: "تونغا" },
  { label: "ترينيداد وتوباغو", value: "ترينيداد وتوباغو" },
  { label: "تونس", value: "تونس" },
  { label: "تركيا", value: "تركيا" },
  { label: "تركمانستان", value: "تركمانستان" },
  { label: "جزر توركس وكايكوس", value: "جزر توركس وكايكوس" },
  {
    label: "الإقليم البريطاني في المحيط الهندي",
    value: "الإقليم البريطاني في المحيط الهندي",
  },
  { label: "بروناي دار السلام", value: "بروناي دار السلام" },
  { label: "بلغاريا", value: "بلغاريا" },
  { label: "بوركينا فاسو", value: "بوركينا فاسو" },
  { label: "بوروندي", value: "بوروندي" },
  { label: "كمبوديا", value: "كمبوديا" },
  { label: "الكاميرون", value: "الكاميرون" },
  { label: "كندا", value: "كندا" },
  { label: "الرأس الأخضر", value: "الرأس الأخضر" },
  { label: "جزر كايمان", value: "جزر كايمان" },
  { label: "جمهورية أفريقيا الوسطى", value: "جمهورية أفريقيا الوسطى" },
  { label: "تشاد", value: "تشاد" },
  { label: "تشيلي", value: "تشيلي" },
  { label: "الصين", value: "الصين" },
  { label: "جزيرة الكريسماس", value: "جزيرة الكريسماس" },
  { label: "جزر كوكوس (كيلينغ)", value: "جزر كوكوس (كيلينغ)" },
  { label: "كولومبيا", value: "كولومبيا" },
  { label: "جزر القمر", value: "جزر القمر" },
  { label: "الكونغو", value: "الكونغو" },
  {
    label: "جمهورية الكونغو الديمقراطية",
    value: "جمهورية الكونغو الديمقراطية",
  },
  { label: "جزر كوك", value: "جزر كوك" },
  { label: "كوستاريكا", value: "كوستاريكا" },
  { label: "ساحل العاج", value: "ساحل العاج" },
  { label: "كرواتيا", value: "كرواتيا" },
  { label: "كوبا", value: "كوبا" },
  { label: "قبرص", value: "قبرص" },
  { label: "جمهورية التشيك", value: "جمهورية التشيك" },
  { label: "الدانمارك", value: "الدانمارك" },
  { label: "جيبوتي", value: "جيبوتي" },
  { label: "دومينيكا", value: "دومينيكا" },
  { label: "جمهورية الدومينيكان", value: "جمهورية الدومينيكان" },
  { label: "الإكوادور", value: "الإكوادور" },
  { label: "مصر", value: "مصر" },
  { label: "السلفادور", value: "السلفادور" },
  { label: "غينيا الاستوائية", value: "غينيا الاستوائية" },
  { label: "إريتريا", value: "إريتريا" },
  { label: "أفغانستان", value: "أفغانستان" },
  { label: "جزر الأراضي", value: "جزر الأراضي" },
  { label: "ألبانيا", value: "ألبانيا" },
  { label: "الجزائر", value: "الجزائر" },
  { label: "ساموا الأمريكية", value: "ساموا الأمريكية" },
  { label: "أندورا", value: "أندورا" },
  { label: "أنغولا", value: "أنغولا" },
  { label: "أنغويلا", value: "أنغويلا" },
  { label: "القارة القطبية الجنوبية", value: "القارة القطبية الجنوبية" },
  { label: "أنتيغوا وبربودا", value: "أنتيغوا وبربودا" },
  { label: "الأرجنتين", value: "الأرجنتين" },
  { label: "أرمينيا", value: "أرمينيا" },
  { label: "أروبا", value: "أروبا" },
  { label: "أستراليا", value: "أستراليا" },
  { label: "النمسا", value: "النمسا" },
  { label: "أذربيجان", value: "أذربيجان" },
  { label: "الباهاما", value: "الباهاما" },
  { label: "البحرين", value: "البحرين" },
  { label: "بنغلاديش", value: "بنغلاديش" },
  { label: "بربادوس", value: "بربادوس" },
  { label: "بيلاروس", value: "بيلاروس" },
  { label: "بلجيكا", value: "بلجيكا" },
  { label: "بليز", value: "بليز" },
  { label: "بنين", value: "بنين" },
  { label: "برمودا", value: "برمودا" },
  { label: "بوتان", value: "بوتان" },
  { label: "بوليفيا", value: "بوليفيا" },
  { label: "البوسنة والهرسك", value: "البوسنة والهرسك" },
  { label: "بوتسوانا", value: "بوتسوانا" },
  { label: "جزيرة بوفيه", value: "جزيرة بوفيه" },
  { label: "البرازيل", value: "البرازيل" },

];



export const ARRAYOFCountriesNL = [
  { label: "Afghanistan", value: "Afghanistan" },
  { label: "Ålandseilanden", value: "Ålandseilanden" },
  { label: "Albanië", value: "Albanië" },
  { label: "Algerije", value: "Algerije" },
  { label: "Amerikaans Samoa", value: "Amerikaans Samoa" },
  { label: "Andorra", value: "Andorra" },
  { label: "Angola", value: "Angola" },
  { label: "Anguilla", value: "Anguilla" },
  { label: "Antarctica", value: "Antarctica" },
  { label: "Antigua en Barbuda", value: "Antigua en Barbuda" },
  { label: "Argentinië", value: "Argentinië" },
  { label: "Armenië", value: "Armenië" },
  { label: "Aruba", value: "Aruba" },
  { label: "Australië", value: "Australië" },
  { label: "Oostenrijk", value: "Oostenrijk" },
  { label: "Azerbeidzjan", value: "Azerbeidzjan" },
  { label: "Bahama's", value: "Bahama's" },
  { label: "Bahrein", value: "Bahrein" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Barbados", value: "Barbados" },
  { label: "Belarus", value: "Belarus" },
  { label: "België", value: "België" },
  { label: "Belize", value: "Belize" },
  { label: "Benin", value: "Benin" },
  { label: "Bermuda", value: "Bermuda" },
  { label: "Bhutan", value: "Bhutan" },
  { label: "Bolivia", value: "Bolivia" },
  { label: "Bosnië en Herzegovina", value: "Bosnië en Herzegovina" },
  { label: "Botswana", value: "Botswana" },
  { label: "Bouveteiland", value: "Bouveteiland" },
  { label: "Brazilië", value: "Brazilië" },
  {
    label: "Britse Indische Oceaanterritorium",
    value: "Britse Indische Oceaanterritorium",
  },
  { label: "Brunei Darussalam", value: "Brunei Darussalam" },
  { label: "Bulgarije", value: "Bulgarije" },
  { label: "Burkina Faso", value: "Burkina" },
  { label: "Burundi", value: "Burundi" },
  { label: "Cambodja", value: "Cambodja" },
  { label: "Kameroen", value: "Kameroen" },
  { label: "Canada", value: "Canada" },
  { label: "Kaapverdië", value: "Kaapverdië" },
  { label: "Caymaneilanden", value: "Caymaneilanden" },
  { label: "Centraal-Afrikaanse Republiek", value: "Centraal-Afrikaanse Republiek" },
  { label: "Tsjaad", value: "Tsjaad" },
  { label: "Chili", value: "Chili" },
  { label: "China", value: "China" },
  { label: "Christmaseiland", value: "Christmaseiland" },
  { label: "Cocoseilanden", value: "Cocoseilanden" },
  { label: "Colombia", value: "Colombia" },
  { label: "Comoren", value: "Comoren" },
  { label: "Congo", value: "Congo" },
  {
    label: "Congo, Democratische Republiek",
    value: "Congo, Democratische Republiek",
  },
  { label: "Cookeilanden", value: "Cookeilanden" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Ivoorkust", value: "Ivoorkust" },
  { label: "Kroatië", value: "Kroatië" },
  { label: "Cuba", value: "Cuba" },
  { label: "Cyprus", value: "Cyprus" },
  { label: "Tsjechië", value: "Tsjechië" },
  { label: "Denemarken", value: "Denemarken" },
  { label: "Djibouti", value: "Djibouti" },
  { label: "Dominica", value: "Dominica" },
  { label: "Dominicaanse Republiek", value: "Dominicaanse Republiek" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Egypte", value: "Egypte" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Equatoriaal-Guinea", value: "Equatoriaal-Guinea" },
  { label: "Eritrea", value: "Eritrea" },
  { label: "Estland", value: "Estland" },
  { label: "Ethiopië", value: "Ethiopië" },
  { label: "Europese Unie", value: "Europese Unie" },

  {
    label: "Falklandeilanden (Malvinas)",
    value: "Falklandeilanden (Malvinas)",
  },
  { label: "Faeröer", value: "Faeröer" },
  { label: "Fiji", value: "Fiji" },
  { label: "Finland", value: "Finland" },
  { label: "Frankrijk", value: "Frankrijk" },
  { label: "Frans-Guyana", value: "Frans-Guyana" },
  { label: "Frans-Polynesië", value: "Frans-Polynesië" },
  {
    label: "Franse Zuidelijke en Antarctische Gebieden",
    value: "Franse Zuidelijke en Antarctische Gebieden",
  },
  { label: "Gabon", value: "Gabon" },
  { label: "Gambia", value: "Gambia" },
  { label: "Georgië", value: "Georgië" },
  { label: "Duitsland", value: "Duitsland" },
  { label: "Ghana", value: "Ghana" },
  { label: "Gibraltar", value: "Gibraltar" },
  { label: "Griekenland", value: "Griekenland" },
  { label: "Groenland", value: "Groenland" },
  { label: "Grenada", value: "Grenada" },
  { label: "Guadeloupe", value: "Guadeloupe" },
  { label: "Guam", value: "Guam" },
  { label: "Guatemala", value: "Guatemala" },
  { label: "Guernsey", value: "Guernsey" },
  { label: "Guinee", value: "Guinee" },
  { label: "Guinee-Bissau", value: "Guinee-Bissau" },
  { label: "Guyana", value: "Guyana" },
  { label: "Haïti", value: "Haïti" },
  {
    label: "Heard en McDonaldeilanden",
    value: "Heard en McDonaldeilanden",
  },
  {
    label: "Heilige Stoel (Vaticaanstad)",
    value: "Heilige Stoel (Vaticaanstad)",
  },
  { label: "Honduras", value: "Honduras" },
  { label: "Hongkong", value: "Hongkong" },
  { label: "Hongarije", value: "Hongarije" },
  { label: "IJsland", value: "IJsland" },
  { label: "India", value: "India" },
  { label: "Indonesië", value: "Indonesië" },
  { label: "Iran, Islamitische Republiek", value: "Iran, Islamitische Republiek" },
  { label: "Irak", value: "Irak" },
  { label: "Ierland", value: "Ierland" },
  { label: "Isle of Man", value: "Isle of Man" },
  { label: "Italië", value: "Italië" },
  { label: "Jamaica", value: "Jamaica" },
  { label: "Japan", value: "Japan" },
  { label: "Jersey", value: "Jersey" },
  { label: "Jordanië", value: "Jordanië" },
  { label: "Kazachstan", value: "Kazachstan" },
  { label: "Kenia", value: "Kenia" },
  { label: "Kiribati", value: "Kiribati" },
  {
    label: "Korea, Democratische Volksrepubliek",
    value: "Korea, Democratische Volksrepubliek",
  },
  { label: "Korea, Republiek", value: "Korea, Republiek" },
  { label: "Koeweit", value: "Koeweit" },
  { label: "Kirgizië", value: "Kirgizië" },
  {
    label: "Laos, Democratische Volksrepubliek",
    value: "Laos, Democratische Volksrepubliek",
  },
  { label: "Letland", value: "Letland" },
  { label: "Libanon", value: "Libanon" },
  { label: "Lesotho", value: "Lesotho" },
  { label: "Liberia", value: "Liberia" },
  { label: "Libische Arabische Republiek", value: "Libische Arabische Republiek" },
  { label: "Liechtenstein", value: "Liechtenstein" },
  { label: "Litouwen", value: "Litouwen" },
  { label: "Luxemburg", value: "Luxemburg" },
  { label: "Macao", value: "Macao" },
  {
    label: "Macedonië, Voormalige Joegoslavische Republiek",
    value: "Macedonië, Voormalige Joegoslavische Republiek",
  },
  { label: "Madagaskar", value: "Madagaskar" },
  { label: "Malawi", value: "Malawi" },
  { label: "Maleisië", value: "Maleisië" },
  { label: "Maldiven", value: "Maldiven" },
  { label: "Mali", value: "Mali" },
  { label: "Malta", value: "Malta" },
  { label: "Marshalleilanden", value: "Marshalleilanden" },
  { label: "Moldavië, Republiek", value: "Moldavië, Republiek" },
  { label: "Monaco", value: "Monaco" },
  { label: "Mongolië", value: "Mongolië" },
  { label: "Montenegro", value: "Montenegro" },
  { label: "Montserrat", value: "Montserrat" },
  { label: "Marokko", value: "Marokko" },
  { label: "Mozambique", value: "Mozambique" },
  { label: "Myanmar", value: "Myanmar" },
  { label: "Namibië", value: "Namibië" },
  { label: "Nauru", value: "Nauru" },
  { label: "Nepal", value: "Nepal" },
  { label: "Nederland", value: "Nederland" },
  { label: "Nederlandse Antillen", value: "Nederlandse Antillen" },
  { label: "Nieuw-Caledonië", value: "Nieuw-Caledonië" },
  { label: "Nieuw-Zeeland", value: "Nieuw-Zeeland" },
  { label: "Nicaragua", value: "Nicaragua" },
  { label: "Niger", value: "Niger" },
  { label: "Nigeria", value: "Nigeria" },
  { label: "Niue", value: "Niue" },
  { label: "Norfolk", value: "Norfolk" },
  { label: "Noordelijke Marianen", value: "Noordelijke Marianen" },
  { label: "Noorwegen", value: "Noorwegen" },
  { label: "Oman", value: "Oman" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Palau", value: "Palau" },
  { label: "Palestijns Gebied, Bezet", value: "Palestijns Gebied, Bezet" },
  { label: "Panama", value: "Panama" },
  { label: "Papoea-Nieuw-Guinea", value: "Papoea-Nieuw-Guinea" },
  { label: "Paraguay", value: "Paraguay" },
  { label: "Peru", value: "Peru" },
  { label: "Filipijnen", value: "Filipijnen" },
  { label: "Pitcairn", value: "Pitcairn" },
  { label: "Polen", value: "Polen" },
  { label: "Portugal", value: "Portugal" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Qatar", value: "Qatar" },
  { label: "Réunion", value: "Réunion" },
  { label: "Roemenië", value: "Roemenië" },
  { label: "Russische Federatie", value: "Russische Federatie" },
  { label: "RWANDA", value: "RWANDA" },
  { label: "Sint-Helena", value: "Sint-Helena" },
  { label: "Saint Kitts en Nevis", value: "Saint Kitts en Nevis" },
  { label: "Saint Lucia", value: "Saint Lucia" },
  { label: "Saint Pierre en Miquelon", value: "Saint Pierre en Miquelon" },
  { label: "Saint Vincent en de Grenadines", value: "Saint Vincent en de Grenadines" },
  { label: "Samoa", value: "Samoa" },
  { label: "San Marino", value: "San Marino" },
  { label: "Sao Tomé en Principe", value: "Sao Tomé en Principe" },
  { label: "Saoedi-Arabië", value: "Saoedi-Arabië" },
  { label: "San Marino", value: "San Marino" },
  { label: "Sao Tomé en Principe", value: "Sao Tomé en Principe" },
  { label: "Saudi-Arabië", value: "Saudi-Arabië" },
  { label: "Senegal", value: "Senegal" },
  { label: "Servië", value: "Servië" },
  { label: "Seychellen", value: "Seychellen" },
  { label: "Sierra Leone", value: "Sierra Leone" },
  { label: "Singapore", value: "Singapore" },
  { label: "Slowakije", value: "Slowakije" },
  { label: "Slovenië", value: "Slovenië" },
  { label: "Salomonseilanden", value: "Salomonseilanden" },
  { label: "Somalië", value: "Somalië" },
  { label: "Zuid-Afrika", value: "Zuid-Afrika" },
  { label: "Zuid-Georgia en de Zuidelijke Sandwicheilanden", value: "Zuid-Georgia en de Zuidelijke Sandwicheilanden" },
  { label: "Spanje", value: "Spanje" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "Soedan", value: "Soedan" },
  { label: "Suriname", value: "Suriname" },
  { label: "Svalbard en Jan Mayen", value: "Svalbard en Jan Mayen" },
  { label: "eSwatini", value: "eSwatini" },
  { label: "Zweden", value: "Zweden" },
  { label: "Zwitserland", value: "Zwitserland" },
  { label: "Syrië", value: "Syrië" },
  { label: "Taiwan, provincie China", value: "Taiwan, provincie China" },
  { label: "Tadzjikistan", value: "Tadzjikistan" },
  { label: "Tanzania, Verenigde Republiek", value: "Tanzania, Verenigde Republiek" },
  { label: "Thailand", value: "Thailand" },
  { label: "Oost-Timor", value: "Oost-Timor" },
  { label: "Togo", value: "Togo" },
  { label: "Tokelau", value: "Tokelau" },
  { label: "Tonga", value: "Tonga" },
  { label: "Trinidad en Tobago", value: "Trinidad en Tobago" },
  { label: "Tunesië", value: "Tunesië" },
  { label: "Turkije", value: "Turkije" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  { label: "Turks- en Caicoseilanden", value: "Turks- en Caicoseilanden" },
  { label: "Tuvalu", value: "Tuvalu" },
  { label: "Oeganda", value: "Oeganda" },
  { label: "Oekraïne", value: "Oekraïne" },
  { label: "Verenigde Arabische Emiraten", value: "Verenigde Arabische Emiraten" },
  { label: "Verenigd Koninkrijk", value: "Verenigd Koninkrijk" },
  { label: "Verenigde Staten", value: "Verenigde Staten" },
  { label: "Kleine afgelegen eilanden van de Verenigde Staten", value: "Kleine afgelegen eilanden van de Verenigde Staten" },
  { label: "Uruguay", value: "Uruguay" },
  { label: "Oezbekistan", value: "Oezbekistan" },
  { label: "Vanuatu", value: "Vanuatu" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Vietnam", value: "Vietnam" },
  { label: "Maagdeneilanden, Britse", value: "Maagdeneilanden, Britse" },
  { label: "Maagdeneilanden, Amerikaanse", value: "Maagdeneilanden, Amerikaanse" },
  { label: "Wallis en Futuna", value: "Wallis en Futuna" },
  { label: "Westelijke Sahara", value: "Westelijke Sahara" },
  { label: "Jemen", value: "Jemen" },
  { label: "Zambia", value: "Zambia" },
  { label: "Zimbabwe", value: "Zimbabwe" }
];
export const ARRAYOFCountriesDE = [
  { label: "Palästina", value: "Palästina" },
  { label: "Panama", value: "Panama" },
  { label: "Papua-Neuguinea", value: "Papua-Neuguinea" },
  { label: "Paraguay", value: "Paraguay" },
  { label: "Peru", value: "Peru" },
  { label: "Philippinen", value: "Philippinen" },
  { label: "Pitcairninseln", value: "Pitcairninseln" },
  { label: "Polen", value: "Polen" },
  { label: "Portugal", value: "Portugal" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Katar", value: "Katar" },
  { label: "Réunion", value: "Réunion" },
  { label: "Rumänien", value: "Rumänien" },
  { label: "Russische Föderation", value: "Russische Föderation" },
  { label: "Ruanda", value: "Ruanda" },
  { label: "St. Helena", value: "St. Helena" },
  { label: "St. Kitts und Nevis", value: "St. Kitts und Nevis" },
  { label: "St. Lucia", value: "St. Lucia" },
  { label: "St. Pierre und Miquelon", value: "St. Pierre und Miquelon" },
  { label: "St. Vincent und die Grenadinen", value: "St. Vincent und die Grenadinen" },
  { label: "Britisches Territorium im Indischen Ozean", value: "Britisches Territorium im Indischen Ozean" },
  { label: "Brunei Darussalam", value: "Brunei Darussalam" },
  { label: "Bulgarien", value: "Bulgarien" },
  { label: "Burkina Faso", value: "Burkina Faso" },
  { label: "Burundi", value: "Burundi" },
  { label: "Kambodscha", value: "Kambodscha" },
  { label: "Kamerun", value: "Kamerun" },
  { label: "Kanada", value: "Kanada" },
  { label: "Kap Verde", value: "Kap Verde" },
  { label: "Kaimaninseln", value: "Kaimaninseln" },
  { label: "Zentralafrikanische Republik", value: "Zentralafrikanische Republik" },
  { label: "Tschad", value: "Tschad" },
  { label: "Chile", value: "Chile" },
  { label: "China", value: "China" },
  { label: "Weihnachtsinsel", value: "Weihnachtsinsel" },
  { label: "Kokosinseln (Keeling)", value: "Kokosinseln (Keeling)" },
  { label: "Kolumbien", value: "Kolumbien" },
  { label: "Komoren", value: "Komoren" },
  { label: "Kongo", value: "Kongo" },
  { label: "Demokratische Republik Kongo", value: "Demokratische Republik Kongo" },
  { label: "Cookinseln", value: "Cookinseln" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Elfenbeinküste", value: "Elfenbeinküste" },
  { label: "Kroatien", value: "Kroatien" },
  { label: "Kuba", value: "Kuba" },
  { label: "Zypern", value: "Zypern" },
  { label: "Tschechische Republik", value: "Tschechische Republik" },
  { label: "Dänemark", value: "Dänemark" },
  { label: "Dschibuti", value: "Dschibuti" },
  { label: "Dominica", value: "Dominica" },
  { label: "Dominikanische Republik", value: "Dominikanische Republik" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Ägypten", value: "Ägypten" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Äquatorialguinea", value: "Äquatorialguinea" },
  { label: "Eritrea", value: "Eritrea" },
  { label: "Estland", value: "Estland" },
  { label: "Äthiopien", value: "Äthiopien" },
  { label: "Tuvalu", value: "Tuvalu" },
  { label: "Uganda", value: "Uganda" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "Vereinigte Arabische Emirate", value: "Vereinigte Arabische Emirate" },
  { label: "Vereinigtes Königreich", value: "Vereinigtes Königreich" },
  { label: "Vereinigte Staaten", value: "Vereinigte Staaten" },
  { label: "Kleinere Amerikanische Überseeinseln", value: "Kleinere Amerikanische Überseeinseln" },
  { label: "Uruguay", value: "Uruguay" },
  { label: "Usbekistan", value: "Usbekistan" },
  { label: "Vanuatu", value: "Vanuatu" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Vietnam", value: "Vietnam" },
  { label: "Britische Jungferninseln", value: "Britische Jungferninseln" },
  { label: "Amerikanische Jungferninseln", value: "Amerikanische Jungferninseln" },
  { label: "Wallis und Futuna", value: "Wallis und Futuna" },
  { label: "Westsahara", value: "Westsahara" },
  { label: "Jemen", value: "Jemen" },
  { label: "Sambia", value: "Sambia" },
  { label: "Simbabwe", value: "Simbabwe" },
  { label: "Samoa", value: "Samoa" },
  { label: "San Marino", value: "San Marino" },
  { label: "São Tomé und Príncipe", value: "São Tomé und Príncipe" },
  { label: "Saudi-Arabien", value: "Saudi-Arabien" },
  { label: "Senegal", value: "Senegal" },
  { label: "Serbien", value: "Serbien" },
  { label: "Seychellen", value: "Seychellen" },
  { label: "Sierra Leone", value: "Sierra Leone" },
  { label: "Singapur", value: "Singapur" },
  { label: "Slowakei", value: "Slowakei" },
  { label: "Slowenien", value: "Slowenien" },
  { label: "Salomonen", value: "Salomonen" },
  { label: "Somalia", value: "Somalia" },
  { label: "Südafrika", value: "Südafrika" },
  { label: "Südgeorgien und die Südlichen Sandwichinseln", value: "Südgeorgien und die Südlichen Sandwichinseln" },
  { label: "Spanien", value: "Spanien" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "Sudan", value: "Sudan" },
  { label: "Suriname", value: "Suriname" },
  { label: "Svalbard und Jan Mayen", value: "Svalbard und Jan Mayen" },
  { label: "Eswatini", value: "Eswatini" },
  { label: "Schweden", value: "Schweden" },
  { label: "Schweiz", value: "Schweiz" },
  { label: "Syrien", value: "Syrien" },
  { label: "Taiwan, Republik China", value: "Taiwan, Republik China" },
  { label: "Tadschikistan", value: "Tadschikistan" },
  { label: "Tansania, Vereinigte Republik", value: "Tansania, Vereinigte Republik" },
  { label: "Thailand", value: "Thailand" },
  { label: "Timor-Leste", value: "Timor-Leste" },
  { label: "Togo", value: "Togo" },
  { label: "Tokelau", value: "Tokelau" },
  { label: "Tonga", value: "Tonga" },
  { label: "Trinidad und Tobago", value: "Trinidad und Tobago" },
  { label: "Tunesien", value: "Tunesien" },
  { label: "Türkei", value: "Türkei" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  { label: "Turks- und Caicosinseln", value: "Turks- und Caicosinseln" },
  { label: "Britisches Territorium im Indischen Ozean", value: "Britisches Territorium im Indischen Ozean" },
  { label: "Brunei Darussalam", value: "Brunei Darussalam" },
  { label: "Bulgarien", value: "Bulgarien" },
  { label: "Burkina Faso", value: "Burkina Faso" },
  { label: "Burundi", value: "Burundi" },
  { label: "Kambodscha", value: "Kambodscha" },
  { label: "Kamerun", value: "Kamerun" },
  { label: "Kanada", value: "Kanada" },
  { label: "Kap Verde", value: "Kap Verde" },
  { label: "Kaimaninseln", value: "Kaimaninseln" },
  { label: "Zentralafrikanische Republik", value: "Zentralafrikanische Republik" },
  { label: "Tschad", value: "Tschad" },
  { label: "Chile", value: "Chile" },
  { label: "China", value: "China" },
  { label: "Weihnachtsinsel", value: "Weihnachtsinsel" },
  { label: "Kokosinseln (Keelinginseln)", value: "Kokosinseln (Keelinginseln)" },
  { label: "Kolumbien", value: "Kolumbien" },
  { label: "Komoren", value: "Komoren" },
  { label: "Kongo", value: "Kongo" },
  { label: "Demokratische Republik Kongo", value: "Demokratische Republik Kongo" },
  { label: "Cookinseln", value: "Cookinseln" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Elfenbeinküste", value: "Elfenbeinküste" },
  { label: "Kroatien", value: "Kroatien" },
  { label: "Kuba", value: "Kuba" },
  { label: "Zypern", value: "Zypern" },
  { label: "Tschechische Republik", value: "Tschechische Republik" },
  { label: "Dänemark", value: "Dänemark" },
  { label: "Dschibuti", value: "Dschibuti" },
  { label: "Dominica", value: "Dominica" },
  { label: "Dominikanische Republik", value: "Dominikanische Republik" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Ägypten", value: "Ägypten" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Äquatorialguinea", value: "Äquatorialguinea" },
  { label: "Eritrea", value: "Eritrea" },
  { label: "Afghanistan", value: "Afghanistan" },
  { label: "Alandinseln", value: "Alandinseln" },
  { label: "Albanien", value: "Albanien" },
  { label: "Algerien", value: "Algerien" },
  { label: "Amerikanisch-Samoa", value: "Amerikanisch-Samoa" },
  { label: "Andorra", value: "Andorra" },
  { label: "Angola", value: "Angola" },
  { label: "Anguilla", value: "Anguilla" },
  { label: "Antarktis", value: "Antarktis" },
  { label: "Antigua und Barbuda", value: "Antigua und Barbuda" },
  { label: "Argentinien", value: "Argentinien" },
  { label: "Armenien", value: "Armenien" },
  { label: "Aruba", value: "Aruba" },
  { label: "Australien", value: "Australien" },
  { label: "Österreich", value: "Österreich" },
  { label: "Aserbaidschan", value: "Aserbaidschan" },
  { label: "Bahamas", value: "Bahamas" },
  { label: "Bahrain", value: "Bahrain" },
  { label: "Bangladesch", value: "Bangladesch" },
  { label: "Barbados", value: "Barbados" },
  { label: "Weißrussland", value: "Weißrussland" },
  { label: "Belgien", value: "Belgien" },
  { label: "Belize", value: "Belize" },
  { label: "Benin", value: "Benin" },
  { label: "Bermuda", value: "Bermuda" },
  { label: "Bhutan", value: "Bhutan" },
  { label: "Bolivien", value: "Bolivien" },
  { label: "Bosnien und Herzegowina", value: "Bosnien und Herzegowina" },
  { label: "Botswana", value: "Botswana" },
  { label: "Bouvetinsel", value: "Bouvetinsel" },
  { label: "Brasilien", value: "Brasilien" }

]