import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
//components
import ShipmentProducts from "./Pages/Shipment/ShipmentProducts/ShipmentProducts";
import ShipmentOrders from "./Pages/Shipment/ShipmentOrders/ShipmentOrders";
import ShipmentOrder from "./Pages/Shipment/ShipmentOrders/ShipmentOrder/ShipmentOrder";
import ShipmentSuggestion from "./Pages/Shipment/ShipmentSuggestion/ShipmentSuggestion";
import AgentInfoForm from "../../Components/AgentVerificationForm/AgentVerificationForm";
//css
import "./ShipmentProcess.css";
//icons
// import { IoDocumentTextOutline } from "react-icons/io5";
// import { Paper } from "react-iconly";
import { DocumentText, DocumentText1, Cards, ReceiptEdit } from "iconsax-react";
//images
// import DocImg from "../../assets/Payments/document.png";
// import PaymentImg from "../../assets/Payments/payment.png";
//pages
import Cart from "./Pages/Cart/Cart";
import Shipment from "./Pages/Shipment/Shipment";
import OrdersRatings from "./Pages/Shipment/ShipmentOrders/ShipmentOrder/OrdersRatings/OrdersRatings";
import OrderRating from "./Pages/Shipment/ShipmentOrders/ShipmentOrder/OrdersRatings/OrderRating/OrderRating";
import ShipmentDocuments from "./Pages/Shipment/ShipmentDocuments/ShipmentDocuments";
//Redux
import { useSelector } from "react-redux";
// import { clearCart } from "../../redux/cartSlice";
//=================Payment======================
import Payment from "./Pages/Shipment/Payment/Payment";
//translation
import { useTranslation } from "react-i18next";
const ShipmentProcess = () => {
  const location = useLocation();
  // const dispatch = useDispatch();

  const shipmentInfo = useSelector((state) => state.shipmentStepper);
  const cart = useSelector((state) => state.cart);
  const [userInfo] = useState(JSON.parse(localStorage.getItem("user-info")));
  const [exportCountryCategories, setExportCountryCategories] = useState([]);
  const atShipmentProducts = location.pathname.includes("shipmentproducts");
  const [chooosenCategoryProducts, setChooosenCategoryProducts] = useState([]);
  const [agent, setAgent] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [docLang, setDocLang] = useState(document.querySelector("html").lang);
  const { t } = useTranslation();
  useEffect(() => {
    setDocLang(document.querySelector("html").lang);
  }, [t]);
  //===========================fetch Export Countries Categories ==============
  const fetchExportCountryCategories = async () => {
    try {
      if (shipmentInfo.exportCountryId) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("token")}`
        );
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const response = await fetch(
          `https://www.api.setalkel.co/export-country/${shipmentInfo.exportCountryId}`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          setExportCountryCategories([]);
          console.log(body);
        } else {
          setExportCountryCategories(body.categories);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchExportCountryCategories();
  }, [shipmentInfo.exportCountryId]);

  //===========================fetch Export Countries Categories Products ==============

  useEffect(() => {
    const fetchExportCountryCategoryProducts = async () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        if (
          shipmentInfo.exportCountryId &&
          categoryId &&
          shipmentInfo.containerId
        ) {
          const response = await fetch(
            `https://www.api.setalkel.co/export-country/products?idc=${shipmentInfo.exportCountryId}&idcat=${categoryId}&containerId=${shipmentInfo.container.container._id}`,
            requestOptions
          );
          const body = await response.json();
          if (!response.ok) {
            console.log(body);
            setChooosenCategoryProducts([]);
          } else {
            setChooosenCategoryProducts(body.prods);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchExportCountryCategoryProducts();
  }, [categoryId, shipmentInfo.containerId, shipmentInfo.exportCountryId]);

  //=====================fetch Agents========================

  useEffect(() => {
    const fetchAgent = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/agent/${userInfo._id}`,
        requestOptions
      );

      const body = await response.json();
      if (!response.ok) {
        setAgent([]);
        console.log(body);
      } else {
        setAgent(body);
      }
    };
    fetchAgent();
  }, [userInfo._id]);
  //===============================================================
  // useEffect(() => {
  //   console.log(location);

  //   const navMenu = document.getElementById("nav-menu-items2");
  //   if (location.pathname === "/shipmentProcess/cart") {
  //     console.log("location 1");
  //     navMenu.style.display = "none";
  //     console.log(navMenu);
  //   } else {
  //     console.log("location 2");
  //   }
  // }, [location.pathname]);
  return (
    <section className="payments">
      {agent.isVerified ? (
        <>
          <div className="payments-sidebar">
            {atShipmentProducts ? (
              <ul
                id="nav-menu-items2"
                className={
                  location.pathname === "/shipmentProcess/shipmentproducts"
                    ? "nav-menu-items"
                    : "nav-menu-items2"
                }
              >
                <li>
                  {cart.cartTotalQuantity > 0 ? (
                    <Link to="/shipmentProcess/cart">
                      <span>{t(`Pages.ShipmentProcess.CartTitle`)}</span>
                    </Link>
                  ) : null}
                </li>
                {exportCountryCategories &&
                exportCountryCategories.length > 0 ? (
                  exportCountryCategories.map((category, index) => {
                    return (
                      <li key={index}>
                        <span
                          onClick={() => {
                            setCategoryId(category._id);
                            setCategoryName(
                              category.cat[docLang]
                                ? category.cat[docLang]
                                : category.cat.en
                            );
                          }}
                        >
                          <span>
                            {category.cat[docLang]
                              ? category.cat[docLang]
                              : category.cat.en}
                          </span>
                        </span>
                      </li>
                    );
                  })
                ) : (
                  <span>No Categories</span>
                )}
              </ul>
            ) : (
              <ul
                id="nav-menu-items2"
                className={
                  location.pathname === "/shipmentProcess/shipmentproducts"
                    ? "nav-menu-items"
                    : "nav-menu-items2"
                }
              >
                <li className="nav-text">
                  <Link to="/shipmentProcess/myorders">
                    <DocumentText1 size="20" />
                    <span>
                      {t(`Pages.ShipmentProcess.SidebarData.MyOrders`)}
                    </span>
                  </Link>
                </li>
                <li className="nav-text">
                  <Link to="/shipmentProcess/shipment">
                    <DocumentText size="20" />

                    <span>
                      {t(`Pages.ShipmentProcess.SidebarData.OrderSuggestion`)}
                    </span>
                  </Link>
                </li>
                <li className="nav-text">
                  <Link to="/shipmentProcess/suggestion">
                    <ReceiptEdit size="20" />
                    <span>
                      {t(
                        `Pages.ShipmentProcess.SidebarData.SuggestionConfirmation`
                      )}
                    </span>
                  </Link>
                </li>
                {/* <li className="nav-text">
                  <Link to="/shipmentProcess/documents">
                    <IoDocumentTextOutline />
                    {t(`Pages.ShipmentProcess.SidebarData.OrderDocs`)}
                  </Link>
                </li> */}
                <li className="nav-text">
                  <Link to="/shipmentProcess/payment">
                    <Cards size="20" />
                    <span>
                      {t(`Pages.ShipmentProcess.SidebarData.Payment`)}
                    </span>
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </>
      ) : (
        <div className="payments-sidebar"></div>
      )}

      <div className="payments-content">
        <Routes>
          <Route
            path="/shipment"
            element={
              agent.isVerified ? (
                <Shipment />
              ) : (
                <div className="shipment-agent-form">
                  <AgentInfoForm />
                </div>
              )
            }
          />
          <Route
            path="/shipmentproducts"
            element={
              <ShipmentProducts
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                chooosenCategoryProducts={chooosenCategoryProducts}
              />
            }
          />
          <Route path="/cart" element={<Cart />} />
          <Route path="/suggestion" element={<ShipmentSuggestion />} />
          <Route path="/myorders" element={<ShipmentOrders />} />
          <Route path="/myorders/:orderId" element={<ShipmentOrder />} />
          <Route path="/ordersratings" element={<OrdersRatings />} />
          <Route
            path="/ordersratings/:ratingorderId"
            element={<OrderRating />}
          />
          <Route path="/payment" element={<Payment />} />
          <Route path="/documents" element={<ShipmentDocuments />} />
        </Routes>
      </div>
    </section>
  );
};

export default ShipmentProcess;
