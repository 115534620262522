import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  exportCountryId: localStorage.getItem("exportCountryId")
    ? localStorage.getItem("exportCountryId")
    : "",
  importCountryId: localStorage.getItem("importCountryId")
    ? localStorage.getItem("importCountryId")
    : "",
  importStateId: localStorage.getItem("importStateId")
    ? localStorage.getItem("importStateId")
    : "",
  deliveryMethod: localStorage.getItem("deliveryMethod")
    ? localStorage.getItem("deliveryMethod")
    : "",
  containerId: localStorage.getItem("containerId")
    ? localStorage.getItem("containerId")
    : "",
  // productsContainerId: localStorage.getItem("productsContainerId")
  //   ? localStorage.getItem("productsContainerId")
  //   : "",
  container: JSON.parse(localStorage.getItem("container"))
    ? JSON.parse(localStorage.getItem("container"))
    : {},
  finalInvoice: localStorage.getItem("finalInvoice")
    ? localStorage.getItem("finalInvoice")
    : "",
};

const shipmentStepperSlice = createSlice({
  name: "shipmentStepper",
  initialState,
  reducers: {
    addExportCountryId: (state, action) => {
      state.exportCountryId = action.payload;
      localStorage.setItem("exportCountryId", action.payload);
    },
    deleteExportCountryId: (state) => {
      state.exportCountryId = "";
    },
    addImportCountryId: (state, action) => {
      state.importCountryId = action.payload;
      localStorage.setItem("importCountryId", action.payload);
    },
    addImportStateId: (state, action) => {
      state.importStateId = action.payload;
      localStorage.setItem("importStateId", action.payload);
    },
    addDeliveryMethod: (state, action) => {
      state.deliveryMethod = action.payload;
      localStorage.setItem("deliveryMethod", action.payload);
    },
    addContainerId: (state, action) => {
      state.containerId = action.payload;
      localStorage.setItem("containerId", action.payload);
    },
    // addProductsContainerId: (state, action) => {
    //   console.log(action.payload);
    //   // state.productsContainerId(action.payload);
    //   // localStorage.setItem("productsContainerId", action.payload);
    // },
    addContainer: (state, action) => {
      state.container = action.payload;
      localStorage.setItem("container", JSON.stringify(action.payload));
    },
    addFinalInvoice: (state, action) => {
      state.finalInvoice = action.payload;
      localStorage.setItem("finalInvoice", action.payload);
    },
  },
});
export const {
  addExportCountryId,
  addImportCountryId,
  addImportStateId,
  addDeliveryMethod,
  addContainerId,
  addContainer,
  addFinalInvoice,
  deleteExportCountryId,
  // addProductsContainerId,
} = shipmentStepperSlice.actions;

export default shipmentStepperSlice.reducer;
