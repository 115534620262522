import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//icons
import { User, Buy, Notification } from "react-iconly";
//components
import SearchBar from "../SearchBar/SearchBar";
import Notifications from "../Notifications/Notifications";
//css
import "./Header.css";
//images
import LOGOIMG from "../../assets/SetalkelLogo.gif";
//Bootstrap
import { Navbar, Nav, Form, Container } from "react-bootstrap";
//redux
import { useDispatch, useSelector } from "react-redux";
import { checkIsVerified, getTotals } from "../../redux/cartSlice";
//translation
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { languages } from "../../languages";
import { getNotificationsForAgent } from "../../redux/notificationSlice";

const Header = () => {
  const [userInfo] = useState(JSON.parse(localStorage.getItem("user-info")));
  const [searchData, setSearchData] = useState([]);
  const Navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  //===========================Start Translation===========================
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  useEffect(() => {
    document.body.dir = currentLanguage.dir;
    document.querySelector("html").dir = currentLanguage.dir;
    document.querySelector("html").lang = currentLanguage.code;
  }, [currentLanguage, t]);
  //===========================End Translation===========================

  //=============================Get Agent Notifications=============
  const getNotifications = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/notification/agent/${userInfo._id}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      dispatch(getNotificationsForAgent([]));
    } else {
      dispatch(getNotificationsForAgent(body));
    }
  };
  //============================Hover=========================
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
    getNotifications();
  };
  //================================changeNotificationLanguage================
  useEffect(() => {
    if (localStorage.getItem("token")) {
      const changeNotificationLanguage = async () => {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("token")}`
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          lang: currentLanguage.code,
        });

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        await fetch(
          "https://www.api.setalkel.co/agent/change-language",
          requestOptions
        )
          .then((response) => response.text())
          .catch((error) => console.log("error", error));
      };
      changeNotificationLanguage();
    }
  }, [currentLanguage, t]);
  //=========================== End Translation===========================
  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);
  //===================search bar & products data fetch==============================
  useEffect(() => {
    const SearchFetch = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/?showHidden=false",
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        setSearchData([]);
      } else {
        setSearchData(data.data);
      }
    };
    SearchFetch();
  }, []);
  //====================login - logout data============================
  const logout = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      fcmToken: localStorage.getItem("fcmToken"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch("https://www.api.setalkel.co/agent/logout", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    localStorage.clear();
    Navigate("/agent/login");
    window.location.reload();
    //========prevent browser go back=================
    window.history.forward();
    function noBack() {
      window.history.forward();
    }
    noBack();
  };
  //=============================Handle Veify Agent======================
  const handleVerifyAgent = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/agent/status",
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
    } else {
      dispatch(checkIsVerified(body.isVerified));
    }
    window.location.reload();
  };
  return (
    <header>
      <Navbar style={{ backgroundColor: "#121212", padding: "0" }} expand="lg">
        <Container fluid className="navbar-container">
          <Navbar.Brand href="/">
            <img
              src={LOGOIMG}
              alt="Setalkel"
              style={{
                width: "99px",
                height: "55px",
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav style={{ margin: "auto" }}>
              <Link className="nav-link" to="/categories">
                {t(`Components.Header.Navbar.Categories`)}
              </Link>

              <Link className="nav-link" to="/ourAgents">
                {t(`Components.Header.Navbar.Agents`)}
              </Link>
{/* 
              <Link className="nav-link" to="/partners">
                {t(`Components.Header.Navbar.Partners`)}
              </Link> */}
              <Link className="nav-link" to="/offers">
                {t(`Components.Header.Navbar.Offers`)}
              </Link>
              <Link className="nav-link" to="/aboutUs">
                {t(`Components.Header.Navbar.AboutUs`)}
              </Link>
              <Link className="nav-link" to="/contactUs">
                {t(`Components.Header.Navbar.ContactUs`)}
              </Link>
              <Nav.Link>
                <div class="nav-dropdown">
                  <span>{t(`Components.Header.Navbar.Others`)}</span>
                  <div class="nav-dropdown-content">
                    <Link to="/products" id="nav-dropdown-link">
                      {t(`Components.Header.Navbar.Products`)}
                    </Link>
                    <br />
                    <Link to="/bites" id="nav-dropdown-link">
                      {t(`Components.Header.Navbar.Bites`)}
                    </Link>
                    <br />
                    <Link to="/address" id="nav-dropdown-link">
                      {t(`Components.Header.Navbar.Address`)}
                    </Link>
                  </div>
                </div>
              </Nav.Link>
            </Nav>
            <Form className="header-form ">
              <SearchBar
                placeholder={t(`Components.Header.Navbar.SearchField`)}
                data={searchData}
              />
              {userInfo && (
                <div
                  className="notification-icon"
                  onMouseOver={handleMouseOver}
                >
                  <Notification set="broken" id="notificationIcon" />
                </div>
              )}
              {isHovering && <Notifications setIsHovering={setIsHovering} />}

              <div className="person-info ">
                {localStorage.getItem("user-info") ? (
                  <div class="auth-dropdown">
                    <User set="broken" id="userIcon" />
                    <div className="auth-dropdown-content">
                      <ul className="list-unstyled">
                        <li>
                          <Nav.Link href="/agent/profile">
                            {t(`Components.Header.profile`)}
                          </Nav.Link>
                        </li>
                        <li onClick={logout}>
                          {t(`Components.Header.logout`)}
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div class="auth-dropdown">
                    <User set="broken" id="userIcon" />
                    <div className="auth-dropdown-content">
                      <ul className="list-unstyled">
                        <li>
                          <Nav.Link href="/agent/register">
                            {t(`Components.Header.register`)}
                          </Nav.Link>
                        </li>
                        <li onClick={{ logout }}>
                          <Nav.Link href="/agent/login">
                            {t(`Components.Header.login`)}
                          </Nav.Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              {userInfo && (
                <div className="header-cart">
                  {cart.cartTotalQuantity > 0 ? (
                    <>
                      <Buy set="broken" id="headerCart" />
                      <Nav.Link href={"/shipmentProcess/cart"}>
                        <span className="cart-quantity">
                          {cart.cartTotalQuantity}
                        </span>
                      </Nav.Link>
                    </>
                  ) : (
                    <div onClick={handleVerifyAgent}>
                      <Link to="/shipmentProcess/shipment">
                        <Buy set="broken" id="headerCart" />
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
