import React from 'react'
import { Button, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Pallet = () => {
    const navigate = useNavigate()
    return (
        <Row>
            <div className='d-flex'>
                <Button variant="warning" id="verify-btn" >
                    Export Excel File for Pallet
                </Button>
                {/* <Button variant="success" id="verify-btn" onClick={getFileExcel}>
            Download Excel File
          </Button> */}

                <Button onClick={()=>{
                    navigate("/dashboard/add-pallet")
                }} variant="warning" id="verify-btn">
                    Add Pallet
                </Button>
            </div>
        </Row>
    )
}

export default Pallet