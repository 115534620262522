import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Button, Form, Modal } from "react-bootstrap";
const CategoryAlAmeen = () => {
  const navigate = useNavigate()
  return (
    <section className="cms-create-categories">
      <Row>
        <div className="crud-categories">
          <div className="crud-title">
            <h1>Categories AlAmeen</h1>
          </div>
          <div className="crud-category">
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: "10px"
            }}>



              <Button type="button" onClick={() => navigate("add-categories-ameen")} variant="warning">
                Add Category AlAmeen
              </Button>

            </div>


          </div>
        </div>
        <hr />
        <Row>

        </Row>
      </Row>
    </section>
  )
}

export default CategoryAlAmeen