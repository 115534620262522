import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Notification from "../../../Components/Notification/Notification";

import DataTable from "react-data-table-component";
//Redux
import { useDispatch } from "react-redux";
import {
  adminCheckIsEditing,
  adminUpdateSuggestion,
  adminUpdateSuggestionShipment,
  shipmentAgentId,
} from "../../../redux/Admin/adminCartSlice";
import {
  adminAddContainer,
  adminAddContainerId,
  adminAddDeliveryMethod,
  adminAddExportCountryId,
  adminAddImportCountryId,
  adminAddImportStateId,
} from "../../../redux/Admin/adminShipmentStepperSlice";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { customStyles } from "../../constants/DataTableCustomeStyles";
var deleteKey = require("key-del");
const SuggestionShipments = () => {
  const [suggestionShippments, setSuggestionShippments] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //==================================Notes Modal =========================================
  const [NotesShow, setNotesShow] = useState(false);
  const handleClose = () => setNotesShow(false);
  const handleShow = () => setNotesShow(true);
  //============================================Delete Suggestion Shipment Modal=============================
  const [
    DeleteSuggestionShipmentModalshow,
    setDeleteSuggestionShipmentModalShow,
  ] = useState(false);

  const handleDeleteSuggestionShipmentModalClose = () =>
    setDeleteSuggestionShipmentModalShow(false);
  const handleDeleteSuggestionShipmentModalShow = () =>
    setDeleteSuggestionShipmentModalShow(true);

  const [deletedProducts, setDeletedProducts] = useState({
    ids: [],
  });
  const [shipmentId, setShipmentId] = useState("");
  const [freezeShipment, setFreezeShipment] = useState({
    freezed: false,
  });
  //========================================Handle Freeze Shipment===========================
  const handleFreezeShipment = (e, shipmentId) => {
    setShipmentId(shipmentId);
    setFreezeShipment({
      freezed: e.target.value,
    });
  };
  //============================get Suggestion========================

  useEffect(() => {
    const getSuggestions = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/shipment/?status=suggestion",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setSuggestionShippments([]);
        console.log(body);
      } else {
        setSuggestionShippments(body);
      }
    };
    getSuggestions();
  }, []);
  //========================================freeze Shipment ==============================
  const PostFreezeShipment = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      freezed: freezeShipment.freezed,
    });
    console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/freezed/${shipmentId}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("Shipment Status Not Changed");
      console.log(body);
    } else {
      alert("Shipment Status Has Been Changed");
      window.location.reload();
    }
  };
  //=========================================download File ===============================================
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };

  //=======================Handle Edit Suggestion=====================

  const handleEdit = async (suggestion) => {
    const adminUpdatedCartItems = [];
    const adminUpdatedShipmentItems = [];
    var result;
    suggestion.order.items.forEach((item) => {
      console.log(item);
      item.product.cartQuantity = item.count;
      adminUpdatedCartItems.push(item.product);
    });
    for (let i = 0; i < adminUpdatedCartItems.length; i++) {
      const element = adminUpdatedCartItems[i];
      element.count = element.cartQuantity;
      element.product = element._id;
      element.note = "";
      result = deleteKey(element, [
        "addedBy",
        "additionalImage",
        "cat",
        "countInBox",
        "cartQuantity",
        "countryOrigin",
        "createdAt",
        "hidden",
        "img",
        "materialProperty",
        "package",
        "packageGrossWeight",
        "peiceQty",
        "stockOff",
        "subCat",
        "supplierName",
        "unitForPackage",
        "updatedAt",
        "volume",
        "is_new",
        "updatedBy",
        "_id",
      ]);
      adminUpdatedShipmentItems.push(result);
    }

    localStorage.setItem(
      "adminUpdatedCartItems",
      JSON.stringify(adminUpdatedCartItems)
    );
    localStorage.setItem(
      "adminUpdatedShipmentItems",
      JSON.stringify(adminUpdatedShipmentItems)
    );
    localStorage.setItem("container", JSON.stringify(suggestion.containerInfo));
    localStorage.setItem("exportCountryId", suggestion.exportCountry._id);
    localStorage.setItem("importCountryId", suggestion.importCountry._id);
    localStorage.setItem("importStateId", suggestion.state._id);
    localStorage.setItem("deliveryMethod", suggestion.deliveryMethod._id);
    localStorage.setItem("containerId", suggestion.containerInfo._id);
    localStorage.setItem("adminUpdatedSuggestionId", suggestion._id);
    localStorage.setItem("adminUpdatedSuggestionAgentId", suggestion.agent._id);
    dispatch(adminCheckIsEditing(true));
    dispatch(adminUpdateSuggestion(adminUpdatedCartItems));
    dispatch(adminUpdateSuggestionShipment(adminUpdatedShipmentItems));
    dispatch(adminAddExportCountryId(suggestion.exportCountry._id));
    dispatch(adminAddImportCountryId(suggestion.importCountry._id));
    dispatch(adminAddImportStateId(suggestion.state._id));
    dispatch(adminAddDeliveryMethod(suggestion.deliveryMethod._id));
    dispatch(adminAddContainerId(suggestion.containerInfo.container._id));
    dispatch(adminAddContainer(suggestion.containerInfo));
    dispatch(shipmentAgentId(suggestion.agent._id));
    navigate("/dashboard/adminCart");
  };

  //============================Delete Shippment===============================

  const DeleteShippment = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/admin/${shipmentId}`,
      requestOptions
    );
    if (!response.ok) {
      alert("Shippment Not Deleted !!!");
      console.log(response);
    } else {
      alert("Shippment Deleted !!");
      window.location.reload();
    }
  };
  //============================Delete Mutliple Product=====================

  const deleteShipments = (e, contactID) => {
    if (e.target.checked) {
      if (!deletedProducts.ids.includes(contactID)) {
        setDeletedProducts({
          ...deletedProducts,
          ids: [...deletedProducts.ids, contactID],
        });
      }
    } else {
      setDeletedProducts({
        ids: deletedProducts.ids.filter((item) => item !== contactID),
      });
    }
  };
  const deleteMultipleShipments = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/shipment/admin/collection",
      requestOptions
    );
    if (!response.ok) {
      alert("There Is An Error ");
      console.log(response);
    } else {
      alert("Shipments Have Been Deleted !!");
      window.location.reload();
    }
  };
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
   
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Cancled",
      selector: (row) => (row.isCanceled === true ? "Yes" : "No"),
    },
    {
      name: "Date",
      selector: (row) => row.order.createdAt,
    },

    {
      name: "Container",
      selector: (row) => row.containerInfo.container.usualName,
    },
    {
      name: "Agent",
      selector: (row) => row.agent.name,
    },
    {
      name: "Export Country",
      selector: (row) => row.exportCountry.countryName.en,
    },
    {
      name: "Import Country",
      selector: (row) =>
        row.importCountry.countryName.en + " / " + row.state.stateName.en,
    },
    {
      name: "Note Per Products",
      selector: (row) => <div style={{
        fontSize: "12px",
        display: "flex",
        flexDirection: "column"
      }}>{row?.order?.items?.map((d) => (
        <>{d.note ? <div>   <span>{d.product.subCat.en}</span> : <span>{d.note}</span></div> :""}</>
      ))}</div>,
      width: "200px"
    },
    {
      name: "Admin Files",
      selector: (row) => (
        <ul className="p-0">
          {row.adminFiles.map((file, index) => {
            return (
              <li>
                <Button
                  variant="warning"
                  onClick={() => getFile(file)}
                  style={{
                    wordBreak: "break-all",
                  }}
                >
                  Admin File #{index}
                </Button>
              </li>
            );
          })}
        </ul>
      ),
    },
    {
      name: "Shipment Files",
      selector: (row) => (
        <ul className="p-0">
          {Object.entries(row.shipmentFiles).map((file) => {
            return (
              <li>
                <Button
                  variant="warning"
                  onClick={() => getFile(file[1])}
                  style={{
                    wordBreak: "break-all",
                  }}
                >
                  {file[0].slice(0, 16)}
                </Button>
              </li>
            );
          })}
        </ul>
      ),
    },
    {
      name: "Freeze",
      selector: (row) => (
        <Form.Select
          name="freeze-shipment"
          value={freezeShipment}
          onChange={(e) => handleFreezeShipment(e, row._id)}
        >
          <option>Freeze Shipment</option>
          <option value="true">Freeze</option>
          <option value="false">DeFreeze</option>
        </Form.Select>
      ),
    },
    {
      name: "Edit",
      selector: (row) => (
        <Button variant="warning" onClick={() => handleEdit(row)}>
          Edit
        </Button>
      ),
    },
    {
      name: "Notes",
      selector: (row) => (
        <>
          <Button variant="warning" onClick={handleShow}>
            Notes
          </Button>
          <Modal show={NotesShow} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Shipment Notes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table>
                <TableHeader>
                  <TableHeaderCell>Id</TableHeaderCell>
                  <TableHeaderCell>Product Name</TableHeaderCell>
                  <TableHeaderCell>Product Gross Weight</TableHeaderCell>
                  <TableHeaderCell>Note</TableHeaderCell>
                </TableHeader>
                <TableBody>
                  {row.order.items.length > 0
                    ? row.order.items.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.product.subCat.en}</TableCell>
                          <TableCell>
                            {item.product.peiceQty.grossWeightForPeice}
                          </TableCell>
                          <TableCell>
                            <Form.Control
                              as="textarea"
                              value={item.note}
                              disabled
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                    : null}
                </TableBody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="warning" onClick={handleClose}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteSuggestionShipmentModalShow();
              setShipmentId(row._id);
            }}
          >
            Delete
          </Button>

          <Modal
            show={DeleteSuggestionShipmentModalshow}
            onHide={handleDeleteSuggestionShipmentModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete This Shipment
              </Modal.Title>
            </Modal.Header>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleDeleteSuggestionShipmentModalClose}
              >
                Close
              </Button>
              <Button variant="danger" onClick={() => DeleteShippment()}>
                Delete Shipment
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ),
    },
    {
      name: "M-Delete",
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedShipments"
          id="deletedShipments"
          onChange={(e) => deleteShipments(e, row._id)}
        />
      ),
    },
  ];
  return (
    <section className="suggestion-shipment">
      <Notification />
      <Row>
        <div className="shippments">
          <Row>
            <div className="crud-title">
              <h1>Hello CRUD Shippments</h1>
            </div>
          </Row>
          <div className="details-table">
            <h2>Suggestion Shipments</h2>
            <hr />

            <Row>
              {deletedProducts.ids.length > 0 ? (
                <Button
                  id="multipleDelete"
                  variant="danger"
                  onClick={() => deleteMultipleShipments()}
                >
                  MultipleDelete
                </Button>
              ) : null}
              {freezeShipment.freezed ? (
                <Button
                  id="freezedShipment"
                  variant="dark"
                  onClick={() => PostFreezeShipment()}
                >
                  Change Shipment Status
                </Button>
              ) : null}
            </Row>
            <Row>
              <DataTable
               fixedHeader
                pagination
                responsive
                subHeaderWrap
                columns={columns}
                data={suggestionShippments}
                customStyles={customStyles}
              />
            </Row>
          </div>
        </div>
      </Row>
    </section>
  );
};

export default SuggestionShipments;
