import React from "react";
//css
import "./AdminNotification.css";
//image
import NotificationImg from "../../../assets/notification.png";

import { useSelector } from "react-redux";

const AdminNotifications = () => {
  const adminNotifications = useSelector(
    (state) => state.notifications.adminNotifications
  );
  //====================translation===================

  return (
    <article className="admin-notifications">
      {adminNotifications.length > 0 ? (
        adminNotifications.map((notification, index) => {
          return (
            <div className="notification" key={index}>
              <div className="notification-img">
              <img src={NotificationImg} alt="Avatar"/>
                {/* <ReactRoundedImage
                  image={NotificationImg}
                  imageWidth="50"
                  imageHeight="50"
                  roundedSize="13"
                  borderRadius="70"
                  roundedColor="#fff"
                /> */}
              </div>
              <div className="notification-info">
                <div>
                  <span>{notification.title.en}</span>
                  <span>{notification.date}</span>
                </div>
                <p>{notification.body.en}</p>
              </div>
            </div>
          );
        })
      ) : (
        <h4>No Notifications</h4>
      )}
    </article>
  );
};

export default AdminNotifications;
