import React from "react";
//css
import "./FinalInvoices.css";
//components
import FinalInvoiceCard from "./FinalInvoiceCard/FinalInvoiceCard";
//translation
import { useTranslation } from "react-i18next";
const FinalInvoices = ({ agentShipments }) => {
  const { t } = useTranslation();

  return (
    <article className="final-invoices-container">
      {agentShipments.map((shipment, index) => {
        return shipment ? (
          <FinalInvoiceCard shipment={shipment} key={index} />
        ) : (
          <p>{t(`Components.PaymentComponents.FinalInvoice.Loading`)}</p>
        );
      })}
    </article>
  );
};

export default FinalInvoices;
