import React from "react";
//css
import "./StepperLabel.css";
const StepperLabel = ({ name, icon, active }) => {
  const Choosen = active ? (
    <article className="stepper-label-active">
      <img src={icon} alt="img" />
      <span>{name}</span>
    </article>
  ) : (
    <article className="stepper-label">
      <img src={icon} alt="img" />
      <span>{name}</span>
    </article>
  );
  return Choosen;
};

export default StepperLabel;
