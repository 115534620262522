import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//bootstrap
import {
  Button,
  Form,
  FormGroup,
  FormLabel,
  Modal,
  Row,
} from "react-bootstrap";
//css
import "./UpdateExportCountry.css";

import DataTable from "react-data-table-component";
import { customStyles } from "../../../../constants/DataTableCustomeStyles";
import toast from "react-hot-toast";
import { FaDownload, FaTrashAlt } from "react-icons/fa";
const UpdateExportCountry = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [values, setValues] = useState({
    _id: "",
    en: "", //required
    ar: "", //required
    fr: "",
    ru: "",
    de: "",
    cn: "",
    tur: "",
    ir: "",
    dk: "",
    countryCode: "",
    containerInCountry: "",
  });
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const [containerId, setContainerId] = useState("");
  //=============================Delete Modal=======================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  //=======================Input Handeling===============
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  //==================Get Country Codes==============================

  useEffect(() => {
    const getCountriesCodes = async () => {
      const response = await fetch("https://restcountries.com/v2/all");
      const body = await response.json();
      if (!response.ok) {
        setCountries([]);
        console.log(body);
      } else {
        setCountries(body);
      }
    };
    getCountriesCodes();
  }, []);
  //=============Add Export Country Image===============
  const addExportExportCountryImage = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var formdata = new FormData();
      formdata.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/export-country/image/${id}`,
        requestOptions
      );
      const body = await response.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  //==================Delete Export Country Image============================
  const deleteExportCountryImage = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/export-country/image/${values._id}`,
      requestOptions
    );
    if (!response.ok) {
      alert("Image Not Deleted");
    } else {
      alert("Image Has Been Deleted");
      window.location.reload();
    }
  };
  //==================================const get export country by id==========================
  const getExportCountryById = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/export-country/${params.Id}`,
      requestOptions
    );
    const body = await response.json();
    setImgfile({ Imgfile: body.img })
    setValues({
      _id: body._id,
      en: body.countryName.en ? body.countryName.en : "", //required
      ar: body.countryName.ar ? body.countryName.ar : "", //required
      fr: body.countryName.fr ? body.countryName.fr : "",
      ru: body.countryName.ru ? body.countryName.ru : "",
      de: body.countryName.de ? body.countryName.de : "",
      cn: body.countryName.cn ? body.countryName.cn : "",
      tur: body.countryName.tur ? body.countryName.tur : "",
      ir: body.countryName.ir ? body.countryName.ir : "",
      dk: body.countryName.dk ? body.countryName.dk : "",
      countryCode: body.countryCode ? body.countryCode : "",
      containerInCountry: body.containerInCountry
        ? body.containerInCountry
        : [],
    });
  }, [params.Id]);
  useEffect(() => {
    getExportCountryById();
  }, [getExportCountryById]);

  //================Update Export Country============
  const updateExportCountryInfo = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = {
      countryCode: values.countryCode,
      countryName: {
        en: values.en, //required
        ar: values.ar, //required
        fr: values.fr,
        ru: values.ru,
        de: values.de,
        cn: values.cn,
        tur: values.tur,
        ir: values.ir,
        dk: values.dk,
      },
    };

    let NewCountryName = raw.countryName;

    Object.keys(NewCountryName).forEach((key) => {
      if (NewCountryName[key] === "") {
        delete NewCountryName[key];
      }
    });

    raw.countryName = NewCountryName;
    let NewRaw = JSON.stringify(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: NewRaw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/export-country/${values._id}`,
      requestOptions
    );
    const body = await response.json();
    await addExportExportCountryImage(body._id);
    if (!response.ok) {
      toast.error("Country NOT Added !!!");
      console.log(body);
    } else {
      toast.success("Country Has Been Added Successfully");
      navigate("/dashboard/crexportcountries");
    }
  };
  //=================================Delete Container From Export Country =============================
  const deleteContainerFromExportCountry = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      exportCountryId: values._id,
      containerId: containerId,
    });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/export-country/container",
      requestOptions
    );
    if (!response.ok) {
      toast.error("Container Not Deleted");
      console.log(response);
    } else {
      toast.success("Container Has Been Deleted !!!!");

    }
  };
  //========================Cancel btn===============================
  const cancel = () => {
    navigate("/dashboard/crexportcountries");
  };
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  //=================================================================================
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Container",
      selector: (row) => <span>{row.usualName}</span>,
    },
    {
      name: "Frozen",
      selector: (row) => <span>{row.isFridge ? "Yes" : "No"}</span>,
    },
    {
      name: "Delete",
      selector: (row) => (
        <span>
          <Button
            variant="danger"
            onClick={() => {
              handleShow();
              setContainerId(row._id);
            }}
          >
            Delete Container
          </Button>

        </span>
      ),
    },
  ];
  return (
    <section className="cms-create-export-country">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Container</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are You Sure You Want To Delete This Container ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={deleteContainerFromExportCountry}
          >
            Delete Container
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <div className="crud-countries">
          <div className="title  text-center">
            <h1>Update Export Countries</h1>
          </div>
        </div>

        <Form>
          <div className="create-country">
            <FormGroup>
              <FormLabel>
                Country Code
                <select
                  id="category-list"
                  name="countryCode"
                  value={values.countryCode}
                  onChange={handleInputChange}
                  required
                >
                  <option>Please Choose A Country Code</option>
                  {countries.map((country, index) => {
                    return (
                      <option value={country.alpha2Code} key={index}>
                        {`${country.alpha2Code + "===>" + country.name}`}
                      </option>
                    );
                  })}
                  <option value={"EU"}>{`EU  ===> European Union`}</option>
                </select>
              </FormLabel>
            </FormGroup>


            <Form.Group>
              <Form.Label htmlFor="CountryNameInEn" style={{ color: "red" }}>
                Arabic & English R Required
              </Form.Label>
              <Form.Label htmlFor="CountryNameInEn">
                Country Name In English /
                <span style={{ color: "red" }}>Required</span>
              </Form.Label>
              <Form.Control
                name="en"
                label="category"
                value={values.en}
                onChange={handleInputChange}
                placeholder="name in en"
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="CountryNameInEn">
                Country Name In Arabic /
                <span style={{ color: "red" }}>Required</span>
              </Form.Label>

              <Form.Control
                name="ar"
                label="category"
                value={values.ar}
                onChange={handleInputChange}
                placeholder="name in ar"
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label htmlFor="CountryNameInEn">
                Country Name In French
              </Form.Label>

              <Form.Control
                name="fr"
                label="category"
                value={values.fr}
                onChange={handleInputChange}
                placeholder="name in fr"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="CountryNameInEn">
                Country Name In Russian
              </Form.Label>

              <Form.Control
                name="ru"
                label="category"
                value={values.ru}
                onChange={handleInputChange}
                placeholder="name in ru"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="CountryNameInEn">
                Country Name In German
              </Form.Label>

              <Form.Control
                name="de"
                label="category"
                value={values.de}
                onChange={handleInputChange}
                placeholder="name in de"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="CountryNameInEn">
                Country Name In China
              </Form.Label>

              <Form.Control
                name="cn"
                label="category"
                value={values.cn}
                onChange={handleInputChange}
                placeholder="name in cn"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="CountryNameInEn">
                Country Name In Turkish
              </Form.Label>
              <Form.Control
                name="tur"
                label="category"
                value={values.tur}
                onChange={handleInputChange}
                placeholder="name in tur"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label htmlFor="CountryNameInEn">
                Country Name In Persian
              </Form.Label>
              <Form.Control
                name="ir"
                label="category"
                value={values.ir}
                onChange={handleInputChange}
                placeholder="name in Persian"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label htmlFor="CountryNameInEn">
                Country Name In Danish
              </Form.Label>
              <Form.Control
                name="dk"
                label="category"
                value={values.dk}
                onChange={handleInputChange}
                placeholder="name in Danish"
              />
            </Form.Group>

            {/* ===============================INFO===================== */}

            <Form.Group>
              <Form.Label htmlFor="CountryImg">Country Image</Form.Label>
              <div className="d-flex flex-column">
                <Form.Control
                  type="file"
                  name="image"
                  id="productImg"
                  onChange={handleImageChange}
                />
                {Imgfile.Imgfile && <div className="position-relative" style={{
                  width: "fit-content"
                }}>
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                    }}
                    src={"https://www.api.setalkel.co" + Imgfile.Imgfile}
                  />
                  <FaDownload onClick={() => getFile(Imgfile.Imgfile)} style={{
                    color: "red",
                    fontSize: "15px",
                    position: "absolute",
                    top: "0",
                    right: "0"

                  }} />
                  <FaTrashAlt style={{
                    color: "red",
                    fontSize: "15px",
                    position: "absolute",
                    top: "0",
                    right: "20px",
                    zIndex: "100"

                  }} />
                </div>
                }
              </div>
            </Form.Group>
          </div>
        </Form>
        <Form>
          <Button variant="warning" onClick={updateExportCountryInfo}>
            Update Export Country
          </Button>
          <Button variant="danger" onClick={deleteExportCountryImage}>
            Delete Export Country Image
          </Button>
          <Button variant="dark" onClick={cancel}>
            cancel
          </Button>
        </Form>
      </Row>
      <Row>
        {/* ================================Containers=============================== */}
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={values.containerInCountry}
          customStyles={customStyles}
        />


      </Row>
    </section>
  );
};

export default UpdateExportCountry;
