import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormLabel,
  Modal,
  Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

//css
import "./UpdateImportCountry.css";
import toast from "react-hot-toast";
import { FaDownload, FaTrashAlt } from "react-icons/fa";
const UpdateImportCountry = () => {
  const navigate = useNavigate();

  const params = useParams();
  const [importCountries, setImportCountries] = useState([]);
  const [importStates, setImportStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [containerId, setContainerId] = useState("");
  const [maxWeightInState, setMaxWeightInState] = useState("");
  const [minWeightInState, setMinWeightInState] = useState("");
  const [countryNames, setCountryNames] = useState({
    en: "", //required
    ar: "", //required
    fr: "",
    ru: "",
    de: "",
    cn: "",
    tur: "",
    ir: "",
    dk: "",
  });
  const [StateNames, setStateNames] = useState({
    en: "", //required
    ar: "", //required
    fr: "",
    ru: "",
    de: "",
    cn: "",
    tur: "",
    ir: "",
    dk: "",
  });
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const [deletedStates, setDeletedStates] = useState([]);

  //===================================Mutliple Delete Offers====================================
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  //==================Get Country Codes==================

  useEffect(() => {
    const getCountriesCodes = async () => {
      const response = await fetch("https://restcountries.com/v2/all");
      const body = await response.json();
      if (!response.ok) {
        setCountries([]);
        console.log(body);
      } else {
        setCountries(body);
      }
    };
    getCountriesCodes();
  }, []);
  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  //=======================Input Handeling===============
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCountryNames({
      ...countryNames,
      [name]: value,
    });
  };
  // //=======================Input State Names Handeling===============
  // const handleStateInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setStateNames({
  //     ...StateNames,
  //     [name]: value,
  //   });
  // };

  //=============================get Import Countries States Agents ==========================
  useEffect(() => {
    const getStatesAgentsInImportCountry = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/import-country/admin",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        // setStatesAgents([]);
        setImportCountries([]);
        console.log(body);
      } else {
        setImportCountries(body);
      }
    };
    getStatesAgentsInImportCountry();
  }, []);
  //===========================================getImportCountryById=============================
  const getImportCountryById = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/import-country/${params.Id}`,
      requestOptions
    );
    const body = await response.json();
    setImportStates(body.states);
    setImgfile({ Imgfile: body.img })
    setCountryNames({
      en: body.countryName.en ? body.countryName.en : "",
      ar: body.countryName.ar ? body.countryName.ar : "",
      fr: body.countryName.fr ? body.countryName.fr : "",
      ru: body.countryName.ru ? body.countryName.ru : "",
      de: body.countryName.de ? body.countryName.de : "",
      cn: body.countryName.cn ? body.countryName.cn : "",
      ir: body.countryName.ir ? body.countryName.ir : "",
      dk: body.countryName.dk ? body.countryName.dk : "",
      tur: body.countryName.tur ? body.countryName.tur : "",
    });
    for (let i = 0; i < body.states.length; i++) {
      const element = body.states[i];
      if (element._id === stateId) {
        setStateNames({
          en: element.stateName.en ? element.stateName.en : "",
          ar: element.stateName.ar ? element.stateName.ar : "",
          fr: element.stateName.fr ? element.stateName.fr : "",
          ru: element.stateName.ru ? element.stateName.ru : "",
          de: element.stateName.de ? element.stateName.de : "",
          cn: element.stateName.cn ? element.stateName.cn : "",
          ir: element.stateName.ir ? element.stateName.ir : "",
          dk: element.stateName.dk ? element.stateName.dk : "",
          tur: element.stateName.tur ? element.stateName.tur : "",
        });
      }
    }
    setCountryId(body._id);
    setCountryCode(body.countryCode);
  }, [params.Id, stateId]);

  useEffect(() => {
    getImportCountryById();
  }, [getImportCountryById]);
  //=====================Add Import Country Image================
  const addImportCountryImage = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    var formdata = new FormData();
    formdata.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/import-country/image/${countryId}`,
      requestOptions
    );
    if (!response.ok) {
      toast.error("Image Has Not Been Added !!!!!!!!!!");
    } else {
      toast.success("Image Has Been Added");
    }
  };
  //=====================update import country===================
  const updateImportCountry = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = {
        countryCode: countryCode,
        countryName: countryNames,
      };
      let NewCountryNames = raw.countryName;
      Object.keys(NewCountryNames).forEach((key) => {
        if (NewCountryNames[key] === "") {
          delete NewCountryNames[key];
        }
      });
      raw.countryName = NewCountryNames;
      let NewRaw = JSON.stringify(raw);
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: NewRaw,
        redirect: "follow",
      };
      const response = await fetch(
        `https://www.api.setalkel.co/import-country/${countryId}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        toast.error("Country Not Updated !!!!!!");
        console.log(body);
      } else {
        if (Imgfile.Imgfile) {
          await addImportCountryImage();
        }
        toast.success("Country Updated Succesfully");
        navigate("/dashboard/crimportcountries");
      }
    } catch (error) {
      console.log(error);
    }
  };


  //=========================cancel=========================
  const cancel = () => {
    navigate("/dashboard/crimportcountries");
  };
  return (
    <section className="cms-update-import-country">
      <Row>
        <div className="crud-countries">
          <div className="title">
            <h1>Update Import Country</h1>
          </div>
        </div>
        <div className="create-import-country">
          <Form>

            <Form.Label htmlFor="CountryNameInEn" style={{ color: "red" }}>
              Arabic & English R Required
            </Form.Label>
            <div className="country-langs">
              <FormGroup>
                <FormLabel>
                  Country Code
                  <select
                    id="category-list"
                    name="countryCode"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    required
                  >
                    <option>Please Choose A Country Code</option>
                    {countries.map((country, index) => {
                      return (
                        <option value={country.alpha2Code} key={index}>
                          {`${country.alpha2Code + "===>" + country.name}`}
                        </option>
                      );
                    })}
                    <option value={"EU"}>{`EU  ===> European Union`}</option>
                  </select>
                </FormLabel>
              </FormGroup>
              <Form.Group>
                <Form.Label htmlFor="CountryNameInEn">
                  Country Name In English /
                  <span style={{ color: "red" }}>Required</span>
                </Form.Label>
                <Form.Control
                  name="en"
                  label="category"
                  value={countryNames.en}
                  onChange={handleInputChange}
                  placeholder="name in en"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="CountryNameInEn">
                  Country Name In Arabic /
                  <span style={{ color: "red" }}>Required</span>
                </Form.Label>

                <Form.Control
                  name="ar"
                  label="category"
                  value={countryNames.ar}
                  onChange={handleInputChange}
                  placeholder="name in ar"
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="CountryNameInEn">
                  Country Name In French
                </Form.Label>

                <Form.Control
                  name="fr"
                  label="category"
                  value={countryNames.fr}
                  onChange={handleInputChange}
                  placeholder="name in fr"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="CountryNameInEn">
                  Country Name In Russian
                </Form.Label>

                <Form.Control
                  name="ru"
                  label="category"
                  value={countryNames.ru}
                  onChange={handleInputChange}
                  placeholder="name in ru"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="CountryNameInEn">
                  Country Name In German
                </Form.Label>

                <Form.Control
                  name="de"
                  label="category"
                  value={countryNames.de}
                  onChange={handleInputChange}
                  placeholder="name in de"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="CountryNameInEn">
                  Country Name In China
                </Form.Label>

                <Form.Control
                  name="cn"
                  label="category"
                  value={countryNames.cn}
                  onChange={handleInputChange}
                  placeholder="name in cn"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="CountryNameInEn">
                  Country Name In Turkish
                </Form.Label>
                <Form.Control
                  name="tur"
                  label="category"
                  value={countryNames.tur}
                  onChange={handleInputChange}
                  placeholder="name in tur"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="CountryNameInEn">
                  Country Name In Persian
                </Form.Label>
                <Form.Control
                  name="ir"
                  label="category"
                  value={countryNames.ir}
                  onChange={handleInputChange}
                  placeholder="Country name in Persian"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="CountryNameInEn">
                  Country Name In Danish
                </Form.Label>
                <Form.Control
                  name="dk"
                  label="category"
                  value={countryNames.dk}
                  onChange={handleInputChange}
                  placeholder="Country name in Danish"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="CountryImg">Country Image</Form.Label>
                <div className="d-flex flex-column">
                  <Form.Control
                    type="file"
                    name="image"
                    id="productImg"
                    onChange={handleImageChange}
                  />
                  {Imgfile.Imgfile && <div className="position-relative" style={{
                    width: "fit-content"
                  }}>
                    <img
                      style={{
                        height: "100px",
                        width: "100px",
                      }}
                      src={"https://www.api.setalkel.co" + Imgfile.Imgfile}
                    />
                    <FaDownload onClick={() => getFile(Imgfile.Imgfile)} style={{
                      color: "red",
                      fontSize: "15px",
                      position: "absolute",
                      top: "0",
                      right: "0"

                    }} />
                    <FaTrashAlt style={{
                      color: "red",
                      fontSize: "15px",
                      position: "absolute",
                      top: "0",
                      right: "20px",
                      zIndex: "100"

                    }} />
                  </div>
                  }
                </div>
              </Form.Group>
            </div>


            <div className="d-flex">
              <Button variant="warning" onClick={updateImportCountry}>
                Update Import Country
              </Button>
              <Button variant="dark" onClick={cancel}>
                Cancel
              </Button>
            </div>

          </Form>
        </div>
      </Row>
      <hr />
      <Row>

      </Row>
    </section>
  );
};

export default UpdateImportCountry;
