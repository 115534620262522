import React from "react";
import { useSelector } from "react-redux";
// scss
import "./topnav.scss";
// images
import CartImg from "../../../assets/Payments/cart.png";
// notification
import Notification from "../Notification/AdminNotification";
// icons
import { BsFillBellFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const TopNav = () => {
  const adminInfo = localStorage.getItem("user-data");

  // Toggle sidebar open/close
  const openSidebar = () => {
    document.body.classList.toggle("sidebar-open");
    // document.querySelector(".main__content").classList.toggle("move")

  };

  const adminCartQuantity = useSelector(
    (state) => state.adminCart.adminCartTotalQuantity
  );

  const navigate = useNavigate();
  const navigateToAdminCart = () => {
    navigate("/dashboard/adminCart");
  };

  console.log(adminCartQuantity);

  return (
    <div className="topnav">
      <div className="topnav-info">
        <span>User : {adminInfo}</span>
        <div>
          <Notification />
          {adminInfo && <BsFillBellFill size={"20px"} />}
        </div>
      </div>

      <div>
        {adminCartQuantity > 0 ? (
          <>
            {adminCartQuantity}
            <br />
            <img
              src={CartImg}
              alt="cart"
              className="cart"
              onClick={() => navigateToAdminCart()}
              style={{
                cursor: "pointer",
              }}
            />
          </>
        ) : null}
      </div>
      
      <div className="sidebar-toggle" onClick={openSidebar}>
        <i className="bx bx-menu-alt-right"></i>
      </div>
    </div>
  );
};

export default TopNav;
