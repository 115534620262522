import React from 'react'
import { Button, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const PromotionalMaterials = () => {
    const navigate = useNavigate()
    return (
        <Row>
            <h1>Promotional Materials</h1>
            <div className='d-flex'>
                <Button variant="warning" id="verify-btn" >
                    Export Excel File for PromotionalMaterials
                </Button>
                <Button onClick={()=>{
                    navigate("/dashboard/add-promotionalmaterials")
                }} variant="warning" id="verify-btn">
                    Add PromotionalMaterials
                </Button>
            </div>
        </Row>
    )
}

export default PromotionalMaterials