import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  agentNotifications: [],
  adminNotifications: [],
  notificationShipmentId: "",
};
const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    getNotificationsForAgent(state, action) {
      state.agentNotifications = action.payload;
    },
    getNotificationsForAdmin(state, action) {
      state.adminNotifications = action.payload;
    },
    getNotificationShipmentId(state, action) {
      state.notificationShipmentId = action.payload;
    },
  },
});
export const {
  getNotificationsForAgent,
  getNotificationsForAdmin,
  getNotificationShipmentId,
} = notificationSlice.actions;

export default notificationSlice.reducer;
