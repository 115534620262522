import { useMutation, useQuery } from "@tanstack/react-query";
import ApiInstances from "./axiso";
import { toast } from "react-hot-toast";



const UnVerifiedAgentquery = () => {
    const queryResult = useQuery({
        queryKey: ["get-agent-not"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/agent/admin/?isVerified=false')
            return data;
        },
    });
    return queryResult;
};
const VerifiedAgentquery = () => {
    const queryResult = useQuery({
        queryKey: ["get-agen"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/agent/admin/?isVerified=true')
            return data;
        },
    });
    return queryResult;
};

const AgentQuery = () => {
    const queryResult = useQuery({
        queryKey: ["get-users"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/agent/admin')
            return data;
        },
        select: (data) =>
            data.map((data) => ({
                value: data._id,
                label: data.name,
            })),
    });
    return queryResult;
};
const GenerateExelFileQuery = () => {
    const queryResult = useQuery({
        queryKey: ["get-ex-file"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/agent/export-agents')
            return data;
        },
    });
    return queryResult;
};

const GetExelFileQuery = (file) => {
    const queryResult = useQuery({
        queryKey: ["get-ex-file"],
        queryFn: async () => {
            const { data } = await ApiInstances.get(`${file}`)
            return data;
        },
        enabled: !!file
    });
    return queryResult;
};

const DeleteAllVerificationFilesQuery = (agentId) => {
    const mutation = useMutation({
        mutationFn: async () => {
            const { data } = await ApiInstances.delete(`agent/verification/${agentId}`)
            return data;
        },
        onSuccess: () => {
            toast.success("Agent Verification Files Has Been Deleted Successfully");
        },
        onError: () => {
            toast.error("files Not Deleted !!!")
        }
    });
    return mutation;
};
const DeleteUnVerificationQuery = (agentId) => {
    console.log(agentId)
    const mutation = useMutation({
        mutationFn: async () => {
            const { data } = await ApiInstances.delete(`agent/${agentId}`)
            return data;
        },
        onSuccess: () => {
            toast.success("item deleted");
        },
        onError: () => {
            toast.error("Something Rong")
        }
    });
    return mutation;
};
const VerifiedAgentQuery = (data) => {
    const mutation = useMutation({
        mutationFn: async () => {
            const res = await ApiInstances.put('/agent/verification', data)
            return res;
        },
        onSuccess: () => {
            console.log('done')
        }
    });
    return mutation;
};

const ShowHideAgentsQuery = (data) => {
    const Result = useMutation({
        mutationFn: async () => {
            const res = await ApiInstances.put('/agent/show-hidden', data)
            return res;
        },
        onSuccess: () => {
            toast.success("Agent/s State Has Been Updated");
        },
        onError: () => {
            toast.error("Agent/s State Not Updated !!!!");
        }
    });
    return Result;
};

const DeleteMultipleِAgents = (deletedProducts) => {
    console.log(deletedProducts)
    const mutation = useMutation({
        mutationFn: async () => {
            const { data } = await ApiInstances.delete(`agent/collection`, {
                data: deletedProducts
            })
            return data;
        },
        onSuccess: () => {
            toast.success("Agents Have Been Deleted !!");
        },
        onError: () => {

            toast.error("There Is An Error ");
        }
    });
    return mutation;
};
const GetFileExcel = async (generateExcelFile) => {
    try {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        const response = await fetch(
            `https://www.api.setalkel.co${generateExcelFile.pathFile}`,
            requestOptions
        );

        if (!response.ok) {
            throw new Error("Failed to fetch file.");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${generateExcelFile.pathFile}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.log("Error:", error);
    }
};

const GenerateExelFileQueryForCategories = () => {
    const queryResult = useQuery({
        queryKey: ["get-ex-file-cat"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/product/cat/exports')
            return data;
        },
    });
    return queryResult;
};
const GenerateExelFileQueryForContainer = () => {
    const queryResult = useQuery({
        queryKey: ["get-ex-file-cont"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/container/exports')
            return data;
        },
    });
    return queryResult;
};
const GenerateExelFileQueryForAboutUs = () => {
    const queryResult = useQuery({
        queryKey: ["get-ex-file-cont"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/aboutUs/exports')
            return data;
        },
    });
    return queryResult;
};
const GenerateExelFileQueryForContactUs = () => {
    const queryResult = useQuery({
        queryKey: ["get-ex-file-conatct-us"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/contactUs/exports')
            return data;
        },
    });
    return queryResult;
};
const GenerateExelFileQueryForActivites = () => {
    const queryResult = useQuery({
        queryKey: ["get-ex-file-active"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/our-activities/exports')
            return data;
        },
    });
    return queryResult;
};
const GenerateExelFileQueryForPartners = () => {
    const queryResult = useQuery({
        queryKey: ["get-ex-file-partners"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/partners/exports')
            return data;
        },
    });
    return queryResult;
};
const GenerateExelFileQueryForKitchen = () => {
    const queryResult = useQuery({
        queryKey: ["get-ex-file-partners"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/kitchen/exports')
            return data;
        },
    });
    return queryResult;
};
const GenerateExelFileQueryForExportCountry = () => {
    const queryResult = useQuery({
        queryKey: ["get-ex-export-country"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/export-country/exports')
            return data;
        },
    });
    return queryResult;
};
const GenerateExelFileQueryForImportCountry = () => {
    const queryResult = useQuery({
        queryKey: ["get-ex-import-country/exports"],
        queryFn: async () => {
            const { data } = await ApiInstances.get('/import-country/exports')
            return data;
        },
    });
    return queryResult;
};
const UnVerifiedAgentsApi = {
    UnVerifiedAgentquery,
    GenerateExelFileQueryForImportCountry,
    GenerateExelFileQueryForExportCountry,
    GenerateExelFileQueryForKitchen,
    GenerateExelFileQueryForAboutUs,
    GenerateExelFileQueryForContactUs,
    DeleteMultipleِAgents,
    VerifiedAgentQuery,
    ShowHideAgentsQuery,
    DeleteAllVerificationFilesQuery,
    DeleteUnVerificationQuery,
    GenerateExelFileQueryForActivites,
    GenerateExelFileQuery,
    GetExelFileQuery,
    GetFileExcel,
    GenerateExelFileQueryForCategories,
    GenerateExelFileQueryForContainer,
    GenerateExelFileQueryForPartners,
    AgentQuery,
    VerifiedAgentquery
}
export default UnVerifiedAgentsApi