import React, { useState, useEffect } from "react";
import axios from "axios";

//css
import "./CRContactUs.css";
//bootstrap
import { Row, Button, Form, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import toast from "react-hot-toast";
import UnVerifiedAgentsApi from "../../Api/UnVerifiedAgentsApi";
//links
const CRContactus = () => {
  const [contacts, setContacts] = useState([]);
  const [contactId, setContactId] = useState("");
  const [deletedContacts, setDeletedContacts] = useState({
    ids: [],
  });
  //======================================Delete Contact Modal States==========================================
  const [DeleteContactShow, setDeleteContactShow] = useState(false);
  const handleClose = () => setDeleteContactShow(false);
  const handleShow = () => setDeleteContactShow(true);
  //======================================Delete Contacts Modal States==========================================
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);
  //=======================Fetch Contacts===============
  const [generateExcelFile, setGenerateExecelFile] = useState()
  useEffect(() => {
    const fetchContacts = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/contactUs",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        console.log(body);
      } else {
        setContacts(body);
      }
    };
    fetchContacts();
  }, []);
  //==============Delete Contact=====================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/contactUs/${contactId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          toast.success("item deleted");
          handleClose()
        }
      })
      .catch((error) => {
        toast.error(error.response);
        console.log("error", error.response);
      });
  };
  //=========================================download File ===============================================
  const getFile = async (file) => {
    console.log(file)
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  //============================Delete Mutliple Product=====================

  const deleteContacts = (e, contactID) => {
    if (e.target.checked) {
      if (!deletedContacts.ids.includes(contactID)) {
        setDeletedContacts({
          ...deletedContacts,
          ids: [...deletedContacts.ids, contactID],
        });
      }
    } else {
      setDeletedContacts({
        ids: deletedContacts.ids.filter((item) => item !== contactID),
      });
    }
  };
  const deleteMultipleContacts = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(deletedContacts);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/contactUs/collection",
      requestOptions
    );
    if (!response.ok) {
      alert("There Is An Error ");
      console.log(response);
    } else {
      alert("Contacts Have Been Deleted !!");
      window.location.reload();
    }
  };
  useEffect(() => {
    if (deletedContacts.ids.length > 0) {
      document.getElementById("multipleDelete").style.visibility = "visible";
    } else {
      document.getElementById("multipleDelete").style.visibility = "hidden";
    }
  }, [deletedContacts.ids]);
  const columns = [
    { name: "Name", selector: (row) => row.name },
    {
      name: "Company Name",
      selector: (row) => row.companyName
        ? row.companyName
        : " لا يوجد اسم شركة"

    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber
        ? row.phoneNumber : "لا يوجد رقم تليفون"
    },
    {
      name: "Website",
      selector: (row) => row.website
        ? row.website : "لا يوجد موقع"
    },
    {
      name: "Role",
      selector: (row) => (
        <span>{row.role ? row.role : " لا يوجد شركة"}</span>
      ),
    },
    {
      name: "Email",
      selector: (row) => (
        <span>
          {row.email ? row.email : " لا يوجد بريد الكتروني"}
        </span>
      ),
    },
    {
      name: "Country",
      selector: (row) => (
        <span>
          {row.country ? row.country : "لا يوجد بلد"}
        </span>
      ),
    },
    {
      name: "City",
      selector: (row) => (
        <span>
          {row.city ? row.city : "لا يوجد مدينة"}
        </span>
      ),
    },
    {
      name: "Trade Activity",
      selector: (row) => (
        <span>
          {row.tradeActivity ? row.tradeActivity : "لا يوجد نشاط تجاري"}
        </span>
      ),

    },
    {
      name: "Visitor Interests",
      selector: (row) => (
        <span>
          {row.visitorInterests?.length > 0 ? row.visitorInterests.map((d) => d).join(",") : "لا يوجد اهتمامات"}
        </span>
      ),
      width: "700px"
    },
    {
      name: "Special Requirements",

      selector: (row) => (
        <Form.Control className="textarea" as="textarea" disabled row={10} cols={10} width="10rem">
          {row.specialRequirements ? row.specialRequirements : "لا يوجد متطلبات"}
        </Form.Control>
      ),

    },
    {
      name: "Visitor Remarks",
      selector: (row) => (
        <span>
          {row.visitorRemarks ? row.visitorRemarks : "لا يوجد ملاحظات"}
        </span>
      ),
    },
    {
      name: "Visitor Summary",
      selector: (row) => (
        <span>
          {row.visitSummary ? row.visitSummary : "لا يوجد ملخصات"}
        </span>
      ),
    },
    {
      name: "Files",
      selector: (row) => (
        <div>
          {row.files.length > 0
            ? row.files.map((file) => {
              return (
                <Button
                  variant="outline-warning"
                  onClick={() => getFile(file)}
                >
                  {file.slice(16)}
                </Button>
              );
            })
            : "No Files To Show / لا يوجد ملفات لعرضها"}
        </div>
      ),
    },
    {
      name: "Recived At",
      selector: (row) => (
        <span>
          {row.createdAt
            ? row.createdAt
            : "No Reciving Date / تاريخ الوصول غير معروف"}
        </span>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <>
          <Button
            variant="danger"
            onClick={() => {
              handleShow();
              setContactId(row._id);
            }}
          >
            Delete
          </Button>


        </>
      ),
    },
    {
      name: "M-Delete",
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteContacts(e, row._id)}
        />
      ),
    },
  ];
  const { data: Excel } = UnVerifiedAgentsApi.GenerateExelFileQueryForContactUs()
  const getFileExcel = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co${generateExcelFile.pathFile}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${generateExcelFile.pathFile}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  return (
    <section className="cms-contactus" style={{
      overflow:"hidden"
    }}>
      <div style={{ width: "100%" }}>
        <div style={{
          width:"100%",
          textAlign:"center"
        }}>
          <h1>Contacts</h1>
        </div>
       
      </div>
      <div style={{
        display: "flex"
      }}>
        {!generateExcelFile ? <Button variant="warning" id="verify-btn" onClick={() => setGenerateExecelFile(Excel)}>
          Export Excel  Contact Us
        </Button> : <Button variant="success" id="verify-btn" onClick={getFileExcel}>
          Download Excel File
        </Button>}
        <Button variant="danger" id="verify-btn" >
          Import Excel
        </Button>
          <Button
            id="multipleDelete"
            variant="outline-danger"
            onClick={() => handleMultipleShow()}
          >
            Multiple Delete
          </Button>
      </div>
      <Row>
        <DataTable
          fixedHeaderScrollHeight="600px"
          // pagination
          fixedHeader
          responsive
          subHeaderWrap
          columns={columns}
          data={contacts}
          customStyles={customStyles}
        />
      </Row>
       <div>
          <br />
        
          <Modal
            size="lg"
            show={MultipleDeleteModalShow}
            onHide={() => setMultipleDeleteModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Are You Sure You Want To Delete All Those Contacts
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleMultipleClose}>
                Close
              </Button>
              <Button variant="danger" onClick={deleteMultipleContacts}>
                Delete Contacts
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      <Modal show={DeleteContactShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure You Want To Delete Contact</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => onDelete()}>
            Delete Contact
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default CRContactus;
