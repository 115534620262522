import React from 'react'
import { useState } from 'react';
import { Row, Button, Form, Modal } from "react-bootstrap";
const AddCategoryAlAmeen = () => {
    const [values, setValues] = useState({
        cat: "",
        en_name: "",
        ar_name: "",
        fr_name: "",
        ru_name: "",
        de_name: "",
        cn_name: "",
        tur_name: "",
        ir_name: "",
        dk_name: "",
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
  return (
    <div>
    <div className="crud-category">
        <Form className="categories-form">
            <div className="category-langs">
                <Form.Group>
                    <Form.Label  htmlFor="ProductNameInEn"style={{
                        color:"red"
                    }}>
                        AlAmeen Category  Name In English
                    </Form.Label >
                    <Form.Control
                    style={{
                        border:"1px solid red"
                    }}
                        name="en_name"
                        label="category"
                        value={values.en_name}
                        onChange={handleInputChange}
                        placeholder="name in en"
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label  htmlFor="CategoryNameInEn" style={{
                        color:"red"
                    }}>
                        AlAmeen Category  Name In Arabic
                    </Form.Label >

                    <Form.Control
                    style={{
                        border:"1px solid red"
                    }}
                        name="ar_name"
                        label="category"
                        value={values.ar_name}
                        onChange={handleInputChange}
                        placeholder="name in ar"
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label  htmlFor="CategoryNameInEn" style={{
                        color:"red"
                    }}>
                        AlAmeen Category  Name In French
                    </Form.Label >
                    <Form.Control
                    style={{
                        border:"1px solid red"
                    }}
                        name="fr_name"
                        label="category"
                        value={values.fr_name}
                        onChange={handleInputChange}
                        placeholder="name in fr"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label  htmlFor="CategoryNameInEn" style={{
                        color:"red"
                    }}>
                        AlAmeen Category  Name In Russian
                    </Form.Label >

                    <Form.Control
                    style={{
                        border:"1px solid red"
                    }}
                        name="ru_name"
                        label="category"
                        value={values.ru_name}
                        onChange={handleInputChange}
                        placeholder="name in ru"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label  htmlFor="CategoryNameInEn" style={{
                        color:"red"
                    }}>
                        AlAmeen Category  Name In German
                    </Form.Label >

                    <Form.Control
                    style={{
                        border:"1px solid red"
                    }}
                        name="de_name"
                        label="category"
                        value={values.de_name}
                        onChange={handleInputChange}
                        placeholder="name in de"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label  htmlFor="CategoryNameInEn" style={{
                        color:"red"
                    }}>
                        AlAmeen Category  Name In China
                    </Form.Label >

                    <Form.Control
                    style={{
                        border:"1px solid red"
                    }}
                        name="cn_name"
                        label="category"
                        value={values.cn_name}
                        onChange={handleInputChange}
                        placeholder="name in cn"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label  htmlFor="CategoryNameInEn" style={{
                        color:"red"
                    }}>
                        AlAmeen Category  Name In Turkish
                    </Form.Label >
                    <Form.Control
                    style={{
                        border:"1px solid red"
                    }}
                        name="tur_name"
                        label="category"
                        value={values.tur_name}
                        onChange={handleInputChange}
                        placeholder="name in tur"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label  htmlFor="CategoryNameInEn" style={{
                        color:"red"
                    }}>
                        AlAmeen Category  Name In Persian
                    </Form.Label >
                    <Form.Control
                    style={{
                        border:"1px solid red"
                    }}
                        name="ir_name"
                        label="category"
                        value={values.ir_name}
                        onChange={handleInputChange}
                        placeholder="name in ir"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label  htmlFor="CategoryNameInEn" style={{
                        color:"red"
                    }}>
                        AlAmeen Category  Name In Danish
                    </Form.Label >
                    <Form.Control
                    style={{
                        border:"1px solid red"
                    }}
                        name="dk_name"
                        label="category"
                        value={values.dk_name}
                        onChange={handleInputChange}
                        placeholder="name in dk"
                    />
                </Form.Group>
              
            </div>
        </Form>
        <Button type="button" variant="warning">
            Add Category
        </Button>
    </div>
</div>
  )
}

export default AddCategoryAlAmeen