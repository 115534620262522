import React, { useState } from "react";
import { Link } from "react-router-dom";
//icons
import SearchImg from "../../assets/Search.png";
//css
import "./SearchBar.css";

const SearchBar = ({ placeholder, data }) => {
  const [filterdData, setFilterdData] = useState([]);
  const [WordEntered, setWordEntered] = useState("");
  const [clicked, setClicked] = useState(false);
  const docLang = document.querySelector("html").lang;
  const handleFilter = (e) => {
    const SearchWord = e.target.value;
    setWordEntered(SearchWord);
    const newFilter = data.filter((value) => {
      return value.subCat[docLang]
        .toLowerCase()
        .includes(SearchWord.toLowerCase());
    });
    if (SearchWord === "") {
      setFilterdData([]);
    } else {
      setFilterdData(newFilter);
    }
  };
  const handleClick = () => {
    setClicked((prev) => !prev);
    setWordEntered("");
  };

  return (
    <div className="search">
      <div className="search-inputs">
        <input
          type="text"
          name="search"
          id="searchInput"
          style={{
            width: clicked && "280px",
            background: clicked && "#fff",
            borderRadius: clicked && "24px",
            padding: clicked && "0.7rem",
          }}
          placeholder={placeholder}
          value={WordEntered}
          onChange={handleFilter}
        />
        <div onMouseMove={handleClick} className="searchIcon">
          <img src={SearchImg} alt="searchImg" />
        </div>
      </div>
      {filterdData.length !== 0 && (
        <div className="dataResults">
          {filterdData.map((value, key) => {
            return (
              <p
                onClick={() => {
                  handleClick();
                  setWordEntered("");
                  setFilterdData([]);
                }}
                key={key}
              >
                <Link to={`/products/${value._id}`} className="data-item">
                  {value.subCat[docLang]
                    ? value.subCat[docLang] + " " + value.peiceQty.usualWeight +  " " + value.cat[docLang]
                    : value.subCat.className + " " + value.peiceQty.usualWeight}
                </Link>
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
