export const languages = [
  {
    code: "fr",
    name: "French",
    dir: "ltr",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    dir: "ltr",
    country_code: "gb",
  },
  {
    code: "ru",
    name: "Russian",
    dir: "ltr",
    country_code: "ru",
  },
  {
    code: "de",
    name: "Germany",
    dir: "ltr",
    country_code: "de",
  },
  {
    code: "cn",
    name: "Chinese",
    dir: "ltr",
    country_code: "cn",
  },
  {
    code: "tr",
    name: "Turkish",
    dir: "ltr",
    country_code: "tr",
  },
  {
    code: "dk",
    name: "Danish",
    dir: "ltr",
    country_code: "dk",
  },
  {
    code: "ir",
    name: "Persian",
    dir: "rtl",
    country_code: "ir",
  },
  {
    code: "ar",
    name: "Arabic",
    dir: "rtl",
    country_code: "sa",
  },
];
