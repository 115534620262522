import React from "react";
//css
import "./CartPopUp.css";

//bootstrap
import Modal from "react-bootstrap/Modal";
//translation
const CartPopUp = ({ img, message, CartMsgModalShow, setCartMsgModalShow }) => {
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img src={img} alt="confirmimg" className="w-100" />
          <p className="text-center">{message}</p>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <MyVerticallyCenteredModal
      show={CartMsgModalShow}
      onHide={() => setCartMsgModalShow(false)}
    />
  );
};

export default CartPopUp;
