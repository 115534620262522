import React, { useEffect, useState } from 'react'

const WebsiteVisitors = () => {
  const [visitors, setVisitours] = useState()
  useEffect(() => {
    const getVisitour = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: myHeaders,
      };

      const response = await fetch(
        "https://www.api.setalkel.co/visitors",
        requestOptions
      );

      const body = await response.json();
      if (!response.ok) {

        console.log(body);
      } else {
        // console.log(body);
        setVisitours(body)
      }
      // console.log(body);
    };
    getVisitour();
  }, []);
  return (
    <div className='visitour'>
      Count Website Visitor : {visitors?.countWebsiteVisitor}
    </div>
  )
}

export default WebsiteVisitors