import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Roles } from "../AdminRoles";
import jwt_decode from "jwt-decode";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../constants/DataTableCustomeStyles";
const UpdateAdmins = () => {
  const adminToken = localStorage.getItem("admin-token");
  var decodedToken = jwt_decode(adminToken);
  const params = useParams();
  const navigate = useNavigate();

  const [adminInfo, setAdminInfo] = useState({
    name: "",
    email: "",
    password: "",
    currentPassword: "",
    role: [],
  });
  //=============================Handle Info===============================

  const handleInfo = (e) => {
    const { name, value } = e.target;
    setAdminInfo({ ...adminInfo, [name]: value });
  };
  //=======================================Check All Privilages====================================
  const checks = document.getElementsByClassName("form-check-input");
  const checkallPrivilages = () => {
    const checkAll_check = document.getElementById("checkAll-check");
    if (checkAll_check.checked) {
      for (let i = 0; i < checks.length; i++) {
        const element = checks[i];
        element.checked = true;
        adminInfo.role.push(element.value);
      }
      adminInfo.role.shift();
    } else {
      adminInfo.role = [];
      for (let i = 0; i < checks.length; i++) {
        const element = checks[i];
        element.checked = false;
      }
    }
  };
  const handleRole = (e) => {
    if (e.target.checked) {
      if (!adminInfo.role.includes(e.target.value)) {
        setAdminInfo({
          ...adminInfo,
          role: [...adminInfo.role, e.target.value],
        });
      }
    } else {
      setAdminInfo({
        ...adminInfo,
        role: adminInfo.role.filter((item) => item !== e.target.value),
      });
    }
  };
  //==================================================Check Existing Checkes======================================
  useEffect(() => {
    if (adminInfo.role !== undefined) {
      for (let i = 0; i < checks.length; i++) {
        const element = checks[i];
        let e = adminInfo.role.find((item) => element.value === item);
        if (e !== undefined) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      }
    }
  }, [adminInfo.role, checks]);

  //=============================Get Admin By Id For Super Admin===========================
  useEffect(() => {
    const getAdminByIdForAdmin = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      if (params.id) {
        const response = await fetch(
          `https://www.api.setalkel.co/admin/${params.id}`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          console.log(body);
        } else {
          setAdminInfo({
            email: body.email,
            name: body.name,
            role: body.role,
          });
        }
      }
    };
    getAdminByIdForAdmin();
  }, [params.id]);

  //======================================Update Admin Passowrd===============================
  const updateAdminPassword = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      currentPassword: adminInfo.currentPassword,
      password: adminInfo.password,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    if (adminInfo.currentPassword && adminInfo.password) {
      const resposne = await fetch(
        "https://www.api.setalkel.co/admin/reset-password",
        requestOptions
      );
      if (!resposne.ok) {
        alert("Password Not Updated");
        console.log(resposne);
      } else {
        alert("Password Has Been Changed Please Keep It Safe");
        window.location.reload();
      }
    }
  };
  //=======================================Update Admin Info From Super Admin=============================
  const updateAdminInfoBySuperAdmin = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: adminInfo.name,
      email: adminInfo.email,
      role: adminInfo.role,
    });
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    if (params.id) {
      const response = await fetch(
        `https://www.api.setalkel.co/admin/update-info-from-admin/${params.id}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        alert("Admin Info Not Updated");
        console.log(body);
      } else {
        adminInfo.currentPassword &&
          adminInfo.password &&
          updateAdminPassword();
        alert("admin Info Has been Updated Successfully");
        navigate("/dashboard/cradmins");
        window.location.reload();
      }
    } else {
      updateAdminInfo();
    }
  };

  //=======================================Update Admin Info=============================
  const updateAdminInfo = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: adminInfo.name,
      email: adminInfo.email,
    });
    console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/admin/update-info",
      requestOptions
    );
    if (!response.ok) {
      alert("Admin Info Not Updated");
      console.log(response);
    } else {
      adminInfo.currentPassword && adminInfo.password && updateAdminPassword();
      alert("admin Info Has been Updated Successfully");
      navigate("/dashboard/cradmins");
      window.location.reload();
    }
  };

  const Cancel = () => {
    navigate("/dashboard/cradmins");
  };

  //===================================================
  const columns = [
    {
      name: "Role",
      selector: (row, index) => (
        <Form.Group className="d-flex gap-2">
          <Form.Check name={row} id={index} value={row} onChange={handleRole} />
          <Form.Label>{row}</Form.Label>
        </Form.Group>
      ),
    },
  ];
  return (
    <section>
      <div className="admins">
        <div className="title">
          <h1>Update Admin</h1>
        </div>
        <div className="create-admin">
          <br />
          <Form>
            <p>Note : C : Create , R : Read , U : Update , D : Delete</p>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter User Name"
                required
                value={adminInfo.name}
                onChange={handleInfo}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                required
                value={adminInfo.email}
                placeholder="Enter Admin email"
                onChange={handleInfo}
              />
            </Form.Group>
            <hr />
            <h6>Do You Want To Chagne Your Password ? </h6>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                name="currentPassword"
                placeholder="Enter Old Password"
                required
                value={adminInfo.currentPassword}
                onChange={handleInfo}
              />
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Enter New Password"
                required
                value={adminInfo.password}
                onChange={handleInfo}
              />
            </Form.Group>
            <Form.Label>What Previlege Do You Want To Give ?</Form.Label>
            <Form.Label>Check All </Form.Label>
            <Form.Check
              block
              label="Check All"
              name="role"
              id="checkAll-check"
              onChange={checkallPrivilages}
            />
            {decodedToken.role.includes("u-admin-with-roles") && (
              <div>
                <DataTable
                  fixedHeaderScrollHeight="300px"
                  responsive
                  subHeaderWrap
                  columns={columns}
                  data={Roles}
                  customStyles={customStyles}
                />
              </div>
            )}

            <Button variant="warning" onClick={updateAdminInfoBySuperAdmin}>
              Update Admin
            </Button>
            <Button variant="dark" onClick={Cancel}>
              Cancel
            </Button>
          </Form>
        </div>
      </div>
    </section>
  );
};

export default UpdateAdmins;
