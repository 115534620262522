import { Link } from "react-router-dom";
//css
import "./Footer.css";
//translation:
import { useTranslation } from "react-i18next";
//images
import footerLogo from "../../assets/SetalkelLogo.gif";
import Catalog from "../../assets/ca2.jpg";
import Facebook from "../../assets/socials/facebook-fill.png";
import Instagram from "../../assets/socials/instagram.png";
import Twitter from "../../assets/socials/twitter-fill.png";
import YouTube from "../../assets/socials/youtube-fill.png";
import CopyRight from "../../assets/copyright-line.png";
//bootstrap
import { Row, Col, NavDropdown, Container } from "react-bootstrap";
import { Message, Call } from "react-iconly";
//translation
import i18next from "i18next";
import cookies from "js-cookie";
import classNames from "classnames";
//translation
import { languages } from "../../languages";
import arr from "../../assets/footerArrow.svg";
const Footer = () => {
  //===========================Start Translation===========================
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <Container>
        <Row>
          <div className="footer-qr">
            <div className="qr-info">
              <p>For More information</p>
              <h3>scan our catalog</h3>
            </div>
            <img
              style={{
                scale: currentLanguageCode === "ar" && "-1 1",
              }}
              src={arr}
              alt="arrow"
            />
            <div className="img-container">
              <img src={Catalog} alt="Catalog" width="70%" />
            </div>
          </div>
        </Row>
        <Row
          style={{
            paddingBottom: "77px",
          }}
        >
          <Col sm="6" md="6" lg="6">
            <img src={footerLogo} alt="footerLogo" className="footerLogo" />
          </Col>
          <Col sm="6" md="6" lg="6">
            <div className="footer-langs">
              <NavDropdown
                title={t(`Components.Footer.Languages.Title`)}
                id="basic-nav-dropdown"
              >
                {languages.map(({ code, name, country_code }) => (
                  <li key={country_code}>
                    <Link
                      to="#"
                      className={classNames("dropdown-item", {
                        disabled: currentLanguageCode === code,
                      })}
                      onClick={() => {
                        i18next.changeLanguage(code);
                        window.location.reload();
                      }}
                      style={{
                        textAlign: "start"
                      }}
                    >
                      <span
                        className={`flag-icon flag-icon-${country_code} mx-2`}
                        style={{
                          opacity: currentLanguageCode === code ? 0.5 : 1,
                        }}
                      ></span>
                      {t(`Components.Footer.Languages.${name}`)}
                    </Link>
                  </li>
                ))}
              </NavDropdown>
            </div>
          </Col>
        </Row>
        <div>
          <div className="footer-row row">
            <div className="footer-list-container">
              <h5>{t(`Components.Footer.SetalkelMain.Title`)}</h5>
              <ul className="footer-list">
                <li id="top">
                  <Link to="/">{t(`Components.Footer.Navbar.Home`)}</Link>
                </li>
                <li id="top">
                  <Link to="/products">
                    {t(`Components.Header.Navbar.Products`)}
                  </Link>
                </li>
                <li id="top">
                  <Link to="/categories">
                    {t(`Components.Header.Navbar.Categories`)}
                  </Link>
                </li>
                <li id="top">
                  <Link to="/bites">{t(`Components.Header.Navbar.Bites`)}</Link>
                </li>
              </ul>
            </div>
            <div className="footer-list-container">
              <h5>{t(`Components.Footer.WhoWeAre.Title`)}</h5>
              <ul className="footer-list">
                <li>
                  <Link to="/ourAgents">
                    {t(`Components.Header.Navbar.Agents`)}
                  </Link>
                </li>

                <li>
                  <Link to="/aboutUs">
                    {t(`Components.Header.Navbar.AboutUs`)}
                  </Link>
                </li>
                <li>
                  <Link to="/contactUs">
                    {t(`Components.Header.Navbar.ContactUs`)}
                  </Link>
                </li>
                <li>
                  <Link to="/partners">
                    {t(`Components.Header.Navbar.Partners`)}
                  </Link>
                </li>
                <li>
                  <Link to="/offers">
                    {t(`Components.Header.Navbar.Offers`)}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-location">
              <h5>{t(`Components.Footer.OurAddress`)}</h5>
              <ul className="location-ul">
                <li>{t(`Components.Footer.Name`)}</li>
                <li>{t(`Components.Footer.Location`)} <br />
                 {t(`Components.Footer.SubLocation`)}</li>
                <li>{t(`Components.Footer.CR`)}</li>
              </ul>
            </div>
            <div className="footer-contact">
              <h5>{t(`Components.Footer.ContactUs`)}</h5>
              <div className="footer-contact-cards">
                <div className="footer-contact-card-1">
                  <div>
                    <span>
                      <Message set="broken" primaryColor="white" />
                    </span>
                    <span>
                      <a
                        href="mailto:Info@nonamegroup.co"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Info@nonamegroup.co
                      </a>
                    </span>
                  </div>
                  <div>
                    <span>
                      <Call set="broken" primaryColor="white" />
                    </span>
                    <span dir="ltr">+962 779 552 266</span>
                  </div>
                  <div>
                    <span>
                      <Message set="broken" primaryColor="white" />
                    </span>
                    <span>
                      <a
                        href="mailto:C.e.o@nonamegroup.co"
                        target="_blank"
                        rel="noreferrer"
                      >
                        C.e.o@nonamegroup.co
                      </a>
                    </span>
                  </div>
                  <div>
                    <span>
                      <Call set="broken" primaryColor="white" />
                    </span>
                    <span dir="ltr">+90(536)6268575</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* <Col sm="12" md="6" lg="3"></Col> */}
        <Row>
          <Col>
            <div className="footer-socials">
              <a
                href="https://www.facebook.com/Setalkel.food"
                alt="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Facebook} alt="Facebook" />
              </a>
              <a
                href="https://www.instagram.com/setalkel.co/"
                alt="Instagram"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Instagram} alt="Instagram" />
              </a>
              <a
                href="https://twitter.com/setalkel1"
                alt="Twitter"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Twitter} alt="Twitter" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCB61B6HwVeZ1BvbWCWsgxGw"
                alt="YouTube"
                target="_blank"
                rel="noreferrer"
              >
                <img src={YouTube} alt="YouTube" />
              </a>
            </div>
            <div className="copyright">
              <img src={CopyRight} alt="CopyRight" />
              {t(`Components.Footer.CopyRight`)}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
