import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { customStyles } from "../../../../constants/DataTableCustomeStyles";

const ImportStateAgents = () => {
  const [importCountries, setImportCountries] = useState([]);
  const [importStates, setImportStates] = useState([]);
  const [stateAgents, setStateAgents] = useState([]);
  const [choosenImportCountryId, setchoosenImportCountryId] = useState("");
  const [choosenImportStateId, setchoosenImportStateId] = useState("");
  const [agents, setAgents] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [agentsIds] = useState([]);
  //===============================Delete Modal======================================
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  //=======================Select Country Handling==========
  const handleCountryChange = (e) => {
    setchoosenImportCountryId(e.target.value);
    importCountries.forEach((country) => {
      if (country._id === e.target.value) {
        setImportStates(country.states);
      }
    });
  };
  const handleStateChange = (e) => {
    setchoosenImportStateId(e.target.value);
  };
  useEffect(() => {
    importStates.forEach((state) => {
      if (state._id === choosenImportStateId) {
        setStateAgents(state.agents);
      }
    });
  }, [choosenImportStateId, importStates]);
  //====================handleAgentChange============
  const handleAgentChange = (e, agent) => {
    if (e.target.checked) {
      if (agentsIds.includes(agent._id)) {
        return;
      } else {
        agentsIds.push(agent._id);
      }
    } else {
      for (let i = 0; i < agentsIds.length; i++) {
        const element = agentsIds[i];
        if (element === agent._id) {
          agentsIds.splice(i, 1);
        }
      }
    }
  };
  //===========================Get Agents =================
  useEffect(() => {
    const getAgents = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/agent",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        console.log(body);
        setAgents([]);
      } else {
        setAgents(body);
      }
    };
    getAgents();
  }, []);

  //================get All Import Countries Info For Admin ===================
  useEffect(() => {
    const getAllImportCountriesInfo = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        "https://www.api.setalkel.co/import-country/admin",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setImportCountries([]);
        console.log(body);
      } else {
        setImportCountries(body);
      }
    };
    getAllImportCountriesInfo();
  }, []);
  //================================= Add Agents To State=========================
  const addAgentsToState = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      countryId: choosenImportCountryId,
      stateId: choosenImportStateId,
      agents: agentsIds,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    if (
      choosenImportCountryId &&
      choosenImportStateId &&
      agentsIds.length > 0
    ) {
      const response = await fetch(
        "https://www.api.setalkel.co/import-country/state/agents",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        console.log(body);
        toast.error("Process Not Completed");
      } else {
        toast.success("Process Has Been Done Successfully !!!")
      }
    } else {
      toast.error(
        "Please Choose Country And State And One Agent At Least To Continue The Process"
      );
    }
  };
  //==============================Delete Agent From State==================
  const deleteAgentFromState = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      countryId: choosenImportCountryId,
      stateId: choosenImportStateId,
      agents: [agentId],
    });
    console.log(raw);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/import-country/state/agents",
      requestOptions
    );
    if (!response.ok) {
      toast.error("Agent Not Deleted");
      console.log(response);
    } else {
      toast.success("Agent has Been Deleted !!");
      handleDeleteModalClose()
    }
  };
  //=================================================================================================
  const columns = [
    { name: "Name", selector: (row) => row.name },
    { name: "Email", selector: (row) => row.email },
    {
      name: "Delete",
      selector: (row) => (
        <span>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteModalShow();
              setAgentId(row._id);
            }}
          >
            Delete
          </Button>

        </span>
      ),
    },
  ];
  return (
    <section>
      <Row>
        <div className="crud-countries">
          <div className="title">
            <h1>Import State Agents</h1>
          </div>
        </div>
      </Row>
      <Row>
        <Form>
          <div className="import-states-agent">
            <div>
              <h6>Choose Import Country</h6>
              <Form.Select
                name="countryName"
                value={choosenImportCountryId}
                onChange={(e) => handleCountryChange(e)}
              >
                <option>Open this select menu</option>
                {importCountries.map((country, index) => {
                  return (
                    <option key={index} value={country._id}>
                      {country.countryName.en ? country.countryName.en : "name"}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div>
              <h6>Choose Import State</h6>
              <Form.Select
                aria-label="Default select example"
                name="countryName"
                value={choosenImportStateId}
                onChange={(e) => handleStateChange(e)}
              >
                <option>Open this select menu</option>
                {importStates.map((state, index) => {
                  return (
                    <option key={index} value={state._id}>
                      {state.stateName.en}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
          </div>

          <Form.Group className="mt-3">
            <Form.Label>
              Choose Agents Who Can Import For This State And Country
            </Form.Label>
            <div className="check-import-state">
              {agents.map((agent, index) => (
                <Form.Check
                  type="checkbox"
                  key={index}
                  label={agent.name}
                  id={agent._id}
                  value={agent._id}
                  onChange={(e) => handleAgentChange(e, agent)}
                />
              ))}
            </div>

          </Form.Group>
          <Button variant="warning" onClick={addAgentsToState}>
            Add Agents To State
          </Button>
        </Form>
      </Row>
      <hr />
      <Row>
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={stateAgents}
          highlightOnHover={true}
          customStyles={customStyles}
        />
      </Row>
      <Modal show={DeleteModalShow} onHide={handleDeleteModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are You Sure You Want To Delete This Container
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="dark" onClick={handleDeleteModalClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => deleteAgentFromState()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default ImportStateAgents;
