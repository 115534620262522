import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const initialState = {
  products: [],
  status: null,
};

export const productsFetch = createAsyncThunk(
  "product/fetchProducts",
  async ({ categoryId, countryId }) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("token")}`
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `https://www.api.setalkel.co/export-country/products?idc=${countryId}&idcat=${categoryId}`,
        requestOptions
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: {
    [productsFetch.pending]: (state, action) => {
      state.status = "pending";
    },
    [productsFetch.fulfilled]: (state, action) => {
      console.log(state);
      console.log(state.products);
      state.products = action.payload;
      state.status = "success";
    },
    [productsFetch.rejected]: (state, action) => {
      state.status = "rejected";
    },
  },
});

export default productsSlice.reducer;
