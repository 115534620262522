import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormLabel,
  Modal,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { customStyles } from "../../../constants/DataTableCustomeStyles";
import "./CRExportCountry.css";
import toast from "react-hot-toast";
import UnVerifiedAgentsApi from "../../../Api/UnVerifiedAgentsApi";

const CRCountries = () => {

  const [countries, setCountries] = useState([]);

  const [
    allExportCountriesWithCategories,
    setAllExportCountriesWithCategories,
  ] = useState([]);

  const [exportCountryId, setExportCountryId] = useState("");

  const [deleteCountriesCollection, setDeleteCountriesCollection] = useState({
    countries: [],
  });

  const [DeleteExportCountryModalShow, setDeleteExportCountryModalShow] = useState(false);

  const handleClose = () => setDeleteExportCountryModalShow(false);
  const handleShow = () => setDeleteExportCountryModalShow(true);

  //===================================Mutliple Delete Offers====================================
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);


  //==============Get All Export Countries With Thier Categories==================
  useEffect(() => {
    const getAllExportCountriesWithCategories = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/export-country/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setAllExportCountriesWithCategories([]);
        console.log(body);
      } else {
        setAllExportCountriesWithCategories(body);
      }
    };
    getAllExportCountriesWithCategories();
  }, []);

  //==========================Delete Export Country==============================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/export-country/${exportCountryId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          toast.success("item deleted");
          handleClose()
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  //============================Delete Mutliple Export Countries===================
  const deleteExportCountriesCollection = (e, countryId) => {
    if (e.target.checked) {
      if (!deleteCountriesCollection.countries.includes(countryId)) {
        setDeleteCountriesCollection({
          ...deleteCountriesCollection,
          countries: [...deleteCountriesCollection.countries, countryId],
        });
      }
    } else {
      setDeleteCountriesCollection({
        countries: deleteCountriesCollection.countries.filter(
          (item) => item !== countryId
        ),
      });
    }
  };

  const deleteCollectionOfExportCountries = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");
      if (deleteCountriesCollection.countries.length > 0) {
        var raw = JSON.stringify({
          countries: deleteCountriesCollection.countries,
        });

        var requestOptions = {
          method: "DELETE",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          "https://www.api.setalkel.co/export-country/",
          requestOptions
        );
        if (!response.ok) {
          alert("There Is An Error ");
          console.log(response);
        } else {
          alert("Countries Have Been Deleted !!");
          window.location.reload();
        }
      } else {
        alert("Please Select Multiple Countries To Delete");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //=======================Toggle Show Hide Delete Btn==========================
  // useEffect(() => {
  //   if (deleteCountriesCollection.countries.length > 0) {
  //     document.getElementById("multipleDelete").style.visibility = "visible";
  //   } else {
  //     document.getElementById("multipleDelete").style.visibility = "hidden";
  //   }
  // }, [deleteCountriesCollection.countries]);

  const columns = [
    {
      name: "Country Code",
      selector: (row) => <span>{row?.countryCode}</span>,
    },
    {
      name: "Image",
      selector: (row) => (
        <span>
          <img
            src={"https://www.api.setalkel.co" + row?.img}
            alt="exportCountryImg"
            width="70px"
            height="70px"
          />
        </span>
      ),
    },
    {
      name: "English Country Name",
      selector: (row) => <span>{row?.countryName?.en}</span>,
    },
    {
      name: "Arabic Country Name",
      selector: (row) => <span>{row?.countryName?.ar}</span>,
    },
    {
      name: "Categories",
      selector: (row) => (
        <span className="">
          <ul className="p-0">
            {row.categories.length > 0
              ? row.categories?.map((category, index) => {
                return (
                  <li className="">
                    <span key={index}>{category?.cat?.en}</span>
                  </li>
                );
              })
              : null}
          </ul>
        </span>
      ),
    },
    {
      name: "Show Categories And Products",
      selector: (row) => (
        <Link
          to={`/dashboard/crexportcountries/showdetails/${row._id}`}
          state={row?.categories}
        >
          <Button variant="dark">Categories & Products</Button>
        </Link>
      ),
    },
    {
      name: "Containers In Country",
      selector: (row) => (
        <span>
          <ul className="p-0">
            {row.containerInCountry.length > 0 &&
              row.containerInCountry.map((container, index) => {
                return (
                  <li>
                    <span key={index}>{container?.usualName}</span>
                    <span>{` ||| `}</span>
                    <span>{container.isFridge ? "Fronzen" : "Not Frozen"}</span>
                    <br />
                  </li>
                );
              })}
          </ul>
        </span>
      ),
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/crexportcountries/${row._id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <span>
          <Button
            onClick={() => {
              handleShow();
              setExportCountryId(row._id);
            }}
            variant="danger"
          >
            Delete
          </Button>


        </span>
      ),
    },
    {
      name: "Multiple Delete",
      selector: (row) => (
        <Form.Check
          className="deletedCountriesCheck"
          type="checkbox"
          name="deletedProducts"
          id="deletedCountries"
          onChange={(e) => deleteExportCountriesCollection(e, row._id)}
        />
      ),
    },
  ];
  const navigate = useNavigate()
  const [generateExcelFile, setGenerateExecelFile] = useState()
  const { data: Excel } = UnVerifiedAgentsApi.GenerateExelFileQueryForExportCountry()
  const getFileExcel = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co${generateExcelFile.pathFile}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${generateExcelFile.pathFile}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  return (
    <section className="cms-create-export-country">
      <Row>
        <div className="crud-countries">
          <div className="title text-center">
            <h1>Export Countries</h1>
          </div>
        </div>

      </Row>
      <div className="d-flex">
        <Button variant="warning" onClick={() => navigate("/dashboard/crexportcountries/add-exporcountry")}>
          Add Export Country
        </Button>
        {deleteCountriesCollection.countries.length > 0 && <Button
          id="multipleDelete"
          variant="danger"
          onClick={handleMultipleShow}
        >
          Multiple Delete
        </Button>}
        {!generateExcelFile ? <Button variant="warning" id="verify-btn" onClick={() => setGenerateExecelFile(Excel)}>
          Export Excel  Export Countries
        </Button> : <Button variant="success" id="verify-btn" onClick={getFileExcel}>
          Download Excel File
        </Button>}
        <Button variant="danger" id="verify-btn" >
          Import Excel
        </Button>
      </div>
      <Row>
        <DataTable
          fixedHeader
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={allExportCountriesWithCategories}
          customStyles={customStyles}
        />
      </Row>
      <Modal show={DeleteExportCountryModalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are You Sure You Want To Delete Export Country
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => onDelete()}>
            Delete Country
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal
        size="lg"
        show={MultipleDeleteModalShow}
        onHide={() => setMultipleDeleteModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Are You Sure You Want To Delete All Those Export Countries
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleMultipleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={deleteCollectionOfExportCountries}
          >
            Delete Export Countries
          </Button>
        </Modal.Footer>
      </Modal>

    </section>
  );
};

export default CRCountries;
