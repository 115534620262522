import React, { useState, useEffect, useRef } from "react";
//css
import "./ShipmentStepper.css";
//stepper
import StepZilla from "react-stepzilla";
import StepperLabel from "../../StepperLabel/StepperLabel";
// images
// import Location from "../../../assets/Payments/Shipment Stepper/location.png";
import GPSImg from "../../../assets/Payments/gps.png";
import Truck from "../../../assets/Payments/truck-tick.png";
import JARImg from "../../../assets/Payments/jar.png";
//components
import ExportCountryCard from "./ExportCountryCard/ExportCountryCard";
import ShipmentMethodCard from "./ShipmentMethodCard/ShipmentMethodCard";
import Cart from "../../../Pages/ShipmentProcess/Pages/Cart/Cart";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { deleteExportCountryId } from "../../../redux/shipmentStepperSlice";
const ShipmentStepper = () => {
  const exportCountryId = useSelector(
    (state) => state.shipmentStepper.exportCountryId
  );
  const [exportCountriesWithCategories, setExportCountriesWithCategories] =
    useState([]);
  const [stepperIndex, setStepperIndex] = useState(0);
  const [active] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [scroll, setScroll] = useState({ firstTime: false, click: false });
  //===================Controlling The Next And Prev Btns=================
  useEffect(() => {
    exportCountryId && stepperIndex === 0
      ? (document.getElementById("next-button").disabled = false)
      : (document.getElementById("next-button").disabled = true);
  }, [exportCountryId, stepperIndex]);

  useEffect(() => {
    if (stepperIndex === 1) {
      const prevBtn = document.getElementById("prev-button");
      prevBtn.addEventListener("click", () => {
        dispatch(deleteExportCountryId());
        window.scrollTo(0, 0);
      });
    }
  }, [dispatch, stepperIndex]);

  useEffect(() => {
    if (stepperIndex === 0 || stepperIndex === 1) {
      const nextBtn = document.getElementById("next-button");
      nextBtn.addEventListener("click", () => {
        window.scrollTo(0, 0);
      });
    }
  }, [stepperIndex]);

  useEffect(() => {
    if (stepperIndex === 2) {
      console.log(stepperIndex);
      alert("Option Has Been Choosen And Cant Be Changed....Thanks");
      const prevBtn = document.getElementById("prev-button");
      prevBtn.setAttribute("disabled", "true");
      prevBtn.style.display = "none";
    }
  }, [stepperIndex]);

  //=================Get All Export Countries With Categories========================

  useEffect(() => {
    const getAllExportCountriesWithCategories = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/export-country/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setExportCountriesWithCategories([]);
        console.log(body);
      } else {
        setExportCountriesWithCategories(body);
      }
    };
    getAllExportCountriesWithCategories();
  }, []);
  useEffect(() => {
    if (scroll.firstTime) {
      document.childNodes[1].scrollTop = ref.current.clientHeight;
    }
  }, [scroll]);

  //=========================Stepper Steps=======================
  const steps = [
    {
      name: (
        <StepperLabel
          name={t(
            `Components.ShipmentComponents.ShipmentStepper.ExportCountry`
          )}
          icon={GPSImg}
          active={stepperIndex === 0 ? active : null}
        />
      ),
      component: (
        <section ref={ref} id="export-country" className="export-country">
          {exportCountriesWithCategories.map((country, index) => {
            return (
              <ExportCountryCard
                scroll={scroll}
                setScroll={setScroll}
                key={index}
                country={country}
              />
            );
          })}
        </section>
      ),
    },
    {
      name: (
        <StepperLabel
          name={t(
            `Components.ShipmentComponents.ShipmentStepper.ShipmentMethod`
          )}
          icon={Truck}
          active={stepperIndex === 1 ? active : null}
        />
      ),
      component: (
        <section id="shipment-method" className="shipment-method">
          <ShipmentMethodCard
            method={["importCountry", "shipmentMethod", "deliveryMethod"]}
            stepperIndex={stepperIndex}
          />
        </section>
      ),
    },
    {
      name: (
        <StepperLabel
          name={t(
            `Components.ShipmentComponents.ShipmentStepper.ChooseProducts`
          )}
          icon={JARImg}
          active={stepperIndex === 2 ? active : null}
        />
      ),
      component: <Cart CountryID={exportCountryId} />,
    },
  ];
  return (
    <article className="shipment-stepper">
      <div className="step-progress">
        <p id="orderShipmentNote">
          {t(
            `Components.ShipmentComponents.ShipmentStepper.SelectExportCountry`
          )}
        </p>
        <StepZilla
          steps={steps}
          showNavigation={true}
          showSteps={true}
          stepsNavigation={true}
          nextButtonCls="stepper-btn"
          backButtonCls="stepper-btn"
          onStepChange={(step) => {
            setStepperIndex(step);
          }}
        />
      </div>
    </article>
  );
};

export default ShipmentStepper;
