import React, { useEffect, useState } from "react";
import { Form, Row, Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { customStyles } from "../../../../constants/DataTableCustomeStyles";

const ImportStateContainer = () => {
  const [importCountries, setImportCountries] = useState([]);
  const [importStates, setImportStates] = useState([]);
  const [stateContainers, setStateContainers] = useState([]);
  const [containers, setContainers] = useState([]);
  const [containerStateValues, setContainerStateValues] = useState({
    maxWeightInState: "",
    minWeightInState: "",
    choosenImportCountryId: "",
    choosenImportStateId: "",
    containerId: "",
  });
  const [maxWeightInState, setMaxWeightInState] = useState("");
  const [minWeightInState, setMinWeightInState] = useState("");
  //==========================Show Containers Modal=======================================
  const [DeleteContainersModalShow, setDeleteContainersModalShow] =
    useState(false);
  const handleDeleteContainersModalClose = () =>
    setDeleteContainersModalShow(false);
  const handleDeleteContainersModalShow = () =>
    setDeleteContainersModalShow(true);

  const [shippingContainerId, setShippingContainerId] = useState("");

  //========================================================================================

  const [UpdateContainerModalShow, setUpdateContainerModalShowShow] =
    useState(false);

  const handleUpdateContainerModalClose = () =>
    setUpdateContainerModalShowShow(false);
  const handleUpdateContainerModalShow = () =>
    setUpdateContainerModalShowShow(true);
  //=======================Select Country Handling==========
  const handleValuesChange = (e) => {
    const { name, value } = e.target;
    setContainerStateValues({
      ...containerStateValues,
      [name]: value,
    });
  };
  const handleCountryChange = (e) => {
    setContainerStateValues({
      ...containerStateValues,
      choosenImportCountryId: e.target.value,
    });
    importCountries.forEach((country) => {
      if (country._id === e.target.value) {
        setImportStates(country.states);
        setStateContainers([]);
      }
      // else {
      //   setImportStates([]);
      //   setStateContainers([]);
      // }
    });
  };
  const handleStateChange = (e) => {
    setContainerStateValues({
      ...containerStateValues,
      choosenImportStateId: e.target.value,
    });
    importStates.forEach((state) => {
      if (state._id === e.target.value) {
        setStateContainers(state.shippingContainers);
      }
    });
  };
  const handleContainerChange = (e) => {
    setContainerStateValues({
      ...containerStateValues,
      containerId: e.target.value,
    });
  };
  const handelEditState = (id) => {
    stateContainers.forEach((d) => {
      if (d._id === id) {
        setMaxWeightInState(d.maxWeightInState)
        setMinWeightInState(d.minWeightInState)

      }
    })
  }
  //===================Containers========================
  useEffect(() => {
    const getContainers = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/container/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setContainers([]);
        console.log(body);
      } else {
        setContainers(body);
      }
    };
    getContainers();
  }, []);
  //================getAllImportCountriesInfoForAdmin===================
  useEffect(() => {
    const getAllImportCountriesInfo = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        "https://www.api.setalkel.co/import-country/admin",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setImportCountries([]);
        console.log(body);
      } else {
        setImportCountries(body);
      }
    };
    getAllImportCountriesInfo();
  }, []);
  //=======================Add State Container===============
  const addStateContainer = async () => {
    try {
      if (
        containerStateValues.choosenImportCountryId &&
        containerStateValues.choosenImportStateId
      ) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify([
          {
            containerId: containerStateValues.containerId,
            maxWeightInState: containerStateValues.maxWeightInState,
            minWeightInState: containerStateValues.minWeightInState,
          },
        ]);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        const response = await fetch(
          `https://www.api.setalkel.co/import-country/add/countainer?idc=${containerStateValues.choosenImportCountryId}&ids=${containerStateValues.choosenImportStateId}`,
          requestOptions
        );

        const body = await response.json();
        if (!response.ok) {
          toast.error("Container Has Not Been Added !!!!!!!!!!");
          console.log(body);
        } else {
          toast.success("Container Has Been Added");
          let state = body.states.find((state) => {
            return state._id === containerStateValues.choosenImportStateId;
          });
          setStateContainers(state.shippingContainers);
          let indexState = importStates.findIndex((e) => {
            return e._id === containerStateValues.choosenImportStateId;
          });
          importStates[indexState].shippingContainers =
            state.shippingContainers;
          setImportStates(importStates);
          setContainerStateValues({
            maxWeightInState: "",
            minWeightInState: "",
            choosenImportCountryId: containerStateValues.choosenImportCountryId,
            choosenImportStateId: containerStateValues.choosenImportStateId,
            containerId: "",
          });
        }
      } else {
        toast.error(
          "Please Select Country And State First To Add A Container To Them"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  //==========================Delete Country State Container===============
  const onDeleteContainer = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/import-country/container?idc=${containerStateValues.choosenImportCountryId}&ids=${containerStateValues.choosenImportStateId}&idcn=${shippingContainerId}`,
      requestOptions
    );
    if (!response.ok) {
      toast.error("There Is Somthing Wrong");
    } else {
      toast.success("Container Has Been Deleted");
      handleDeleteContainersModalClose();
      let containersFilterd = stateContainers.filter((container) => {
        return container._id !== shippingContainerId;
      });
      setStateContainers(containersFilterd);
      let indexState = importStates.findIndex((e) => {
        return e._id === containerStateValues.choosenImportStateId;
      });
      importStates[indexState].shippingContainers = containersFilterd;
      setImportStates(importStates);
    }
  };
  //==============================Update State Container===========================
  const updateStateContainer = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      maxWeightInState: maxWeightInState,
      minWeightInState: minWeightInState,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/import-country/container?idc=${containerStateValues.choosenImportCountryId}&ids=${containerStateValues.choosenImportStateId}&idcn=${shippingContainerId}`,
      requestOptions
    );
    const body = await response.json();
    console.log(body);
    if (!response.ok) {
      toast.error("Container Not Updated");
      console.log(response);
    } else {
      toast.success("Container Has Been Updated Successfully !!!");
      handleUpdateContainerModalClose();
      let indexState = importStates.findIndex((state) => {
        return state._id === containerStateValues.choosenImportStateId;
      });

      let indexContainer = importStates[
        indexState
      ].shippingContainers.findIndex((container) => {
        return container._id === shippingContainerId;
      });
      importStates[indexState].shippingContainers[
        indexContainer
      ].maxWeightInState = maxWeightInState;
      importStates[indexState].shippingContainers[
        indexContainer
      ].minWeightInState = minWeightInState;
    }
  };
  const columns = [
    {
      name: "Container Max Weight In State",
      selector: (row) => row.maxWeightInState,
    },
    {
      name: "Container Min Weight In State",
      selector: (row) => row.minWeightInState,
    },
    {
      name: "Container Usual Name",
      selector: (row) => row.container.usualName,
    },
    {
      name: "Container Freeze",
      selector: (row) => (row.container.isFridge ? "Yes" : "No"),
    },
    {
      name: "Delete",
      selector: (row) => (
        <>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteContainersModalShow(true);
              setShippingContainerId(row._id);
            }}
          >
            Delete
          </Button>

        </>
      ),
    },
    {
      name: "Update",
      selector: (row) => (
        <>
          <Button
            onClick={() => {
              handleUpdateContainerModalShow();
              setShippingContainerId(row._id);
              handelEditState(row._id)
            }}
            variant="dark"
          >
            Update
          </Button>

        </>
      ),
    },
  ];
  return (
    <section>
      <Row>
        <div className="crud-countries">
          <div className="title">
            <h1>Import State Containers</h1>
          </div>
        </div>
      </Row>
      <Row>
        <Form>
          <div className="import-states-agent">
            <div>
              <h6>Choose Import Country</h6>
              <Form.Select
                aria-label="Default select example"
                name="countryName"
                value={containerStateValues.choosenImportCountryId}
                onChange={(e) => handleCountryChange(e)}
              >
                <option>Open this select menu</option>
                {importCountries.map((country, index) => {
                  return (
                    <option key={index} value={country._id}>
                      {country.countryName.en ? country.countryName.en : "name"}
                    </option>
                  );
                })}
              </Form.Select>
            </div>


            <div>
              <h6>Choose Import State</h6>
              <Form.Select
                aria-label="Default select example"
                name="countryName"
                value={containerStateValues.choosenImportStateId}
                onChange={(e) => handleStateChange(e)}
              >
                <option>Open this select menu</option>
                {importStates.map((state, index) => {
                  return (
                    <option key={index} value={state._id}>
                      {state.stateName.en}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            {/* ===============================Choose Container===================== */}
            <div>
              <h6>Choose Container</h6>
              <Form.Select onChange={(e) => handleContainerChange(e)}>
                <option>Open this select menu</option>
                {containers.map((container, index) => (
                  <option key={index} value={container._id}>
                    {container.usualName}
                  </option>
                ))}
              </Form.Select>
            </div>



            <div>
              <h6>Container Max Weight In The State</h6>
              <Form.Control
                type="number"
                placeholder="Container Max Weight In The State"
                name="maxWeightInState"
                value={containerStateValues.maxWeightInState}
                onChange={(e) => handleValuesChange(e)}
              />
            </div>

            <div>
              <h6>Container Min Weight In The State</h6>
              <Form.Control
                type="number"
                placeholder="Container Min Weight In The State"
                name="minWeightInState"
                value={containerStateValues.minWeightInState}
                onChange={(e) => handleValuesChange(e)}
              />
            </div>
          </div>


          <Button variant="warning" onClick={addStateContainer}>
            Add Import Country State Container
          </Button>
        </Form>
      </Row>
      <hr />
      <Row>
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={stateContainers}
          highlightOnHover={true}
          customStyles={customStyles}
        />
        <Modal
          show={DeleteContainersModalShow}
          onHide={handleDeleteContainersModalClose}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Are You Sure You Want To Delete This Container ?
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleDeleteContainersModalClose}
            >
              Close
            </Button>
            <Button variant="danger" onClick={onDeleteContainer}>
              Delete Container
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={UpdateContainerModalShow}
          onHide={handleUpdateContainerModalClose}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Container :</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Max Weight In State</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Max Weight In State"
                  autoFocus
                  value={maxWeightInState}
                  onChange={(e) => setMaxWeightInState(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Min Weight In State</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Min Weight In State"
                  autoFocus
                  value={minWeightInState}
                  onChange={(e) => setMinWeightInState(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleUpdateContainerModalClose}
            >
              Close
            </Button>
            <Button variant="warning" onClick={updateStateContainer}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </section>
  );
};

export default ImportStateContainer;
