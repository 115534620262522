import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//css
import "./ShipmentSuggestion.css";
//icon
import { BsDownload } from "react-icons/bs";
//images
import DOCSImg from "../../../../../assets/Payments/docs.png";
//redux
import { useDispatch } from "react-redux";
import {
  addContainer,
  addContainerId,
  addDeliveryMethod,
  addExportCountryId,
  addImportCountryId,
  addImportStateId,
} from "../../../../../redux/shipmentStepperSlice";
import {
  checkIsEditing,
  UpdateSuggestion,
  UpdateSuggestionShipment,
} from "../../../../../redux/cartSlice";
//translation
import { useTranslation } from "react-i18next";
//key del
var deleteKey = require("key-del");

const ShipmentSuggestion = () => {
  const [suggestionShipment, setSuggestionShipment] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //===========================fetch Only Suggestion Shipment==============

  useEffect(() => {
    const getSuggestionShipment = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/shipment/agent?status=suggestion",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setSuggestionShipment([]);
        console.log(body);
      } else {
        setSuggestionShipment(body);
      }
    };
    getSuggestionShipment();
  }, []);
  //=========================================download File ===============================================
  const getFile = async (file) => {
    console.log(file);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  //=========================Verify Shipment By Agent=============
  const verifyShipment = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      isVerified: true,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/verified/${id}`,
      requestOptions
    );
    if (!response.ok) {
      alert(
        t(
          `Pages.ShipmentProcess.Shipment.ShipmentSuggestion.ShipmentRefusedNote`
        )
      );
    } else {
      alert(
        t(
          `Pages.ShipmentProcess.Shipment.ShipmentSuggestion.ShipmentApprovedNote`
        )
      );
      navigate("/shipmentProcess/payment");
    }
  };
  //=======================Handle Edit Suggestion=====================
  const handleEdit = (suggestion) => {
    const UpdatedCartItems = [];
    const UpdatedShipmentItems = [];
    var result;

    suggestion.order.items.forEach((item) => {
      console.log(item);
      item.product.cartQuantity = item.count;
      UpdatedCartItems.push(item.product);
    });
    for (let i = 0; i < UpdatedCartItems.length; i++) {
      const element = UpdatedCartItems[i];
      element.count = element.cartQuantity;
      element.product = element._id;
      element.note = "";
      result = deleteKey(element, [
        "addedBy",
        "additionalImage",
        "cat",
        "countInBox",
        "cartQuantity",
        "countryOrigin",
        "createdAt",
        "hidden",
        "img",
        "materialProperty",
        "package",
        "packageGrossWeight",
        "peiceQty",
        "stockOff",
        "subCat",
        "supplierName",
        "unitForPackage",
        "updatedAt",
        "volume",
        "_id",
      ]);
      UpdatedShipmentItems.push(result);
    }

    dispatch(checkIsEditing(true));

    dispatch(UpdateSuggestion(UpdatedCartItems));
    dispatch(UpdateSuggestionShipment(UpdatedShipmentItems));

    dispatch(addExportCountryId(suggestion.exportCountry._id));
    dispatch(addImportCountryId(suggestion.importCountry._id));
    dispatch(addImportStateId(suggestion.state._id));
    dispatch(addDeliveryMethod(suggestion.deliveryMethod._id));
    dispatch(addContainerId(suggestion.containerInfo.container._id));
    dispatch(addContainer(suggestion.containerInfo));

    localStorage.setItem("UpdatedCartItems", JSON.stringify(UpdatedCartItems));
    localStorage.setItem(
      "UpdatedShipmentItems",
      JSON.stringify(UpdatedShipmentItems)
    );
    localStorage.setItem("container", JSON.stringify(suggestion.containerInfo));
    localStorage.setItem("exportCountryId", suggestion.exportCountry._id);
    localStorage.setItem("importCountryId", suggestion.importCountry._id);
    localStorage.setItem("importStateId", suggestion.state._id);
    localStorage.setItem("deliveryMethod", suggestion.deliveryMethod._id);
    localStorage.setItem("containerId", suggestion.containerInfo._id);
    localStorage.setItem("UpdatedSuggestionId", suggestion._id);

    navigate("/shipmentProcess/cart");
  };

  return (
    <section className="shipment-suggestion">
      {suggestionShipment.map((suggestion, index) => {
        return (
          <>
            <div className="suggestion-files-container">
              <div className="suggestion-title" key={index}>
                <h4>{`${t(
                  `Pages.ShipmentProcess.Shipment.ShipmentSuggestion.Title`
                )}  ${suggestion.orderNumber}`}</h4>
                <p>
                  {t(
                    `Pages.ShipmentProcess.Shipment.ShipmentSuggestion.FinalInvoiceNote`
                  )}
                </p>
              </div>
              <img src={DOCSImg} alt="docsImg" />
              <div className="suggestion-files">
                <div className="suggestion-file">
                  <button onClick={() => getFile(suggestion.agentFiles)}>
                    {suggestion.agentFiles && suggestion.agentFiles.length > 0
                      ? suggestion.agentFiles.slice(5).slice(17, 38)
                      : null}
                    {t(
                      `Pages.ShipmentProcess.Shipment.ShipmentSuggestion.Download`
                    )}
                    <BsDownload />
                  </button>
                </div>
              </div>
              {suggestion ? (
                <div className="btns">
                  <button
                    className="primary-btn approve-suggestion"
                    onClick={() => verifyShipment(suggestion._id)}
                  >
                    {t(
                      `Pages.ShipmentProcess.Shipment.ShipmentSuggestion.ApproveSuggestion`
                    )}
                  </button>
                  <button
                    className="primary-btn edit-suggestion"
                    onClick={() => {
                      handleEdit(suggestion);
                    }}
                  >
                    {t(
                      `Pages.ShipmentProcess.Shipment.ShipmentSuggestion.EditSuggestion`
                    )}
                  </button>
                </div>
              ) : null}
            </div>
          </>
        );
      })}
    </section>
  );
};

export default ShipmentSuggestion;
