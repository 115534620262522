import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//bootstrap
import { Button, Form, Row } from "react-bootstrap";

const UpdateTerms = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [terms, setTerms] = useState({
    _id: "",
    terms: { en: "", ar: "", fr: "", de: "", ru: "", cn: "", tur: "" },
  });
  const [agentId, setAgentId] = useState("");
  const [agents, setAgents] = useState([]);

  //=======================Input Handeling===============
  const handleTermsChange = (e) => {
    const { name, value } = e.target;
    setTerms({
      ...terms,
      terms: { ...terms.terms, [name]: value },
    });
  };

  //===========================================get Terms By Id==================================
  const getTerms = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/payment-term/${params.Id}`,
      requestOptions
    );
    const body = await response.json();

    setAgentId(body._id);
    setTerms({
      _id: body._id,
      terms: {
        en: body.paymentTerm.en ? body.paymentTerm.en : "",
        ar: body.paymentTerm.ar ? body.paymentTerm.ar : "",
        fr: body.paymentTerm.fr ? body.paymentTerm.fr : "",
        ru: body.paymentTerm.ru ? body.paymentTerm.ru : "",
        de: body.paymentTerm.de ? body.paymentTerm.de : "",
        cn: body.paymentTerm.cn ? body.paymentTerm.cn : "",
        tur: body.paymentTerm.tur ? body.paymentTerm.tur : "",
      },
    });
  }, [params.Id]);
  useEffect(() => {
    getTerms();
  }, [getTerms]);
  //================================get agents=======================
  const getAgents = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/agent/",
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      setAgents([]);
      console.log(body);
    } else {
      setAgents(body);
    }
  };
  //========================Update Agent Terms================
  const UpdateAgentTerms = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = terms.terms;
    let NewRaw = raw;
    const NewTerms = terms.terms;

    Object.keys(NewTerms).forEach((key) => {
      if (NewTerms[key] === "" || NewTerms[key] === "undefined") {
        delete NewTerms[key];
      }
    });
    Object.keys(NewRaw).forEach((key) => {
      if (NewRaw[key] === "" || NewRaw[key] === "undefined") {
        delete NewRaw[key];
      }
    });
    const raw2 = JSON.stringify(NewRaw);
    console.log(raw2);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw2,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/payment-term/${agentId}`,
      requestOptions
    );
    if (!response.ok) {
      alert("Terms Not Updated !!!");
      console.log(response);
    } else {
      alert("Terms Has Been Updated Successfuly");
      navigate("/dashboard/shippingterms");
    }
  };
  //======================Cancel=======================
  const cancel = () => {
    localStorage.removeItem("term-values");
    navigate("/dashboard/shippingterms");
  };
  useEffect(() => {
    getAgents();
  }, []);
  return (
    <section className="shipping-terms">
      <Row>
        <div className="crud-terms">
          <div className="crud-title">
            <h1>Update Terms</h1>
          </div>
        </div>
        <div className="terms">
          <div className="">
            <h2> Terms </h2>
            <Form>
              <Form.Select
                name="agentId"
                value={agentId}
                onChange={(e) => setAgentId(e.target.value)}
              >
                <option>Select Agent</option>
                {agents.map((agent, index) => {
                  return agent._id === agentId ? (
                    <option key={index} value={agent._id}>
                      {agent.name}
                    </option>
                  ) : null;
                })}
              </Form.Select>
              <br />
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>English textarea</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="en"
                  value={terms.terms.en}
                  onChange={handleTermsChange}
                  placeholder="Terms In English"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Arabic textarea</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="ar"
                  value={terms.terms.ar}
                  onChange={handleTermsChange}
                  placeholder="Terms In Arabic"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>French textarea</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="fr"
                  value={terms.terms.fr}
                  onChange={handleTermsChange}
                  placeholder="Terms in fr"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Russian textarea</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="ru"
                  value={terms.terms.ru}
                  onChange={handleTermsChange}
                  placeholder="Terms in ru"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>German textarea</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="de"
                  value={terms.terms.de}
                  onChange={handleTermsChange}
                  placeholder="Terms in de"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Chineese textarea</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="cn"
                  value={terms.terms.cn}
                  onChange={handleTermsChange}
                  placeholder="Terms in cn"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Turkish textarea</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="tur"
                  value={terms.terms.tur}
                  onChange={handleTermsChange}
                  placeholder="Terms in tur"
                />
              </Form.Group>
              <Button variant="warning" onClick={UpdateAgentTerms}>
                Update Terms
              </Button>
              <Button variant="secondary" onClick={cancel}>
                Cancel
              </Button>
            </Form>
          </div>
        </div>
      </Row>
    </section>
  );
};

export default UpdateTerms;
