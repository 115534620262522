import React, { useEffect, useState } from "react";
//css
import "./ShipmentTotals.css";
//images
import Volume from "../../../assets/Payments/volume.png";
import Weight from "../../../assets/Payments/weight.png";
import Price from "../../../assets/Payments/price.png";
//redux
import { useDispatch, useSelector } from "react-redux";
import { shipmentInRange } from "../../../redux/cartSlice";
//translation
import { useTranslation } from "react-i18next";

const ShipmentTotals = () => {
  const { t } = useTranslation();
  const cart = useSelector((state) => state.cart);
  const state = useSelector((state) => state);
  console.log(cart);
  const dispatch = useDispatch();
  const [totalWeightWarn, setTotalWeightWarn] = useState(false);
  const [totalVolumeWarn, setTotalVolumeWarn] = useState(false);
  const choosenContainer = state.shipmentStepper;
  const totalVolume = state.cart.cartTotalVolume;
  const totalWeight = state.cart.cartTotalWeight;
  // console.log("TOTAL VOLUME " + totalVolume);
  // console.log("TOTAL Weight " + totalWeight);
  // console.log("----------------------------");
  // console.log(
  //   "Container MAX VOLUME " +
  //     parseInt(choosenContainer.container.container.maxVolume)
  // );
  // console.log(
  //   "Container MAX Weight " + choosenContainer.container.maxWeightInState
  // );
  // console.log(
  //   "Container MIN Weight " + choosenContainer.container.minWeightInState
  // );
  useEffect(() => {
    if (
      choosenContainer.container.container.maxVolume !== null &&
      choosenContainer.container.maxWeightInState !== null &&
      choosenContainer.container.minWeightInState !== null &&
      !isNaN(choosenContainer.container.minWeightInState) &&
      !isNaN(choosenContainer.container.maxWeightInState) &&
      !isNaN(choosenContainer.container.container.maxVolume)
    ) {
      if (
        totalVolume <= choosenContainer.container.container.maxVolume &&
        totalWeight <= choosenContainer.container.maxWeightInState &&
        totalWeight >= choosenContainer.container.minWeightInState
      ) {
        // console.log("Good Mood");
        setTotalVolumeWarn(false);
        setTotalWeightWarn(false);
        dispatch(shipmentInRange(true));
      } else {
        if (
          totalVolume > choosenContainer.container.container.maxVolume &&
          (totalWeight > choosenContainer.container.maxWeightInState ||
            totalWeight < choosenContainer.container.minWeightInState)
        ) {
          // console.log("Over Volume And Or Over/Under Weight");
          setTotalVolumeWarn(true);
          setTotalWeightWarn(true);
          dispatch(shipmentInRange(false));
          return;
        } else if (
          totalVolume > choosenContainer.container.container.maxVolume
        ) {
          // console.log("Over Volume");
          setTotalVolumeWarn(true);
          setTotalWeightWarn(false);
          dispatch(shipmentInRange(false));
          return;
        } else if (totalWeight > choosenContainer.container.maxWeightInState) {
          // console.log("Over Weight");
          setTotalVolumeWarn(false);
          setTotalWeightWarn(true);
          dispatch(shipmentInRange(false));
          return;
        } else if (totalWeight < choosenContainer.container.minWeightInState) {
          // console.log("under Weight");
          setTotalWeightWarn(true);
          setTotalVolumeWarn(false);
          dispatch(shipmentInRange(false));
          return;
        } else {
          // console.log("final mode");
        }
      }
    }
  }, [
    choosenContainer.container.container.maxVolume,
    choosenContainer.container.maxWeightInState,
    choosenContainer.container.minWeightInState,
    dispatch,
    totalVolume,
    totalWeight,
  ]);
  return (
    <article className="cart-totals">
      <div
        className="cart-totals-overload-div"
        style={{
          background: totalVolumeWarn ? "var(--color-warning)" : "",
        }}
      >
        <div>
          <img src={Volume} alt="Volume" />
          <span>
            {t(`Components.ShipmentComponents.ShipmentTotals.TotalVolume`)}
          </span>
        </div>
        <span>{cart.cartTotalVolume}</span>
      </div>

      <div
        className="cart-totals-overload-div"
        style={{
          background: totalWeightWarn ? "var(--color-warning)" : "",
        }}
      >
        <div>
          <img src={Weight} alt="wight" />
          <span>
            {t(`Components.ShipmentComponents.ShipmentTotals.TotalWeight`)}
          </span>
        </div>
        <span>{cart.cartTotalWeight} Kg</span>
      </div>
      <div className="cart-totals-overload-div">
        <div>
          <img src={Price} alt="Price" />
          <span>
            {t(`Components.ShipmentComponents.ShipmentTotals.TotalPrice`)}
          </span>
        </div>
        <span>{cart.cartTotalAmount}$</span>
      </div>
    </article>
  );
};

export default ShipmentTotals;
