import React from "react";
//image
import LoaderImg from "../../assets/setalkel-logo1.gif";
//css
import "./PreLoader.css";
const PreLoader = () => {
  return (
    <div className="lazy-loader">
      <div>
        <img src={LoaderImg} alt="loader" />
      </div>
    </div>
  );
};

export default PreLoader;
