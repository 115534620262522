import React, { useEffect, useState } from "react";
//css
import "./AdminShipmentProducts.css";
//components
import AdminShipmentProductCard from "./AdminShipmentProductCard/AdminShipmentProductCard";
//redux
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const AdminShipmentProducts = () => {
  const shipmentInfo = useSelector((state) => state.shipmentStepper);
  const shipmentAgentId = useSelector(
    (state) => state.adminCart.shipmentAgentId
  );
  const [exportCountryCategories, setExportCountryCategories] = useState([]);
  const [chooosenCategoryProducts, setChooosenCategoryProducts] = useState([]);
  const [categoryId, setCategoryId] = useState("");

  //===========================fetch Export Countries Categories ==============

  useEffect(() => {
    const fetchExportCountryCategories = async () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const response = await fetch(
          `https://www.api.setalkel.co/export-country/${shipmentInfo.exportCountryId}`,
          requestOptions
        );
        const body = await response.json();
        console.log(body);
        if (!response.ok) {
          setExportCountryCategories([]);
          console.log(body);
        } else {
          setExportCountryCategories(body.categories);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchExportCountryCategories();
  }, [shipmentInfo.exportCountryId]);

  //===========================fetch Export Countries Categories Products ==============

  useEffect(() => {
    const fetchExportCountryCategoryProducts = async () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `https://www.api.setalkel.co/export-country/admin/products?idc=${shipmentInfo.exportCountryId}&idcat=${categoryId}&containerId=${shipmentInfo.container.container._id}&agentId=${shipmentAgentId}`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          console.log(body);
        } else {
          setChooosenCategoryProducts(body.prods);
          console.log(body);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchExportCountryCategoryProducts();
  }, [
    categoryId,
    shipmentAgentId,
    shipmentInfo.container.container._id,
    shipmentInfo.exportCountryId,
  ]);

  return (
    <article className="admin-shipment-products">
      <h1>
        <Link to="/dashboard/adminCart">Back TO Cart</Link>
      </h1>
      <div className="admin-cart-categories">
        {exportCountryCategories && exportCountryCategories.length > 0
          ? exportCountryCategories.map((category, index) => {
              return (
                <span
                  id="category-span"
                  onClick={() => setCategoryId(category._id)}
                >
                  {category.cat.en}
                </span>
              );
            })
          : null}
      </div>

      <div className="admin-shipment-products-cards">
        {chooosenCategoryProducts && chooosenCategoryProducts.length > 0 ? (
          chooosenCategoryProducts?.map((product, index) => {
            return <AdminShipmentProductCard key={index} product={product} />;
          })
        ) : (
          <h2>Welcome Please Choose Category Products</h2>
        )}
      </div>
    </article>
  );
};

export default AdminShipmentProducts;
