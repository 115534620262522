import { Link } from "react-router-dom";
//css
import "./Error404.css";
//bootstrap
import { Button } from "react-bootstrap";
//image
import ERRORImg from "../../assets/404 Error-cuate 1.png";
const Error404 = () => {
  return (
    <section className="error-page">
      <img src={ERRORImg} alt="" />
      <Link to="/">
        <Button
          className="primary-btn"
          style={{
            width: "fit-content",
          }}
        >
          Back To Home
        </Button>
      </Link>
    </section>
  );
};

export default Error404;
