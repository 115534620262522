import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

//bootstrap
import { Form, Button } from "react-bootstrap";

//images
import Logo from "../../../assets/img/Pages/Agent/Logo.png"; //logo
import CircleImg from "../../../assets/img/Pages/Agent/Shape.png"; //shape
import Leaf from "../../../assets/img/Pages/Agent/Icons/Group 54.png"; //leaf

//Css
import "./OTP.css";
import { useTranslation } from "react-i18next";
const OTP = () => {
  const { t } = useTranslation();
  const [OTP, setOTP] = useState("");
  const [counter, setCounter] = useState(59);
  let navigate = useNavigate();
  //========================OTP Timer====================
  // useEffect(() => {
  //   const timer =
  //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);
  //=========================Resend OTP====================
  const resendOTP = async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };
    const id = localStorage.getItem("RegisteredAgentID");
    await fetch(
      `https://www.api.setalkel.co/agent/resend_otp/${id}`,
      requestOptions
    );
  };
  //=========================Post OTP====================
  const PostOTP = async (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      otp: OTP,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/agent/verify_email",
      requestOptions
    );
    if (!response.ok) {
      const body = await response.json();
      alert(body.message);
      console.log(body);
    } else {
      navigate("/agent/login");
    }
  };

  return (
    <section className="OTP-bg">
      <div className="OTP-FH">
        <div className="OTP-FH-form">
          <form className="login-form">
            <img src={Leaf} alt="" />
            <h2>{t(`Pages.Auth.OTP.Title`)}</h2>
            <div className="formComponents">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{t(`Pages.Auth.OTP.Title`)}</Form.Label>
                <div className="form-inputs">
                  <OtpInput
                    value={OTP}
                    numInputs={6}
               
                    onChange={(otp) => setOTP(otp)}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>

                <span style={{ display: "inline-block" }}>
                  {t(`Pages.Auth.OTP.Counter`)} : 00:
                  {counter}
                </span>
                {counter === 0 ? (
                  <Button className="OTP-btn" onClick={() => resendOTP()}>
                    {t(`Pages.Auth.OTP.ResendBtn`)}
                  </Button>
                ) : null}
              </Form.Group>
            </div>
            <Button type="submit" className="OTP-btn" onClick={PostOTP}>
              {t(`Pages.Auth.OTP.Btn`)}
            </Button>
          </form>
        </div>
      </div>

      <div className="OTP-SH">
        <img src={Logo} alt="setalkelLogo" />
        <img src={CircleImg} alt="setalkel" />
      </div>
    </section>
  );
};

export default OTP;
