import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
//boostrap
import { Button, Form, Modal, Row } from "react-bootstrap";
//redux
import { useDispatch } from "react-redux";

import DataTable from "react-data-table-component";

//components
import Notification from "../../../Components/Notification/Notification";
import {
  adminCheckIsEditing,
  adminUpdateSuggestion,
  adminUpdateSuggestionShipment,
  shipmentAgentId,
} from "../../../redux/Admin/adminCartSlice";
import {
  adminAddContainer,
  adminAddContainerId,
  adminAddDeliveryMethod,
  adminAddExportCountryId,
  adminAddImportCountryId,
  adminAddImportStateId,
} from "../../../redux/Admin/adminShipmentStepperSlice";
import { customStyles } from "../../constants/DataTableCustomeStyles";
var deleteKey = require("key-del");
const WaitingPaymentShipment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [WaitingPaymentShipments, setWaitingPaymentShipments] = useState([]); //third Status

  const [shipmentId, setShipmentId] = useState("");
  const [FreezedShipmentId, setFreezedShipmentId] = useState("");
  const [shipmentStatus, setShipmentStatus] = useState("");
  const [freezeShipment, setFreezeShipment] = useState({
    freezed: false,
  });
  //=======================================Delete Payment Shipment Modal================================
  const [DeletePaymentShipmentModalShow, setDeletePaymentShipmentModalShow] =
    useState(false);

  const handleDeletePaymentShipmentModalClose = () =>
    setDeletePaymentShipmentModalShow(false);
  const handleDeletePaymentShipmentModalShow = () =>
    setDeletePaymentShipmentModalShow(true);
  //========================================Handle Freeze Shipment===========================
  const handleFreezeShipment = (e, shipmentId) => {
    setFreezedShipmentId(shipmentId);
    setFreezeShipment({
      freezed: e.target.value,
    });
  };
  //==================get Waiting Finish Payments ==============================

  useEffect(() => {
    const getWaitingFinishPayments = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/shipment/?status=waiting finish payment",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setWaitingPaymentShipments([]);
        console.log(body);
      } else {
        setWaitingPaymentShipments(body);
      }
    };
    getWaitingFinishPayments();
  }, []);

  //========================================freeze Shipment ==============================
  const PostFreezeShipment = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      freezed: freezeShipment.freezed,
    });
    console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/freezed/${FreezedShipmentId}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("Shipment Status Not Changed");
      console.log(body);
    } else {
      alert("Shipment Status Has Been Changed");
      window.location.reload();
    }
  };
  //=============================download File ===============================================
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  //================================Delete Shipment==============================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/shipment/admin/${shipmentId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          alert("item deleted");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  //===============================Change Shipment Status========================
  const chagneShipmentStatus = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      status: shipmentStatus,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/update-shipment-status/${shipmentId}`,
      requestOptions
    );
    if (!response.ok) {
      alert("Shipment Status Not Updated");
      console.log(response);
    } else {
      alert("Shipment Status Has Been Changed");
      window.location.reload();
    }
  };

  //=======================Handle Edit Suggestion=====================

  const handleEdit = async (suggestion) => {
    const adminUpdatedCartItems = [];
    const adminUpdatedShipmentItems = [];
    var result;
    suggestion.order.items.forEach((item) => {
      item.product.cartQuantity = item.count;
      adminUpdatedCartItems.push(item.product);
    });
    for (let i = 0; i < adminUpdatedCartItems.length; i++) {
      const element = adminUpdatedCartItems[i];
      element.count = element.cartQuantity;
      element.product = element._id;
      element.note = "";
      result = deleteKey(element, [
        "addedBy",
        "additionalImage",
        "cat",
        "countInBox",
        "cartQuantity",
        "countryOrigin",
        "createdAt",
        "hidden",
        "img",
        "materialProperty",
        "package",
        "packageGrossWeight",
        "peiceQty",
        "stockOff",
        "subCat",
        "supplierName",
        "unitForPackage",
        "updatedAt",
        "volume",
        "is_new",
        "updatedBy",
        "_id",
      ]);
      adminUpdatedShipmentItems.push(result);
    }

    localStorage.setItem(
      "adminUpdatedCartItems",
      JSON.stringify(adminUpdatedCartItems)
    );
    localStorage.setItem(
      "adminUpdatedShipmentItems",
      JSON.stringify(adminUpdatedShipmentItems)
    );
    localStorage.setItem("container", JSON.stringify(suggestion.containerInfo));
    localStorage.setItem("exportCountryId", suggestion.exportCountry._id);
    localStorage.setItem("importCountryId", suggestion.importCountry._id);
    localStorage.setItem("importStateId", suggestion.state._id);
    localStorage.setItem("deliveryMethod", suggestion.deliveryMethod._id);
    localStorage.setItem("containerId", suggestion.containerInfo._id);
    localStorage.setItem("adminUpdatedSuggestionId", suggestion._id);
    localStorage.setItem("adminUpdatedSuggestionAgentId", suggestion.agent._id);
    dispatch(adminCheckIsEditing(true));
    dispatch(adminUpdateSuggestion(adminUpdatedCartItems));
    dispatch(adminUpdateSuggestionShipment(adminUpdatedShipmentItems));
    dispatch(adminAddExportCountryId(suggestion.exportCountry._id));
    dispatch(adminAddImportCountryId(suggestion.importCountry._id));
    dispatch(adminAddImportStateId(suggestion.state._id));
    dispatch(adminAddDeliveryMethod(suggestion.deliveryMethod._id));
    dispatch(adminAddContainerId(suggestion.containerInfo.container._id));
    dispatch(adminAddContainer(suggestion.containerInfo));
    dispatch(shipmentAgentId(suggestion.agent._id));

    navigate("/dashboard/adminCart");
  };
  const columns = [
    {
      name: "ID",
      selector: (row, index) => console.log(row),
    },
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },

    {
      name: "Date",
      selector: (row) => row.order.createdAt.slice(0, 10),
    },
    {
      name: "Container",
      selector: (row) => row.containerInfo.container.usualName,
    },
    {
      name: "Agent",
      selector: (row) => row.agent.name,
    },
    {
      name: "Export Country",
      selector: (row) => row.exportCountry.countryName.en,
    },
    {
      name: "Import Country",
      selector: (row) =>
        row.importCountry.countryName.en + " / " + row.state.stateName.en,
    },
    {
      name: "Note Per Products",
      selector: (row) => <div style={{
        fontSize: "12px",
        display: "flex",
        flexDirection: "column"
      }}>{row?.order?.items?.map((d) => (
        <>{d.note ? <div>   <span>{d.product.subCat.en}</span> : <span>{d.note}</span></div> :""}</>
      ))}</div>,
      width: "200px"
    },
    {
      name: "Admin Files",
      selector: (row) => (
        <ul className="p-0">
          {row.adminFiles.map((file, index) => {
            return (
              <li>
                <Button
                  variant="warning"
                  onClick={() => getFile(file)}
                  style={{
                    wordBreak: "break-all",
                  }}
                >
                  File #{index}
                </Button>
              </li>
            );
          })}
        </ul>
      ),
    },
    {
      name: "Shipment Files",
      selector: (row) => (
        <ul className="p-0">
          {Object.entries(row.shipmentFiles).map((file) => {
            return (
              <li>
                <Button
                  variant="warning"
                  onClick={() => getFile(file[1])}
                  style={{
                    wordBreak: "break-all",
                  }}
                >
                  {file[0].slice(0, 16)}
                </Button>
              </li>
            );
          })}
        </ul>
      ),
    },
    {
      name: "Change Stauts",
      selector: (row) => (
        <Form.Select
          name="changeStatus"
          value={shipmentId}
          onChange={(e) => {
            setShipmentId(row._id);
            setShipmentStatus(e.target.value);
          }}
        >
          <option>Change Status</option>
          <option value="finished">finished</option>
        </Form.Select>
      ),
    },
    {
      name: "Freeze",
      selector: (row) => (
        <Form.Select
          name="freeze-shipment"
          value={freezeShipment}
          onChange={(e) => handleFreezeShipment(e, row._id)}
        >
          <option>Freeze Shipment</option>
          <option value="true">Freeze</option>
          <option value="false">DeFreeze</option>
        </Form.Select>
      ),
    },
    {
      name: "Edit",
      selector: (row) => (
        <Button variant="warning" onClick={() => handleEdit(row)}>
          Edit
        </Button>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <>
          <Button
            variant="danger"
            onClick={() => {
              handleDeletePaymentShipmentModalShow();
              setShipmentId(row._id);
            }}
          >
            Delete
          </Button>

          <Modal
            show={DeletePaymentShipmentModalShow}
            onHide={handleDeletePaymentShipmentModalClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete This Shipment
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleDeletePaymentShipmentModalClose}
              >
                Close
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                Delete Shipment
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ),
    },
  ];
  return (
    <section className="waiting-payment-shipping">
      <Notification />
      <Row>
        <h2>Waiting Payment Shipments</h2>
        {shipmentId ? (
          <Button variant="warning" onClick={chagneShipmentStatus}>
            Change Shipment Status
          </Button>
        ) : null}
        {freezeShipment.freezed ? (
          <Button
            id="freezedShipment"
            variant="dark"
            onClick={() => PostFreezeShipment()}
          >
            Change Shipment Status
          </Button>
        ) : null}
      </Row>
      <Row>
        <DataTable
        fixedHeader
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={WaitingPaymentShipments}
          highlightOnHover={true}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
};

export default WaitingPaymentShipment;
