import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Events = () => {
    const navigate = useNavigate()
    return (
        <Row>
            <Col md={12}>
                <div className="crud-title">
                    <h1>Our Events</h1>
                </div>
            </Col>
            <div className="d-flex">
                <Button variant="warning" id="verify-btn">
                    Export Excel for Events
                </Button>
                <Button variant="danger" id="verify-btn" >
                    Import Excel
                </Button>
                <Button variant="warning" id="verify-btn" onClick={() => navigate("/dashboard/add-events")}>
                    Add Events
                </Button>
            </div>
        </Row>
    )
}

export default Events