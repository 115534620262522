import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
var firebaseConfig = {
  apiKey: "AIzaSyCB-v6GO9Cd6PrShIxOpgj0O-hV9tJua3E",
  authDomain: "setalkel-5606d.firebaseapp.com",
  projectId: "setalkel-5606d",
  storageBucket: "setalkel-5606d.appspot.com",
  messagingSenderId: "93628215470",
  appId: "1:93628215470:web:25f025bd83282cfbb0fd3f",
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BIpBRNt7dpw0u9-L95SN8BidE4JE8sMRe7SHaCxfoOA4GSPNdM3dLOBJ3dy94jXeSzFVHmGEXXcbYb4B1dVJRtQ",
  })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("fcmToken", currentToken);
        localStorage.setItem("admin-fcm", currentToken);
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
