import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
//css
import "./SharedLayout.css";
//components
import PreLoader from "../../Components/PreLoader/PreLoader";
const SharedLayout = () => {
  //==========================loader Settings===============================

  const [isLoading, setLoading] = useState(true);
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const fakeApi = async () => {
      await sleep(2000);
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      await fetch("https://www.api.setalkel.co/agent/", requestOptions)
        .then((response) => response.text())
        .then(() => setLoading(false))
        .catch((error) => console.log("error", error));
    };
    fakeApi();
  }, []);
  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default SharedLayout;
