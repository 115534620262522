import React, { useState, useEffect } from "react";
//css
import "./Notifications.css";
//image
import NotificationImg from "../../assets/no.png";
//translation
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

const Notifications = ({ setIsHovering }) => {
  const agentNotifications = useSelector(
    (state) => state.notifications.agentNotifications
  );
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  //====================translation===================
  const [docLang, setDocLang] = useState(document.querySelector("html").lang);
  const { t } = useTranslation();
  useEffect(() => {
    setDocLang(document.querySelector("html").lang);
  }, [t]);

  return (
    <article className="notifications" onMouseLeave={handleMouseOut}>
      {agentNotifications.length > 0 ? (
        agentNotifications.map((notification, index) => {
          return (
            <div
              className={
                notification.notificationType !== true
                  ? `notification`
                  : "notification notificationClick"
              }
              key={index}
            >
              <div className="notification-img">
              <img src={NotificationImg} alt="Avatar"/>
              </div>
              <div className="notification-info">
                <div>
                  <span>
                    {notification.title[docLang]
                      ? notification.title[docLang]
                      : notification.title.en}
                  </span>
                  <span>{notification.date}</span>
                </div>
                <p>
                  {notification.body[docLang]
                    ? notification.body[docLang]
                    : notification.body.en}
                </p>
              </div>
            </div>
          );
        })
      ) : (
        <h4>Loading</h4>
      )}
    </article>
  );
};

export default Notifications;
