
import CryptoJS from 'crypto-js';
const secretKey = "LLjo;bhdjvcludfl476%&$^%&^O*bkjsdv98njsldgUJGKBL"
const secretIV = "Pjoinklskgb9nkly3$#^*ihklfnJH:KG"
const key = CryptoJS.SHA512(secretKey).toString(CryptoJS.enc.Hex).substring(0, 64);
const encIv = CryptoJS.SHA512(secretIV).toString(CryptoJS.enc.Hex).substring(0, 32);
export  function decryptData(encryptedData) {
  const decrypted = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Hex.parse(key), {
    iv: CryptoJS.enc.Hex.parse(encIv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString(CryptoJS.enc.Utf8);
  return decrypted;
}
