import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'

const UploadVideo = () => {
    const [videoSrc, setVideoSrc] = useState(null);

    const handleVideoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const videoURL = URL.createObjectURL(file);
            setVideoSrc(videoURL);
        }
    };

    const handleRemoveVideo = () => {
        setVideoSrc(null);
        URL.revokeObjectURL(videoSrc); // Release memory
    };

    return (
        <div className=''>
            <h4 className='text-center mb-4'>Upload Video To Home Page</h4>
            <Form.Group>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    <Form.Control
                        type="file"
                        name="video"
                        id="videoUpload"
                        accept="video/*"
                        onChange={handleVideoUpload}
                    />
                </div>
            </Form.Group>

            {videoSrc && (
                <div style={{ marginTop: "20px" }}>
                    <video width="100%" controls>
                        <source src={videoSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <Button variant="danger" onClick={handleRemoveVideo} style={{ marginTop: "10px" }}>
                        Remove Video
                    </Button>
                </div>
            )}
        </div>
    )
}

export default UploadVideo
