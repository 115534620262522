import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//bootstrap
import { Button, Form, Row } from "react-bootstrap";

import "./UpdatePartners.css";
import toast from "react-hot-toast";
import { FaDownload, FaTrashAlt } from "react-icons/fa";
const UpdatePartners = () => {
  const params = useParams();
  let navigate = useNavigate();

  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const [ImgGallery, setImgGallery] = useState({
    ImgGallery: [],
  });
  const [values, setValues] = useState({
    _id: "",
    title: {
      en: "",
      ar: "",
      fr: "",
      de: "",
      ru: "",
      cn: "",
      tur: "",
      ir: "",
      dk: "",
    },
    desc: {
      en: "",
      ar: "",
      fr: "",
      de: "",
      ru: "",
      cn: "",
      tur: "",
      ir: "",
      dk: "",
    },
  });

  //=======================Handle Imput Desc=========================
  const handleInputDesc = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      desc: { ...values.desc, [name]: value },
    });
  };
  //=======================Handle Imput Title=========================
  const handleInputTitle = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      title: { ...values.title, [name]: value },
    });
  };
  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  //=======================Image Gallery Handeling===============
  const handleGalleryChange = (e) => {
    setImgGallery({
      ImgGallery: e.target.files,
    });
  };
  //==============Update Partner Data=====================
  const getPartnerById = useCallback(async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/partners/${params.Id}`,
      requestOptions
    );
    const body = await response.json();
    setImgfile({ Imgfile: body.img })
    setValues({
      _id: body._id,
      title: {
        ar: body.title.ar ? body.title.ar : "",
        en: body.title.en ? body.title.en : "",
        fr: body.title.fr ? body.title.fr : "",
        de: body.title.de ? body.title.de : "",
        ru: body.title.ru ? body.title.ru : "",
        cn: body.title.cn ? body.title.cn : "",
        tur: body.title.tur ? body.title.tur : "",
        ir: body.title.ir ? body.title.ir : "",
        dk: body.title.dk ? body.title.dk : "",
      },
      desc: {
        ar: body.desc.ar ? body.desc.ar : "",
        en: body.desc.en ? body.desc.en : "",
        fr: body.desc.fr ? body.desc.fr : "",
        de: body.desc.de ? body.desc.de : "",
        ru: body.desc.ru ? body.desc.ru : "",
        cn: body.desc.cn ? body.desc.cn : "",
        tur: body.desc.tur ? body.desc.tur : "",
        ir: body.desc.ir ? body.desc.ir : "",
        dk: body.desc.dk ? body.desc.dk : "",
      },
    });
  }, [params.Id]);

  useEffect(() => {
    getPartnerById();
  }, [getPartnerById]);

  //=====================================Post Kitchen Gallery====================
  const UpdatePartnersGallery = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      for (let i = 0; i < ImgGallery.ImgGallery.length; i++) {
        fd.append(
          "images",
          ImgGallery.ImgGallery[i],
          ImgGallery.ImgGallery[i].name
        );
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/partners/${id}/gallery`,
        requestOptions
      );
      const body = await ImgResponse.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  //=====================================Post Kitchen Image====================

  const UpdatePartnerImg = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();

      fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/partners/image/${id}`,
        requestOptions
      );
      const body = await ImgResponse.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };

  //=======================Update Partner=========================
  const UpdatePartner = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = {
      title: {
        ar: values.title.ar,
        en: values.title.en,
        de: values.title.de,
        fr: values.title.fr,
        ru: values.title.ru,
        cn: values.title.cn,
        tur: values.title.tur,
        ir: values.title.ir,
        dk: values.title.dk,
      },
      desc: {
        ar: values.desc.ar,
        en: values.desc.en,
        de: values.desc.de,
        fr: values.desc.fr,
        ru: values.desc.ru,
        cn: values.desc.cn,
        tur: values.desc.tur,
        ir: values.desc.ir,
        dk: values.desc.dk,
      },
    };
    let NewTitle = raw.title;
    let NewDesc = raw.desc;
    let NewRaw = raw;
    Object.keys(NewTitle).forEach((key) => {
      if (NewTitle[key] === "" || NewTitle[key] === "undefined") {
        delete NewTitle[key];
      }
    });
    Object.keys(NewDesc).forEach((key) => {
      if (NewDesc[key] === "" || NewDesc[key] === "undefined") {
        delete NewDesc[key];
      }
    });
    Object.keys(NewRaw).forEach((key) => {
      if (NewRaw[key] === "" || NewRaw[key] === "undefined") {
        delete NewRaw[key];
      }
    });
    const raw2 = JSON.stringify(NewRaw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw2,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/partners/${values._id}`,
      requestOptions
    );
    const body = await response.json();

    if (!response.ok) {
      toast.error("Partner Not Updated");
      console.log(body);
    } else {
      if (ImgGallery.ImgGallery.length > 0) {
        await UpdatePartnersGallery(body._id);
      }
      if (Imgfile.Imgfile) {
        await UpdatePartnerImg(body._id);
      }
      toast.success("Partner Updated");
      navigate("/dashboard/crpartners");
    }
  };
  //==========================================Cancel Btn====================================
  const CancelUpdate = () => {
    navigate("/dashboard/crpartners");
  };
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <section className="cms-create-partners">
      <Row>
        <div className="crud-partners">
          <div className="crud-title">
            <h1>Update Partners</h1>
          </div>
          <div className="create-partner">
            <div className="partner-titles-form">
              <Form>
                {/* ======================Start Title Langs=========================*/}
                <Form.Group>
                  <Form.Label>Title In English</Form.Label>
                  <Form.Control
                    type="text"
                    name="en"
                    value={values.title.en}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder="Title In English"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Title In Arabic</Form.Label>
                  <Form.Control
                    type="text"
                    name="ar"
                    value={values.title.ar}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder="Title In Arabic"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Title In French</Form.Label>
                  <Form.Control
                    type="text"
                    name="fr"
                    value={values.title.fr}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder="Title In French"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Title In Russian</Form.Label>
                  <Form.Control
                    type="text"
                    name="ru"
                    placeholder="Title In Russia"
                    value={values.title.ru}
                    onChange={(e) => handleInputTitle(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Title In German</Form.Label>
                  <Form.Control
                    type="text"
                    name="de"
                    value={values.title.de}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder="Title In Germany"
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Title In Chinese</Form.Label>
                  <Form.Control
                    type="text"
                    name="cn"
                    placeholder="Title In China"
                    value={values.title.cn}
                    onChange={(e) => handleInputTitle(e)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Title In Turkish</Form.Label>
                  <Form.Control
                    type="text"
                    name="tur"
                    value={values.title.tur}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder="Title In Turkish"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Title In Persian</Form.Label>
                  <Form.Control
                    type="text"
                    name="ir"
                    value={values.title.ir}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder="Title In Persian"
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Title In Danish</Form.Label>
                  <Form.Control
                    type="text"
                    name="dk"
                    value={values.title.dk}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder="Title In Danish"
                  />
                </Form.Group>
                {/* ======================End Title Langs=========================*/}

                <Form.Group>
                  <Form.Label htmlFor="ProductImg">Product Image</Form.Label>
                  <div className="d-flex flex-column">
                    <Form.Control
                      type="file"
                      name="image"
                      id="productImg"
                      onChange={handleImageChange}
                    />
                    {Imgfile.Imgfile && <div className="position-relative" style={{
                      width: "fit-content"
                    }}>
                      <img
                        style={{
                          height: "100px",
                          width: "100px",
                        }}
                        src={"https://www.api.setalkel.co" + Imgfile.Imgfile}
                      />
                      <FaDownload onClick={() => getFile(Imgfile.Imgfile)} style={{
                        color: "red",
                        fontSize: "15px",
                        position: "absolute",
                        top: "0",
                        right: "0"

                      }} />
                      <FaTrashAlt style={{
                        color: "red",
                        fontSize: "15px",
                        position: "absolute",
                        top: "0",
                        right: "20px",
                        zIndex: "100"

                      }} />
                    </div>
                    }
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="PartnerGallery">
                    Partner Image Gallery
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="images"
                    multiple
                    id="partnerImg"
                    onChange={handleGalleryChange}
                  />
                </Form.Group>
                <div className="d-flex">
                  <Button variant="warning" onClick={UpdatePartner}>
                    Update Partner
                  </Button>

                  <Button variant="outline-dark" onClick={CancelUpdate}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
            {/* ======================Start Title Desc=========================*/}

            <div className="partner-desc-form">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description In English</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="en"
                    value={values.desc.en}
                    onChange={(e) => handleInputDesc(e)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description In Arabic</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="ar"
                    value={values.desc.ar}
                    onChange={(e) => handleInputDesc(e)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description In French</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={values.desc.fr}
                    name="fr"
                    onChange={(e) => handleInputDesc(e)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description In Russian</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={values.desc.ru}
                    name="ru"
                    onChange={(e) => handleInputDesc(e)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description In German</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={values.desc.de}
                    name="de"
                    onChange={(e) => handleInputDesc(e)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description In Chinese</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={values.desc.cn}
                    name="cn"
                    onChange={(e) => handleInputDesc(e)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description In Turkish</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="tur"
                    value={values.desc.tur}
                    onChange={(e) => handleInputDesc(e)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description In Persian</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="ir"
                    value={values.desc.ir}
                    onChange={(e) => handleInputDesc(e)}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description In Danish</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="tur"
                    value={values.desc.dk}
                    onChange={(e) => handleInputDesc(e)}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </Row>
    </section>
  );
};

export default UpdatePartners;
