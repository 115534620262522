import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//slider

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//boostrap
import { Button, Modal } from "react-bootstrap";
//css
import "./ShipmentOrderCard.css";
//image
import OrderBox from "../../../assets/Payments/orderBox.png";
import FollowOrder from "../../../assets/Payments/followOrder.png";
import Edit from "../../../assets/Payments/edit.png";
import Calender from "../../../assets/Payments/Calendar.png";
//icons
// import { GrFormClose } from "react-icons/gr";
import { TimeCircle, Paper, TickSquare, Delete } from "react-iconly";
//translation
import { useTranslation } from "react-i18next";

const ShipmentOrderCard = ({ order, id }) => {
  const [DeleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const { t } = useTranslation();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  //==========================handleOrderDetails==================
  const navigate = useNavigate();
  const handleOrderDetails = (order) => {
    if (order.status === "suggestion") {
      navigate(`/shipmentProcess/suggestion`);
    } else if (order.status === "waiting shipping") {
      alert("Please Wait Until Setalkel Admin Add The Shipment Shipping Fee");
    } else if (order.status === "waiting finish payment") {
      navigate(`/shipmentProcess/payment`);
    } else if (order.status === "finished") {
      navigate(`/shipmentProcess/myorders/${order._id}`);
    } else {
      navigate(`/shipmentProcess/myorders`);
    }
  };
  //====================Delete Suggestion===============
  const deleteSuggestion = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/${id}`,
      requestOptions
    );
    if (!response.ok) {
      alert("Suggestion Not Deleted Please Contact Support ");
      console.log(response);
    } else {
      alert("Suggestion Has Been Deleted Successfully");
      window.location.reload();
    }
  };

  //===================DeleteOrderConfirmModal====================
  function DeleteOrderConfirmModal(props) {
    const suggestionOrder = props.order;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t(
              `Components.ShipmentComponents.ShipmentOrderCard.DeleteModal.Title`
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {t(
              `Components.ShipmentComponents.ShipmentOrderCard.DeleteModal.Warning`
            )}
            !!!!
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={props.onHide}>
            {t(
              `Components.ShipmentComponents.ShipmentOrderCard.DeleteModal.CloseBtn`
            )}
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteSuggestion(suggestionOrder._id)}
          >
            {t(
              `Components.ShipmentComponents.ShipmentOrderCard.DeleteModal.DeleteBtn`
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <article className="shipment-order-card" id={id}>
      <div className="order-card-header">
        <img src={OrderBox} alt="orderBox" id="order-card-header-img" />
        <div className="order-card-header-info">
          <div>
            <div>
              <span>{`${t(
                `Components.ShipmentComponents.ShipmentOrderCard.OrderNumber`
              )} : `}</span>
              <span>{`${order.orderNumber}`}</span>
            </div>

            <p>
              <img src={Calender} alt="date" />
              <span>{order.order.createdAt.slice(0, 10)}</span>
            </p>
          </div>
          <div className="order-status">
            {order.status === "suggestion" ? (
              <div className="order-status-label suggesion-tag">
                <Paper set="broken" />
                <p>
                  {t(
                    `Components.ShipmentComponents.ShipmentOrderCard.OrderConditions.Suggestion`
                  )}
                </p>
              </div>
            ) : order.status === "waiting shipping" ? (
              <div className="order-status-label shipping-tag">
                <TimeCircle set="broken" />
                <p>
                  {t(
                    `Components.ShipmentComponents.ShipmentOrderCard.OrderConditions.WaitingShipping`
                  )}
                </p>
              </div>
            ) : order.status === "waiting finish payment" ? (
              <div className="order-status-label payment-tag">
                <TimeCircle set="broken" />
                <p>
                  {t(
                    `Components.ShipmentComponents.ShipmentOrderCard.OrderConditions.WaitingFinishPayment`
                  )}
                </p>
              </div>
            ) : (
              <div className="order-status-label finished-tag">
                <TickSquare set="broken" />
                <p>
                  {t(
                    `Components.ShipmentComponents.ShipmentOrderCard.OrderConditions.Finished`
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr id="breaker" />
      <div className="order-card-body">
        <h3>
          {t(`Components.ShipmentComponents.ShipmentOrderCard.OrderContent`)}
        </h3>

        <div className="order-card-body-items">
          <Carousel
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={true}
            centerMode={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5 ease"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-0-px"
          >
            {order.order.items.map((product, index) => {
              return (
                <img
                  src={`https://www.api.setalkel.co${product.product.img}`}
                  alt="product"
                  key={index}
                />
              );
            })}
          </Carousel>
        </div>
        <hr id="breaker2" />

        <div className="order-card-body-info">
          <p>
            <span>
              {t(`Components.ShipmentComponents.ShipmentOrderCard.TotalPrice`)}
            </span>
            <span>{` : ${order.order.orderPrice.toFixed(2)}$`}</span>
          </p>
          <p>
            <span>
              {t(`Components.ShipmentComponents.ShipmentOrderCard.TotalWeight`)}
            </span>
            <span>{` : ${order.order.orderWeight.toFixed(2)}KG`}</span>
          </p>
        </div>
      </div>

      <div className="order-card-footer">
        <div className="order-card-footer-btns">
          {order.status === "suggestion" ? (
            <>
              <Button onClick={() => setDeleteConfirmModal(true)}>
                <span>
                  <Delete set="broken" />
                  {t(
                    `Components.ShipmentComponents.ShipmentOrderCard.DeleteOrder`
                  )}
                </span>
              </Button>
              <DeleteOrderConfirmModal
                show={DeleteConfirmModal}
                order={order}
                onHide={() => setDeleteConfirmModal(false)}
              />
            </>
          ) : (
            <a
              href="https://www.searates.com/ar/container/tracking/?{%22event%22:%22search%20complete%22,%22container%22:%22DFSU7125808%22,%22searchType%22:%22auto%22,%22result%22:%22not-found%22}"
              target="_blank"
              rel="noreferrer"
            >
              <span>
                <img src={FollowOrder} alt="follow" />
                {t(
                  `Components.ShipmentComponents.ShipmentOrderCard.OrderTracking`
                )}
              </span>
            </a>
          )}

          <Button onClick={() => handleOrderDetails(order)}>
            {order.status !== "finished" ? (
              <span>
                <img src={Edit} alt="edit" />
                {t(
                  `Components.ShipmentComponents.ShipmentOrderCard.ContinueOrder`
                )}
              </span>
            ) : (
              <span>
                {t(
                  `Components.ShipmentComponents.ShipmentOrderCard.OrderDetails`
                )}
                <img src={Edit} alt="edit" />
              </span>
            )}
          </Button>
        </div>
      </div>
    </article>
  );
};

export default ShipmentOrderCard;
