import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Form, Row } from "react-bootstrap";
import ApiInstances from '../../Api/axiso';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

const PriceAgent = () => {
  const [Prices, setPrices] = useState([])
  const [priceId, setPriceId] = useState('')
  const [agents, setAgents] = useState([])
  const [agents2, setAgents2] = useState([])
  const [selectedAgents, setSelectedAgents] = useState([])

  const fetchPrice = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    const response = await fetch(
      `https://www.api.setalkel.co/prices`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      console.log(body);
    } else {
      setPrices(body?.prices)
    }
  };

  useEffect(() => {
    fetchPrice()
  }, [])

  const handlePriceChange = (e) => {
    setPriceId(e.target.value);
  };

  useEffect(() => {
    const getAgentsisVerified = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: myHeaders,
      };

      const response = await fetch(
        "https://www.api.setalkel.co/agent/admin/?isVerified=true",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        console.log(body);
        setAgents([]);
      } else {
        setAgents(body);
      }
    };
    getAgentsisVerified();
  }, []);

  useEffect(() => {
    const getAgentsisUNVerified = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: myHeaders,
      };

      const response = await fetch(
        "https://www.api.setalkel.co/agent/admin/?isVerified=false",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        console.log(body);
        setAgents2([]);
      } else {
        setAgents2(body);
      }
    };
    getAgentsisUNVerified();
  }, []);

  const handleAgentChange = (e, agent) => {
    if (e.target.checked) {
      setSelectedAgents((prev) => [...prev, agent._id]);
    } else {
      setSelectedAgents((prev) => prev.filter((id) => id !== agent._id));
    }
  };
  const { mutate , isPending } = useMutation({
    mutationFn: async (res) => {
        const { data } = await ApiInstances.post(`/agent/add-price`, res);
        return data;
    },
    onSuccess: () => {
        toast.success("Successfully...");
    
    },
});
  const handleAddAgentsToPrice = () => {
    const agentPriceArray = selectedAgents.map((agentId) => ({
      agentId,
      priceId,
    }));
    console.log(agentPriceArray);
    mutate(agentPriceArray)
    // You can then send this array to your API or handle it as needed

  };

  return (
    <section>
      <Row>
        <div className="crud-countries">
          <div className="title">
            <h1>PriceAgent</h1>
          </div>
        </div>
      </Row>
      <Row>
        <Button style={{
          width: "fit-content"
        }} variant="warning" id="verify-btn">
          Export Excel File for Agents
        </Button>
        <Form>
          <div className="import-states-agent">
            <div>
              <h6>Choose Price</h6>
              <Form.Select
                name="countryName"
                value={priceId}
                onChange={(e) => handlePriceChange(e)}
              >
                <option>Open this select menu</option>
                {Prices?.map((pr, index) => {
                  return (
                    <option key={index} value={pr._id}>
                      {pr.name}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
          </div>
          <h4>Verified Agents </h4>
          <Form.Group className="mt-3">
            <Form.Label>
              Choose Agents for this Price
            </Form.Label>
            <div className="check-import-state">
              {agents?.map((agent, index) => (
                <Form.Check
                  type="checkbox"
                  key={index}
                  label={agent.name}
                  id={agent._id}
                  value={agent._id}
                  onChange={(e) => handleAgentChange(e, agent)}
                />
              ))}
            </div>
          </Form.Group>
          <hr />
          <h4>Un Verified Agents</h4>
          <Form.Group className="mt-3">
            <Form.Label>
              Choose Agents for this Price
            </Form.Label>
            <div className="check-import-state">
              {agents2?.map((agent, index) => (
                <Form.Check
                  type="checkbox"
                  key={index}
                  label={agent.name}
                  id={agent._id}
                  value={agent._id}
                  onChange={(e) => handleAgentChange(e, agent)}
                />
              ))}
            </div>
          </Form.Group>
          <Button variant="warning" disabled={isPending} onClick={handleAddAgentsToPrice}>
            Add Agents To Price
          </Button>
        </Form>
      </Row>
      <hr />
    </section>
  )
}

export default PriceAgent;
