import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
//boostrap
import { Row, Form, Button, Modal } from "react-bootstrap";
//css
import "./UpdateBites.css";
//semantic ui
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { FaDownload, FaTrashAlt } from "react-icons/fa";
const UpdateKitchen = () => {
  const params = useParams();
  const [SingleBite, setSingleBite] = useState({});
  const [deletedImg, setDeletedImg] = useState("");
  let navigate = useNavigate();
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const [ImgGallery, setImgGallery] = useState({
    ImgGallery: [],
  });
  const [Videofile, setVideofile] = useState({
    Videofile: null,
  });
  const [values, setValues] = useState({
    _id: "",
    title: {
      en: "",
      ar: "",
      fr: "",
      de: "",
      ru: "",
      cn: "",
      tur: "",
      ir: "",
      dk: "",
    },

    prep: [
      {
        en: "",
        ar: "",
        fr: "",
        de: "",
        ru: "",
        cn: "",
        tur: "",
        ir: "",
        dk: "",
      },
    ],
    ingredient: [
      {
        en: "",
        ar: "",
        fr: "",
        de: "",
        ru: "",
        cn: "",
        tur: "",
        ir: "",
        dk: "",
      },
    ],
  });
  //=================================Delete Bite Img========================
  const [DeleteBiteImgModalShow, setDeleteBiteImgModalShow] = useState(false);
  const handleDeleteBiteImgModalClose = () => setDeleteBiteImgModalShow(false);
  const handleDeleteBiteImgModalShow = () => setDeleteBiteImgModalShow(true);
  //=================================Delete Bite Gallery========================
  const [DeleteBiteGalleryModalShow, setDeleteBiteGalleryModalShow] =
    useState(false);
  const handleDeleteBiteGalleryModalClose = () =>
    setDeleteBiteGalleryModalShow(false);
  const handleDeleteBiteGalleryModalShow = () =>
    setDeleteBiteGalleryModalShow(true);
  //=================================Delete Bite Video========================
  const [DeleteBiteVideoModalShow, setDeleteBiteVideoModalShow] =
    useState(false);
  const handleDeleteBiteVideoModalClose = () =>
    setDeleteBiteVideoModalShow(false);
  const handleDeleteBiteVideoModalShow = () =>
    setDeleteBiteVideoModalShow(true);
  //=======================Title Input Handeling===============
  const handleTitleChange = (e) => {
    const { name, value } = e.target;

    setValues({ ...values, title: { ...values.title, [name]: value } });
  };
  //=======================Title Input Handeling===============
  const handlePrepChange = (e) => {
    const { name, value } = e.target;
    const element = values.prep[0];
    element[name] = value;
    setValues({ ...values, prep: [element] });
  };
  //=======================Title Input Handeling===============
  const handleIngredientChange = (e) => {
    const { name, value } = e.target;
    const element = values.ingredient[0];
    element[name] = value;
    setValues({ ...values, ingredient: [element] });
  };
  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  //=======================Video Handeling===============
  const handleVideoChange = (e) => {
    setVideofile({
      Videofile: e.target.files[0],
    });
  };
  //=======================Image Gallery Handeling===============
  const handleGalleryChange = (e) => {
    setImgGallery({
      ImgGallery: e.target.files,
    });
  };
  //============================getBiteById==============================
  const getBiteById = useCallback(async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/kitchen/${params.Id}`,
      requestOptions
    );
    const body = await response.json();
    setSingleBite(body);
    setImgfile({ Imgfile: body.img })
    setValues({
      _id: body._id,
      title: {
        en: body.title.en ? body.title.en : "",
        ar: body.title.ar ? body.title.ar : "",
        fr: body.title.fr ? body.title.fr : "",
        de: body.title.de ? body.title.de : "",
        ru: body.title.ru ? body.title.ru : "",
        cn: body.title.cn ? body.title.cn : "",
        tur: body.title.tur ? body.title.tur : "",
        ir: body.title.ir ? body.title.ir : "",
        dk: body.title.dk ? body.title.dk : "",
      },

      prep: [
        {
          en: body.prep[0].en ? body.prep[0].en : "",
          ar: body.prep[0].ar ? body.prep[0].ar : "",
          fr: body.prep[0].fr ? body.prep[0].fr : "",
          de: body.prep[0].de ? body.prep[0].de : "",
          ru: body.prep[0].ru ? body.prep[0].ru : "",
          cn: body.prep[0].cn ? body.prep[0].cn : "",
          tur: body.prep[0].tur ? body.prep[0].tur : "",
          ir: body.prep[0].ir ? body.prep[0].ir : "",
          dk: body.prep[0].dk ? body.prep[0].dk : "",
        },
      ],
      ingredient: [
        {
          en: body.ingredient[0].en ? body.ingredient[0].en : "",
          ar: body.ingredient[0].ar ? body.ingredient[0].ar : "",
          fr: body.ingredient[0].fr ? body.ingredient[0].fr : "",
          de: body.ingredient[0].de ? body.ingredient[0].de : "",
          ru: body.ingredient[0].ru ? body.ingredient[0].ru : "",
          cn: body.ingredient[0].cn ? body.ingredient[0].cn : "",
          tur: body.ingredient[0].tur ? body.ingredient[0].tur : "",
          ir: body.ingredient[0].ir ? body.ingredient[0].ir : "",
          dk: body.ingredient[0].dk ? body.ingredient[0].dk : "",
        },
      ],
    });
  }, [params.Id]);
  useEffect(() => {
    getBiteById();
  }, [getBiteById]);
  //==============Update Product Data=====================

  //=====================================Post Activity Video====================
  const UpdatetBiteVideo = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      fd.append("file", Videofile.Videofile, Videofile.Videofile.name);

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/kitchen/video/${id}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        alert("Video Not Uploaded");
        console.log(body);
      } else {
        alert("Video Has Been Uploaded");
      }
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  //=====================================Post Kitchen Gallery====================

  const UpdateBiteGallery = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      for (let i = 0; i < ImgGallery.ImgGallery.length; i++) {
        fd.append(
          "images",
          ImgGallery.ImgGallery[i],
          ImgGallery.ImgGallery[i].name
        );
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/kitchen/${id}/gallery`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        alert("Gallery Not Uploaded");
        console.log(body);
      } else {
        alert("Gallery Has Been Uploaded");
      }
    } catch (error) {
      console.log(error);
    }
  };
  //=====================================Post Kitchen Image====================

  const UpdateBiteImg = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();

      fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/kitchen/image/${id}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        alert("Image Not Uploaded");
        console.log(body);
      } else {
        alert("Image Has Been Uploaded");
      }
    } catch (error) {
      console.log(error);
    }
  };
  //===============Update Bite====================

  const UpdateBiteData = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = {
      title: {
        en: values.title.en,
        ar: values.title.ar,
        fr: values.title.fr,
        de: values.title.de,
        ru: values.title.ru,
        cn: values.title.cn,
        tur: values.title.tur,
        ir: values.title.ir,
        dk: values.title.dk,
      },

      prep: [
        {
          en: values.prep[0].en,
          ar: values.prep[0].ar,
          fr: values.prep[0].fr,
          de: values.prep[0].de,
          ru: values.prep[0].ru,
          cn: values.prep[0].cn,
          tur: values.prep[0].tur,
          ir: values.prep[0].ir,
          dk: values.prep[0].dk,
        },
      ],
      ingredient: [
        {
          en: values.ingredient[0].en,
          ar: values.ingredient[0].ar,
          fr: values.ingredient[0].fr,
          de: values.ingredient[0].de,
          ru: values.ingredient[0].ru,
          cn: values.ingredient[0].cn,
          tur: values.ingredient[0].tur,
          ir: values.ingredient[0].ir,
          dk: values.ingredient[0].dk,
        },
      ],
    };
    let NewTitle = raw.title;
    let NewPrep = raw.prep[0];
    let NewIngredient = raw.ingredient[0];
    let NewRaw = raw;

    Object.keys(NewTitle).forEach((key) => {
      if (NewTitle[key] === "" || NewTitle[key] === "undefined") {
        delete NewTitle[key];
      }
    });
    Object.keys(NewPrep).forEach((key) => {
      if (NewPrep[key] === "" || NewPrep[key] === "undefined") {
        delete NewPrep[key];
      }
    });
    Object.keys(NewIngredient).forEach((key) => {
      if (NewIngredient[key] === "" || NewIngredient[key] === "undefined") {
        delete NewIngredient[key];
      }
    });
    Object.keys(NewRaw).forEach((key) => {
      if (NewRaw[key] === "" || NewRaw[key] === "undefined") {
        delete NewRaw[key];
      }
    });

    const raw2 = JSON.stringify(NewRaw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw2,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/kitchen/${values._id}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("Bite Not Update !!!!");
      console.log(body);
    } else {
      if (Imgfile.Imgfile) {
        await UpdateBiteImg(body._id);
      }
      if (ImgGallery.ImgGallery.length > 0) {
        await UpdateBiteGallery(body._id);
      }
      if (Videofile.Videofile) {
        await UpdatetBiteVideo(body._id);
      }
      alert("Bite Has Been Updated");
      navigate("/dashboard/crbites");
    }
  };
  const Cancel = () => {
    navigate("/dashboard/crbites");
  };
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  //==================================================Delete Bite Gallery=======================================
  const deleteBiteGallery = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/kitchen/${values._id}/gallery`,
      requestOptions
    );
    if (!response.ok) {
      alert("Gallery Not Deleted");
      console.log(response);
    } else {
      alert("Gallery Deleted");
      window.location.reload();
    }
  };
  //======================================Delete Img From Gallery======================
  const deleteImgFromBiteGallery = async () => {
    const imgId = deletedImg.slice(7);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/kitchen/image-gallary/${imgId}/${values._id}`,
      requestOptions
    );
    if (!response.ok) {
      alert("Image Not Deleted");
      console.log(response);
    } else {
      alert("Image Deleted");
      window.location.reload();
    }
  };
  //==================================================Delete Video Bite=========================
  const deleteBiteVideo = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/kitchen/video/${values._id}`,
      requestOptions
    );
    if (!response.ok) {
      alert("Video Not Deleted");
      console.log(response);
    } else {
      alert("Video Deleted");
      window.location.reload();
    }
  };
  return (
    <section className="cms-update-bites">
      <Row style={{ width: "100%" }}>
        <div className="crud-kitchen">
          <div className="crud-title">
            <h1>Bites</h1>
          </div>

          <div className="crud-kitchen">
            <Form className="kitchen-form">
              <Form.Group>
                <h4>Bites Langs </h4>
                <div className="kitchen-langs">
                  {/* =================================Start Bite Langs================================= */}

                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Name In English
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="en"
                      label="category"
                      value={values.title.en}
                      onChange={handleTitleChange}
                      placeholder="Bite name in english"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Name In Arabic
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="ar"
                      label="category"
                      value={values.title.ar}
                      onChange={handleTitleChange}
                      placeholder="Bite Name in arabic"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Name In French
                    </Form.Label>

                    <Form.Control
                      name="fr"
                      label="category"
                      value={values.title.fr}
                      onChange={handleTitleChange}
                      placeholder="Bite name in french"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Name In Russian
                    </Form.Label>

                    <Form.Control
                      name="ru"
                      label="category"
                      value={values.title.ru}
                      onChange={handleTitleChange}
                      placeholder="Bite name in russian"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Name In German
                    </Form.Label>

                    <Form.Control
                      name="de"
                      label="category"
                      value={values.title.de}
                      onChange={handleTitleChange}
                      placeholder="Bite name in detuch"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Name In China
                    </Form.Label>

                    <Form.Control
                      name="cn"
                      label="category"
                      value={values.title.cn}
                      onChange={handleTitleChange}
                      placeholder="name in cn"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Name In Turkish
                    </Form.Label>
                    <Form.Control
                      name="tur"
                      label="category"
                      value={values.title.tur}
                      onChange={handleTitleChange}
                      placeholder="Bite name in turkish"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Name In Persian
                    </Form.Label>
                    <Form.Control
                      name="ir"
                      label="category"
                      value={values.title.ir}
                      onChange={handleTitleChange}
                      placeholder="Bite name in Persian"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Name In Danish
                    </Form.Label>
                    <Form.Control
                      name="dk"
                      label="category"
                      value={values.title.dk}
                      onChange={handleTitleChange}
                      placeholder="Bite name in Danish"
                    />
                  </Form.Group>

                  {/* =================================End Bite Langs================================= */}

                  <Form.Group>
                    <Form.Label htmlFor="KitchenImg">Bite Image</Form.Label>
                    <div className="d-flex flex-column">
                      <Form.Control
                        type="file"
                        name="image"
                        id="productImg"
                        onChange={handleImageChange}
                      />
                      {Imgfile.Imgfile && <div className="position-relative" style={{
                        width: "fit-content"
                      }}>
                        <img
                          style={{
                            height: "100px",
                            width: "100px",
                          }}
                          src={"https://www.api.setalkel.co" + Imgfile.Imgfile}
                        />
                        <FaDownload onClick={() => getFile(Imgfile.Imgfile)} style={{
                          color: "red",
                          fontSize: "15px",
                          position: "absolute",
                          top: "0",
                          right: "0"

                        }} />
                        <FaTrashAlt style={{
                          color: "red",
                          fontSize: "15px",
                          position: "absolute",
                          top: "0",
                          right: "20px",
                          zIndex: "100"

                        }} />
                      </div>
                      }
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="KitchenImg">
                      Bite Image Gallery
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="images"
                      multiple
                      id="kitchenImg"
                      onChange={handleGalleryChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Setalkel Bite Video</Form.Label>
                    <Form.Control
                      type="file"
                      name="video"
                      onChange={handleVideoChange}
                    />
                  </Form.Group>
                </div>
                {/* =================================Start Bite Preperations================================= */}
                <br />
                <h4>Bite Preperations </h4>
                <div className="kitchen-prep">

                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Preparation In English
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="en"
                      label="category"
                      onChange={handlePrepChange}
                      value={values.prep[0].en}
                      placeholder="Bite Preparation In English"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Preparation In Arabic
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="ar"
                      label="category"
                      value={values.prep[0].ar}
                      onChange={handlePrepChange}
                      placeholder=" Bite Preparation In Arabic"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Preparation In French
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      name="fr"
                      value={values.prep[0].fr}
                      label="category"
                      onChange={handlePrepChange}
                      placeholder="name in fr"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Preparation In Russian
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      name="ru"
                      label="category"
                      value={values.prep[0].ru}
                      onChange={handlePrepChange}
                      placeholder="name in ru"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Preparation In German
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="de"
                      label="category"
                      value={values.prep[0].de}
                      onChange={handlePrepChange}
                      placeholder="name in de"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Preparation In China
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      name="cn"
                      label="category"
                      value={values.prep[0].cn}
                      onChange={handlePrepChange}
                      placeholder="name in cn"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Preparation In Turkish
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="tur"
                      label="category"
                      value={values.prep[0].tur}
                      onChange={handlePrepChange}
                      placeholder="name in tur"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Preparation In Persian
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="ir"
                      label="category"
                      value={values.prep[0].ir}
                      onChange={handlePrepChange}
                      placeholder="name in ir"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Preparation In Danish
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="dk"
                      label="category"
                      value={values.prep[0].dk}
                      onChange={handlePrepChange}
                      placeholder="name in dk"
                    />
                  </Form.Group>
                </div>
                {/* =================================End Bite Preperations================================= */}

                {/* ==========================Start Bite ingredient================= */}
                <h4>Bite Ingredients </h4>
                <div className="kitchen-ingredient">
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Ingredient In English
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="en"
                      label="category"
                      value={values.ingredient[0].en}
                      onChange={handleIngredientChange}
                      placeholder="name in en"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Ingredient In Arabic
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="ar"
                      label="category"
                      value={values.ingredient[0].ar}
                      onChange={handleIngredientChange}
                      placeholder="name in ar"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Ingredient In French
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="fr"
                      value={values.ingredient[0].fr}
                      label="category"
                      onChange={handleIngredientChange}
                      placeholder="name in fr"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Ingredient In Russian
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="ru"
                      label="category"
                      value={values.ingredient[0].ru}
                      onChange={handleIngredientChange}
                      placeholder="name in ru"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Ingredient In German
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="de"
                      label="category"
                      value={values.ingredient[0].de}
                      onChange={handleIngredientChange}
                      placeholder="name in de"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Ingredient In China
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="cn"
                      label="category"
                      value={values.ingredient[0].cn}
                      onChange={handleIngredientChange}
                      placeholder="name in cn"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Ingredient In Turkish
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="tur"
                      label="category"
                      value={values.ingredient[0].tur}
                      onChange={handleIngredientChange}
                      placeholder="name in tur"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Ingredient In Persian
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="ir"
                      label="category"
                      value={values.ingredient[0].ir}
                      onChange={handleIngredientChange}
                      placeholder="name in ir"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="ProductNameInEn">
                      Bite Ingredient In Danish
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="dk"
                      label="category"
                      value={values.ingredient[0].dk}
                      onChange={handleIngredientChange}
                      placeholder="name in dk"
                    />
                  </Form.Group>
                </div>
              </Form.Group>
            </Form>
            <Button type="button" onClick={UpdateBiteData} variant="warning">
              Update Bite
            </Button>
            <Button type="buttom" onClick={Cancel} variant="dark">
              Cancel
            </Button>
            <br />
            <Button
              type="buttom"
              onClick={handleDeleteBiteGalleryModalShow}
              variant="danger"
            >
              Delete Gallery
            </Button>

            <Modal
              show={DeleteBiteGalleryModalShow}
              onHide={handleDeleteBiteGalleryModalClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Are You Sure You Want To Delete This Gallery
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button variant="danger" onClick={deleteBiteGallery}>
                  Delete Galley
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </Row>
      <Row>
        <Table>
          <TableHeader>
            <TableHeaderCell>Id</TableHeaderCell>
            <TableHeaderCell>Image</TableHeaderCell>
            <TableHeaderCell>Delete</TableHeaderCell>
          </TableHeader>
          <TableBody>
            {SingleBite.gallery
              ? SingleBite.gallery.length > 0
                ? SingleBite.gallery.map((img, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <img
                          src={`https://www.api.setalkel.co${img}`}
                          alt="img"
                          width="70px"
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="danger"
                          onClick={() => {
                            handleDeleteBiteImgModalShow();
                            setDeletedImg(img);
                          }}
                        >
                          Delete Img
                        </Button>

                        <Modal
                          show={DeleteBiteImgModalShow}
                          onHide={handleDeleteBiteImgModalClose}
                          backdrop="static"
                          keyboard={false}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Are You Sure You Want To Delete This Img
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Footer>
                            <Button
                              variant="danger"
                              onClick={() => deleteImgFromBiteGallery()}
                            >
                              Delete
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </TableCell>
                    </TableRow>
                  );
                })
                : "Loadnig"
              : null}
          </TableBody>
        </Table>
      </Row>
      <hr />
      <Row>
        <Table>
          <TableHeader>
            <TableHeaderCell>Id</TableHeaderCell>
            <TableHeaderCell>video</TableHeaderCell>
            <TableHeaderCell>delete</TableHeaderCell>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>
                <video
                  controls
                  loop={true}
                  volume="1"
                  src={`https://www.api.setalkel.co${SingleBite.video}`}
                  style={{
                    width: "50%",
                    height: "50%",
                  }}
                />
              </TableCell>
              <TableCell>
                <Button
                  variant="danger"
                  onClick={() => {
                    handleDeleteBiteVideoModalShow();
                  }}
                >
                  Delete
                </Button>
                <Modal
                  show={DeleteBiteVideoModalShow}
                  onHide={handleDeleteBiteVideoModalClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Are You Sure You Want To Delete This Video
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Footer>
                    <Button
                      variant="danger"
                      onClick={handleDeleteBiteVideoModalClose}
                    >
                      Close
                    </Button>
                    <Button variant="danger" onClick={deleteBiteVideo}>
                      Delete Video
                    </Button>
                  </Modal.Footer>
                </Modal>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Row>
    </section>
  );
};

export default UpdateKitchen;
