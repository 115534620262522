import React, { useEffect } from "react";
//css
import "./PopUp.css";
//image
import UploadFiles from "../../../assets/Payments/agentInfoUpload.gif";
//bootstrap
import { Button, Modal } from "react-bootstrap";
//translation
import { useTranslation } from "react-i18next";

const PopUp = () => {
  const { t } = useTranslation();
  function AgentVerificationPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t(`Components.AgentVerificationForm.AgentVerificationPopUp.Title`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="AgentVerificationPopUp-body">
          <img src={UploadFiles} alt="upload files" />
          <p>
            {t(`Components.AgentVerificationForm.AgentVerificationPopUp.Desc`)}
          </p>
          <ol>
            <li>
              {t(
                `Components.AgentVerificationForm.AgentVerificationPopUp.FilesNames.CommercialRegister`
              )}
            </li>
            <li>
              {t(
                `Components.AgentVerificationForm.AgentVerificationPopUp.FilesNames.CompanyLogo`
              )}
            </li>
            <li>
              {t(
                `Components.AgentVerificationForm.AgentVerificationPopUp.FilesNames.CompanyName`
              )}
            </li>
            <li>
              {t(
                `Components.AgentVerificationForm.AgentVerificationPopUp.FilesNames.Companyownerdocuments`
              )}
            </li>
            <li>
              {t(
                `Components.AgentVerificationForm.AgentVerificationPopUp.FilesNames.Practicelicense`
              )}
            </li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button className="primary-btn" onClick={props.onHide}>
            {t(
              `Components.AgentVerificationForm.AgentVerificationPopUp.CloseBtn`
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    setModalShow(true);
  }, []);
  return (
    <article className="pop-up">
      <AgentVerificationPopUp
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </article>
  );
};

export default PopUp;
