import React, { useEffect, useState } from "react";
//css
import "./Payment.css";
//components
import FinalInvoices from "../../../../../Components/PaymentComponents/FinalInvoice/FinalInvoices";
import SwiftPaymentCard from "../../../../../Components/PaymentComponents/SwiftPaymentCard/SwiftPaymentCard";
import ShipmentPopUp from "../../../../../Components/ShipmentComponents/ShipmentPopUp/ShipmentPopUp";
//stepper
import StepZilla from "react-stepzilla";
import StepperLabel from "../../../../../Components/StepperLabel/StepperLabel";
//images
import Wallet from "../../../../../assets/Payments/Wallet-cuate (1) 1.png";
import Cards from "../../../../../assets/Payments/cards.png";
import Paper from "../../../../../assets/Payments/document-text.png";
//bootstrap
import { Col, Row } from "react-bootstrap";
//redux
import { useSelector } from "react-redux";
//translation
import { t } from "i18next";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
const Payment = () => {
  const [agentShipments, setAgentShipments] = useState([]);
  const [agentShipmentFinalInvoice, setAgentShipmentFinalInvoice] = useState(
    {}
  );
  const [stepperIndex, setStepperIndex] = useState(0);
  const [active] = useState(true);
  const userInfo = JSON.parse(localStorage.getItem("user-info"));
  const FinalInvoice = useSelector(
    (state) => state.shipmentStepper.finalInvoice
  );
  const FinalInvoiceData = JSON.parse(
    localStorage.getItem("agentShipmentFinalInvoice")
  );
  //============================get Agent Shipments ===============================
  useEffect(() => {
    const getShipmentByAgent = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/shipment/agent`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setAgentShipments([]);
        console.log(body);
      } else {
        setAgentShipments(body);
      }
    };
    getShipmentByAgent();
  }, []);
  //==================================get Shipment By Id=============

  useEffect(() => {
    const getShipmentById = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/shipment/${FinalInvoice}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setAgentShipmentFinalInvoice({});
        console.log(body);
      } else {
        setAgentShipmentFinalInvoice(body);
        localStorage.setItem("agentShipmentFinalInvoice", JSON.stringify(body));
      }
    };
    getShipmentById();
  }, [FinalInvoice]);
  //===================Getting Country ID From First External Component=======
  useEffect(() => {
    FinalInvoice &&
    agentShipmentFinalInvoice.shippingFee &&
    agentShipmentFinalInvoice.totalPrice &&
    stepperIndex === 0
      ? (document.getElementById("next-button").disabled = false)
      : (document.getElementById("next-button").disabled = true);
  }, [
    FinalInvoice,
    agentShipmentFinalInvoice.shippingFee,
    agentShipmentFinalInvoice.totalPrice,
    stepperIndex,
  ]);
  //=========================Stepper Steps=======================
  const steps = [
    {
      name: (
        <StepperLabel
          name={t(`Components.StepperLabel.FinalInvoices`)}
          icon={Cards}
          active={stepperIndex === 0 ? active : null}
        />
      ),
      component: (
        <div className="final-invoice-step">
          <FinalInvoices agentShipments={agentShipments} />
        </div>
      ),
    },
    {
      name: (
        <StepperLabel
          name={t(`Components.StepperLabel.PaymentInfo`)}
          icon={Paper}
          active={stepperIndex === 1 ? active : null}
        />
      ),
      component: (
        <div className="payment-info-step">
          <SwiftPaymentCard />

          <Row>
            <Col>
              <span id="moneyId">
                {t(`Pages.ShipmentProcess.Shipment.Payment.OrderDetails`)}
              </span>
              <Table className="finalInvoiceDataTable">
                <Thead>
                  <Tr>
                    <Th>
                      {t(
                        `Pages.ShipmentProcess.Shipment.Payment.SubTotalPrice`
                      )}
                    </Th>
                    <Th>
                      {t(`Pages.ShipmentProcess.Shipment.Payment.ShippingFee`)}
                    </Th>
                    <Th>
                      {t(`Pages.ShipmentProcess.Shipment.Payment.TotalPrice`)}
                    </Th>
                  </Tr>
                </Thead>
                {FinalInvoiceData && (
                  <Tbody>
                    <Tr>
                      <Td>
                        <span>{`${
                          FinalInvoiceData.order.items.length > 0 &&
                          FinalInvoiceData.order.orderPrice &&
                          FinalInvoiceData.order.orderPrice.toFixed(2)
                        }$`}</span>
                      </Td>
                      <Td>
                        <span>{`${
                          FinalInvoiceData.order.items.length > 0 &&
                          FinalInvoiceData.shippingFee &&
                          FinalInvoiceData.shippingFee.toFixed(2)
                        }$`}</span>
                      </Td>
                      <Td>
                        <span id="final-span">{`${
                          FinalInvoiceData.order.items.length > 0 &&
                          FinalInvoiceData.totalPrice &&
                          FinalInvoiceData.totalPrice.toFixed(2)
                        }$`}</span>
                      </Td>
                    </Tr>
                  </Tbody>
                )}
              </Table>
            </Col>
            <Col id="finalInvoiceWalletImgContainer">
              <img src={Wallet} alt="wallet" id="finalInvoiceWalletImg" />
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <section className="payment">
      <ShipmentPopUp userInfo={userInfo} />
      <p id="payment-paragraph">
        {stepperIndex === 0 &&
          t(`Components.PaymentComponents.FinalInvoice.PaymentNotice`)}
      </p>
      <div className="step-progress">
        <StepZilla
          steps={steps}
          showNavigation={true}
          showSteps={true}
          stepsNavigation={true}
          nextButtonCls="stepper-btn"
          backButtonCls="stepper-btn"
          onStepChange={(step) => {
            setStepperIndex(step);
          }}
        />
      </div>
    </section>
  );
};

export default Payment;
