import React from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
const AddActivities = () => {
  const [values, setValues] = useState({
    title: {
      ar: "",
      en: "",
      de: "",
      fr: "",
      ru: "",
      cn: "",
      tur: "",
      ir: "",
      dk: "",
    },
    desc: {
      ar: "",
      en: "",
      de: "",
      fr: "",
      ru: "",
      cn: "",
      tur: "",
      ir: "",
      dk: "",
    },
    loc: {
      ar: "",
      en: "",
      de: "",
      fr: "",
      ru: "",
      cn: "",
      tur: "",
      ir: "",
      dk: "",
    },
    date: "",
  });
  const [Videofile, setVideofile] = useState({
    Videofile: null,
  });
  const [ImgGallery, setImgGallery] = useState({
    ImgGallery: [],
  });
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  //=======================Handle Imput Desc=========================
  const handleInputDesc = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      desc: { ...values.desc, [name]: value },
    });
  };
  //=======================Handle Imput Title=========================
  const handleInputTitle = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      title: { ...values.title, [name]: value },
    });
  };
  //=======================Handle Imput Location=========================
  const handleInputLocation = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      loc: { ...values.loc, [name]: value },
    });
  };

  //=======================Input Handeling===============
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  //=======================Video Handeling===============
  const handleVideoChange = (e) => {
    setVideofile({
      Videofile: e.target.files[0],
    });
  };
  //=======================Image Gallery Handeling===============
  const handleGalleryChange = (e) => {
    const newImages = Array.from(e.target.files);
    setImgGallery(prevState => {
      const allImages = [...prevState.ImgGallery, ...newImages];
      return {
        ImgGallery: allImages
      };
    });
  };
  const PostActivityVideo = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      fd.append("file", Videofile.Videofile, Videofile.Videofile.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/our-activities/video/${id}`,
        requestOptions
      );
      const body = await ImgResponse.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  //=====================================Post Activity Gallery====================
  const PostActivityGallery = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      for (let i = 0; i < ImgGallery.ImgGallery.length; i++) {
        fd.append(
          "images",
          ImgGallery.ImgGallery[i],
          ImgGallery.ImgGallery[i].name
        );
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/our-activities/${id}/gallery`,
        requestOptions
      );
      const body = await ImgResponse.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  //=====================================Post Kitchen Image====================
  const PostActivityImg = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/our-activities/image/${id}`,
        requestOptions
      );
      const body = await ImgResponse.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate()
  const PostActivity = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    if (
      values.title.ar &&
      values.title.en &&
      values.desc.ar &&
      values.desc.en &&
      values.loc.ar &&
      values.loc.en
    ) {
      var raw = {
        title: {
          ar: values.title.ar,
          en: values.title.en,
          de: values.title.de,
          fr: values.title.fr,
          ru: values.title.ru,
          cn: values.title.cn,
          tur: values.title.tur,
          ir: values.title.ir,
          dk: values.title.dk,
        },
        desc: {
          ar: values.desc.ar,
          en: values.desc.en,
          de: values.desc.de,
          fr: values.desc.fr,
          ru: values.desc.ru,
          cn: values.desc.cn,
          tur: values.desc.tur,
          ir: values.desc.ir,
          dk: values.desc.dk,
        },
        loc: {
          ar: values.loc.ar,
          en: values.loc.en,
          de: values.loc.de,
          fr: values.loc.fr,
          ru: values.loc.ru,
          cn: values.loc.cn,
          tur: values.loc.tur,
          ir: values.loc.ir,
          dk: values.loc.dk,
        },
        date: values.date,
      };
      let NewTitle = raw.title;
      let NewDesc = raw.desc;
      let NewLoc = raw.loc;
      let NewRaw = raw;

      Object.keys(NewTitle).forEach((key) => {
        if (NewTitle[key] === "" || NewTitle[key] === "undefined") {
          delete NewTitle[key];
        }
      });
      Object.keys(NewDesc).forEach((key) => {
        if (NewDesc[key] === "" || NewDesc[key] === "undefined") {
          delete NewDesc[key];
        }
      });
      Object.keys(NewLoc).forEach((key) => {
        if (NewLoc[key] === "" || NewLoc[key] === "undefined") {
          delete NewLoc[key];
        }
      });
      Object.keys(NewRaw).forEach((key) => {
        if (NewRaw[key] === "" || NewRaw[key] === "undefined") {
          delete NewRaw[key];
        }
      });

      const raw2 = JSON.stringify(NewRaw);
      console.log(raw2);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw2,
        redirect: "follow",
      };
      if (values.date === "") {
        toast.error("Cannot Add New Activity Without Date");
        return
      }
      const response = await fetch(
        "https://www.api.setalkel.co/our-activities",
        requestOptions
      );
      const body = await response.json();

      if (!response.ok) {
        toast.error("There is Something Wrong");
      } else {
        if (Imgfile.Imgfile) {
          await PostActivityImg(body._id);
        }
        if (ImgGallery.ImgGallery.length > 0) {
          await PostActivityGallery(body._id);
        }
        if (Videofile.Videofile) {
          await PostActivityVideo(body._id);
        }
        toast.success("Activity Has Been Added");
        navigate("/dashboard/crouractivities")
        setValues({
          title: {
            ar: "",
            en: "",
            de: "",
            fr: "",
            ru: "",
            cn: "",
            tur: "",
            ir: "",
            dk: "",
          },
          desc: {
            ar: "",
            en: "",
            de: "",
            fr: "",
            ru: "",
            cn: "",
            tur: "",
            ir: "",
            dk: "",
          },
          loc: {
            ar: "",
            en: "",
            de: "",
            fr: "",
            ru: "",
            cn: "",
            tur: "",
            ir: "",
            dk: "",
          },
          date: "",
        });
      }
    } else {
      toast.error("Cannot Add New Activity Without Arabic And English Data");
    }
  };
  const fileInputRef = useRef()
  return (
    <Row>
      <div className="crud-activity">
        <Form className="activities-form">
          <Row>
            <Col>
              <div className="activity-langs">
                {/* =====================================Start Activity Name=========================================== */}
                <h4>Activity Name </h4>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Name In English
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="en"
                    label="Our-Activity"
                    value={values.title.en}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder=" Activity Name In English"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Name In Arabic
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="ar"
                    label="Our-Activity"
                    value={values.title.ar}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder="Activity Name In Arabic"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Name In French
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="fr"
                    label="Our-Activity"
                    value={values.title.fr}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder=" Activity Name In French"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Name In Russian
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="ru"
                    label="Our-Activity"
                    value={values.title.ru}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder=" Activity Name In Russian"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Name In German
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="de"
                    label="Our-Activity"
                    value={values.title.de}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder="Activity Name In Germany"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Name In China
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="cn"
                    label="Our-Activity"
                    value={values.title.cn}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder="Activity Name In China"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Name In Turkish
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="tur"
                    label="Our-Activity"
                    value={values.title.tur}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder=" Activity Name In Turkish"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Name In Persian
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="ir"
                    label="Our-Activity"
                    value={values.title.ir}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder=" Activity Name In Persian"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Name In Danish
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="dk"
                    label="Our-Activity"
                    value={values.title.dk}
                    onChange={(e) => handleInputTitle(e)}
                    placeholder=" Activity Name In Danish"
                  />
                </Form.Group>
              </div>
              {/* =====================================End Activity Name=========================================== */}

              <Form.Group>
                <Form.Label htmlFor="KitchenImg">Activity Image</Form.Label>
                <div className="d-flex flex-column gap-2">
                  <Form.Control
                    type="file"
                    name="image"
                    id="productImg"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    style={{
                      height: "fit-content"
                    }}
                  />
                  {Imgfile.Imgfile && (
                    <div className="position-relative" style={{
                      width: "fit-content"
                    }}>
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={URL.createObjectURL(Imgfile.Imgfile)}
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setImgfile({
                            Imgfile: null,
                          });
                          if (fileInputRef.current) {
                            fileInputRef.current.value = ""; // إفراغ الحقل باستخدام useRef
                          }
                        }}
                        style={{
                          position: "absolute",
                          top: "-18px",
                          right: "-13px",
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer"
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  )}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="KitchenImg">
                  Activity Images Gallery
                </Form.Label>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                  <Form.Control
                    type="file"
                    name="images"
                    multiple
                    id="productImg"
                    onChange={handleGalleryChange}
                  />
                  <div style={{ display: "flex", gap: "5px" }}>
                    {ImgGallery.ImgGallery.map((image, index) => (
                      <div key={index} style={{ position: "relative", display: "inline-block" }}>
                        <img
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover"
                          }}
                          src={URL.createObjectURL(image)}
                          alt={`Gallery Img ${index + 1}`}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setImgGallery(prevState => ({
                              ImgGallery: prevState.ImgGallery.filter((_, indexIM) => indexIM !== index)
                            }));
                          }}
                          style={{
                            position: "absolute",
                            top: "-5px",
                            right: "-11px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Activity Video</Form.Label>
                <Form.Control
                  type="file"
                  name="video"
                  onChange={handleVideoChange}
                />
              </Form.Group>
            </Col>

            {/* =====================================Start Activity Desc=========================================== */}

            <Col>
              <div className="activity-desc">
                <h4>Activity Description </h4>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Description In English
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    label="Our-Activity"
                    name="en"
                    value={values.desc.en}
                    onChange={(e) => handleInputDesc(e)}
                    placeholder="Activity Description In English"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Description In Arabic
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="ar"
                    label="Our-Activity"
                    value={values.desc.ar}
                    onChange={(e) => handleInputDesc(e)}
                    placeholder="Activity Description In Arabic"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Description In French
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="fr"
                    label="Our-Activity"
                    value={values.desc.fr}
                    onChange={(e) => handleInputDesc(e)}
                    placeholder="Activity Description In French"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Description In Russian
                  </Form.Label>

                  <Form.Control
                    as="textarea"
                    name="ru"
                    label="Our-Activity"
                    value={values.desc.ru}
                    onChange={(e) => handleInputDesc(e)}
                    placeholder="Activity Description In Russian"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Description In German
                  </Form.Label>

                  <Form.Control
                    as="textarea"
                    name="de"
                    label="Our-Activity"
                    value={values.desc.de}
                    onChange={(e) => handleInputDesc(e)}
                    placeholder=" Activity Description In Germany"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Description In China
                  </Form.Label>

                  <Form.Control
                    as="textarea"
                    name="cn"
                    label="Our-Activity"
                    value={values.desc.cn}
                    onChange={(e) => handleInputDesc(e)}
                    placeholder=" Activity Description In China"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Description In Turkish
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="tur"
                    label="Our-Activity"
                    value={values.desc.tur}
                    onChange={(e) => handleInputDesc(e)}
                    placeholder="Activity Description In Turkish"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Description In Persian
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="ir"
                    label="Our-Activity"
                    value={values.desc.ir}
                    onChange={(e) => handleInputDesc(e)}
                    placeholder="Activity Description In Persian"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Description In Danish
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="dk"
                    label="Our-Activity"
                    value={values.desc.dk}
                    onChange={(e) => handleInputDesc(e)}
                    placeholder="Activity Description In Danish"
                  />
                </Form.Group>
              </div>
            </Col>
            <Col>
              {/* ==========================End Activity Desc============================== */}
              {/* ==========================Start Activity Location============================== */}
              <div className="activity-location">
                <h4>Activity Location </h4>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Location In English
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="en"
                    label="Our-Activity"
                    value={values.loc.en}
                    onChange={(e) => handleInputLocation(e)}
                    placeholder=" Activity Location In English"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Location In Arabic
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="ar"
                    label="Our-Activity"
                    value={values.loc.ar}
                    onChange={(e) => handleInputLocation(e)}
                    placeholder="Activity Location In Arabic"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Location In French
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="fr"
                    label="Our-Activity"
                    value={values.loc.fr}
                    onChange={(e) => handleInputLocation(e)}
                    placeholder="Activity Location In French"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Location In Russian
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="ru"
                    label="Our-Activity"
                    value={values.loc.ru}
                    onChange={(e) => handleInputLocation(e)}
                    placeholder="Activity Location In Russian"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Location In German
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="de"
                    label="Our-Activity"
                    value={values.loc.de}
                    onChange={(e) => handleInputLocation(e)}
                    placeholder="Activity Location In German"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Location In China
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="cn"
                    label="Our-Activity"
                    value={values.loc.cn}
                    onChange={(e) => handleInputLocation(e)}
                    placeholder="Activity Location In China"
                  />
                </Form.Group>
                <Form.Group>
                  {" "}
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Location In Turkish
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="tur"
                    label="Our-Activity"
                    value={values.loc.tur}
                    onChange={(e) => handleInputLocation(e)}
                    placeholder="Activity Location In Turkish"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Location In Persian
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="ir"
                    label="Our-Activity"
                    value={values.loc.ir}
                    onChange={(e) => handleInputLocation(e)}
                    placeholder="Activity Location In Persian"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    Activity Location In Danish
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="dk"
                    label="Our-Activity"
                    value={values.loc.dk}
                    onChange={(e) => handleInputLocation(e)}
                    placeholder="Activity Location In Danish"
                  />
                </Form.Group>
                {/* ==========================End Activity Location============================== */}

                <Form.Label htmlFor="ProductNameInEn">
                  Activity Date
                </Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  label="Our-Activity"
                  value={values.date}
                  onChange={handleInputChange}
                  placeholder="yyyy-mm-dd"
                />
                <Button variant="warning" onClick={PostActivity}>
                  Add Activity
                </Button>

              </div>
            </Col>
            <hr />
          </Row>
        </Form>
      </div>
    </Row>
  )
}

export default AddActivities