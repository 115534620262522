import React, { useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import {
    Button,
    Form,
    FormGroup,
    FormLabel,
} from "react-bootstrap";
import "./CRExportCountry.css";
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
const AddExportCountry = () => {
    const fileInputRef = useRef(null);
    const [countries, setCountries] = useState([]);
    const [countryNames, setCountryNames] = useState({
        en: "", //required
        ar: "", //required
        fr: "",
        ru: "",
        de: "",
        cn: "",
        tur: "",
        ir: "",
        dk: "",
        countryCode: "",
    });

    const [Imgfile, setImgfile] = useState({
        Imgfile: null,
    });
    const handleImageChange = (e) => {
        setImgfile({
            Imgfile: e.target.files[0],
        });
    };

    //=======================Input Handeling===============
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCountryNames({
            ...countryNames,
            [name]: value,
        });
    };
    //==================Get Country Codes==============================
    useEffect(() => {
        const getCountriesCodes = async () => {
            const response = await fetch("https://restcountries.com/v2/all");
            const body = await response.json();
            if (!response.ok) {
                setCountries([]);
                console.log(body);
            } else {
                setCountries(body);
            }
        };
        getCountriesCodes();
    }, []);
    const navigate = useNavigate()
    const addExportCountry = async () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `bearer ${localStorage.getItem("admin-token")}`
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = {
            countryCode: countryNames.countryCode,
            countryName: {
                en: countryNames.en, //required
                ar: countryNames.ar, //required
                fr: countryNames.fr,
                ru: countryNames.ru,
                de: countryNames.de,
                cn: countryNames.cn,
                tur: countryNames.tur,
                ir: countryNames.ir,
                dk: countryNames.dk,
            },
        };

        let NewCountryName = raw.countryName;

        Object.keys(NewCountryName).forEach((key) => {
            if (NewCountryName[key] === "") {
                delete NewCountryName[key];
            }
        });

        raw.countryName = NewCountryName;
        let NewRaw = JSON.stringify(raw);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: NewRaw,
            redirect: "follow",
        };

        const response = await fetch(
            "https://www.api.setalkel.co/export-country/create/country",
            requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
            toast.error("Country NOT Added !!!");
            console.log(body);
        } else {
            toast.success("Country Has Been Added Successfully");
            if (Imgfile.Imgfile) {
                await addExportExportCountryImage(body._id);
            }
            setCountryNames({
                en: "", //required
                ar: "", //required
                fr: "",
                ru: "",
                de: "",
                cn: "",
                tur: "",
                ir: "",
                dk: "",
                countryCode: "",
            });
        }
        navigate("/dashboard/crexportcountries")
    };

    //=============Add Export Country Image===============
    const addExportExportCountryImage = async (id) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                `bearer ${localStorage.getItem("admin-token")}`
            );

            var formdata = new FormData();
            formdata.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };

            const response = await fetch(
                `https://www.api.setalkel.co/export-country/image/${id}`,
                requestOptions
            );
            const body = await response.json();
            // console.log(body);
        } catch (error) {
            console.log(error);
        }
    };
    return (

        <>
            <div className="crud-countries">
                <div className="title text-center">
                    <h1>Add Export Countries</h1>
                </div>
            </div>
            <Form.Label htmlFor="CountryNameInEn" style={{ color: "red" }}>
                CounrtyCode &&  ArabicName & EnglishName R Required
            </Form.Label>
            <Form>


                <div className='create-country'>
                    <FormGroup>
                        <FormLabel>
                            Country Code
                            <select
                                id="category-list"
                                name="countryCode"
                                value={countryNames.countryCode}
                                onChange={handleInputChange}
                                required
                            >
                                <option>Please Choose A Country Code</option>
                                {countries.map((country, index) => {
                                    return (
                                        <option value={country.alpha2Code} key={index}>
                                            {`${country.alpha2Code + "===>" + country.name}`}
                                        </option>
                                    );
                                })}
                                <option value={"EU"}>{`EU  ===> European Union`}</option>
                            </select>
                        </FormLabel>
                    </FormGroup>

                    <Form.Group>

                        <Form.Label htmlFor="CountryNameInEn">
                            Country Name In English /
                            <span style={{ color: "red" }}>Required</span>
                        </Form.Label>
                        <Form.Control
                            name="en"
                            label="category"
                            value={countryNames.en}
                            onChange={handleInputChange}
                            placeholder="name in en"
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="CountryNameInEn">
                            Country Name In Arabic /
                            <span style={{ color: "red" }}>Required</span>
                        </Form.Label>

                        <Form.Control
                            name="ar"
                            label="category"
                            value={countryNames.ar}
                            onChange={handleInputChange}
                            placeholder="name in ar"
                            required
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor="CountryNameInEn">
                            Country Name In French
                        </Form.Label>

                        <Form.Control
                            name="fr"
                            label="category"
                            value={countryNames.fr}
                            onChange={handleInputChange}
                            placeholder="name in fr"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="CountryNameInEn">
                            Country Name In Russian
                        </Form.Label>

                        <Form.Control
                            name="ru"
                            label="category"
                            value={countryNames.ru}
                            onChange={handleInputChange}
                            placeholder="name in ru"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="CountryNameInEn">
                            Country Name In German
                        </Form.Label>

                        <Form.Control
                            name="de"
                            label="category"
                            value={countryNames.de}
                            onChange={handleInputChange}
                            placeholder="name in de"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="CountryNameInEn">
                            Country Name In China
                        </Form.Label>

                        <Form.Control
                            name="cn"
                            label="category"
                            value={countryNames.cn}
                            onChange={handleInputChange}
                            placeholder="name in cn"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="CountryNameInEn">
                            Country Name In Turkish
                        </Form.Label>
                        <Form.Control
                            name="tur"
                            label="category"
                            value={countryNames.tur}
                            onChange={handleInputChange}
                            placeholder="name in tur"
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor="CountryNameInEn">
                            Country Name In Persian
                        </Form.Label>
                        <Form.Control
                            name="ir"
                            label="category"
                            value={countryNames.ir}
                            onChange={handleInputChange}
                            placeholder="name in Persian"
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor="CountryNameInEn">
                            Country Name In Danish
                        </Form.Label>
                        <Form.Control
                            name="dk"
                            label="category"
                            value={countryNames.dk}
                            onChange={handleInputChange}
                            placeholder="name in Danish"
                        />
                    </Form.Group>

                    {/* ===============================INFO===================== */}

                    <Form.Group>
                        <Form.Label htmlFor="CountryImg">Country Image</Form.Label>
                        <div className="d-flex flex-column ">
                <Form.Control
                  type="file"
                  name="image"
                  id="productImg"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                  style={{
                    height: "fit-content"
                  }}
                />
                {Imgfile.Imgfile && (
                  <div className="position-relative" style={{
                    width: "fit-content"
                  }}>
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={URL.createObjectURL(Imgfile.Imgfile)}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setImgfile({
                          Imgfile: null,
                        });
                        if (fileInputRef.current) {
                          fileInputRef.current.value = ""; // إفراغ الحقل باستخدام useRef
                        }
                      }}
                      style={{
                        position: "absolute",
                        top: "-18px",
                        right: "5px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                    >
                      &times;
                    </button>
                  </div>
                )}
              </div>
                    </Form.Group>
                    <Button variant="warning" onClick={addExportCountry}>
                        Add Export Country
                    </Button>
                </div>






            </Form>
        </>

    )
}

export default AddExportCountry