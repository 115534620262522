import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";

//css
import "./DeliveryMethod.css";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
const CRDeliveryMethod = () => {
  const [methodName, setMethodName] = useState("");
  const [methodeId, setMethodeId] = useState("");
  const [deliveryMethods, setDeliveryMethods] = useState([]);

  //=================================== Delete Methods====================================

  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleClose = () => setDeleteModalShow(false);
  const handleShow = () => setDeleteModalShow(true);

  //======================Get All Delivery Methods=============

  useEffect(() => {
    const getAllDeliveryMethods = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/delivery-method/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setDeliveryMethods([]);
        console.log(body);
      } else {
        setDeliveryMethods(body);
      }
    };
    getAllDeliveryMethods();
  }, []);
  //======================add Delivery Method Name=============

  const addMethodName = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      methodName: methodName,
    });
    console.log(raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/delivery-method/",
      requestOptions
    );
    if (!response.ok) {
      alert("Delivery Method Not Added !!!!");
    } else {
      alert("Delivery Method Has Been Added");
      window.location.reload();
    }
  };

  //===========================Delete Delivery Method=========================

  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/delivery-method/${methodeId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          alert("item deleted");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  //==================================================================================
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Method Name",
      selector: (row) => <span>{row.methodName}</span>,
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/crdeliverymethod/${row._id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button
            onClick={() => {
              handleShow();
              setMethodeId(row._id);
            }}
            variant="danger"
          >
            Delete
          </Button>
          <Modal show={DeleteModalShow} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete The Method
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                Delete Method
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];
  return (
    <section className="cms-create-delivery-method">
      <Row>
        <div className="crud-title">
          <h1>Delivery Method</h1>
        </div>
        <div className="create-delivery-method">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Method Name</Form.Label>
              <Form.Check
                type="radio"
                name="deliveryMethod"
                label="EXW"
                id="1"
                value={"EXW"}
                onChange={(e) => setMethodName(e.target.value)}
              />
              <Form.Check
                type="radio"
                name="deliveryMethod"
                label="CIF"
                id="2"
                value={"CIF"}
                onChange={(e) => setMethodName(e.target.value)}
              />
            </Form.Group>
            <Button variant="warning" onClick={addMethodName}>
              Add Delivery Method
            </Button>
          </Form>
        </div>
      </Row>
      <hr />
      <Row>
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={deliveryMethods}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
};

export default CRDeliveryMethod;
