import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaRegEye, FaEyeSlash } from "react-icons/fa";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
//data table
import DataTable from "react-data-table-component";
import { customStyles } from "../../../constants/DataTableCustomeStyles";
//images
import "../VerifiedAgents/style.css"

import { toast } from "react-hot-toast";
import UnVerifiedAgentsApi from "../../../Api/UnVerifiedAgentsApi";
import { decryptData } from "../../../Api/dercrypt";
import ApiInstances from "../../../Api/axiso";
import { useMutation, useQueryClient } from "@tanstack/react-query";
const UnVerifiedAgents = ({ online }) => {
  const [onlineAgent, setOnlineAgent] = useState({});
  const [ContactFiles, setContactFiles] = useState({
    ContactFiles: [],
  });
  const [showAgentOnWebsite] = useState([]);
  const [Prices, setPrices] = useState()

  const [files, setFiles] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [isVerifiedAgentId, setIsVerifiedAgentId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [AgentFilesModalShow, setAgentFilesModalShow] = useState(false);
  const [verifyAgentInfo, setVerifyAgentInfo] = useState({});
  const [isVerifiedAgentPrice, setIsVerifiedAgentPrice] = useState([]);
  //=============================Delete Files========================
  const [DeleteVerifyFilesModalShow, setDeleteVerifyFilesModalShow] =
    useState(false);
  const handleDeleteVerifyModalClose = () =>
    setDeleteVerifyFilesModalShow(false);
  const handleDeleteVerifyModalShow = () => setDeleteVerifyFilesModalShow(true);
  //================================Delete Agents Modal States===========================
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);
  //================================Delete Agent Modal States===========================
  const [DeleteModalShow, setDeleteModal] = useState(false);
  const handleClose = () => setDeleteModal(false);
  const handleShow = () => setDeleteModal(true);
  const [deletedProducts, setDeletedProducts] = useState({
    ids: [],
  });
  const { data: UnVerifiedAgents } = UnVerifiedAgentsApi.UnVerifiedAgentquery()
  //=======================handleVerification======================
  const handleVerification = (e, Id) => {
    setVerifyAgentInfo({
      agentId: Id,
      isVerified: e.target.value,
    });
  };
  const handleVerificationPrice = (e, Id) => {
    const selectedPrice = {
      agentId: Id,
      priceId: e.target.value,
    };

    setIsVerifiedAgentPrice((prevPrices) => {
      const existingPrice = prevPrices.find(price => price.agentId === Id);
      if (existingPrice) {
        return prevPrices.map(price =>
          price.agentId === Id ? { ...price, priceId: e.target.value } : price
        );
      } else {
        return [...prevPrices, selectedPrice];
      }
    });
  };
  //=======================handleShowHideOnWebsite======================
  const handleShowHideOnWebsite = (e, Id) => {
    if (e.target.value) {
      if (showAgentOnWebsite.length > 0) {
        for (let i = 0; i < showAgentOnWebsite.length; i++) {
          if (showAgentOnWebsite[i].agentId === Id) {
            showAgentOnWebsite[i].hidden = e.target.value;
            return;
          }
        }
        showAgentOnWebsite.push({
          agentId: Id,
          hidden: e.target.value,
        });
      } else {
        showAgentOnWebsite.push({
          agentId: Id,
          hidden: e.target.value,
        });
      }
    }
  };
  const fetchPrice = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    const response = await fetch(
      `https://www.api.setalkel.co/prices`,
      requestOptions
    );
    const body = await response.json();
    // console.log(body);
    if (!response.ok) {

      console.log(body);
    } else {
      setPrices(body?.prices)
      // console.log(body)
    }

  };
  useEffect(() => {
    fetchPrice()
  }, [])

  //=======================Files Handeling===============
  const handleFilesChange = (e) => {
    setContactFiles({
      ContactFiles: e.target.files,
    });
  };
  //==========================Fetch Agent===================


  //==========================Delete Product=====================
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async () => {
      const { data } = await ApiInstances.delete(`agent/${agentId}`)
      return data;
    },
    onSuccess: () => {
      toast.success("item deleted");
      handleClose()
      queryClient.refetchQueries(["get-agent-not"])
    },
    onError: () => {
      toast.error("Something Rong")
    }
  });

  const OnDelete = () => {
    mutation.mutate(agentId)
  };
  //============================Delete Mutliple Product=====================

  const deleteProducts = (e, productId) => {
    if (e.target.checked) {
      if (!deletedProducts.ids.includes(productId)) {
        setDeletedProducts({
          ...deletedProducts,
          ids: [...deletedProducts.ids, productId],
        });
      }
    } else {
      setDeletedProducts({
        ids: deletedProducts.ids.filter((item) => item !== productId),
      });
    }
  };

  const { mutate: DeleteProduct } = useMutation({
    mutationFn: async () => {
      const { data } = await ApiInstances.delete(`agent/collection`, {
        data: deletedProducts
      })
      return data;
    },
    onSuccess: () => {
      toast.success("Agents Have Been Deleted !!");
    },
    onError: () => {

      toast.error("There Is An Error ");
    }
  });
  const deleteMultipleِAgents = async () => {
    DeleteProduct(deletedProducts)
  };
  //=================================Show Hide Agent ==================
  const { mutate: mutateShwo } = UnVerifiedAgentsApi.ShowHideAgentsQuery()
  const showHideAgents = () => {
    if (showAgentOnWebsite.length > 0) {
      mutateShwo(showAgentOnWebsite)

    } else {
      toast.error("Please Choose 1 Agent At least to change his status");

    }
  };
  //=========================================download File ===============================================
  const getFile = async (file) => {
    // console.log(file + " clicked");

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  const [image, setImage] = useState("")
  //=============================================Agent Files Modal========================================
  const AgentFilesModal = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Agent Files :
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>This Is The Agent Verification Files</h4>
          <div className="d-flex align-items-center gap-2">
            <div>
              <h6>Agent Image</h6>

              <img
                src={`https://www.api.setalkel.co${image}`}
                alt="agentImage"
                width={100}
                height={100}
                onClick={() => getFile(image)}
              />

            </div>
            {files.length > 0
              ? files?.map((file, index) => {
                return (
                  <ul key={index}>
                    <li>
                      <Button variant="warning" onClick={() => getFile(file)}>
                        {file}
                      </Button>
                    </li>

                  </ul>
                );
              })
              : "No Files To Show / لا يوجد ملفات لعرضها"}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  //=====================================================Delete All Verification Agent Files===============================================
  const { mutate: deleteFiles } = UnVerifiedAgentsApi.DeleteAllVerificationFilesQuery(agentId)

  const deleteAllVerificationFiles = async () => {
    deleteFiles(agentId)

  };
  //==========================Get Agent Verification Files===================

  useEffect(() => {
    const getVerifyAgentFiles = async () => {
      if (isVerifiedAgentId) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const response = await fetch(
          `https://www.api.setalkel.co/agent/verification/${isVerifiedAgentId}`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          setFiles([]);
          setIsLoading(true);
          // console.log(body);
        } else {
          setFiles(body.files);
          setImage(body.img);
          setIsLoading(false);
        }
      }
    };
    getVerifyAgentFiles();
  }, [isVerifiedAgentId]);
  //============================Send Message To Agent ===================
  const sendRejectionFile = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var formdata = new FormData();
    for (let i = 0; i < ContactFiles.ContactFiles.length; i++) {
      formdata.append(
        "file",
        ContactFiles.ContactFiles[i],
        ContactFiles.ContactFiles[i].name
      );
      // console.log(ContactFiles.ContactFiles[i].name);
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/agent/verification/message/${agentId}`,
      requestOptions
    );
    if (!response.ok) {
      toast.error("Message Not Sent")
      // console.log(response);
    } else {
      toast.success("Message Has Been Sent To Agent")
    }
  };
  //============================Detect IF Agent Verified Or Not=====================
  const verifyAgents = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(verifyAgentInfo);
    // console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/agent/verification/",
      requestOptions
    );
    if (!response.ok) {
      toast.error("Agent Not Verified");
    } else {
      toast.success("Agent State Has Been Changed Successfuly");

    }
  };
  const [showPassword, setShowPassword] = useState({
    ids: []
  });

  const handleTogglePassword = (id) => {
    const updatedIds = showPassword.ids.includes(id)
      ? showPassword.ids.filter((itemId) => itemId !== id)
      : [...showPassword.ids, id];

    setShowPassword({ ids: updatedIds });
  };

  //========================================================================================================
  const columns = [
    {
      name: "Image",
      selector: (row) => (
        <span>
          <img
            src={`https://www.api.setalkel.co${row.img}`}
            alt="agentImage"
            width={100}
            height={100}
          />
        </span>
      ),
    },
    {
      name: "Agent Name",
      selector: (row) => <span>{row.name}</span>,
    },

    {
      name: " Agent Email",
      selector: (row) => <span>{row.email}</span>,
    },
    {
      name: "Agent Password",
      selector: (row) => <div onClick={() => handleTogglePassword(row._id)}>
        {!showPassword.ids.includes(row._id) ? (
          <div className="d-flex gap-2 align-items-center">
            <span>********</span>
            <FaRegEye className="icon" />
          </div>
        ) : (
          <div className="d-flex gap-2 align-items-center">
            <span>{decryptData(row.password)}</span>
            <FaEyeSlash className="icon" />
          </div>
        )}
      </div>

    },
    {
      name: "Created At :",
      selector: (row) => <span>{row.email}</span>,
    },
    {
      name: "Shown ?",
      selector: (row) => <span>{row.hidden ? "No" : "Yes"}</span>,
    },
    {
      name: "Agent Phone",
      selector: (row) => (
        <span>
          {row.phone ? row.phone : "No Agent Phone / لا يوجد رقم لهذا الوكيل"}
        </span>
      ),
    },
    {
      name: "Agent Mobile Phone",
      selector: (row) => (
        <span>
          {row.mobilePhone
            ? row.mobilePhone
            : "No Agent Phone / لا يوجد رقم لهذا الوكيل"}
        </span>
      ),
    },
    {
      name: "Agent Port",
      selector: (row) => (
        <span>
          {row.port ? row.port : "No Agent Port / لا يوجد مرفأ لهذا الوكيل"}
        </span>
      ),
    },

    {
      name: "Agent Company Name",
      selector: (row) => (
        <span>
          {row.companyName
            ? row.companyName
            : "No Agent Company Name : لا يوجد اسم شركة لهذا الوكيل "}
        </span>
      ),
    },
    {
      name: "Agent Address",
      selector: (row) => (
        <span>
          {row.address
            ? row.address
            : "No Agent Address / لا يوجد عنوان لهذا الوكيل"}
        </span>
      ),
    },
    {
      name: "Agent Country",
      selector: (row) => (
        <span>
          {row.country
            ? row.country
            : "No Agent Country / لا يوجد بلد لهذا الوكيل"}
        </span>
      ),
    },
    {
      name: "Price of Agent",
      selector: (row) => (
        <span>
          {row.prices ? <>{Prices?.find((d) => d._id === row.prices)?.name}</> : "لايوجد أسعار مرتبطة"}
        </span>
      ),
    },
    {
      name: "Agent Contact Emails",
      selector: (row) => (
        <span>
          {row.contactEmails
            ? row.contactEmails
            : "لا يوجد بريد الكتروني بديل لهذا الوكيل"}
        </span>
      ),
    },
    {
      name: "Show Files of Agent",
      selector: (row) => (
        <div>
          <Button
            variant="warning"
            onClick={() => {
              setAgentFilesModalShow(true);
              setIsVerifiedAgentId(row._id);
            }}
          >
            Show Files
          </Button>

        </div>
      ),
    },
    {
      name: "Verify/Unverify Agent",
      selector: (row) => (
        <Form.Select onChange={(e) => handleVerification(e, row._id)}>
          <option>Choose State</option>
          <option value="true">Verify</option>
          <option value="false">UnVerify</option>
        </Form.Select>
      ),
    },
    {
      name: "Show/Hide Agent",
      selector: (row) => (
        <Form.Select onChange={(e) => handleShowHideOnWebsite(e, row._id)}>
          <option>Choose State</option>
          <option value="false">Show</option>
          <option value="true">Hide</option>
        </Form.Select>
      ),
    },

    {
      name: "Send Message",
      selector: (row) => (
        <Form>
          <Form.Group className="mb-3" controlId="formBasicUserEmail">
            <Form.Label>Agent Rejection File Upload</Form.Label>
            <Form.Control
              type="file"
              multiple
              name="files"
              placeholder="Upload Rejection File"
              onChange={(e) => {
                handleFilesChange(e);
                setAgentId(row._id);
              }}
            />
          </Form.Group>
        </Form>
      ),
    },
    {
      name: "Delete Files of Agent",
      selector: (row) => (
        <span>
          {row.files.length > 0 && (
            <>
              <Button variant="danger" onClick={handleDeleteVerifyModalShow}>
                Delete Files
              </Button>
              <Modal
                show={DeleteVerifyFilesModalShow}
                onHide={handleDeleteVerifyModalClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    Are You Sure You Want To Delete Verification Files
                  </Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleDeleteVerifyModalClose}
                  >
                    Close
                  </Button>
                  <Button variant="danger" onClick={deleteAllVerificationFiles}>
                    Delete Files
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </span>
      ),
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/cragents/${row._id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },

    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleShow();
              setAgentId(row._id);
            }}
          >
            Delete
          </Button>


        </div>
      ),
    },
    {
      name: "M-Delete",
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteProducts(e, row._id)}
        />
      ),
    },
  ];
  const { mutate, isPending } = useMutation({
    mutationFn: async (res) => {
      const { data } = await ApiInstances.post(`/agent/add-price`, res);
      return data;
    },
    onSuccess: () => {
      toast.success("Successfully...");
      const inputFields = document.querySelectorAll("#select-price");
      inputFields.forEach((field) => {
        field.value = "";
      });

    },
  });
  const verifyPrices = async () => {
    // console.log(isVerifiedAgentPrice)
    if (isVerifiedAgentPrice.length === 0) {
      toast.error("Please Select Price...");
    } else {
      mutate(isVerifiedAgentPrice)

    }


  };
  const [generateExcelFile, setGenerateExecelFile] = useState()
  const { data: Excel } = UnVerifiedAgentsApi.GenerateExelFileQuery()
  const getFileExcel = async () => {
    UnVerifiedAgentsApi.GetFileExcel(generateExcelFile)
    setGenerateExecelFile(undefined)
  };
  return (
    <section>
      <AgentFilesModal
        show={AgentFilesModalShow}
        onHide={() => setAgentFilesModalShow(false)}
      />
      <Modal
        size="lg"
        show={DeleteModalShow}
        onHide={() => setDeleteModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Are You Sure You Want To Delete This Agent
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => OnDelete()}>
            Delete Agent
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <div className="crud-title">
          <h2>UnVerified Agents</h2>
        </div>
      </Row>
      <Row>
      <Form className="form-agent">
          <div className="d-flex">
            {!generateExcelFile ? <Button variant="warning" id="verify-btn" onClick={() => setGenerateExecelFile(Excel)}>
              Export Excel File for Agents
            </Button> : <Button variant="success" id="verify-btn" onClick={getFileExcel}>
              Download Excel File
            </Button>}
            <Button variant="danger" id="verify-btn" >
              Import Excel
            </Button>
            <Button variant="warning" id="verify-btn" onClick={showHideAgents}>
              Show Hide Agent
            </Button>
            {ContactFiles.ContactFiles.length > 0 && (
              <Button variant="warning" onClick={sendRejectionFile}>
                Send Rejection File
              </Button>
            )}
            {verifyAgentInfo && (
              <Button variant="warning" id="verify-btn" onClick={verifyAgents}>
                Verify Agent/s
              </Button>
            )}
            <Button variant="warning" id="verify-btn" onClick={verifyPrices}>
              Verify Price
            </Button>
          </div>
          {deletedProducts.ids.length > 0 ? (
            <>
              <Button
                id="multipleDelete"
                variant="outline-danger"
                onClick={() => handleMultipleShow()}
              >
                Multiple Delete
              </Button>

              <Modal
                size="lg"
                show={MultipleDeleteModalShow}
                onHide={() => setDeleteModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    Are You Sure You Want To Delete All Those Agents
                  </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleMultipleClose}>
                    Close
                  </Button>
                  <Button variant="danger" onClick={deleteMultipleِAgents}>
                    Delete Agents
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : null}
        </Form>
      </Row>
      <Row>
        <DataTable
          fixedHeader
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={UnVerifiedAgents}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
};

export default UnVerifiedAgents;
