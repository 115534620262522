import "./assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import "./scss/App.scss";
import { ToastContainer } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import Notification from "../Components/Notification/Notification";
import Login from "./Admin/Login";
import Offers from "./pages/Offers/CROffers";
import OfferDrag from "./pages/Offers/OfferDrag";

import UpdateOffers from "./pages/Offers/UpdateOffers/UpdateOffers";
import AboutUs from "./pages/AboutUs/CRAboutUS";
import AboutUsDrag from "./pages/AboutUs/AboutUsDrag";
import UploadVideo from "./pages/UploadVideo/UploadVideo";
import AddAboutUs from "./pages/AboutUs/AddAboutUs";
import CRProducts from "./pages/Products/ProductTable";
import ProductTableDrag from "./pages/Products/ProductTableDrag";

import AddProduct from "./pages/Products/AddProduct";
import Pallet from "./pages/Pallet/Pallet";
import AddPallet from "./pages/Pallet/AddPallet";

import ProductTable from "./pages/Products/ProductTable";
import SessionsAdmin from "./pages/Sessions/Admen";
import SessionsAgent from "./pages/Sessions/Agent";

import UpdateProduct from "./pages/Products/UpdateProduct/UpdateProduct";
import CRContactUs from "./pages/ContactUs/CRContactus";
import ProductPrice from "./pages/ProductPrice/ProductPrice";
import PriceAgent from "./pages/ProductPrice/PriceAgent";

import Prices from "./pages/ProductPrice/Prices";
import PriceProprties from "./pages/ProductPrice/PriceProprties";

import Advertisements from "./pages/Advertisements/Advertisements";
import AddAdvertisements from "./pages/Advertisements/AddAdvertisements";

import CRBites from "./pages/Bites/CRBites";
import AddBites from "./pages/Bites/AddBites";
import UpdateBites from "./pages/Bites/UpdateBites/UpdateBites";
import CRPartners from "./pages/Partners/CRPartners";
import AddCRPathers from "./pages/Partners/AddCRPathers";
import UpdatePartners from "./pages/Partners/UpdatePartners/UpdatePartners";
import CRCategories from "./pages/Categories/CRCategories";
import CategoryDrag from "./pages/Categories/CategoryDrag";

import CategoryAlAmeen from "./pages/Categories/CategoryAlAmeen";

import UpdateCategories from "./pages/Categories/UpdateCategories/UpdateCategories";
import CROurActivities from "./pages/OurActivities/CROurActivities";
import SubActivity from "./pages/OurActivities/SubActivity/SubActivity";
import AddSubActivity from "./pages/OurActivities/SubActivity/AddSubActivity";

import CROurActivitiesDrag from "./pages/OurActivities/CROurActivitiesDrag";

import AddActivities from "./pages/OurActivities/AddActivities";

import UpdateOurActivities from "./pages/OurActivities/UpdateOurActivities/UpdateOurActivities";
import UploadShipmentFiles from "./pages/Shipments/UploadShipmentFiles";
import SuggestionShipments from "./pages/Shipments/SuggestionShipments";
import WaitingShippingShipments from "./pages/Shipments/WaitingShippingShipments";
import WaitingPaymentShipments from "./pages/Shipments/WaitingPaymentShipment";
import FinishShipments from "./pages/Shipments/FinishedShipments";
import AdminCart from "./pages/Shipments/EditShipment/AdminCart/AdminCart";
import AdminShipmentProducts from "./pages/Shipments/EditShipment/AdminShipmentProducts/AdminShipmentProducts";
import ShippingTerms from "./pages/Shipments/Terms/ShippingTerms";
import UpdateTerms from "./pages/Shipments/Terms/UpdateTerms";
import FreezedShipments from "./pages/Shipments/FreezedShipments";
import CRContainers from "./pages/Containers/CRContainers";
import UpdateContainer from "./pages/Containers/UpdateContainer/UpdateContainer";
import CRUDAgents from "./pages/Agents/CRUDAgents";
import CRPrices from "./pages/Agents/Prices/CRPrices";
import UpdateAgents from "./pages/Agents/UpdateAgents/UpdateAgents";
import VerifiedAgents from "./pages/Agents/VerifiedAgents/VerifiedAgents";
import WebsiteVisitors from "./pages/Agents/WebsiteVisitors";
import ApplicationVisitors from "./pages/Agents/ApplicationVisitors";

import VerifiedAgentsDrag from "./pages/Agents/VerifiedAgents/VerifiedAgentsDrag";

import UnVerifiedAgents from "./pages/Agents/UnVerifiedAgents/UnVerifiedAgents";
import UnVerifiedAgentsDrag from "./pages/Agents/UnVerifiedAgents/UnVerifiedAgentsDrag";

import CRExportCountry from "./pages/Countries/ExportCountry/CRExportCountry";
import ExportCountryDeag from "./pages/Countries/ExportCountry/ExportCountryCategoriesAndProducts/ExportCountryDeag";

import AddExportCountry from "./pages/Countries/ExportCountry/AddExportCountry";

import UpdateExportCountry from "./pages/Countries/ExportCountry/UpdateExportCountry/UpdateExportCountry";
import CRExportCountriesCategoriesAndProducts from "./pages/Countries/ExportCountry/ExportCountryCategoriesAndProducts/ExportCountryCategoriesAndProducts";
import CategoriesAndProducts from "./pages/Countries/ExportCountry/CategoriesAndProducts/CategoriesAndProducts";
import ExportCountriesStatesContainers from "./pages/Countries/ExportCountry/Containers/ExportContainers";
import CRImportCountry from "./pages/Countries/ImportCountry/CRImportCountry";
import AddImportCountries from "./pages/Countries/ImportCountry/AddImportCountries";

import UpdateImportCountry from "./pages/Countries/ImportCountry/UpdateImportCountry/UpdateImportCountry";
import CRImportCountryState from "./pages/Countries/ImportCountry/ImportCountriesState/CRImportCountryState";
import CRImportCountryStateContainer from "./pages/Countries/ImportCountry/ImportStateContainer/ImportStateContainer";
import CRImportCountryStateAgents from "./pages/Countries/ImportCountry/ImportStateAgents/ImportStateAgents";
import CRAdmins from "./pages/Admins/CRAdmins";
import UpdateAdmin from "./pages/Admins/UpdateAdmins/UpdateAdmins";
import CRDeliveryMethod from "./pages/DeliveryMethod/CRDeliveryMethod";
import UpdateDeliveryMethod from "./pages/DeliveryMethod/UpdateDeliveryMethod/UpdateDeliveryMethod";
import Banner from "./pages/Banner/Banner";

import Ratings from "./pages/Ratings/Ratings";
import PaymentAccount from "./pages/Payment/PaymentAccount";
import AddCatgories from "./pages/Categories/AddCatgories";
import AddCategoryAlAmeen from "./pages/Categories/AddCategoryAlAmeen";
import CategoryOrganizationCoding from "./pages/Categories/CategoryOrganizationCoding";
import AddCategoryOrganizationCoding from "./pages/Categories/AddCategoryOrganizationCoding";

import AddConatiner from "./pages/Containers/AddConatiner";
import AddAddress from "./pages/AddAddress";
import Address from "./pages/Address";

import OurNewDrag from "./pages/Products/OurNewDrag";
import BannerDrag from "./pages/Banner/BannerDrag";
import CRBitesDrag from "./pages/Bites/CRBitesDrag";
import PathnerDarg from "./pages/Partners/PathnerDarg";
import PromotionalMaterials from "./pages/PromotionalMaterials/PromotionalMaterials";
import AddPromotionalMaterials from "./pages/PromotionalMaterials/AddPromotionalMaterials";

import ImportCountryDrag from "./pages/Countries/ExportCountry/ExportCountryCategoriesAndProducts/ImportCountryDrag";
import AppVeriosn from "./pages/AppVersion/AppVeriosn";
import AddAppVersion from "./pages/AppVersion/AddAppVersion";
import Events from "./pages/Events/Events";
import AddEvents from "./pages/Events/AddEvents";



function App({ online, agent }) {
  return (
    <div className="dashboard" dir="ltr">
      {localStorage.getItem("admin-token") && <Notification />}
      <Routes>
        {/* =============Admin Auth============= */}
        <Route path="/adminlogin" element={<Login />} />

        <Route path="/" element={<MainLayout />}>
          <Route path="crprices3/:id" element={<ProductPrice />} />
          <Route path="addaddress" element={<AddAddress />} />
          <Route path="/address/:id" element={<AddAddress />} />
          <Route path="app-version" element={<AppVeriosn />} />
          <Route path="add-app-version" element={<AddAppVersion />} />
          <Route path="app-version/:id" element={<AddAppVersion />} />
          <Route path="events" element={<Events />} />
          <Route path="add-events" element={<AddEvents />} />
          
          <Route path="address" element={<Address />} />
          <Route path="upload-video" element={<UploadVideo />} />

          <Route path="session-admin" element={<SessionsAdmin />} />
          <Route path="session-agent" element={<SessionsAgent />} />
          <Route path="advertisements" element={<Advertisements />} />
          <Route path="add-advertisements" element={<AddAdvertisements />} />
          <Route
            path="promotionalmaterials"
            element={<PromotionalMaterials />}
          />
          <Route
            path="add-promotionalmaterials"
            element={<AddPromotionalMaterials />}
          />

          <Route path="agentprice" element={<PriceAgent />} />

          <Route path="pallet" element={<Pallet />} />
          <Route path="add-pallet" element={<AddPallet />} />

          <Route path="crprices3" element={<Prices />} />
          <Route path="crprices2" element={<PriceProprties />} />

          <Route path="croffers" element={<Offers />} />
          <Route path="croffers/drag" element={<OfferDrag />} />

          <Route path="croffers/:Id" element={<UpdateOffers />} />
          <Route path="craboutUs">
            <Route index element={<AboutUs />} />
            <Route path="drag" element={<AboutUsDrag />} />

            <Route path="add-about" element={<AddAboutUs />} />
          </Route>
          <Route path="new/drag" element={<OurNewDrag />} />
          <Route path="crproducts">
            <Route index element={<ProductTable />} />
            <Route path="drag" element={<ProductTableDrag />} />

            <Route path="add-prod" element={<AddProduct />} />
            <Route path=":Id" element={<UpdateProduct />} />
          </Route>

          <Route path="crcontactus" element={<CRContactUs />} />
          {/* =============Bites=========== */}
          <Route path="crbites/*">
            <Route index element={<CRBites />} />
            <Route path="add-bites" element={<AddBites />} />
            <Route path="drag" element={<CRBitesDrag />} />
          </Route>
          <Route path="crbites/:Id" element={<UpdateBites />} />
          <Route path="crpartners">
            <Route index element={<CRPartners />} />
            <Route path="drag" element={<PathnerDarg />} />

            <Route path="add-partners" element={<AddCRPathers />} />
          </Route>
          <Route path="crpartners/:Id" element={<UpdatePartners />} />

          <Route path="crcategories/*" element={<CRCategories />} />
          <Route path="crcategories/drag" element={<CategoryDrag />} />

          <Route
            path="crcategories/add-categories"
            element={<AddCatgories />}
          />
          <Route path="crcategoriesalameen" element={<CategoryAlAmeen />} />
          <Route
            path="crcategoryorganizationCoding"
            element={<CategoryOrganizationCoding />}
          />
          <Route
            path="crcategoryorganizationCoding/add"
            element={<AddCategoryOrganizationCoding />}
          />
          <Route
            path="crcategoriesalameen/add-categories-ameen"
            element={<AddCategoryAlAmeen />}
          />

          <Route path="crcategories/:Id" element={<UpdateCategories />} />
          <Route path="crouractivities">
            <Route index element={<CROurActivities />} />
            <Route path="sub-activity" element={<SubActivity />} />
            <Route path="add-sub-activity" element={<AddSubActivity />} />
            <Route path="drag" element={<CROurActivitiesDrag />} />

            <Route path="add-activities" element={<AddActivities />} />
          </Route>
          <Route path="crouractivities/:Id" element={<UpdateOurActivities />} />
          <Route path="cragents" element={<CRUDAgents online={online} />} />
          <Route path="cragents/:Id" element={<UpdateAgents />} />
          {/* <Route path="verifyagents" element={<VerifyAgent />} /> */}
          <Route
            path="verifiedAgents"
            element={<VerifiedAgents online={online} />}
          />
          <Route path="websitevisitors" element={<WebsiteVisitors />} />
          <Route path="applicationvisitors" element={<ApplicationVisitors />} />
          <Route
            path="verifiedAgents/drag"
            element={<VerifiedAgentsDrag online={online} />}
          />
          <Route
            path="unverifiedAgents"
            element={<UnVerifiedAgents online={online} />}
          />
          <Route
            path="unverifiedAgents/drag"
            element={<UnVerifiedAgentsDrag online={online} />}
          />

          <Route path="crprices" element={<CRPrices />} />
          {/*============================Shipment============================== */}
          <Route path="uploadshipmentfiles" element={<UploadShipmentFiles />} />
          <Route path="suggestionshipments" element={<SuggestionShipments />} />
          <Route
            path="waitingshippingshipment"
            element={<WaitingShippingShipments />}
          />
          <Route
            path="waitingpaymentshipment"
            element={<WaitingPaymentShipments />}
          />
          <Route path="finishshipment" element={<FinishShipments />} />
          <Route path="shippingterms" element={<ShippingTerms />} />
          <Route path="shippingterms/:Id" element={<UpdateTerms />} />
          <Route path="freezedshipments" element={<FreezedShipments />} />
          {/* ========================================================================= */}
          <Route path="adminCart" element={<AdminCart />} />
          <Route path="adminproducts" element={<AdminShipmentProducts />} />
          <Route path="crcontainers" element={<CRContainers />} />
          <Route path="crcontainers/add-conainer" element={<AddConatiner />} />

          <Route path="crcontainers/:Id" element={<UpdateContainer />} />

          <Route path="cradmins" element={<CRAdmins />} />
          <Route path="cradmins/:id" element={<UpdateAdmin />} />
          <Route path="crexportcountries">
            <Route index element={<CRExportCountry />} />
            <Route path="drag" element={<ExportCountryDeag />} />

            <Route path="add-exporcountry" element={<AddExportCountry />} />
          </Route>
          <Route
            path="crexportcountries/:Id"
            element={<UpdateExportCountry />}
          />
          <Route
            path="crexportcountriescategoriesandproducts"
            element={<CRExportCountriesCategoriesAndProducts />}
          />
          <Route
            path="crexportcountries/showdetails/:Id"
            element={<CategoriesAndProducts />}
          />
          <Route path="crimportcountries">
            <Route index element={<CRImportCountry />} />
            <Route path="drag" element={<ImportCountryDrag />} />
            <Route path="add-import-country" element={<AddImportCountries />} />
            <Route path=":Id" element={<UpdateImportCountry />} />
          </Route>

          <Route
            path="crimportcountriesstate"
            element={<CRImportCountryState />}
          />
          <Route
            path="crimportcountrycontainer"
            element={<CRImportCountryStateContainer />}
          />
          <Route
            path="crimportcountryagents"
            element={<CRImportCountryStateAgents />}
          />
          <Route path="crdeliverymethod" element={<CRDeliveryMethod />} />
          <Route
            path="crdeliverymethod/:Id"
            element={<UpdateDeliveryMethod />}
          />
          <Route
            path="exportcountriesstatescontainers"
            element={<ExportCountriesStatesContainers />}
          />
          <Route path="bannerimages" element={<Banner />} />
          <Route path="bannerimages/drag" element={<BannerDrag />} />

          <Route path="ratings" element={<Ratings />} />
          <Route path="paymentaccount" element={<PaymentAccount />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
