import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//bootstrap
import { Button, Form, Row } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { FaDownload, FaTrashAlt } from "react-icons/fa";

const UpdateOffers = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [CatFilterID, setCatFilterID] = useState("");
  const [categories, setCategories] = useState([]);
  const [offerProducts, setOfferProducts] = useState([]);
  const [values, setValues] = useState({
    _id: "",
    offerName: {
      en: "",
      ar: "",
      fr: "",
      ru: "",
      de: "",
      dk: "",
      ir: "",
      cn: "",
      tur: "",
    },
    offer: [],
    grossWeight: "",
    volume: "",
    countInBox: "",
  });
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });

  const [isFree] = useState(false);
  //=======================handle Names Input======================
  const handleNamesInput = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      offerName: { ...values.offerName, [name]: value },
    });
  };
  //============================handleDeleteOfferProduct======================
  const handleDeleteOfferProduct = (offerProduct) => {
    setValues({
      ...values,
      offer: values.offer.filter(
        (item) => item.product._id !== offerProduct.product._id
      ),
    });
  };
  //=======================handle Input======================
  const handleDataInput = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };

  //=======================handle Input======================

  const handleSwitch = (e, product) => {
    if (e.target.checked) {
      for (let i = 0; i < values.offer.length; i++) {
        const element = values.offer[i];
        if (element.product._id !== product._id) {
          setValues({
            ...values,
            offer: [
              ...values.offer,
              { product: product, count: 1, free: isFree },
            ],
          });
          setOfferProducts([
            ...offerProducts,
            { product: product, count: 1, free: isFree },
          ]);
        }
      }
    } else {
      setValues({
        ...values,
        offer: values.offer.filter((item) => item.product._id !== product._id),
      });
      setOfferProducts(
        offerProducts.filter((item) => item.product._id !== product._id)
      );
    }
  };
  //========================================================================================
  const handleSwitchFree = (e, productID) => {
    if (e.target.checked) {
      for (let i = 0; i < values.offer.length; i++) {
        const element = values.offer[i];
        if (element.product._id === productID) {
          if (element.free === false) {
            element.free = true;
          }
        }
      }
      for (let j = 0; j < offerProducts.length; j++) {
        const element = offerProducts[j];
        if (element.product._id === productID) {
          element.free = true;
        }
      }
    } else {
      for (let i = 0; i < values.offer.length; i++) {
        const element = values.offer[i];
        if (element.product._id === productID) {
          if (element.free === true) {
            element.free = false;
          }
        }
      }
      for (let j = 0; j < offerProducts.length; j++) {
        const element = offerProducts[j];
        if (element.product._id === productID) {
          element.free = false;
        }
      }
    }
  };
  const handleCount = (value, productId) => {
    for (let i = 0; i < values.offer.length; i++) {
      const element = values.offer[i];
      if (element.product._id === productId) {
        element.count = parseInt(value);
      }
    }
    for (let i = 0; i < offerProducts.length; i++) {
      const element = offerProducts[i];
      if (element.product._id === productId) {
        element.count = parseInt(value);
      }
    }
  };

  //=============================================Fetching Categories========================================
  useEffect(() => {
    const fetchCategories = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/cat/",
        requestOptions
      );
      const body = await response.json();
      // console.log(body);

      if (!response.ok) {
        setCategories([]);
        console.log(body);
      } else {
        setCategories(body);
      }
    };
    fetchCategories();
  }, []);
  //===================================================Filter Products Products==================================================================
  const filterProductsByCategoryID = async () => {
    if (CatFilterID) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/product/?cat=${CatFilterID}`,
        requestOptions
      );
      const body = await response.json();

      if (!response.ok) {
        setCategoryProducts([]);
        console.log(body);
      } else {
        setCategoryProducts(body.data);
      }
    } else {
      var requestOptions2 = {
        method: "GET",
        redirect: "follow",
      };

      const response2 = await fetch(
        `https://www.api.setalkel.co/product`,
        requestOptions2
      );
      const body2 = await response2.json();

      if (!response2.ok) {
        setCategoryProducts([]);
        console.log(body2);
      } else {
        setCategoryProducts(body2.data);
      }
    }
  };
  useEffect(() => {
    filterProductsByCategoryID();
  }, [CatFilterID]);
  //====================================get Offer By Id =========================
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  const getOfferById = useCallback(async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/offer/${params.Id}`,
      requestOptions
    );
    const body = await response.json();
    console.log(body);
    setOfferProducts(body.offer);
    setImgfile({ Imgfile: body.img })
    setValues({
      _id: body._id,
      offerName: {
        en: "",
        ar: "",
        fr: "",
        ru: "",
        de: "",
        dk: "",
        ir: "",
        cn: "",
        tur: "",
      },
      offer: body.offer,
      grossWeight: body.grossWeight,
      volume: body.volume,
      countInBox: body.countInBox,
    });
    body.offerName
      ? setValues({
        _id: body._id,
        offerName: {
          en: body.offerName.en ? body.offerName.en : "",
          ar: body.offerName.ar ? body.offerName.ar : "",
          fr: body.offerName.fr ? body.offerName.fr : "",
          ru: body.offerName.ru ? body.offerName.ru : "",
          de: body.offerName.de ? body.offerName.de : "",
          dk: body.offerName.dk ? body.offerName.dk : "",
          ir: body.offerName.ir ? body.offerName.ir : "",
          cn: body.offerName.cn ? body.offerName.cn : "",
          tur: body.offerName.tur ? body.offerName.tur : "",
        },
        offer: body.offer,
        grossWeight: body.grossWeight,
        volume: body.volume,
        countInBox: body.countInBox,
      })
      : setValues({
        _id: body._id,
        offerName: {
          en: "",
          ar: "",
          fr: "",
          ru: "",
          de: "",
          dk: "",
          ir: "",
          cn: "",
          tur: "",
        },
        offer: body.offer,
        grossWeight: body.grossWeight,
        volume: body.volume,
        countInBox: body.countInBox,
      });
  }, [params.Id]);
  useEffect(() => {
    getOfferById();
  }, [getOfferById]);
  //===================================================Posting Image==================================================================
  const UpdateOfferImg = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      await fetch(
        `https://www.api.setalkel.co/offer/image/${id}`,
        requestOptions
      );
      // const body = await ImgResponse.json();
      alert("Offer Image Updated");
    } catch (error) {
      console.log(error);
    }
  };
  //===================================================Update Offer==================================================================
  const UpdateOffer = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var NewOfferArr = [];
    for (let i = 0; i < values.offer.length; i++) {
      const offer = values.offer[i];
      NewOfferArr.push({
        product: offer.product._id,
        free: offer.free,
        count: offer.count,
      });
    }

    var raw = {
      offerName: values.offerName,
      offer: NewOfferArr,
      grossWeight: values.grossweight,
      volume: values.volume,
      countInBox: values.countInBox,
    };
    let NewRaw = raw;
    let NewOfferName = raw.offerName;
    Object.keys(NewOfferName).forEach((key) => {
      if (NewOfferName[key] === "") {
        delete NewOfferName[key];
      }
    });
    Object.keys(NewRaw).forEach((key) => {
      if (NewRaw[key] === "") {
        delete NewRaw[key];
      }
    });
    const LastRaw = JSON.stringify(NewRaw);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: LastRaw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/offer/${values._id}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("Offer Not Updated");
    } else {
      if (Imgfile.Imgfile) {
        await UpdateOfferImg(body._id);
      }
      alert("Offer Updated");
      navigate("/dashboard/croffers");
    }
  };
  //=====================================Cancel Btn===============================
  const Cancel = () => {
    navigate("/dashboard/croffers");
  };
  return (
    <section className="cms-update-offers">
      <Row>
        <div className="crud-title">
          <h2>Update Offer</h2>
        </div>
      </Row>
      <Row>
        <div className="create-offer">
          <Form>
            <div className="product-langs">
              <Form.Label htmlFor="ProductNameInEn" style={{ color: "red" }}>
                Arabic & English R Required
              </Form.Label>
              <Form.Group>
                <Form.Label>
                  Offer Name In English /
                  <span style={{ color: "red" }}>Required</span>
                </Form.Label>
                <Form.Control
                  name="en"
                  label="Offer"
                  value={values.offerName.en}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in english"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Offer Name In Arabic /
                  <span style={{ color: "red" }}>Required</span>
                </Form.Label>

                <Form.Control
                  name="ar"
                  label="Offer"
                  value={values.offerName.ar}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in arabic"
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Offer Name In French</Form.Label>

                <Form.Control
                  name="fr"
                  label="Offer"
                  value={values.offerName.fr}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in french"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Offer Name In Russian</Form.Label>

                <Form.Control
                  name="ru"
                  label="Offer"
                  value={values.offerName.ru}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in russian"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Offer Name In German</Form.Label>

                <Form.Control
                  name="de"
                  label="Offer"
                  value={values.offerName.de}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in german"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Offer Name In China</Form.Label>

                <Form.Control
                  name="cn"
                  label="Offer"
                  value={values.offerName.cn}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer name in chineese"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Offer Name In Turkish</Form.Label>
                <Form.Control
                  name="tur"
                  label="Offer"
                  value={values.offerName.tur}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in turkish"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Offer Name In Persian</Form.Label>
                <Form.Control
                  name="ir"
                  label="Offer"
                  value={values.offerName.ir}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in Persian"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Offer Name In Danish</Form.Label>
                <Form.Control
                  name="dk"
                  label="Offer"
                  value={values.offerName.dk}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in Danish"
                />
              </Form.Group>
            </div>
            <Form.Group>
              <Form.Label>Gross Weight</Form.Label>
              <Form.Control
                type="text"
                name="grossWeight"
                value={values.grossWeight}
                onChange={handleDataInput}
                placeholder="Gross Weight"
              />
              <Form.Label>Product Volume</Form.Label>
              <Form.Control
                type="text"
                name="volume"
                value={values.volume}
                onChange={handleDataInput}
                placeholder="Product Volume"
              />
              <Form.Label>Product Count In Box</Form.Label>
              <Form.Control
                type="text"
                name="countInBox"
                value={values.countInBox}
                onChange={handleDataInput}
                placeholder="Product Count In Box"
              />
              <Form.Label htmlFor="ProductImg">Offer Image</Form.Label>
              <div className="d-flex flex-column">
                <Form.Control
                  type="file"
                  name="image"
                  id="productImg"
                  onChange={handleImageChange}
                />
                {Imgfile.Imgfile && <div className="position-relative" style={{
                  width: "fit-content"
                }}>
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                    }}
                    src={"https://www.api.setalkel.co" + Imgfile.Imgfile}
                  />
                  <FaDownload onClick={() => getFile(Imgfile.Imgfile)} style={{
                    color: "red",
                    fontSize: "15px",
                    position: "absolute",
                    top: "0",
                    right: "0"

                  }} />
                  <FaTrashAlt style={{
                    color: "red",
                    fontSize: "15px",
                    position: "absolute",
                    top: "0",
                    right: "20px",
                    zIndex: "100"

                  }} />
                </div>
                }
              </div>
            </Form.Group>
            <Button variant="warning" onClick={UpdateOffer}>
              Update Offer
            </Button>
            <Button variant="outline-dark" onClick={Cancel}>
              Cancel
            </Button>
          </Form>
        </div>
      </Row>
      <hr />
      <Row>
        <Table>
          <TableHeader>
            <TableHeaderCell>ID</TableHeaderCell>
            <TableHeaderCell>Image</TableHeaderCell>
            <TableHeaderCell>Product Names</TableHeaderCell>
            <TableHeaderCell>Piece Details</TableHeaderCell>
            <TableHeaderCell>Country Origin</TableHeaderCell>
            <TableHeaderCell>Material Property</TableHeaderCell>
            <TableHeaderCell>Supplier Name</TableHeaderCell>
            <TableHeaderCell>Stock Off ? </TableHeaderCell>
            <TableHeaderCell>Hidden ? </TableHeaderCell>
            <TableHeaderCell>Is New ? </TableHeaderCell>
            <TableHeaderCell>Free ?</TableHeaderCell>
            <Table.HeaderCell>Delete</Table.HeaderCell>
          </TableHeader>
          <TableBody>
            {offerProducts.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <img
                      src={`https://www.api.setalkel.co${item.product.img}`}
                      alt="productImg"
                      style={{
                        height: "70px",
                        width: "70px",
                      }}
                    />
                  </TableCell>
                  <TableCell>{item.product.subCat.en}</TableCell>
                  <TableCell>{item.product.peiceQty.usualWeight}</TableCell>
                  <TableCell>
                    <Form.Control
                      as="textarea"
                      disabled
                      value={
                        item.product.countryOrigin
                          ? item.product.countryOrigin
                          : "No Country Origin"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Form.Control
                      as="textarea"
                      disabled
                      value={
                        item.product.countryOrigin
                          ? item.product.materialProperty
                          : "No Country Origin"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {item.product.supplierName
                      ? item.product.supplierName
                      : "No Supplier Name"}
                  </TableCell>

                  <TableCell>
                    {item.product.stockOff ? "Yes" : "No Stock Off"}
                  </TableCell>
                  <TableCell>{item.product.hidden ? "Yes" : "No"}</TableCell>
                  <TableCell>{item.product.is_new ? "Yes" : "No"}</TableCell>
                  <TableCell>{item.free ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteOfferProduct(item)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <hr />
        <Form.Group>
          <Form.Label>Filter Category</Form.Label>
          <select
            id="category-list"
            name="catFilterId"
            value={CatFilterID}
            onChange={(e) => setCatFilterID(e.target.value)}
          >
            <option>Please Choose A Category</option>
            {categories.map((category, index) => {
              return (
                <option key={index} value={category._id}>
                  {category.en}
                </option>
              );
            })}
          </select>
        </Form.Group>
        <Table>
          <TableRow>
            <TableHeaderCell>ID</TableHeaderCell>
            <TableHeaderCell>Image</TableHeaderCell>
            <TableHeaderCell>Category</TableHeaderCell>
            <TableHeaderCell>Product Names</TableHeaderCell>
            <TableHeaderCell>Piece Details</TableHeaderCell>
            {/* <TableHeaderCell>Package Details</TableHeaderCell> */}
            <TableHeaderCell>Country Origin</TableHeaderCell>
            <TableHeaderCell>Material Property</TableHeaderCell>
            <TableHeaderCell>Supplier Name</TableHeaderCell>
            <TableHeaderCell>Stock Off ? </TableHeaderCell>
            <TableHeaderCell>Hidden ? </TableHeaderCell>
            <TableHeaderCell>Is New ? </TableHeaderCell>
            <Table.HeaderCell>Add To Offer</Table.HeaderCell>
            <Table.HeaderCell>Free ? </Table.HeaderCell>
            <Table.HeaderCell>Count</Table.HeaderCell>
          </TableRow>
          <TableBody>
            {categoryProducts?.map((product, index) => {
              return (
                <TableRow key={product._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <img
                      src={`https://www.api.setalkel.co${product.img}`}
                      alt="productImg"
                      style={{
                        height: "70px",
                        width: "70px",
                      }}
                    />
                  </TableCell>
                  <TableCell>{product.cat.en}</TableCell>
                  <TableCell>{product.subCat.en}</TableCell>
                  <TableCell>{product.peiceQty.usualWeight}</TableCell>
                  <TableCell>
                    <Form.Control
                      as="textarea"
                      disabled
                      value={
                        product.countryOrigin
                          ? product.countryOrigin
                          : "No Country Origin"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Form.Control
                      as="textarea"
                      disabled
                      value={
                        product.countryOrigin
                          ? product.materialProperty
                          : "No Country Origin"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {product.supplierName
                      ? product.supplierName
                      : "No Supplier Name"}
                  </TableCell>

                  <TableCell>
                    {product.stockOff ? "Yes" : "No Stock Off"}
                  </TableCell>
                  <TableCell>{product.hidden ? "Yes" : "No"}</TableCell>
                  <TableCell>{product.is_new ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <Form.Check
                      type="switch"
                      name="offer"
                      id="offer"
                      onChange={(e) => handleSwitch(e, product)}
                    />
                  </TableCell>
                  <TableCell>
                    <Form.Check
                      type="switch"
                      name="free"
                      id="free"
                      onChange={(e) => handleSwitchFree(e, product._id)}
                    />
                  </TableCell>
                  <TableCell>
                    <Form.Control
                      type="number"
                      name="count"
                      onKeyUp={(e) => {
                        handleCount(e.target.value, product._id);
                      }}
                      placeholder="How Many"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Row>
    </section>
  );
};

export default UpdateOffers;
