import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
//css
import "./SwiftPaymentCard.css";
//image
import LogoImg from "../../../assets/Payments/logo-set-alkel2.png";
//copy-to-clipboard
import copy from "copy-to-clipboard";
//translation
import { useTranslation } from "react-i18next";
const SwiftPaymentCard = () => {
  const [paymentAccount, setPaymentAccount] = useState([]);

  const { t } = useTranslation();

  const copyToClipboard = () => {
    paymentAccount.forEach((item) => {
      copy(item.swiftAccount);
    });
    alert("The Swift Number Has Been Copied");
  };

  //======================Get Swift Account===================
  const getPaymentAccount = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/account/",
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      setPaymentAccount([]);
    } else {
      setPaymentAccount(body);
    }
  };
  useEffect(() => {
    getPaymentAccount();
  }, []);
  return (
    <article className="swift-payment-card">
      <div className="swift-image">
        <div>
          <img src={LogoImg} alt="docs" />
        </div>
        <small>
          {t(`Components.PaymentComponents.SwiftPaymentCard.Title`)}
        </small>
      </div>
      {paymentAccount.map((item, index) => {
        return (
          <h2 id="account-number" key={index}>
            {index + 1} -- {item.swiftAccount} <br />
          </h2>
        );
      })}
      <Button className="primary-btn" onClick={copyToClipboard}>
        {t(`Components.PaymentComponents.SwiftPaymentCard.Btn`)}
      </Button>
    </article>
  );
};

export default SwiftPaymentCard;
