import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  checkIsEditing,
  clearCart,
  getTotals,
} from "../../../../redux/cartSlice";
//css
import "./Cart.css";
//images
import EmptyBox from "../../../../assets/Payments/empty cart.gif";
import ConfirmImg from "../../../../assets/suggestion confirm.gif";
import OverWeight from "../../../../assets/overweightclsoed.gif";
//components
import CartCard from "../../../../Components/cartCard/CartCard";
import ShipmentTotals from "../../../../Components/ShipmentComponents/ShipmentTotals/ShipmentTotals";
import CartPopUp from "../../../../Components/ShipmentComponents/CartPopUp/CartPopUp";
//translation
import { useTranslation } from "react-i18next";

var deleteKey = require("key-del");

const Cart = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [CartMsgModalShow, setCartMsgModalShow] = React.useState(false);
  const cart = useSelector((state) => state.cart);
  const shipmentData = useSelector((state) => state.shipmentStepper.container);
  const shipmentInfo = useSelector((state) => state.shipmentStepper);
  const [shipmentOrder, setShipmentOrder] = useState({
    order: {
      items: [],
    },
    exportCountry: "",
    importCountry: "",
    state: "",
    container: "",
    deliveryMethod: "",
  });
  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);
  //======================handleClearCart===============================

  const handleClearCart = () => {
    dispatch(clearCart());
  };
  //====================navigateToCartProducts===========================
  const navigateToCartProducts = () => {
    navigate("/shipmentProcess/shipmentproducts");
  };
  //======================Add Shipment Suggestion========================
  const handleArrays = () => {
    const shipmentItems = cart.cartItems;
    var result;
    const Arr = [];
    for (let i = 0; i < shipmentItems.length; i++) {
      const element = shipmentItems[i];
      // element.cartQuantity =  element.count;
      result = deleteKey(element, [
        "addedBy",
        "additionalImage",
        "cat",
        "countInBox",
        "countryOrigin",
        "createdAt",
        "hidden",
        "img",
        "materialProperty",
        "package",
        "packageGrossWeight",
        "peiceQty",
        "productPrice",
        "stockOff",
        "subCat",
        "supplierName",
        "unitForPackage",
        "updatedAt",
        "volume",
        "is_new",
        "ratingValue",
        "updatedBy",
      ]);
      result["count"] = result["cartQuantity"];
      delete result["cartQuantity"];
      result["product"] = result["_id"];
      delete result["_id"];
      Arr.push(result);
      setShipmentOrder({
        ...shipmentOrder,
        order: { ...shipmentOrder.order, items: Arr },
      });
    }
  };
  useEffect(() => {
    handleArrays();
  }, [cart.cartItems]);
  //=====================================================================
  const addShipmentSuggestion = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      order: shipmentOrder.order,
      exportCountry: shipmentInfo.exportCountryId,
      importCountry: shipmentInfo.importCountryId,
      state: shipmentInfo.importStateId,
      container: shipmentInfo.containerId,
      deliveryMethod: shipmentInfo.deliveryMethod,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/shipment",
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("Suggestion Not Sent !!!");
      console.log(body);
    } else {
      setCartMsgModalShow(true);
      setTimeout(() => {
        localStorage.removeItem("cartItems");
        localStorage.removeItem("shipmentItems");
        localStorage.removeItem("importCountryId");
        localStorage.removeItem("importStateId");
        localStorage.removeItem("exportCountryId");
        localStorage.removeItem("container");
        localStorage.removeItem("containerId");
        localStorage.removeItem("deliveryMethod");
        localStorage.removeItem("categoryId");
        dispatch(clearCart());
        dispatch(checkIsEditing(false));
        navigate("/shipmentProcess/myorders");
      }, 4000);
    }
  };
  //===========================Update Shipment Agent =====================
  const updateShipment = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    // console.log(shipmentOrder.order)
    var raw = JSON.stringify(shipmentOrder.order);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log(raw);
    const response = await fetch(
      `https://www.api.setalkel.co/shipment/${localStorage.getItem(
        "UpdatedSuggestionId"
      )}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("Suggestion Update Not Sent !!!");
      console.log(body);
    } else {
      setCartMsgModalShow(true);
      setTimeout(() => {
        localStorage.removeItem("cartItems");
        localStorage.removeItem("shipmentItems");
        localStorage.removeItem("importCountryId");
        localStorage.removeItem("importStateId");
        localStorage.removeItem("exportCountryId");
        localStorage.removeItem("container");
        localStorage.removeItem("containerId");
        localStorage.removeItem("deliveryMethod");
        localStorage.removeItem("categoryId");
        dispatch(clearCart());
        dispatch(checkIsEditing(false));
        navigate("/shipmentProcess/myorders");
      }, 4000);
    }
  };
  return (
    <section
      style={{ padding: `${cart.cartItems.length === 0 && "0 6%"}` }}
      className="cart-container"
    >
      {cart.cartItems.length === 0 ? (
        <div className="cart-empty">
          <img src={EmptyBox} alt="emptybox" />
          <p>{t(`Pages.ShipmentProcess.Cart.EmptyCart`)}</p>
          <div className="start-shopping">
            <button className="primary-btn" onClick={navigateToCartProducts}>
              {t(`Pages.ShipmentProcess.Cart.StartShopping`)}
            </button>
          </div>
        </div>
      ) : (
        <div className="cart-items">
          {cart.cartItems &&
            cart.cartItems.map((cartItem, index) => (
              <CartCard
                key={index}
                cartItem={cartItem}
                shipmentOrder={shipmentOrder}
                setShipmentOrder={setShipmentOrder}
              />
            ))}
        </div>
      )}
      <div className="cart-summary">
        {cart.cartTotalQuantity > 0 ? (
          <>
            <button className="clear-btn" onClick={() => handleClearCart()}>
              {t(`Pages.ShipmentProcess.Cart.ClearCart`)}
            </button>
            <div className="cart-buttons">
              {cart.cartTotalQuantity > 0 &&
              cart.shipmentInRange === true &&
              !cart.isEditing ? (
                <>
                  <div
                    className="continue-shopping"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <Link to="/shipmentProcess/shipmentproducts">
                      <span>
                        {t(`Pages.ShipmentProcess.Cart.ContinueShopping`)}
                      </span>
                    </Link>
                  </div>
                  <button
                    className="primary-btn cart-btn"
                    onClick={() => {
                      addShipmentSuggestion();
                    }}
                  >
                    {t(`Pages.ShipmentProcess.Cart.SendShipmentSuggestion`)}
                  </button>
                  <CartPopUp
                    img={ConfirmImg}
                    message={t(
                      `Components.ShipmentComponents.CartMsgPopUp.MsgBody`
                    )}
                    CartMsgModalShow={CartMsgModalShow}
                  />
                </>
              ) : cart.cartTotalQuantity > 0 &&
                cart.shipmentInRange === false &&
                !cart.isEditing ? (
                <>
                  <div className="continue-shopping">
                    <Link to="/shipmentProcess/shipmentproducts">
                      <span>
                        {t(`Pages.ShipmentProcess.Cart.ContinueShopping`)}
                      </span>
                    </Link>
                  </div>
                  <button
                    className="primary-btn cart-btn"
                    onClick={() => {
                      setCartMsgModalShow(true);
                    }}
                  >
                    {t(`Pages.ShipmentProcess.Cart.SendShipmentSuggestion`)}
                  </button>
                  <CartPopUp
                    img={OverWeight}
                    message={`${t(
                      `Pages.ShipmentProcess.Cart.CartPopUp.OverWeightNotice`
                    )} ${t(
                      `Components.ShipmentComponents.CartMsgPopUp.From`
                    )} ${shipmentData.minWeightInState} KG ${t(
                      `Components.ShipmentComponents.CartMsgPopUp.To`
                    )} ${shipmentData.maxWeightInState} ${t(
                      `Components.ShipmentComponents.CartMsgPopUp.AndVolumeUnder`
                    )} ${shipmentData.container.maxVolume} ${t(
                      `Pages.ShipmentProcess.Cart.CartPopUp.Remaining`
                    )} ${
                      shipmentData.minWeightInState - cart.cartTotalWeight
                    } KG`}
                    CartMsgModalShow={CartMsgModalShow}
                    setCartMsgModalShow={setCartMsgModalShow}
                  />
                </>
              ) : cart.cartTotalQuantity > 0 &&
                cart.shipmentInRange === true &&
                cart.isEditing ? (
                <>
                  <div
                    className="continue-shopping"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <Link to="/shipmentProcess/shipmentproducts">
                      <span>
                        {t(`Pages.ShipmentProcess.Cart.ContinueShopping`)}
                      </span>
                    </Link>
                  </div>
                  <button
                    className="primary-btn cart-btn"
                    onClick={() => {
                      updateShipment();
                    }}
                  >
                    {t(`Pages.ShipmentProcess.Cart.UpdateShipmentSuggestion`)}
                  </button>
                </>
              ) : cart.cartTotalQuantity > 0 &&
                cart.shipmentInRange === false &&
                cart.isEditing ? (
                <>
                  <button
                    className="primary-btn cart-btn"
                    onClick={() => {
                      setCartMsgModalShow(true);
                    }}
                  >
                    {t(`Pages.ShipmentProcess.Cart.UpdateShipmentSuggestion`)}
                  </button>
                  <CartPopUp
                    img={OverWeight}
                    message={`${t(
                      `Pages.ShipmentProcess.Cart.CartPopUp.OverWeightNotice`
                    )} From ${shipmentData.minWeightInState} KG To ${
                      shipmentData.maxWeightInState
                    } KG `}
                    CartMsgModalShow={CartMsgModalShow}
                    setCartMsgModalShow={setCartMsgModalShow}
                  />
                </>
              ) : null}
            </div>

            <ShipmentTotals />
          </>
        ) : null}
      </div>
    </section>
  );
};

export default Cart;
