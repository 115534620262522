import React, { useEffect, useState } from "react";
import axios from "axios";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
//css
import "./Ratings.css";

//components
import { Rating } from "react-simple-star-rating";
//images
import Star from "../../../assets/Payments/Star.svg";
import Star2 from "../../../assets/Payments/Star2.svg";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
const Ratings = () => {
  const [agents, setAgents] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [agentRatings, setAgentRatings] = useState([]);
  const [deletedRatings, setDeletedRatings] = useState({
    rates: [],
  });
  const [DeleteRateShow, setDeleteRateShow] = useState(false);
  const handleDeleteRateClose = () => setDeleteRateShow(false);
  const handleDeleteRateShow = () => setDeleteRateShow(true);
  //=============================get Agents======================

  useEffect(() => {
    const getAgents = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/agent/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setAgents([]);
        console.log(body);
      } else {
        setAgents(body);
      }
    };
    getAgents();
  }, []);
  //==========================get Agent Rating ================
  useEffect(() => {
    const getAgentRatings = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/rating-product/agent/${agentId}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setAgentRatings([]);
        console.log(body);
      } else {
        setAgentRatings(body);
      }
    };
    getAgentRatings();
  }, [agentId]);

  //==========================Delete Product==============================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/rating-product/${agentId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          alert("item deleted");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  //============================Delete Mutliple Product===================

  const deleteRatings = (e, rateId) => {
    if (e.target.checked) {
      if (!deletedRatings.rates.includes(rateId)) {
        setDeletedRatings({
          ...deletedRatings,
          rates: [...deletedRatings.rates, rateId],
        });
      }
    } else {
      setDeletedRatings({
        rates: deletedRatings.rates.filter((item) => item !== rateId),
      });
    }
  };
  //=======================delete Multiple Products========================
  const deleteMultipleProducts = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(deletedRatings);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/rating-product/",
      requestOptions
    );

    if (!response.ok) {
      alert("There Is An Error ");
      console.log(response);
    } else {
      alert("Ratings Have Been Deleted !!");
      window.location.reload();
    }
  };

  //=======================Toggle Show Hide Delete Btn==========================
  useEffect(() => {
    if (deletedRatings.rates.length > 0) {
      document.getElementById("multipleDelete").style.visibility = "visible";
    } else {
      document.getElementById("multipleDelete").style.visibility = "hidden";
    }
  }, [deletedRatings.rates]);
  //====================================================================================================
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Image",
      selector: (row) => (
        <span>
          <img
            src={`https://www.api.setalkel.co${row.productId.img}`}
            alt="img"
            width="70px"
            height="70px"
          />
        </span>
      ),
    },
    {
      name: "Name",
      selector: (row) => <span>{row.productId.subCat.en}</span>,
    },
    {
      name: "Usual Weight",
      selector: (row) => <span>{`row.productId.cat.en`}</span>,
    },
    {
      name: "Cateogry",
      selector: (row) => <span>{row.productId.cat.en}</span>,
    },
    {
      name: "Country Origin",
      selector: (row) => <span>{row.productId.countryOrigin}</span>,
    },
    {
      name: "Supplier Name",
      selector: (row) => <span>{row.productId.supplierName}</span>,
    },
    {
      name: "Visible",
      selector: (row) => <span>{row.showHidden ? "Yes" : "No"}</span>,
    },
    {
      name: "Agent Rate",
      selector: (row) => (
        <span>
          <Rating
            ratingValue={row.value * 20}
            fullIcon={<img src={Star} alt="fillStart" />}
            emptyIcon={<img src={Star2} alt="emptyStar" />}
            allowHalfIcon={true}
            fullClassName="fillStar"
            emptyClassName="emptyStar"
            readonly={true}
          />
        </span>
      ),
    },
    {
      name: "Agent Rate Note",
      selector: (row) => (
        <Form.Control
          rows={3}
          cols={20}
          as="textarea"
          disabled
          value={row.note ? row.note : "No Comment On This Product"}
        />
      ),
    },
    {
      name: "Global Rate",
      selector: (row) => <span></span>,
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteRateShow();
              setAgentId(row._id);
            }}
          >
            Delete
          </Button>

          <Modal show={DeleteRateShow} onHide={handleDeleteRateClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete This Rate
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteRateClose}>
                Close
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                Delete Rate
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
    {
      name: "M-Delete",
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteRatings(e, row._id)}
        />
      ),
    },
  ];
  return (
    <section className="cms-ratings">
      <Row>
        <div className="crud-products">
          <div className="crud-title">
            <h1>Hello Ratings</h1>
          </div>
        </div>
      </Row>
      <Row>
        <div className="list-ratings">
          <Form>
            <h2>Choose Agent</h2>
            <Form.Select
              name="agentId"
              id="agentId"
              value={agentId}
              onChange={(e) => setAgentId(e.target.value)}
            >
              <option>Choose Agent</option>
              {agents?.map((agent, index) => {
                return (
                  <option key={index} value={agent._id}>
                    {agent.name}
                  </option>
                );
              })}
            </Form.Select>
            <Button
              id="multipleDelete"
              variant="outline-danger"
              onClick={deleteMultipleProducts}
            >
              Multiple Delete
            </Button>
          </Form>
          <hr />
        </div>
      </Row>
      <Row>
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={agentRatings}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
};

export default Ratings;
