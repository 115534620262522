import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

//boostrap
import { Row, Button, Form, Modal } from "react-bootstrap";
//css
import "./CRCategories.css";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import UnVerifiedAgentsApi from "../../Api/UnVerifiedAgentsApi";
import { FaDownload } from "react-icons/fa";

const CRCategories = () => {
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const [values, setValues] = useState({
    cat: "",
    en_name: "",
    ar_name: "",
    fr_name: "",
    ru_name: "",
    de_name: "",
    cn_name: "",
    tur_name: "",
    ir_name: "",
    dk_name: "",
  });
  const [deletedCategories, setDeletedCategories] = useState({
    ids: [],
  });
  //========================================Delete Category Modal==========================================
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleClose = () => setDeleteModalShow(false);
  const handleShow = () => setDeleteModalShow(true);
  //========================================Delete Categories Modal==========================================
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);
  //=======================Input Handeling===============

  //==============Fetching Categories=====================
  useEffect(() => {
    const fetchCategories = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/cat/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setCategories([]);
        console.log(body);
      } else {
        setCategories(body);
      }
    };
    fetchCategories();
  }, []);

  //==============Delete Product=====================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/product/cat/${categoryId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          alert("item deleted");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  //============================Delete Mutliple Product=====================

  const deleteCategories = (e, categoryID) => {
    if (e.target.checked) {
      if (!deleteCategories.ids.includes(categoryID)) {
        setDeletedCategories({
          ...deleteCategories,
          ids: [...deleteCategories.ids, categoryID],
        });
      }
    } else {
      setDeletedCategories({
        ids: deleteCategories.ids.filter((item) => item !== categoryID),
      });
    }
  };
  const deleteMultipleCategories = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deleteCategories);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/product/cat/collection",
      requestOptions
    );
    if (!response.ok) {
      alert(response.body.message);
      console.log(response);
    } else {
      alert("Categories Have Been Deleted !!");
      window.location.reload();
    }
  };
  useEffect(() => {
    if (deletedCategories.ids.length > 0) {
      document.getElementById("multipleDelete").style.visibility = "visible";
    } else {
      document.getElementById("multipleDelete").style.visibility = "hidden";
    }
  }, [deletedCategories.ids]);
  const [showAgentOnWebsite] = useState([]);
  const handleShowHideOnWebsite = (e, Id) => {
    if (e.target.value) {
      if (showAgentOnWebsite.length > 0) {
        for (let i = 0; i < showAgentOnWebsite.length; i++) {
          if (showAgentOnWebsite[i].agentId === Id) {
            showAgentOnWebsite[i].hidden = e.target.value;
            return;
          }
        }
        showAgentOnWebsite.push({
          agentId: Id,
          hidden: e.target.value,
        });
      } else {
        showAgentOnWebsite.push({
          agentId: Id,
          hidden: e.target.value,
        });
      }
    }
  }; 
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Image",
      selector: (row) => (
        <div className="position-relative">
          <img
            src={"https://www.api.setalkel.co" + row.img}
            alt="productImg"
            style={{
              height: "100px",
              width: "100px",
            }}
          />
          <FaDownload onClick={() => getFile(row.img)} style={{
            color: "red",
            fontSize: "15px",
            position: "absolute",
            top: "0",
            right: "0",
            cursor:"pointer"

          }} />

        </div>
      ),
    },
    {
      name: "Name In En",
      selector: (row) => <span>{row.en}</span>,
    },
    {
      name: "Name In Ar",
      selector: (row) => <span>{row.ar}</span>,
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/crcategories/${row._id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },
    {
      name: "Show/Hide Categories",
      selector: (row) => (
        <Form.Select style={{
          border: "1px solid red"
        }} onChange={(e) => handleShowHideOnWebsite(e, row._id)}>
          <option>Choose State</option>
          <option value="false">Show</option>
          <option value="true">Hide</option>
        </Form.Select>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <>
          <Button
            variant="danger"
            onClick={() => {
              handleShow();
              setCategoryId(row._id);
            }}
          >
            Delete
          </Button>

          <Modal show={DeleteModalShow} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete {row.en}
                Category
              </Modal.Title>
            </Modal.Header>

            <Modal.Footer>
              <Button variant="dark" onClick={handleClose}>
                Close
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ),
    },
    {
      name: "M-Delete",
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteCategories(e, row._id)}
        />
      ),
    },
  ];
  const navigate = useNavigate()
  const [generateExcelFile, setGenerateExecelFile] = useState()
  const { data: Excel } = UnVerifiedAgentsApi.GenerateExelFileQueryForCategories()
  const getFileExcel = async () => {
    UnVerifiedAgentsApi.GetFileExcel(generateExcelFile)
    setGenerateExecelFile(undefined)
  };
  return (
    <section className="cms-create-categories">
      <Row>
        <div className="crud-categories">
          <div className="crud-title">
            <h1>Categories</h1>
          </div>
          <div className="crud-category">
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: "10px"
            }}>
              {!generateExcelFile ? <Button variant="warning" id="verify-btn" onClick={() => setGenerateExecelFile(Excel)}>
                Export Excel File for Categories
              </Button> : <Button variant="success" id="verify-btn" onClick={getFileExcel}>
                Download Excel File
              </Button>}
              <Button variant="danger" id="verify-btn" >
                Import Excel
              </Button>

              <Button type="button" onClick={() => navigate("add-categories")} variant="warning">
                Add Category
              </Button>

              <Button
                id="multipleDelete"
                variant="outline-danger"
                onClick={() => handleMultipleShow()}
              >
                Multiple Delete
              </Button>
            </div>

            <Modal
              size="lg"
              show={MultipleDeleteModalShow}
              onHide={() => setMultipleDeleteModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Are You Sure You Want To Delete All Those Categories
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleMultipleClose}>
                  Close
                </Button>
                <Button variant="danger" onClick={deleteMultipleCategories}>
                  Delete Categories
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <hr />
        <Row>
          <div className="list-category">
            <DataTable
             fixedHeader
              pagination
              responsive
              subHeaderWrap
              columns={columns}
              data={categories}
              customStyles={customStyles}
            />
          </div>
        </Row>
      </Row>
    </section>
  );
};

export default CRCategories;
