import React, { useState } from 'react'
import { Button, Form, Modal, Row } from "react-bootstrap";
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const AddAboutUs = () => {
    const navigate = useNavigate()
    const [values, setValues] = useState({
        desc: {
            ar: "",
            en: "",
            de: "",
            fr: "",
            cn: "",
            ru: "",
            tur: "",
            ir: "",
            dk: "",
        },
    });
    const [ImgGallery, setImgGallery] = useState({
        ImgGallery: [],
    });
    const [Videofile, setVideofile] = useState({
        Videofile: null,
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            desc: { ...values.desc, [name]: value },
        });
    };
    const handleGalleryChange = (e) => {
        const newImages = Array.from(e.target.files);

        setImgGallery(prevState => {
            const allImages = [...prevState.ImgGallery, ...newImages];
            return {
                ImgGallery: allImages
            };
        });
    };
    // console.log(ImgGallery)
    const handleVideoChange = (e) => {
        setVideofile({
            Videofile: e.target.files[0],
        });
    };
    const PostAboutUsVideo = async (id) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + localStorage.getItem("admin-token")
            );

            const fd = new FormData();
            fd.append("file", Videofile.Videofile, Videofile.Videofile.name);

            var requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: fd,
                redirect: "follow",
            };

            await fetch(`https://www.api.setalkel.co/aboutUs/video`, requestOptions);
            toast.success("Video has been added");
        } catch (error) {
            console.log(error);
        }
    };
    //=====================================Post About Us Gallery====================
    const PostAboutUsGallery = async (id) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + localStorage.getItem("admin-token")
            );
            const fd = new FormData();
            for (let i = 0; i < ImgGallery.ImgGallery.length; i++) {
                fd.append(
                    "images",
                    ImgGallery.ImgGallery[i],
                    ImgGallery.ImgGallery[i].name
                );
            }
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: fd,
                redirect: "follow",
            };

            await fetch(`https://www.api.setalkel.co/aboutUs/image`, requestOptions);
            toast.success("Image/s has been added");
        } catch (error) {
            console.log(error);
        }
    };
    const PostAboutUs = async () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `bearer ${localStorage.getItem("admin-token")}`
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = {
            desc: {
                ar: values.desc.ar,
                en: values.desc.en,
                de: values.desc.de,
                fr: values.desc.fr,
                cn: values.desc.cn,
                ru: values.desc.ru,
                tur: values.desc.tur,
                ir: values.desc.ir,
                dk: values.desc.dk,
            },
        };
        let newDesc = values.desc;
        let newRaw = raw;
        Object.keys(newDesc).forEach((key) => {
            if (newDesc[key] === "" || newDesc[key] === "undefined") {
                delete newDesc[key];
            }
        });
        newRaw.desc = newDesc;
        var raw2 = JSON.stringify(newRaw);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw2,
            redirect: "follow",
        };

        const response = await fetch(
            "https://www.api.setalkel.co/aboutUs",
            requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
            toast.error("There Is An Error");
            console.log(response);
            console.log(body);
        } else {
            toast.success("About Us Has Been Added");
            setValues({
                desc: {
                    ar: "",
                    en: "",
                    de: "",
                    fr: "",
                    cn: "",
                    ru: "",
                    tur: "",
                    ir: "",
                    dk: "",
                },
            });
            navigate("/dashboard/craboutUs")
        }
    };
    return (
        <>
            <Row className='text-center mb-3'><h2>Add About Us</h2></Row>
            <Row>
                <div className="create-about-us">
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>English textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="en"
                                value={values.desc.en}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Arabic textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="ar"
                                value={values.desc.ar}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>French textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="fr"
                                value={values.desc.fr}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Russian textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="ru"
                                value={values.desc.ru}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Chinese textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="cn"
                                value={values.desc.cn}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>German textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="de"
                                value={values.desc.de}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Turkesh textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="tur"
                                value={values.desc.tur}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Persian textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="ir"
                                value={values.desc.ir}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Danish textarea</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="dk"
                                value={values.desc.dk}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="KitchenImg">
                                About Us Image Gallery
                            </Form.Label>
                            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    <Form.Control
                      type="file"
                      name="images"
                      multiple
                      id="productImg"
                      onChange={handleGalleryChange}
                    />
                    <div style={{ display: "flex", gap: "5px" }}>
                      {ImgGallery.ImgGallery.map((image, index) => (
                        <div key={index} style={{ position: "relative", display: "inline-block" }}>
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover"
                            }}
                            src={URL.createObjectURL(image)}
                            alt={`Gallery Img ${index + 1}`}
                          />
                          <button
                          type="button"
                            onClick={() => {
                              setImgGallery(prevState => ({
                                ImgGallery: prevState.ImgGallery.filter((_, indexIM) => indexIM !== index)
                              }));
                            }}
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "-11px",
                              backgroundColor: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer"
                            }}
                          >
                            &times;
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>About Us Video</Form.Label>
                            <Form.Control
                                type="file"
                                name="video"
                                onChange={handleVideoChange}
                            />
                        </Form.Group>

                    </Form>
                    <div className='btn'>    <Button variant="warning" onClick={PostAboutUs}>
                        Add About Us
                    </Button>
                        <Button variant="warning" onClick={PostAboutUsGallery}>
                            Upload Image Gallery
                        </Button>
                        <Button variant="warning" onClick={PostAboutUsVideo}>
                            Upload Video
                        </Button></div>

                </div>
            </Row>
        </>
    )
}

export default AddAboutUs