export const Roles = [
  "c-productCat",
  "u-productCat",
  "d-productCat",
  "c-product",
  "u-product",
  "d-product",
  "c-admin",
  "r-admin",
  "u-admin",
  "d-admin",
  "u-admin-with-roles",
  "c-agent",
  "r-agent",
  "u-agent",
  "d-agent",
  "c-productPrice",
  "r-productPrice",
  "u-productPrice",
  "d-productPrice",
  "c-container",
  "r-container",
  "u-container",
  "d-container",
  "c-shipment",
  "r-shipment",
  "u-shipment",
  "d-shipment",
  "upload-shipment-file",
  "r-contactUs",
  "d-contactUs",
  "c-kitchen",
  "u-kitchen",
  "d-kitchen",
  "c-activity",
  "u-activity",
  "d-activity",
  "aboutUs",
  "partner",
  "c-offer",
  "u-offer",
  "d-offer",
  "uploads",
  "c-country",
  "u-country",
  "d-country",
  "r-country",
  "c-state",
  "u-state",
  "d-state",
  "r-state",
  "c-max-weight-container",
  "u-max-weight-container",
  "d-max-weight-container",
  "r-max-weight-container",
  "c-delivery-method",
  "r-delivery-method",
  "u-delivery-method",
  "d-delivery-method",
  "c-notification",
  "r-notification",
  "d-notification",
  "r-rating",
  "d-rating",
  "u-rating",
  "show-hidden-rating",
  "c-account",
  "r-account",
  "d-account",
  "super admin",
  "r-product",
];
