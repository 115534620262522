import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//bootstrap
import { Button } from "react-bootstrap";
//translation
import { useTranslation } from "react-i18next";
//components
import ShipmentOrderCard from "../../../../../Components/ShipmentComponents/ShipmentOrderCard/ShipmentOrderCard";
//css
import "./ShipmentOrders.css";

const ShipmentOrders = () => {
  const [orders, setOrders] = useState([]);
  const { t } = useTranslation();
  const naviagte = useNavigate();

  //=======================get All AgentShipments=============

  useEffect(() => {
    const getAllAgentShipments = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/shipment/agent",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setOrders([]);
        console.log(body);
      } else {
        setOrders(body);
        console.log(body);
      }
    };
    getAllAgentShipments();
  }, []);
  return (
    <section className="shipment-orders">
      <div className="shipment-orders-title">
        <h6>{t(`Pages.ShipmentProcess.Shipment.ShipmentOrders.Title`)}</h6>
      </div>
      <div className="orders-container">
        {orders.length > 0 ? (
          orders?.map((order, index) => {
            return (
              <ShipmentOrderCard key={index} order={order} id={order._id} />
            );
          })
        ) : (
          <h2>{t(`Pages.ShipmentProcess.Shipment.ShipmentOrders.NoOrder`)}</h2>
        )}
        <Button
          className="primary-btn addOrder"
          onClick={() => naviagte("/shipmentProcess/shipment")}
        >
          {t(`Pages.ShipmentProcess.Shipment.ShipmentOrders.Btn`)}
        </Button>
      </div>
    </section>
  );
};

export default ShipmentOrders;
