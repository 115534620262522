import React from "react";
import { useParams } from "react-router-dom";
//css
import "./OrderRating.css";
//component
import RatingComponent from "../../../../../../../../Components/Rating/RatingComponent";
const OrderRating = () => {
  const params = useParams();
  console.log(params);
  return (
    <section className="order-rating">
      <h1>dwdwd</h1>
      <RatingComponent />
    </section>
  );
};

export default OrderRating;
