import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//bootstrap
import { Button, Form, Row } from "react-bootstrap";
//css
import "./UpdateDeliveryMethod.css";
const UpdateDeliveryMethod = () => {
  const params = useParams();

  const [methodInfo, setmethodInfo] = useState({
    methodId: "",
    methodName: "",
  });
  const navigate = useNavigate();
  //===================================handleMethodName===========================
  // const handleMethodName = (e) => {
  //   const { name, value } = e.target;
  //   setMethodName({ ...methodName, [name]: value });
  // };
  //===========handle Info ===============
  const handleInfo = (e) => {
    const { name, value } = e.target;
    setmethodInfo({ ...methodInfo, [name]: value });
  };
  //================================================getMethodById================================
  const getMethodById = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/delivery-method/${params.Id}`,
      requestOptions
    );
    const body = await response.json();
    setmethodInfo({
      methodId: body._id,
      methodName: body.methodName,
    });
  }, [params.Id]);
  useEffect(() => {
    getMethodById();
  }, [getMethodById]);

  //=====================Update Delivery Method==================
  const updateDeliveryMethod = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      methodName: methodInfo.methodName,
    });
    console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      `https://www.api.setalkel.co/delivery-method/${methodInfo.methodId}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("Delivery Method Not Updated !!!!!");
      console.log(body);
    } else {
      alert("Delivery Method Updated Succesfully");
      navigate("/dashboard/crdeliverymethod");
    }
  };
  //=====================Cancel=======================
  const cancel = () => {
    navigate("/dashboard/crdeliverymethod");
  };
  return (
    <section className="cms-update-delivery-method">
      <Row>
        <div className="crud-title">
          <h1>Delivery Method</h1>
        </div>
        <div className="create-delivery-method">
          <Form>
            {/* <Form.Group>
              <Form.Label>Method English Name</Form.Label>
              <Form.Control
                name="en"
                label="English Name"
                placeholder="Method Name in English"
                required
                value={methodName.en}
                onChange={(e) => handleMethodName(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Method Arabic Name</Form.Label>
              <Form.Control
                name="ar"
                label="Arabic Name"
                placeholder="Method Name in Arabic"
                required
                value={methodName.ar}
                onChange={(e) => handleMethodName(e)}
                // onChange={productNameInputHandle}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Method Frensh Name</Form.Label>
              <Form.Control
                name="fr"
                label="Frensh Name"
                placeholder="Method Name in Frensh"
                required
                value={methodName.fr}
                onChange={(e) => handleMethodName(e)}
                // onChange={productNameInputHandle}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Method Russian Name</Form.Label>
              <Form.Control
                name="ru"
                label="Russian Name"
                placeholder="Method Name in Russian"
                required
                value={methodName.ru}
                onChange={(e) => handleMethodName(e)}
                // onChange={productNameInputHandle}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Method German Name</Form.Label>
              <Form.Control
                name="de"
                label="German Name"
                placeholder="Method Name in German"
                required
                value={methodName.de}
                onChange={(e) => handleMethodName(e)}
                // onChange={productNameInputHandle}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Method Danish Name</Form.Label>
              <Form.Control
                name="dk"
                label="Danish Name"
                placeholder="Method Name in Danish"
                required
                value={methodName.dk}
                onChange={(e) => handleMethodName(e)}
                // onChange={productNameInputHandle}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Method Chinees Name</Form.Label>
              <Form.Control
                name="cn"
                label="Chinees Name"
                placeholder="Method Name in Chinees"
                required
                value={methodName.cn}
                onChange={(e) => handleMethodName(e)}
                // onChange={productNameInputHandle}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Method Persian Name</Form.Label>
              <Form.Control
                name="ir"
                label="Persian Name"
                placeholder="Method Name in Persian"
                required
                value={methodName.ir}
                onChange={(e) => handleMethodName(e)}
                // onChange={productNameInputHandle}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Method Turkish Name</Form.Label>
              <Form.Control
                name="tur"
                label="Turkish Name"
                placeholder="Method Name in Turkish"
                required
                value={methodName.tur}
                onChange={(e) => handleMethodName(e)}
                // onChange={productNameInputHandle}
              />
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Method Name</Form.Label>
              <Form.Check
                type="radio"
                name="methodName"
                label="EXW"
                id="1"
                value={"EXW"}
                onChange={(e) => handleInfo(e)}
              />
              <Form.Check
                type="radio"
                name="methodName"
                label="CIF"
                id="2"
                value={"CIF"}
                onChange={(e) => handleInfo(e)}
              />
            </Form.Group>
            <Button variant="warning" onClick={updateDeliveryMethod}>
              Update Delivery Method
            </Button>
            <Button variant="dark" onClick={cancel}>
              Cancel
            </Button>
          </Form>
        </div>
      </Row>
    </section>
  );
};

export default UpdateDeliveryMethod;
