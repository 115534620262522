import React, { useEffect, useState } from "react";
//bootstrap
import { Button, Form, Row } from "react-bootstrap";
//semantic ui
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
//css
import "./ExportCountryCategoriesAndProducts.css";

const ExportCountryCategoriesAndProducts = () => {

  const [exportCountries, setExportCountries] = useState([]);
  const [exportCountryId, setExportCountryId] = useState("");
  const [choosenExportCountryCategoryId, setchoosenExportCountryCategoryId] =
    useState("");
  const [choosenExportCountryCategories, setChoosenExportCountryCategories] =
    useState([]);
  const [
    PrtoductsFromCategoryAndExportCountry,
    setPrtoductsFromCategoryAndExportCountry,
  ] = useState([]);
  const [choosenCategories, setChoosenCategories] = useState([]);
  const [choosenProducts, setChoosenProducts] = useState([]);
  const [
    choosenCategoryAndProductsToSend,
    setChoosenCategoryAndProductsToSend,
  ] = useState({
    categories: [],
  });

  //===============================handleCheckAll==========================
  const handleCheckAll = (e) => {
    if (e.target.checked) {
      const ProductsChecks = document.querySelectorAll("#productCheck");
      const ProductsChecksArray = Array.from(ProductsChecks);
      ProductsChecksArray.forEach((item) => {
        item.setAttribute("checked", true);
        choosenCategoryAndProductsToSend.categories.forEach((object) => {
          if (object.categoryId === choosenExportCountryCategoryId) {
            object.products = [...object.products, item.value];
          }
        });
      });
    } else {
      const ProductsChecks = document.querySelectorAll("#productCheck");
      const ProductsChecksArray = Array.from(ProductsChecks);
      ProductsChecksArray.forEach((item) => {
        item.removeAttribute("checked");
        choosenCategoryAndProductsToSend.categories.forEach((object) => {
          object.products = [];
        });
      });
    }
  };
  //==================Handle Category Check Change=================
  const handleCategoryCheckChange = (e, category) => {
    //====================Disable & Enable============
    if (e.target.checked) {
      const categoriesChecks = document.querySelectorAll("#categoryCheck");
      const categoriesChecksArray = Array.from(categoriesChecks);
      categoriesChecksArray.forEach((item) => {
        if (item.value !== category._id) {
          item.setAttribute("disabled", true);
        }
      });
      //====================Deal With Category=====================

      setchoosenExportCountryCategoryId(category._id);
      choosenExportCountryCategories.forEach((item) => {
        if (item._id === category._id) {
          if (!choosenCategories.includes(category)) {
            setChoosenCategories([...choosenCategories, category]);
            setChoosenCategoryAndProductsToSend({
              ...choosenCategoryAndProductsToSend,
              categories: [
                ...choosenCategoryAndProductsToSend.categories,
                { categoryId: category._id, products: [] },
              ],
            });
          } else {
            setChoosenCategories([...choosenCategories]);
          }
        }
      });
    } else {
      const categoriesChecks = document.querySelectorAll("#categoryCheck");
      const categoriesChecksArray = Array.from(categoriesChecks);
      categoriesChecksArray.forEach((item) => {
        item.removeAttribute("disabled");
      });
      setChoosenCategories(
        choosenCategories.filter((item) => item._id !== category._id)
      );
      setChoosenProducts(
        choosenProducts.filter((item) => item.cat._id !== category._id)
      );
      setPrtoductsFromCategoryAndExportCountry([]);
      for (
        let i = 0;
        i < choosenCategoryAndProductsToSend.categories.length;
        i++
      ) {
        const element = choosenCategoryAndProductsToSend.categories[i];
        if (element.categoryId === category._id) {
          choosenCategoryAndProductsToSend.categories.pop();
        }
      }
    }
  };
  //==================Handle Products Check Change=================
  const handleProductCheckChange = (e, product) => {
    if (e.target.checked) {
      PrtoductsFromCategoryAndExportCountry.forEach((product) => {
        if (product._id === e.target.value) {
          setChoosenProducts([...choosenProducts, product]);
        }
      });

      //====================Adding Product to his particular object===================
      choosenCategoryAndProductsToSend.categories.forEach((object, index) => {
        if (object.categoryId === product.cat._id) {
          object.products = [...object.products, product._id];
        }
      });
    } else {
      choosenCategoryAndProductsToSend.categories.forEach((object) => {
        for (var i = 0; i < object.products.length; i++) {
          if (object.products[i] === product._id) {
            object.products.splice(i, 1);
          }
        }
      });
    }
  };
  //=======================getAllExportCountries=================================
  useEffect(() => {
    const getAllExportCountries = async () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          "https://www.api.setalkel.co/export-country/",
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          setExportCountries([]);
          console.log(body);
        } else {
          setExportCountries(body);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllExportCountries();
  }, []);
  //====================getExportCountryCategoriesById===========================
  const getExportCountryCategoriesById = async () => {
    try {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/cat/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setChoosenExportCountryCategories([]);
        console.log(body);
      } else {
        setChoosenExportCountryCategories(body);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //=======Get Prtoducts From Category And Country From Export Country============
  useEffect(() => {
    const getPrtoductsFromCategoryAndExportCountry = async () => {
      if (choosenExportCountryCategoryId) {
        var requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const response = await fetch(
          `https://www.api.setalkel.co/product/?cat=${choosenExportCountryCategoryId}`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          setPrtoductsFromCategoryAndExportCountry([]);
          console.log(body);
        } else {
          console.log(body);
          setPrtoductsFromCategoryAndExportCountry(body.data);
        }
      }
    };
    getPrtoductsFromCategoryAndExportCountry();
  }, [choosenExportCountryCategoryId]);
  //======================Add Category To Export Country========================
  const addCategoryToExportCountry = async () => {
  console.log(choosenCategoryAndProductsToSend)
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(choosenCategoryAndProductsToSend);
    console.log(raw);
    if (exportCountryId && choosenCategories.length > 0) {
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/export-country/add/category/${exportCountryId}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok && body.message === "Product category already listed") {
        alert(
          "Please Go To Add Products To Category Page To Add Products To Wanted Category"
        );
        console.log(body);
      } else {
        alert("Categories And Products Have Been Added ....Enjoy");
        // window.location.reload();
      }
    } else {
      alert(
        "Please Choose A Country And Categories To Add The Categories And Products To The Country :)"
      );
    }
  };
  useEffect(() => {
    getExportCountryCategoriesById();
  }, [exportCountryId]);

  return (
    <section className="cms-create-export-country-category-and-products">
      <Row>
        <div className="crud-countries">
          <div className="title">
            <h1>Export Countries Categories And Products</h1>
          </div>
        </div>
        <div className="add-categories-and-products">
          <Form.Label>Please Choose An Export Country</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="export-country"
            value={exportCountryId}
            onChange={(e) => setExportCountryId(e.target.value)}
          >
            <option>Select Export Country</option>
            {exportCountries.map((country, index) => {
              return (
                <option key={index} value={country._id}>
                  {country.countryName.en}
                </option>
              );
            })}
          </Form.Select>
        </div>
      </Row>
      <hr />
      <h2>Choose Categories</h2>
      <Table>
        <TableHeader>
          <TableHeaderCell>ID</TableHeaderCell>
          <TableHeaderCell>Image</TableHeaderCell>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Add Category</TableHeaderCell>
        </TableHeader>
        <TableBody>
          {choosenExportCountryCategories.length > 0
            ? choosenExportCountryCategories?.map((category, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <img
                        src={`https://www.api.setalkel.co${category.img}`}
                        alt="categoryImage"
                        width="70px"
                        height="70px"
                      />
                    </TableCell>
                    <TableCell>{category.en}</TableCell>
                    <TableCell>
                      <Form.Check
                        name="categoryCheck"
                        className="categoryCheck"
                        key={index}
                        label={category.en}
                        type="checkbox"
                        id={"categoryCheck"}
                        value={category._id}
                        onChange={(e) => handleCategoryCheckChange(e, category)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
      <h2>Choose Products</h2>
      <Row>
        <Table>
          <TableHeader>
            <TableHeaderCell>ID</TableHeaderCell>
            <TableHeaderCell>Image</TableHeaderCell>
            <TableHeaderCell>Category</TableHeaderCell>
            <TableHeaderCell>Product Name</TableHeaderCell>
            <TableHeaderCell>Product Usual Weight</TableHeaderCell>
            <TableHeaderCell>Country Origin</TableHeaderCell>
            <TableHeaderCell>Material Property</TableHeaderCell>
            <TableHeaderCell>Package Gross Weight</TableHeaderCell>
            <TableHeaderCell>Stock Off ? </TableHeaderCell>
            <TableHeaderCell>Hidden ? </TableHeaderCell>
            <TableHeaderCell>Is New ? </TableHeaderCell>
            <TableHeaderCell>
              {PrtoductsFromCategoryAndExportCountry.length > 0 ? (
                <Form.Check
                  label={`Check All`}
                  name="checkAll"
                  type="checkbox"
                  id={"productCheckAll"}
                  onChange={(e) => handleCheckAll(e)}
                />
              ) : null}
              <span>Add Product</span>
            </TableHeaderCell>
          </TableHeader>
          <TableBody>
            {PrtoductsFromCategoryAndExportCountry.length > 0
              ? PrtoductsFromCategoryAndExportCountry.map((product, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <img
                          src={`https://www.api.setalkel.co${product.img}`}
                          alt="productImg"
                          width="70px"
                          height="70px"
                        />
                      </TableCell>
                      <TableCell>{product.cat.en}</TableCell>
                      <TableCell>{product.subCat.en}</TableCell>
                      <TableCell>
                        {product.peiceQty.usualWeight
                          ? product.peiceQty.usualWeight
                          : "No Gross Weight For Peice"}
                      </TableCell>

                      <TableCell>
                        {product.countryOrigin[0]
                          ? product.countryOrigin[0]
                          : "No Country Origin"}
                      </TableCell>
                      <TableCell>
                        {product.materialProperty[0]
                          ? product.materialProperty[0]
                          : "No material Property"}
                      </TableCell>
                      <TableCell>
                        {product.packageGrossWeight
                          ? product.packageGrossWeight
                          : "No Package Gross Weight"}
                      </TableCell>
                      <TableCell>{product.stockOff ? "Yes" : "NO"}</TableCell>
                      <TableCell>{product.hidden ? "Yes" : "NO"}</TableCell>
                      <TableCell>{product.is_new ? "Yes" : "NO"}</TableCell>

                      <TableCell>
                        <Form.Check
                          key={product._id}
                          name="productCheck"
                          type="checkbox"
                          id={"productCheck"}
                          value={product._id}
                          onChange={(e) => handleProductCheckChange(e, product)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              : "No Products Yet"}
          </TableBody>
        </Table>
        <hr />
        <Button onClick={addCategoryToExportCountry} variant="warning">
          Add Categories And Products
        </Button>
      </Row>
    </section>
  );
};

export default ExportCountryCategoriesAndProducts;
