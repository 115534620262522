import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
//semantic Ui
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
//css
import "./CROffers.css";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import { useRef } from "react";
const CROffers = () => {
  const [pieceQTYModalShow, setPieceQTYModalShow] = React.useState(false);
  const [productNamesModalShow, setProductNamesModalShow] = useState(false);
  const [packageModalShow, setPackageModalShow] = React.useState(false);
  const [offers, setOffers] = useState([]);
  const [offerId, setOfferId] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [CatFilterID, setCatFilterID] = useState("");
  const [isFree] = useState("false");
  const [offerData, setOfferData] = useState({
    offer: [],
  });
  const fileInputRef = useRef(null);
  const [values, setValues] = useState({
    offerName: {
      en: "",
      ar: "",
      fr: "",
      ru: "",
      de: "",
      dk: "",
      ir: "",
      cn: "",
      tur: "",
    },
    grossweight: "",
    volume: "",
    countInBox: "",
  });
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const [deletedProducts, setDeletedProducts] = useState({
    ids: [],
  });
  const [choosenProduct, setChoosenProduct] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //=================Delete Offer Modal=============================
  const [DeleteModalShow, setDeleteOfferModalShow] = useState(false);
  const handleDeleteOfferModalClose = () => setDeleteOfferModalShow(false);
  const handleDeleteOfferModalShow = () => setDeleteOfferModalShow(true);
  //=========================Delete Multiple Delete Offer=======================

  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);
  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  //=======================handle Names Input======================
  const handleNamesInput = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      offerName: { ...values.offerName, [name]: value },
    });
  };
  //=======================handle Input======================
  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSwitch = (e, productId) => {
    if (e.target.checked) {
      if (!offerData.offer.includes(productId)) {
        setOfferData({
          ...offerData,
          offer: [
            ...offerData.offer,
            { product: productId, free: isFree, count: "1" },
          ],
        });
      } else {
        setOfferData({
          ...offerData,
          offer: offerData.offer.filter((item) => item.product !== productId),
        });
      }
    } else {
      setOfferData({
        ...offerData,
        offer: offerData.offer.filter((item) => item.product !== productId),
      });
    }
  };
  const handleSwitchFree = (e, productID) => {
    if (e.target.checked) {
      offerData.offer.forEach((item) => {
        if (offerData.offer.includes(item)) {
          if (item.product === productID) {
            if (item.free === "false") {
              item.free = "true";
            }
          }
        }
      });
    } else {
      offerData.offer.forEach((item) => {
        if (offerData.offer.includes(item)) {
          if (item.product === productID) {
            if (item.free === "true") {
              item.free = "false";
            }
          }
        }
      });
    }
  };
  const handleCount = (value, productId) => {
    offerData.offer.forEach((item) => {
      if (offerData.offer.includes(item)) {
        if (item.product === productId) {
          item.count = value;
        }
      }
    });
  };
  //=============================fetch Offers=============================

  useEffect(() => {
    const fetchOffers = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/offer",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setOffers([]);
        console.log(body);
      } else {
        setOffers(body);
      }
    };
    fetchOffers();
  }, []);

  //=============================================Fetching Categories========================================

  useEffect(() => {
    const fetchCategories = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/cat/",
        requestOptions
      );
      const body = await response.json();
      // console.log(body);

      if (!response.ok) {
        setCategories([]);
        console.log(body);
      } else {
        setCategories(body);
      }
    };
    fetchCategories();
  }, []);

  //===================================================Filter Products Products==================================================================
  const filterProductsByCategoryID = async () => {
    if (CatFilterID) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/product/?cat=${CatFilterID}`,
        requestOptions
      );
      const body = await response.json();

      if (!response.ok) {
        setCategoryProducts([]);
        console.log(body);
      } else {
        setCategoryProducts(body.data);
      }
    } else {
      var requestOptions2 = {
        method: "GET",
        redirect: "follow",
      };

      const response2 = await fetch(
        `https://www.api.setalkel.co/product`,
        requestOptions2
      );
      const body2 = await response2.json();

      if (!response2.ok) {
        setCategoryProducts([]);
        console.log(body2);
      } else {
        setCategoryProducts(body2.data);
      }
    }
  };
  useEffect(() => {
    filterProductsByCategoryID();
  }, [CatFilterID]);
  //===================================================Posting Image==================================================================
  const PostOfferImg = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();

      fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/offer/image/${id}`,
        requestOptions
      );
      await ImgResponse.json();

      alert("Image Has Been Uploaded");
    } catch (error) {
      console.log(error);
    }
  };
  //====================================POST Offer====================================
  const PostOffer = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = {
      offerName: values.offerName,
      offer: offerData.offer,
      grossWeight: values.grossweight,
      volume: values.volume,
      countInBox: values.countInBox,
    };
    let NewRaw = raw;
    let NewOfferName = raw.offerName;
    Object.keys(NewOfferName).forEach((key) => {
      if (NewOfferName[key] === "") {
        delete NewOfferName[key];
      }
    });
    Object.keys(NewRaw).forEach((key) => {
      if (NewRaw[key] === "") {
        delete NewRaw[key];
      }
    });
    const LastRaw = JSON.stringify(NewRaw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: LastRaw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/offer",
      requestOptions
    );
    const body = await response.json();

    if (!response.ok) {
      alert("There Is An Error !!");
      setOfferData([]);
      setValues([]);
      console.log(body);
    } else {
      if (Imgfile) {
        await PostOfferImg(body._id);
      }
      alert("Offer Has Been Added");
      window.location.reload();
    }
  };
  //==========================Delete Product=====================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/offer/${offerId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          alert("item deleted");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  //============================Delete Mutliple Product=====================

  const deleteProducts = (e, contactID) => {
    if (e.target.checked) {
      if (!deletedProducts.ids.includes(contactID)) {
        setDeletedProducts({
          ...deletedProducts,
          ids: [...deletedProducts.ids, contactID],
        });
      }
    } else {
      setDeletedProducts({
        ids: deletedProducts.ids.filter((item) => item !== contactID),
      });
    }
  };
  const deleteMultipleOffers = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/offer/collection",
      requestOptions
    );

    if (!response.ok) {
      alert("There Is An Error ");
      console.log(response);
    } else {
      alert("Offers Have Been Deleted !!");
      window.location.reload();
    }
  };

  //=============================Modals=============================
  //=============================================================Product Modals===============================================================
  function PieceDetailsModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Product Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <TableHeader>
              <TableHeaderCell>Gross Weight For Peice</TableHeaderCell>
              <TableHeaderCell>Net Weight For Peice</TableHeaderCell>
              <TableHeaderCell>Drained Weight For Peice</TableHeaderCell>
              <TableHeaderCell>Peice Free Offer</TableHeaderCell>
              <TableHeaderCell>Peice Unit</TableHeaderCell>
              <TableHeaderCell>Package For Piece</TableHeaderCell>
              <TableHeaderCell>Usual Weight</TableHeaderCell>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  {choosenProduct
                    ? choosenProduct.peiceQty.grossWeightForPeice
                    : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct
                    ? choosenProduct.peiceQty.netWeightForPeice
                    : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct
                    ? choosenProduct.peiceQty.drainedWeightForPeice
                    : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.peiceQty.freeOffer : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.peiceQty.unit : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct
                    ? choosenProduct.peiceQty.packageForPeice
                    : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.peiceQty.usualWeight : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  function ProductNamesModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Product Names
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <TableHeader>
              <TableHeaderCell>English</TableHeaderCell>
              <TableHeaderCell>Arabic</TableHeaderCell>
              <TableHeaderCell>French</TableHeaderCell>
              <TableHeaderCell>Turkish</TableHeaderCell>
              <TableHeaderCell>Dutch</TableHeaderCell>
              <TableHeaderCell>German</TableHeaderCell>
              <TableHeaderCell>Chineese</TableHeaderCell>
              <TableHeaderCell>Russian</TableHeaderCell>
              <TableHeaderCell>Persian</TableHeaderCell>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.en : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.ar : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.fr : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.tur : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.dk : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.de : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.cn : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.ru : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.ir : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  function PackageInfoModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Package Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <TableHeader>
              <TableHeaderCell>Package Gross Weight</TableHeaderCell>
              <TableHeaderCell>Unit For Package</TableHeaderCell>
              <TableHeaderCell>Volume</TableHeaderCell>
              <TableHeaderCell>Count In Box</TableHeaderCell>
            </TableHeader>

            <TableBody>
              <TableRow>
                <TableCell>
                  {choosenProduct ? choosenProduct.packageGrossWeight : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.unitForPackage : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.volume : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.countInBox : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  //=======================================================================================================
  const OffersColumns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Image",
      selector: (row) => (
        <span>
          <img
            src={`https://www.api.setalkel.co${row.img}`}
            alt="offerImg"
            width="100px"
            height="100px"
          />
        </span>
      ),
    },
    {
      name: "Offer Name",
      selector: (row) => <span>{row.offerName.en}</span>,
    },
    {
      name: "Number Of Products",
      selector: (row) => <span>{row.offer.length}</span>,
    },
    {
      name: "Products Names & Free",
      selector: (row) => (
        <ul className="p-0">
          {row.offer.map((item) => {
            return (
              <li>{`${item.product.subCat.en} ===> ${item.free ? "Yes" : "NO"
                }`}</li>
            );
          })}
        </ul>
      ),
    },
    {
      name: "Gross Weight",
      selector: (row) => <span>{row.grossWeight}</span>,
    },
    {
      name: "Volume",
      selector: (row) => <span>{row.volume}</span>,
    },
    {
      name: "Count In Box",
      selector: (row) => <span>{row.countInBox}</span>,
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/croffers/${row._id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button variant="danger" onClick={() => {
            handleDeleteOfferModalShow()
            setOfferId(row._id)
          }}>
            Delete
          </Button>

          <Modal show={DeleteModalShow} onHide={handleDeleteOfferModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete The Offer
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteOfferModalClose}>
                Close
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                Delete Offer
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
    {
      name: "M-Delete",
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteProducts(e, row._id)}
        />
      ),
    },
  ];
  const columns = [
    {
      name: "Image",
      selector: (row) => (
        <span>
          <img
            src={"https://www.api.setalkel.co" + row.img}
            alt="productImg"
            style={{
              height: "70px",
              width: "70px",
            }}
          />
        </span>
      ),
    },
    { name: "Category", selector: (row) => <span>{row.cat.en}</span> },
    {
      name: "Product Name",
      selector: (row) => (
        <div>
          <Button
            id="productName"
            variant="dark"
            onClick={() => {
              setProductNamesModalShow(true);
              setChoosenProduct(row);
            }}
          >
            Product Names
          </Button>

          <ProductNamesModal
            show={productNamesModalShow}
            onHide={() => setProductNamesModalShow(false)}
          />
        </div>
      ),
    },
    {
      name: "Piece Details",
      selector: (row) => (
        <div>
          <Button
            id="usualWeight"
            variant="dark"
            onClick={() => {
              setPieceQTYModalShow(true);
              setChoosenProduct(row);
            }}
          >
            {row.peiceQty.usualWeight}
          </Button>

          <PieceDetailsModal
            show={pieceQTYModalShow}
            onHide={() => setPieceQTYModalShow(false)}
          />
        </div>
      ),
    },
    {
      name: "Package Details",
      selector: (row) => (
        <div>
          <Button
            variant="dark"
            onClick={() => {
              setPackageModalShow(true);
              setChoosenProduct(row);
            }}
          >
            Package Info
          </Button>

          <PackageInfoModal
            show={packageModalShow}
            onHide={() => setPackageModalShow(false)}
          />
        </div>
      ),
    },
    {
      name: "Country Origin",
      selector: (row) => (
        <Form.Control
          as="textarea"
          disabled
          value={row.countryOrigin ? row.countryOrigin : "No Country Origin"}
        />
      ),
    },
    {
      name: "Material Property",
      selector: (row) => (
        <Form.Control
          as="textarea"
          disabled
          value={
            row.materialProperty ? row.materialProperty : "No Material Property"
          }
        />
      ),
    },
    {
      name: "Supplier Name",
      selector: (row) => (
        <span>{row.supplierName ? row.supplierName : "No Supplier Name"}</span>
      ),
    },
    {
      name: "Stock Off",
      selector: (row) => <span>{row.stockOff ? "Yes" : "No Stock Off"}</span>,
    },
    {
      name: "Hidden ? ",
      selector: (row) => <span>{row.hidden ? "Yes" : "No"}</span>,
    },
    {
      name: "New ?",
      selector: (row) => <span>{row.is_new ? "Yes" : "No"}</span>,
    },
    {
      name: "Add To Offer",
      selector: (row) => (
        <Form.Check
          type="switch"
          name="offer"
          id="offer"
          onChange={(e) => handleSwitch(e, row._id)}
        />
      ),
    },
    {
      name: "Free ? ",
      selector: (row) => (
        <Form.Check
          type="switch"
          name="free"
          id="free"
          onChange={(e) => handleSwitchFree(e, row._id)}
        />
      ),
    },
    {
      name: "Count ? ",
      selector: (row) => (
        <Form.Control
          type="number"
          name="count"
          onKeyUp={(e) => {
            handleCount(e.target.value, row._id);
          }}
          placeholder="How Many"
        />
      ),
    },
  ];
  return (
    <section className="cms-create-offers">
      <Row>
        <div className="crud-title">
          <h2>Offers</h2>
        </div>
      </Row>
      <Row>
        <div className="create-offer">
          <Form>
            <div className="product-langs">
              <Form.Label htmlFor="ProductNameInEn" style={{ color: "red" }}>
                Arabic & English R Required
              </Form.Label>
              <Form.Group>
                <Form.Label htmlFor={values.offerName.en}>
                  Offer Name In English /
                  <span style={{ color: "red" }}>Required</span>
                </Form.Label>
                <Form.Control
                  name="en"
                  label="Offer"
                  value={values.offerName.en}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in english"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor={values.offerName.ar}>
                  Offer Name In Arabic /
                  <span style={{ color: "red" }}>Required</span>
                </Form.Label>

                <Form.Control
                  name="ar"
                  label="Offer"
                  value={values.offerName.ar}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in arabic"
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor={values.offerName.fr}>
                  Offer Name In French
                </Form.Label>

                <Form.Control
                  name="fr"
                  label="Offer"
                  value={values.offerName.fr}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in french"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor={values.offerName.ru}>
                  Offer Name In Russian
                </Form.Label>

                <Form.Control
                  name="ru"
                  label="Offer"
                  value={values.offerName.ru}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in russian"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor={values.offerName.de}>
                  Offer Name In German
                </Form.Label>

                <Form.Control
                  name="de"
                  label="Offer"
                  value={values.offerName.de}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in german"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor={values.offerName.cn}>
                  Offer Name In China
                </Form.Label>

                <Form.Control
                  name="cn"
                  label="Offer"
                  value={values.offerName.cn}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer name in chineese"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor={values.offerName.tur}>
                  Offer Name In Turkish
                </Form.Label>
                <Form.Control
                  name="tur"
                  label="Offer"
                  value={values.offerName.tur}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in turkish"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor={values.offerName.ir}>
                  Offer Name In Persian
                </Form.Label>
                <Form.Control
                  name="ir"
                  label="Offer"
                  value={values.offerName.ir}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in Persian"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor={values.offerName.dk}>
                  Offer Name In Danish
                </Form.Label>
                <Form.Control
                  name="dk"
                  label="Offer"
                  value={values.offerName.dk}
                  onChange={(e) => handleNamesInput(e)}
                  placeholder="Offer Name in Danish"
                />
              </Form.Group>
            </div>
            <Form.Group>
              <Form.Label>Offer Gross Weight</Form.Label>
              <Form.Control
                type="text"
                name="grossweight"
                value={values.grossweight}
                onChange={handleInput}
                placeholder="Gross Weight"
                required
              />
              <Form.Label>Offer Volume</Form.Label>
              <Form.Control
                type="text"
                name="volume"
                value={values.volume}
                onChange={handleInput}
                placeholder="Product Volume"
                required
              />
              <Form.Label>Offer Count In Box</Form.Label>
              <Form.Control
                type="text"
                name="countInBox"
                value={values.countInBox}
                onChange={handleInput}
                placeholder="Product Count In Box"
                required
              />
              <Form.Label htmlFor="ProductImg">Offer Image</Form.Label>
              <div className="d-flex flex-column ">
                <Form.Control
                  type="file"
                  name="image"
                  id="productImg"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                  style={{
                    height: "fit-content"
                  }}
                />
                {Imgfile.Imgfile && (
                  <div className="position-relative" style={{
                    width: "fit-content"
                  }}>
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={URL.createObjectURL(Imgfile.Imgfile)}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setImgfile({
                          Imgfile: null,
                        });
                        if (fileInputRef.current) {
                          fileInputRef.current.value = ""; // إفراغ الحقل باستخدام useRef
                        }
                      }}
                      style={{
                        position: "absolute",
                        top: "-18px",
                        right: "5px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                    >
                      &times;
                    </button>
                  </div>
                )}
              </div>
            </Form.Group>
            <Button
              style={{ margin: "10px" }}
              variant="warning"
              onClick={PostOffer}
            >
              Post Offer
            </Button>
            <Button
              style={{ margin: "10px" }}
              variant="outline-dark"
              onClick={handleShow}
            >
              View Offers
            </Button>
            <Modal fullscreen={true} show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Our Offers
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  {deletedProducts.ids.length > 0 ? (
                    <>
                      <Button
                        id="multipleDelete"
                        variant="outline-danger"
                        onClick={handleMultipleShow}
                      >
                        Multiple Delete
                      </Button>

                      <Modal
                        size="lg"
                        show={MultipleDeleteModalShow}
                        onHide={() => setMultipleDeleteModal(false)}
                        aria-labelledby="example-modal-sizes-title-lg"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-modal-sizes-title-lg">
                            Are You Sure You Want To Delete All Those Offers
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleMultipleClose}
                          >
                            Close
                          </Button>
                          <Button
                            variant="danger"
                            onClick={deleteMultipleOffers}
                          >
                            Delete Offers
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </>
                  ) : null}
                </div>

                <DataTable
                  fixedHeader
                  pagination
                  responsive
                  subHeaderWrap
                  columns={OffersColumns}
                  data={offers}
                  customStyles={customStyles}
                />
              </Modal.Body>
              <Button variant="dark" onClick={handleClose}>
                Close
              </Button>
            </Modal>
          </Form>
          <hr />
          <Form.Group>
            <Form.Label>Filter Category</Form.Label>
            <select
              id="category-list"
              name="catFilterId"
              value={CatFilterID}
              onChange={(e) => setCatFilterID(e.target.value)}
            >
              <option>Please Choose A Category</option>
              {categories.map((category, index) => {
                return (
                  <option key={index} value={category._id}>
                    {category.en}
                  </option>
                );
              })}
            </select>
          </Form.Group>
        </div>
      </Row>
      <Row>
        <DataTable
        fixedHeader
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={categoryProducts}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
};

export default CROffers;
