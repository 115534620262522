import axios from "axios";
import React, { useEffect, useState } from "react";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
//css
import "./CRImportCountryState.css";
import ApiInstances from "../../../../Api/axiso";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { customStyles } from "../../../../constants/DataTableCustomeStyles";

const CRImportCountryState = () => {
  const navigate = useNavigate();
  const [stateNames, setStateNames] = useState({
    en: "", //required
    ar: "", //required
    fr: "",
    ru: "",
    de: "",
    cn: "",
    tur: "",
    ir: "",
    dk: "",
  });

  const [stateId, setStateId] = useState("");
  const [importCountries, setImportCountries] = useState([]);
  const [importStates, setImportStates] = useState([]);
  const [choosenImportCountryId, setchoosenImportCountryId] = useState("");
  //========================================================================================
  const [UpdateStateModalShow, setUpdateStateModalShow] = useState(false);
  const handleUpdateStateModalClose = () => setUpdateStateModalShow(false);
  const handleUpdateStateModalShow = () => setUpdateStateModalShow(true);
  //=================================== Delete States====================================
  const [DeleteStateModalShow, setDeleteStateModalShow] = useState(false);
  const handleDeleteStateClose = () => setDeleteStateModalShow(false);
  const handleDeleteStateShow = () => setDeleteStateModalShow(true);
  //=======================Select Country Handling==========
  const handleCountryChange = (e) => {
    setchoosenImportCountryId(e.target.value);
  };

  //=======================Input State Handeling===============
  const handleStateInputChange = (e) => {
    setStateNames({ ...stateNames, [e.target.name]: e.target.value });
  };

  //================getAllImportCountriesInfoForAdmin===================
  useEffect(() => {
    const getAllImportCountriesInfo = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        "https://www.api.setalkel.co/import-country/admin",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setImportCountries([]);
        console.log(body);
      } else {
        setImportCountries(body);
      }
    };
    getAllImportCountriesInfo();
  }, []);
  const queryClient = useQueryClient()
  const GetImportStatesQuery = (choosenImportCountryId) => {
    const queryResult = useQuery({
      queryKey: ["get-state", choosenImportCountryId],
      queryFn: async () => {
        const { data } = await ApiInstances.get(`https://www.api.setalkel.co/import-country/${choosenImportCountryId}`)
        return data;
      },
      enabled: !!choosenImportCountryId
    });
    return queryResult;
  };
  const { data: ImportStatess } = GetImportStatesQuery(choosenImportCountryId)
  useEffect(() => {
    ImportStatess?.states?.forEach((s) => {
      if (s?._id === stateId) {
        setStateNames({
          en: s.stateName.en ? s.stateName.en : "",
          ar: s.stateName.ar ? s.stateName.ar : "",
          fr: s.stateName.fr ? s.stateName.fr : "",
          ru: s.stateName.ru ? s.stateName.ru : "",
          de: s.stateName.de ? s.stateName.de : "",
          cn: s.stateName.cn ? s.stateName.cn : "",
          ir: s.stateName.ir ? s.stateName.ir : "",
          dk: s.stateName.dk ? s.stateName.dk : "",
          tur: s.stateName.tur ? s.stateName.tur : "",
        });
      }
    })

  }, [importStates, stateId]);
  //======================Add Import State Info===============

  const addImportStateInfo = async () => {
    try {
      if (stateNames.ar && stateNames.en) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );
        myHeaders.append("Content-Type", "application/json");

        let NewStateNames = stateNames;
        Object.keys(NewStateNames).forEach((key) => {
          if (NewStateNames[key] === "") {
            delete NewStateNames[key];
          }
        });
        var raw = JSON.stringify({
          stateName: NewStateNames,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          `https://www.api.setalkel.co/import-country/create/state/${choosenImportCountryId}`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          toast.error("State Not Added !!!!!!!!");
          console.log(body);
        } else {
          toast.success("State Has Been Added");
          queryClient.refetchQueries(["get-state"])
          setStateNames({
            ar:"",
            cn:"",
            de:"",
            dk:"",
            en:"",
            fr:"",
            ir:"",
            ru:"",
            tur:""
          })

        }
      } else {
        toast.error(
          "No State Names Were Entered Please Provide Arabic And English Names At Least :)"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  //===========================Update Import Country State======================
  const updateImportCountryState = async (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = {
      stateName: stateNames,
    };
    let NewStateNames = raw.stateName;
    Object.keys(NewStateNames).forEach((key) => {
      if (NewStateNames[key] === "") {
        delete NewStateNames[key];
      }
    });
    let NewRaw = JSON.stringify(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: NewRaw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/import-country/state?idc=${choosenImportCountryId}&ids=${stateId}`,
      requestOptions
    );

    const body = await response.json();
    if (!response.ok) {
      toast.error("State Not Updated");
      console.log(body);
    } else {
      toast.success("State Has Been Updated");
      handleUpdateStateModalClose();
      let indexItem = importStates.findIndex((e) => {
        return stateId === e._id;
      });
     
      queryClient.refetchQueries(["get-state"])
      setStateNames({
        ar:"",
        cn:"",
        de:"",
        dk:"",
        en:"",
        fr:"",
        ir:"",
        ru:"",
        tur:""
      })
      setStateId("")
    }
  };

  //=================================================Delete Country State=====================================
  const onDelete = (stateId, countryId) => {
    axios
      .delete(
        `https://www.api.setalkel.co/import-country/state?idc=${countryId}&ids=${stateId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("admin-token"),
          },
        }
      )
      .then((response) => {
        if (response.status === 204) {
          toast.success("State Has Been Deleted");
          queryClient.refetchQueries(["get-state"])
          navigate("/dashboard/crimportcountriesstate");
          handleDeleteStateClose()
        } else {
          console.log(response.data.msg);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  const columns = [
    {
      name: "State Name",
      selector: (row) => row.stateName.en,
    },

    {
      name: "Update",
      selector: (row) => (
        <>
          <Button
            variant="dark"
            onClick={() => {
              setStateId(row._id);
            }}
          >
            Update State
          </Button>

        </>
      ),
    },

    {
      name: "Delete",
      selector: (row) => (
        <>
          <Button variant="danger" onClick={handleDeleteStateShow}>
            Delete
          </Button>

          <Modal show={DeleteStateModalShow} onHide={handleDeleteStateClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete The State
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteStateClose}>
                Close
              </Button>
              <Button
                variant="danger"
                onClick={() => onDelete(row._id, choosenImportCountryId)}
              >
                Delete State
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ),
    },
  ];

  return (
    <section>
      <div className="country-langs-import">
        <Form>

          <div>
            <label style={{ marginTop: "30px" }}>Choose Import Country</label>
            <Form.Select
              aria-label="Default select example"
              name="countryName"
              value={choosenImportCountryId}
              onChange={(e) => handleCountryChange(e)}
            >
              <option>Open this select menu</option>
              {importCountries.map((country, index) => {
                return (
                  <option key={index} value={country._id}>
                    {country.countryName.en ? country.countryName.en : "name"}
                  </option>
                );
              })}
            </Form.Select>
          </div>

          <Form.Group className="mrgin">
            <Form.Label htmlFor="StateNameInEn">
              State Name In English /
              <span style={{ color: "red" }}>Required</span>
            </Form.Label>

            <Form.Control
              id="StateNameInEn"
              type="text"
              name="en"
              value={stateNames.en}
              onChange={handleStateInputChange}
              placeholder="State Name In English"
              required
            />
          </Form.Group>

          <Form.Group className="mrgin">
            <Form.Label htmlFor="StateNameInAr">
              State Name In Arabic /
              <span style={{ color: "red" }}>Required</span>
            </Form.Label>

            <Form.Control
              id="StateNameInAr"
              type="text"
              name="ar"
              value={stateNames.ar}
              onChange={handleStateInputChange}
              placeholder="State Name In Arabic"
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="StateNameInFr">
              State Name In French
            </Form.Label>

            <Form.Control
              id="StateNameInFr"
              type="text"
              name="fr"
              value={stateNames.fr}
              onChange={handleStateInputChange}
              placeholder="State Name In French"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="StateNameInRu">
              State Name In Russian
            </Form.Label>

            <Form.Control
              id="StateNameInRu"
              type="text"
              name="ru"
              value={stateNames.ru}
              onChange={handleStateInputChange}
              placeholder="State Name In Russian"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="StateNameInDe">
              State Name In German
            </Form.Label>

            <Form.Control
              id="StateNameInDe"
              type="text"
              name="de"
              value={stateNames.de}
              onChange={handleStateInputChange}
              placeholder="State Name In German"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="StateNameInCn">State Name In China</Form.Label>

            <Form.Control
              id="StateNameInCn"
              type="text"
              name="cn"
              value={stateNames.cn}
              onChange={handleStateInputChange}
              placeholder="State Name In China"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="StateNameInTur">
              State Name In Turkish
            </Form.Label>
            <Form.Control
              id="StateNameInTur"
              type="text"
              name="tur"
              value={stateNames.tur}
              onChange={handleStateInputChange}
              placeholder="State Name In Turkish"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="StateNameInIr">
              State Name In Persian
            </Form.Label>
            <Form.Control
              id="StateNameInIr"
              type="text"
              name="ir"
              value={stateNames.ir}
              onChange={handleStateInputChange}
              placeholder="State Name In Persian"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="StateNameInDk">
              State Name In Danish
            </Form.Label>
            <Form.Control
              id="StateNameInDk"
              type="text"
              name="dk"
              value={stateNames.dk}
              onChange={handleStateInputChange}
              placeholder="State Name In Danish"
            />
          </Form.Group>

          <Button variant="warning" onClick={stateId ? updateImportCountryState  : addImportStateInfo}>
            {stateId ? "  Edit Import Country State" : "  Add Import Country State"}
          </Button>
        </Form>
      </div>

      <hr />
      <Row>
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={ImportStatess?.states}
          customStyles={customStyles}
          noDataComponent={<h5 className="padding">Please Select Import Country</h5>}
        />
      </Row>
    </section>
  );
};

export default CRImportCountryState;
