import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { requestForToken, onMessageListener } from "../../firebase";
import {
  getNotificationsForAdmin,
  // getNotificationsForAgent,
} from "../../redux/notificationSlice";
const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  // const [agentNotifications, setAgentNotifications] = useState([]);
  const [adminNotifications, setAdminNotifications] = useState([]);
  const [userInfo] = useState(JSON.parse(localStorage.getItem("user-info")));
  const dispatch = useDispatch();
  // dispatch(getNotificationsForAgent(agentNotifications));
  dispatch(getNotificationsForAdmin(adminNotifications));
  requestForToken();
  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }
  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  //=====================getNotifications For admin===================

  useEffect(() => {
    const getNotifications = async () => {
      if (localStorage.getItem("admin-token")) {
        var myHeaders = new Headers();

        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          "https://www.api.setalkel.co/notification/admin",
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          setAdminNotifications([]);
        } else {
          setAdminNotifications(body);
        }
      }
    };
    getNotifications();
  }, [userInfo, notification]);

  onMessageListener()
    .then((payload) => {
      console.log(payload);
      setNotification({
        title: payload.notification.body,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  return <Toaster />;
};

export default Notification;
