import axios from "axios";
import React, { useEffect, useState } from "react";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
//semantuc ui
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
//components
import Notification from "../../../Components/Notification/Notification";
import { customStyles } from "../../constants/DataTableCustomeStyles";
const FinishedShipments = () => {
  const [finishedShipments, setFinishedShipments] = useState([]); //final status
  const [shipmentId, setShipmentId] = useState("");
  // const [AgentInfomodalShow, setAgentInfoModalShow] = React.useState(false);
  //=================================== Delete Product====================================
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalShowClose = () => setDeleteModalShow(false);
  const handleDeleteModalShowShow = () => setDeleteModalShow(true);
  const [OrderDetailsModalShow, setOrderDetailsModalShow] =
    React.useState(false);

  const [deletedProducts, setDeletedProducts] = useState({
    ids: [],
  });

  //======================getFinishedShipments=================

  useEffect(() => {
    const getFinishedShipments = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/shipment/?status=finished",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setFinishedShipments([]);
        console.log(body);
      } else {
        setFinishedShipments(body);
      }
    };
    getFinishedShipments();
  }, []);

  //=========================================download File ===============================================
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  //===========================Agent Info Modal=========================
  function AgentInfoModal(props) {
    const AgentInfo = props.shipment.agent;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Agent Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Agent Info</h4>

          <Table>
            <TableHeader>
              <TableHeaderCell>ID</TableHeaderCell>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>Mobile Phone</TableHeaderCell>
              <TableHeaderCell>Phone</TableHeaderCell>
            </TableHeader>
            <TableBody>
              <TableRow key={AgentInfo._id}>
                <TableCell>1</TableCell>
                <TableCell>{AgentInfo.name}</TableCell>
                <TableCell>{AgentInfo.mobilePhone}</TableCell>
                <TableCell>{AgentInfo.phone}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  //==============================Order Details Modal ======================
  function OrderDetailsModal(props) {
    const OrderDetail = props.shipment.order;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <TableHeader>
              <TableHeaderCell>ID</TableHeaderCell>
              <TableHeaderCell>Created At</TableHeaderCell>
              <TableHeaderCell>Items</TableHeaderCell>
              <TableHeaderCell>Order Price</TableHeaderCell>
              <TableHeaderCell>Order Volume</TableHeaderCell>
              <TableHeaderCell>Order Weight</TableHeaderCell>
            </TableHeader>
            <TableBody>
              <TableRow key={OrderDetail._id}>
                <TableCell>1</TableCell>
                <TableCell>{OrderDetail.createdAt}</TableCell>
                <TableCell>
                  {OrderDetail.items.map((item, index) => {
                    return (
                      <ul key={index}>
                        <li>{`Item Name: ${item.product.subCat.en}`}</li>
                        <li>{`Item Price: ${item.price}`}</li>
                        <li>{`Item Count: ${item.count}`}</li>
                        <li>{`Item Volume: ${item.volume}`}</li>
                        <li>{`Item Weight: ${item.weight}`}</li>
                      </ul>
                    );
                  })}
                </TableCell>
                <TableCell>{OrderDetail.orderPrice}</TableCell>
                <TableCell>{OrderDetail.orderVolume}</TableCell>
                <TableCell>{OrderDetail.orderWeight}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  //============================Delete Shippment===============================

  const DeleteShippment = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("admin-token")}`
    );
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/admin/${id}`,
      requestOptions
    );
    if (!response.ok) {
      alert("Shippment Not Deleted !!!");
      console.log(response);
    } else {
      alert("Shippment Deleted !!");
      window.location.reload();
    }
  };
  //============================Delete Mutliple Product=====================

  const deleteShipments = (e, contactID) => {
    if (e.target.checked) {
      if (!deletedProducts.ids.includes(contactID)) {
        setDeletedProducts({
          ...deletedProducts,
          ids: [...deletedProducts.ids, contactID],
        });
        console.log(deletedProducts);
      }
    } else {
      setDeletedProducts({
        ids: deletedProducts.ids.filter((item) => item !== contactID),
      });
    }
  };
  const deleteMultipleShipments = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);
    console.log(raw);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/shipment/admin/collection",
      requestOptions
    );
    if (!response.ok) {
      alert("There Is An Error ");
      console.log(response);
    } else {
      alert("Shipments Have Been Deleted !!");
      window.location.reload();
    }
  };
  //==========================Delete Product==============================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/shipment/admin/${shipmentId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          alert("item deleted");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  //============================================================================================
  const columns = [
    {
      name: "ID",
      selector: (row, index) => console.log(row),
    },
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },

    {
      name: "Date",
      selector: (row) => row.order.createdAt.slice(0, 10),
    },
    {
      name: "Container",
      selector: (row) => row.containerInfo.container.usualName,
    },
    {
      name: "Agent",
      selector: (row) => row.agent.name,
    },
    {
      name: "Export Country",
      selector: (row) => row.exportCountry.countryName.en,
    },
    {
      name: "Import Country",
      selector: (row) =>
        row.importCountry.countryName.en + " / " + row.state.stateName.en,
    },
    {
      name: "import State",
      selector: (row) => <span>{`${row.state.stateName.en}`}</span>,
    },
    {
      name: "Note Per Products",
      selector: (row) => <div style={{
        fontSize: "12px",
        display: "flex",
        flexDirection: "column"
      }}>{row?.order?.items?.map((d) => (
        <>{d.note ? <div>   <span>{d.product.subCat.en}</span> : <span>{d.note}</span></div> :""}</>
      ))}</div>,
      width: "200px"
    },
    {
      name: "Order Details",
      selector: (row) => (
        <span>
          <Button
            variant="warning"
            onClick={() => setOrderDetailsModalShow(true)}
          >
            Order Details
          </Button>

          <OrderDetailsModal
            shipment={row}
            show={OrderDetailsModalShow}
            onHide={() => setOrderDetailsModalShow(false)}
          />
        </span>
      ),
    },
    {
      name: "Admin Files",
      selector: (row) => (
        <span>
          {row.adminFiles.map((file, index) => {
            return (
              <Button
                key={index}
                variant="warning"
                onClick={() => getFile(file)}
              >
                {file.slice(16)}
              </Button>
            );
          })}
        </span>
      ),
    },
    {
      name: "Shipment Files",
      selector: (row) => (
        <ul className="p-0">
          {Object.entries(row.shipmentFiles).map((file) => {
            return (
              <li>
                <Button
                  variant="warning"
                  onClick={() => getFile(file[1])}
                  style={{
                    wordBreak: "break-all",
                  }}
                >
                  {file[0].slice(0, 16)}
                </Button>
              </li>
            );
          })}
        </ul>
      ),
    },

    {
      name: "Delete",
      selector: (row) => (
        <span>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteModalShowShow();
              setShipmentId(row._id);
            }}
          >
            Delete
          </Button>
          <Modal show={DeleteModalShow} onHide={handleDeleteModalShowClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete This Shipment
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteModalShowClose}>
                Close
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                Delete Shipment
              </Button>
            </Modal.Footer>
          </Modal>
        </span>
      ),
    },
    {
      name: "Multiple Delete",
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedShipments"
          id="deletedShipments"
          onChange={(e) => deleteShipments(e, row._id)}
        />
      ),
    },
  ];
  return (
    <section className="finished-shipments">
      <Notification />
      <Row>
        <h2>Finished Shipments</h2>
        {deletedProducts.ids.length > 0 ? (
          <Button
            id="multipleDelete"
            variant="danger"
            onClick={() => deleteMultipleShipments()}
          >
            MultipleDelete
          </Button>
        ) : null}
      </Row>
      <Row>
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={finishedShipments}
          highlightOnHover={true}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
};

export default FinishedShipments;
