import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";

import { customStyles } from "../../constants/DataTableCustomeStyles";

const PaymentAccount = () => {
  const [paymentAccountId, setPaymentAccountId] = useState("");
  const [paymentAccount, setPaymentAccount] = useState([]);
  //===============================Delete Modal======================================
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  //======================Get Swift Account===================

  useEffect(() => {
    const getPaymentAccount = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/account/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setPaymentAccount([]);
        console.log(body);
      } else {
        setPaymentAccount(body);
      }
    };
    getPaymentAccount();
  }, []);
  //====================Add Payment Account===============
  const addPaymentAccount = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      swiftAccount: paymentAccountId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/account/",
      requestOptions
    );
    if (!response.ok) {
      alert("Payment Account Not Added");
      console.log(response);
    } else {
      alert("Payment Account Has Been Added Succssfuly");
      setPaymentAccount([]);
    }
  };
  //==========================Delete Product==============================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/account/${paymentAccountId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          alert("item deleted");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Account",
      selector: (row) => <span>{row.swiftAccount}</span>,
    },
    {
      name: "Delete",
      selector: (row) => (
        <span>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteModalShow();
              setPaymentAccountId(row._id);
            }}
          >
            Delete
          </Button>
          <Modal show={DeleteModalShow} onHide={handleDeleteModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete This Container
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="dark" onClick={handleDeleteModalClose}>
                Close
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </span>
      ),
    },
  ];
  return (
    <section className="payment-account">
      <Row>
        <div className="crud-products">
          <div className="crud-title">
            <h1>Payment Account</h1>
          </div>
        </div>
      </Row>
      <Row>
        <Form>
          <h2>Payment Account ID : </h2>
          <Form.Control
            type="text"
            id="paymentAcc"
            name="paymentAcc"
            placeholder="please Enter Payment Account"
            value={paymentAccountId}
            onChange={(e) => setPaymentAccountId(e.target.value)}
          />
          <Button variant="warning" onClick={addPaymentAccount}>
            Add Account
          </Button>
        </Form>
      </Row>
      <hr />
      <Row>
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={paymentAccount}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
};

export default PaymentAccount;
