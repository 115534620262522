import React from 'react'
import { Button, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Advertisements = () => {
    const navigate = useNavigate()
    return (
        <Row style={{
            background: "white",
            textAlign: "center",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px"
        }}>
            <h1>Advertisements</h1>
            <Row>
                <Button style={{
                    width:"fit-content"
                }} variant="warning" id="verify-btn" onClick={() => navigate("/dashboard/add-advertisements")}>
                    Add Advertisements
                </Button>
            </Row>
        </Row>
    )
}

export default Advertisements