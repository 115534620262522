import React, { useEffect } from "react";
//css
import "./ShipmentProducts.css";
//components
import ShipmentProductCard from "../../../../../Components/ShipmentComponents/ShipmentProductCard/ShipmentProductCard";
import { useTranslation } from "react-i18next";
const ShipmentProducts = ({
  chooosenCategoryProducts,
  categoryName,
  setCategoryName,
}) => {
  useEffect(() => {
    setCategoryName("");
  }, []);

  const { t } = useTranslation();
  return (
    <section className="shipment-products-container">
      {categoryName ? (
        <>
          <div className="shipment-products-category">
            <div className="shipment-products-category-flag">
              <h4>{categoryName}</h4>
            </div>
          </div>
        </>
      ) : null}
      <article className="shipment-products">
        <div className="shipment-products-cards">
          {chooosenCategoryProducts && chooosenCategoryProducts.length > 0 ? (
            chooosenCategoryProducts?.map((product, index) => {
              return <ShipmentProductCard key={index} product={product} />;
            })
          ) : (
            <h2>
              {t(
                `Pages.ShipmentProcess.Shipment.ShipmentProducts.WelcomeMessage`
              )}
            </h2>
          )}
        </div>
      </article>
    </section>
  );
};

export default ShipmentProducts;
