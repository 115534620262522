import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//boostrap
import { FaRegEye, FaEyeSlash, FaDownload, FaTrashAlt } from "react-icons/fa";
import { Button, Form, Row, InputGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { AiOutlineFilePdf, AiOutlineFileWord, AiOutlineFileExcel } from "react-icons/ai"; // Import file icons
import "./UpdateAgents.css"
import { decryptData } from "../../../Api/dercrypt";
import { useQueryClient } from "@tanstack/react-query";
const UpdateAgents = () => {

  const [showPassword, setShowPassword] = useState(false);
  const params = useParams();
  let navigate = useNavigate();
  const [AgentInfo, setAgentInfo] = useState({
    _id: "",
    name: "",
    email: "",
    mobilePhone: "",
    phone: "",
    country: "",
    address: "",
    companyNameVerification: "",
    port: "",
    companyName: "",
    password: "",
    contactEmails: [],
  });
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  //=======================Input Handeling===============
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAgentInfo({
      ...AgentInfo,
      [name]: value,
    });
  };
  const renderFileIcon = (file) => {

    if (file.includes("png") || file.includes("jpeg") || file.includes("jpg")) {
      return <img src={URL.createObjectURL(file)} alt={file.name} style={{ width: "100px", height: "100px" }} />;
    } else if (file.includes("pdf")) {
      return <AiOutlineFilePdf size={50} color="red" />;
    } else if (file.includes("doc")) {
      return <AiOutlineFileWord size={50} color="blue" />;
    } else if (file.includes("xl")) {
      return <AiOutlineFileExcel size={50} color="green" />;
    } else {
      return <span className="position-relative">{file.name}</span>;
    }
  };
  //===================get Agent By Id==========================
  const getAgentById = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    const response = await fetch(
      `https://www.api.setalkel.co/agent/admin/${params.Id}`,
      requestOptions
    );
    const body = await response.json();
    // console.log(body);
    setImgfile({ Imgfile: body.img })
    setFiles(body.files)
    setAgentInfo({
      _id: body._id,
      name: body.name,
      email: body.email,
      mobilePhone: body.mobilePhone === "undefined" ? "" : body.mobilePhone,
      phone: body.phone === "undefined" ? "" : body.phone,
      country: body.country === "undefined" ? "" : body.country,
      address: body.address === "undefined" ? "" : body.address,
      port: body.port === "undefined" ? "" : body.port,
      password: body.password ? decryptData(body.password) : "",
      companyName: body.companyName === "undefined" ? "" : body.companyName,
      companyNameVerification: body.companyNameVerification === "undefined" ? "" : body.companyNameVerification,
      contactEmails:
        body.contactEmails === "undefined" ? "" : body.contactEmails,
    });
    //  body.files.length > 0 && setfileGallery((file) => [...file, body.files]);
  }, [params.Id]);
  useEffect(() => {
    getAgentById();
  }, [getAgentById]);
  const [files, setFiles] = useState([])
  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  //===================================================Posting Agents Images==================================================================
  const UpdateAgentImg = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/agent/image-from-admin/${id}`,
        requestOptions
      );
      const body = await ImgResponse.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  const [fileGallery, setfileGallery] = useState([]);
  //===================Handle Contact email=============================
  const handlefilesChange = (e) => {
    setfileGallery((file) => [...file, e.target.files]);
  };
  //============================================================Update Agent=============================
  const queryClient = useQueryClient()
  const UpdateAgent = async () => {
    const formData = new FormData()
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    // formData.append("id", AgentInfo._id)
    formData.append("name", AgentInfo.name)
    formData.append("email", AgentInfo.email)
    formData.append("mobilePhone", AgentInfo.mobilePhone)
    formData.append("phone", AgentInfo.phone)
    formData.append("country", AgentInfo.country)
    formData.append("address", AgentInfo.address)
    formData.append("port", AgentInfo.port)
    formData.append("companyName", AgentInfo.companyName)
    formData.append("password", AgentInfo.password)
    // AgentInfo.contactEmails.length > 0 && formData.append("contactEmails", JSON.stringify(AgentInfo.contactEmails))
    // formData.append("companyNameVerification", AgentInfo.companyNameVerification)
    console.log(formData)
    var raw = {
      name: AgentInfo.name,
      email: AgentInfo.email,
      mobilePhone: AgentInfo.mobilePhone,
      phone: AgentInfo.phone,
      country: AgentInfo.country,
      address: AgentInfo.address,
      port: AgentInfo.port,
      companyName: AgentInfo.companyName,
      password: AgentInfo.password,
      contactEmails: AgentInfo.contactEmails,
      companyNameVerification: AgentInfo.companyNameVerification
    };
    let NewRaw = raw;
    Object.keys(NewRaw).forEach((key) => {
      if (NewRaw[key] === "" || NewRaw[key] === "undefined") {
        delete NewRaw[key];
      }
    });
    var raw2 = JSON.stringify(NewRaw);
    console.log(raw2);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw2,
      redirect: "follow",
    };
    const response = await fetch(
      `https://www.api.setalkel.co/agent/admin/update-info/${AgentInfo._id}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      toast.error(body.message);
      console.log(body);
    } else {
      if (Imgfile.Imgfile) {
        await UpdateAgentImg(body._id);
        toast.success("Image Uploaded");
      }
      toast.success("Agent Updated");
      queryClient.refetchQueries(["get-agent"])
      queryClient.refetchQueries(["get-agent-not"])
      navigate(-1);
    }
  };
  const Cancel = () => {
    navigate(-1);
  };
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <section className="cms-update-agents">
      <Row>
        <div className="crud-title">
          <h2>Update Agents</h2>
        </div>
      </Row>
      <Row>
        <div className="create-agent">
          <Form>
            <Form.Group>
              <Form.Label>Agent Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={AgentInfo.name}
                onChange={handleInputChange}
                placeholder="Agent Name"
              />
              <Form.Label>Agent Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={AgentInfo.email}
                onChange={handleInputChange}
                placeholder="Agent Email"
              />

              <Form.Label>Agent Password</Form.Label>
              <div className="password">
                <Form.Control
                  type={`${!showPassword ? "password" : "text"}`}
                  name="password"
                  value={AgentInfo.password}
                  onChange={handleInputChange}
                  placeholder="Agent Password"
                />
                <span onClick={() => toggleShowPassword()}>{!showPassword ? <FaRegEye /> : <FaEyeSlash />}</span>
              </div>
              <Form.Label>Agent Mobile Phone</Form.Label>
              <Form.Control
                type="text"
                name="mobilePhone"
                value={AgentInfo.mobilePhone}
                onChange={handleInputChange}
                placeholder="Agent Mobile Phone"
              />
              <Form.Label>Agent Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={AgentInfo.phone}
                onChange={handleInputChange}
                placeholder="Agent Phone"
              />
              <Form.Label>Agent Country</Form.Label>
              <Form.Control
                type="text"
                name="country"
                value={AgentInfo.country}
                onChange={handleInputChange}
                placeholder="Agent Country"
              />
              <Form.Label>Agent Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={AgentInfo.address}
                onChange={handleInputChange}
                placeholder="Agent Address"
              />
              <Form.Label>Agent Port</Form.Label>
              <Form.Control
                type="text"
                name="port"
                value={AgentInfo.port}
                onChange={handleInputChange}
                placeholder="Agent Port"
              />
              <Form.Label>Agent Company Name</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                value={AgentInfo.companyName}
                onChange={handleInputChange}
                placeholder="Agent Company Name"
              />
              <div>
                <Form.Label>Agent Company Name Verification</Form.Label>
                <Form.Control
                  type="text"
                  name="companyNameVerification"
                  value={AgentInfo.companyNameVerification}
                  onChange={handleInputChange}
                  placeholder="Agent Company Name Verification"
                />
              </div>
              {/* <Form.Label>Agent Contact Emails</Form.Label>
              <Form.Control
                type="text"
                name="contactEmails"
                value={AgentInfo.contactEmails}
                onChange={handleInputChange}
                placeholder="Agent Contact Emails"
              /> */}
              <Form.Label htmlFor="ProductImg">Agent Image</Form.Label>
              <div className="d-flex flex-column">
                <Form.Control
                  type="file"
                  name="image"
                  id="productImg"
                  onChange={handleImageChange}
                />
                {Imgfile.Imgfile && <div className="position-relative" style={{
                  width: "fit-content"
                }}>
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                    }}
                    src={"https://www.api.setalkel.co" + Imgfile.Imgfile}
                  />
                  <FaDownload onClick={() => getFile(Imgfile.Imgfile)} style={{
                    color: "red",
                    fontSize: "15px",
                    position: "absolute",
                    top: "0",
                    right: "0"

                  }} />
                  <FaTrashAlt style={{
                    color: "red",
                    fontSize: "15px",
                    position: "absolute",
                    top: "0",
                    right: "20px",
                    zIndex: "100"

                  }} />
                </div>
                }
              </div>
              <Form.Group>
                <Form.Label htmlFor="productImg">
                  Files of Agent
                </Form.Label>
                <Form.Control
                  type="file"
                  name="images"
                  multiple
                  id="productImg"
                  onChange={handlefilesChange}
                />
                <div className="uploaded-files-list  mt-2 d-flex gap-2">
                  {files.map((file, index) => (
                    <div key={index} className="uploaded-file-item position-relative">
                      <div>{renderFileIcon(file)}</div>
                      <Button
                        variant="danger"
                        size="sm"
                        // ref={filesRef}
                        onClick={() => getFile(file)}
                        style={{ background: "none", border: "none", position: "absolute", top: "-5px", left: "26px", cursor: "pointer" }}
                      >
                        <FaDownload color="red" />

                      </Button>

                    </div>
                  ))}
                </div>
              </Form.Group>
              <Button variant="warning" onClick={UpdateAgent}>
                Update Agent
              </Button>
              <Button variant="outline-dark" onClick={Cancel}>
                cancel
              </Button>
            </Form.Group>
          </Form>
        </div>
      </Row>
    </section>
  );
};

export default UpdateAgents;
