import React from "react";
//css
import "./Shipment.css";
//components
import ShipmentStepper from "../../../../Components/ShipmentComponents/ShipmentStepper/ShipmentStepper";
const Shipment = () => {
  return (
    <section className="shipment">
      <div className="shipment-stepper">
        <ShipmentStepper />
      </div>
    </section>
  );
};

export default Shipment;
