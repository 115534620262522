import React, { useEffect, useState } from "react";
//bootstrap
import { Button, Form, Modal } from "react-bootstrap";
//translation
import { useTranslation } from "react-i18next";
//css
import "./ShipmentPopUp.css";

const ShipmentPopUp = ({ userInfo }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [agentTerms, setAgentTerms] = useState([]);
  const [docLang, setDocLang] = useState(document.querySelector("html").lang);
  const { t } = useTranslation();
  useEffect(() => {
    setDocLang(document.querySelector("html").lang);
  }, [t]);
  //======================get Payment Terms ==================
  const getPaymentTerms = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/payment-term/${userInfo._id}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      setAgentTerms([]);
      console.log(response);
    } else {
      setAgentTerms(body);
    }
  };
  function ShipmentMessagePopUp(props) {
    return (
      <Modal {...props} centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t(`Components.ShipmentComponents.ShipmentPopUp.Title`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="AgentVerificationPopUp-body">
          <Form.Control
            as="textarea"
            name="payment-terms"
            value={
              agentTerms.paymentTerm ? (
                agentTerms.paymentTerm[docLang] ? (
                  agentTerms.paymentTerm[docLang]
                ) : (
                  agentTerms.paymentTerm.en
                )
              ) : (
                <p>
                  {t(`Components.ShipmentComponents.ShipmentPopUp.Loading`)}
                </p>
              )
            }
            disabled
            rows={20}
            style={{ background: "white" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="primary-btn" onClick={props.onHide}>
            {t(`Components.ShipmentComponents.ShipmentPopUp.CloseBtn`)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  useEffect(() => {
    getPaymentTerms();
    setModalShow(true);
  }, []);
  return (
    <ShipmentMessagePopUp show={modalShow} onHide={() => setModalShow(false)} />
  );
};

export default ShipmentPopUp;
