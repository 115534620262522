import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { customStyles } from "../../../constants/DataTableCustomeStyles";
import moment from "moment"
import "./style.css"
//images
import CryptoJS from 'crypto-js';
import { FaRegEye, FaEyeSlash } from "react-icons/fa";
import greenDot from "../../../../assets/greendot.png";
import redDot from "../../../../assets/reddot.png";
import axios from "axios";
import { toast } from "react-hot-toast";
import { decryptData } from "../../../Api/dercrypt";
import UnVerifiedAgentsApi from "../../../Api/UnVerifiedAgentsApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ApiInstances from "../../../Api/axiso";
// import { decryptData } from "../../../Api/dercrypt";
const VerifiedAgents = ({ online }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [onlineAgent, setOnlineAgent] = useState({});
  const [agents, setAgents] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [isVerifiedAgentId, setIsVerifiedAgentId] = useState("");
  const [isVerifiedAgentPrice, setIsVerifiedAgentPrice] = useState([]);


  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [verifyAgentInfo, setVerifyAgentInfo] = useState({});
  const [showAgentOnWebsite] = useState([]);
  const [AgentFilesModalShow, setAgentFilesModalShow] = useState(false);

  //================================Delete Agents Modal States===========================
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);
  //================================Delete Agent Modal States===========================
  const [DeleteModalShow, setDeleteModal] = useState(false);
  const handleClose = () => setDeleteModal(false);
  const handleShow = () => setDeleteModal(true);
  const [deletedProducts, setDeletedProducts] = useState({
    ids: [],
  });
  //=============================Delete Files========================
  const [DeleteVerifyFilesModalShow, setDeleteVerifyFilesModalShow] =
    useState(false);
  const handleDeleteVerifyModalClose = () =>
    setDeleteVerifyFilesModalShow(false);
  const handleDeleteVerifyModalShow = () => setDeleteVerifyFilesModalShow(true);
  //=======================handleVerification======================
  const handleVerification = (e, Id) => {
    setVerifyAgentInfo({
      agentId: Id,
      isVerified: e.target.value,
    });
  };
  const handleVerificationPrice = (e, Id, def) => {
    const selectedPrice = {
      agentId: Id,
      priceId: e.target.value,
    };
    // console.log(selectedPrice)
    mutate(selectedPrice)
    // setIsVerifiedAgentPrice((prevPrices) => {
    //   const existingPrice = prevPrices.find(price => price.agentId === Id);
    //   if (existingPrice) {
    //     return prevPrices.map(price =>
    //       price.agentId === Id ? { ...price, priceId: e.target.value } : price
    //     );
    //   } else {
    //     return [...prevPrices, selectedPrice];
    //   }
    // });
  };
  //=======================handleShowHideOnWebsite======================
  const handleShowHideOnWebsite = (e, Id) => {
    if (e.target.value) {
      if (showAgentOnWebsite.length > 0) {
        for (let i = 0; i < showAgentOnWebsite.length; i++) {
          if (showAgentOnWebsite[i].agentId === Id) {
            showAgentOnWebsite[i].hidden = e.target.value;
            return;
          }
        }
        showAgentOnWebsite.push({
          agentId: Id,
          hidden: e.target.value,
        });
      } else {
        showAgentOnWebsite.push({
          agentId: Id,
          hidden: e.target.value,
        });
      }
    }
  };
  //==========================Fetch Agent===================
  const [Prices, setPrices] = useState()
  const queryClient = useQueryClient()
  const { data, refetch } = useQuery({
    queryKey: ["get-agent"],
    queryFn: async () => {
      const { data } = await ApiInstances.get(`/agent/admin/?isVerified=true`)
      return data;
    },

  });
  useEffect(() => {
    const fetchAgents = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: myHeaders,
      };

      const response = await fetch(
        "https://www.api.setalkel.co/agent/admin/?isVerified=true",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        toast.error("There is an Error");
        setAgents([]);
        // console.log(body);
      } else {
        setAgents(body);
        // console.log(agents[0].password)
        // console.log(decryptedData);
      }
    };
    fetchAgents();
  }, []);
  //============================Detect IF Agent Online Or Not=====================
  // const crypto = require(CryptoJS);


  //============================Detect IF Agent Verified Or Not=====================
  const verifyAgents = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(verifyAgentInfo);
    // console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/agent/verification/",
      requestOptions
    );
    if (!response.ok) {
      toast.error("Agent Not Verified");
    } else {
      toast.success("Agent State Has Been Changed Successfuly");

    }
  };
  //=================================Show Hide Agent ==================
  const showHideAgents = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(showAgentOnWebsite);
    // console.log(raw);
    if (showAgentOnWebsite.length > 0) {
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/agent/show-hidden",
        requestOptions
      );
      if (!response.ok) {
        toast.error("Agent/s State Not Updated !!!!");
      } else {
        toast.success("Agent/s State Has Been Updated");
      }
    } else {
      toast.error("Please Choose 1 Agent At least to change his status");
    }
  };
  //==========================Delete Product=====================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/agent/${agentId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          // console.log(response.data.msg);
        } else {
          toast.success("item deleted");
          queryClient.refetchQueries(["get-agent"])
          handleDeleteVerifyModalClose()
        }
      })
      .catch((error) => {
        toast.error(error.response)
        // console.log("error", error.response);
      });
  };
  const [selectedPrices, setSelectedPrices] = useState([]);
  //============================Delete Mutliple Product=====================

  const { mutate, isPending } = useMutation({
    mutationFn: async (res) => {
      const { data } = await ApiInstances.post(`/agent/verifiy-price`, res);
      return data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
      // console.log(data)
      const inputFields = document.querySelectorAll("#select-price");

      // inputFields.forEach((field) => {
      //   field.value = "";
      // });


    },
  });
  const verifyPrices = async () => {
    // // console.log(isVerifiedAgentPrice)
    // if (isVerifiedAgentPrice.length === 0) {
    //   toast.error("Please Select Price...");
    // } else {
    //   mutate(isVerifiedAgentPrice)
    // }


  };
  const deleteProducts = (e, productId) => {
    if (e.target.checked) {
      if (!deletedProducts.ids.includes(productId)) {
        setDeletedProducts({
          ...deletedProducts,
          ids: [...deletedProducts.ids, productId],
        });
      }
    } else {
      setDeletedProducts({
        ids: deletedProducts.ids.filter((item) => item !== productId),
      });
    }
  };
  const deleteMultipleِAgents = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/agent/collection",
      requestOptions
    );
    if (!response.ok) {
      toast.error("There Is An Error ")
    } else {
      toast.success("Agents Have Been Deleted !!");
      setDeleteModal(false)
    }
  };
  //=====================================================Delete All Verification Agent Files===============================================
  const deleteAllVerificationFiles = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/agent/verification/${agentId}`,
      requestOptions
    );
    if (!response.ok) {
      toast.error("files Not Deleted !!!");
      // console.log(response);
    } else {
      toast.success("Agent Verification Files Has Been Deleted Successfully");
      setMultipleDeleteModal(false)
    }
  };
  //==========================Get Agent Verification Files===================

  useEffect(() => {
    const getVerifyAgentFiles = async () => {
      if (isVerifiedAgentId) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const response = await fetch(
          `https://www.api.setalkel.co/agent/verification/${isVerifiedAgentId}`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          setFiles([]);
          setIsLoading(true);
          // console.log(body);
        } else {
          setFiles(body.files);
          setImage(body.img);
          setIsLoading(false);
        }
      }
    };
    getVerifyAgentFiles();
  }, [isVerifiedAgentId]);
  useEffect(() => {
    const getPrice = async () => {

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `https://www.api.setalkel.co/agent/productPrice`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        // setFiles([]);
        // setIsLoading(true);

      } else {
        console.log(body);
        // setFiles(body.files);
        // setImage(body.img);
        // setIsLoading(false);
      }

    };
    getPrice();
  }, []);
  //=========================================download File ===============================================
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };

  //=============================================Agent Files Modal========================================
  const AgentFilesModal = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Agent Files :
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>This Is The Agent Verification Files</h4>
          <div className="d-flex align-items-center gap-2">
            <div>
              <h6>Agent Image</h6>

              <img
                src={`https://www.api.setalkel.co${image}`}
                alt="agentImage"
                width={100}
                height={100}
                onClick={() => getFile(image)}
              />

            </div>
            {files.length > 0
              ? files?.map((file, index) => {
                return (
                  <ul key={index}>
                    <li>
                      <Button variant="warning" onClick={() => getFile(file)}>
                        {file}
                      </Button>
                    </li>

                  </ul>
                );
              })
              : "No Files To Show / لا يوجد ملفات لعرضها"}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const [showPassword, setShowPassword] = useState({
    ids: []
  });

  const handleTogglePassword = (id) => {
    const updatedIds = showPassword.ids.includes(id)
      ? showPassword.ids.filter((itemId) => itemId !== id)
      : [...showPassword.ids, id];

    setShowPassword({ ids: updatedIds });
  };
  const fetchPrice = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    const response = await fetch(
      `https://www.api.setalkel.co/prices`,
      requestOptions
    );
    const body = await response.json();
    // console.log(body);
    if (!response.ok) {

      console.log(body);
    } else {
      setPrices(body?.prices)
      // console.log(body)
    }

  };
  useEffect(() => {
    fetchPrice()
  }, [])

  //========================================================================================================
  const [image, setImage] = useState("")
  const columns = [
    {
      name: "Image",
      selector: (row) => (
        <span>
          <img
            src={`https://www.api.setalkel.co${row.img}`}
            alt="agentImage"
            width={100}
            height={100}
          />
        </span>
      ),

    },
    {
      name: "Agent Name",
      selector: (row) => <span>{row.name}</span>,

    },
    {
      name: "Agent Email",
      selector: (row) => <span>{row.email}</span>,

    },
    {
      name: " Agent Password",
      selector: (row) => <div onClick={() => handleTogglePassword(row._id)}>
        {!showPassword.ids.includes(row._id) ? (
          <div className="d-flex gap-2 align-items-center">
            <span>********</span>
            <FaRegEye className="icon" />
          </div>
        ) : (
          <div className="d-flex gap-2 align-items-center">
            <span>{decryptData(row.password)}</span>
            <FaEyeSlash className="icon" />
          </div>
        )}
      </div>

    },
    {
      name: "Created At :",
      selector: (row) => <span>{moment(row.updatedAt).format('YYYY MMM DDD, h:mm:ss a')}</span>,

    },
    {
      name: "Shown ?",
      selector: (row) => <span className="">{row.hidden ? "No" : "Yes"}</span>,

    },
    {
      name: "Agent Phone",
      selector: (row) => (
        <span>
          {row.phone ? row.phone : "No Agent Phone / لا يوجد رقم لهذا الوكيل"}
        </span>
      ),


    },
    {
      name: " Agent Mobile Phone",
      selector: (row) => (
        <span>
          {row.mobilePhone
            ? row.mobilePhone
            : "No Agent Phone / لا يوجد رقم لهذا الوكيل"}
        </span>
      ),
    },
    {
      name: "Agent  Port",
      selector: (row) => (
        <span>
          {row.port ? row.port : "No Agent Port / لا يوجد مرفأ لهذا الوكيل"}
        </span>
      ),
    },
    {
      name: "Agent  Company Name",
      selector: (row) => (
        <span>
          {row.companyName
            ? row.companyName
            : "No Agent Company Name : لا يوجد اسم شركة لهذا الوكيل "}
        </span>
      ),
    },
    {
      name: "Agent  Address",
      selector: (row) => (
        <span>
          {row.address
            ? row.address
            : "No Agent Address / لا يوجد عنوان لهذا الوكيل"}
        </span>
      ),
    },
    {
      name: "Agent Country",
      selector: (row) => (
        <span>
          {row.country
            ? row.country
            : "No Agent Country / لا يوجد بلد لهذا الوكيل"}
        </span>
      ),
    },
    {
      name: "Price of Agent",
      selector: (row) => (
        <span>
          {row.prices ? <>{Prices?.find((d) => d._id === row.prices)?.name}</> : "لايوجد أسعار مرتبطة"}
        </span>
      ),
    },
    {
      name: "Agent Contact Emails",
      selector: (row) => (
        <span>
          {row.contactEmails
            ? row.contactEmails
            : "لا يوجد بريد الكتروني بديل لهذا الوكيل"}
        </span>
      ),
    },
    {
      name: "Show Files of Agent",
      selector: (row) => (
        <div>
          <Button
            variant="warning"
            onClick={() => {
              setAgentFilesModalShow(true);
              setIsVerifiedAgentId(row._id);
              // setImage(row.img)
            }}
          >
            Show Files
          </Button>

        </div>
      ),
    },
    {
      name: "Delete  Files of Agent",
      selector: (row) => (
        <span>
          {row.files.length > 0 && (
            <>
              <Button variant="danger" onClick={handleDeleteVerifyModalShow}>
                Delete Files
              </Button>
              <Modal
                show={DeleteVerifyFilesModalShow}
                onHide={handleDeleteVerifyModalClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    Are You Sure You Want To Delete Verification Files
                  </Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleDeleteVerifyModalClose}
                  >
                    Close
                  </Button>
                  <Button variant="danger" onClick={deleteAllVerificationFiles}>
                    Delete Files
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </span>
      ),
    },
    {
      name: "Verify/Unverify Agent",
      selector: (row) => (
        <Form.Select onChange={(e) => handleVerification(e, row._id)}>
          <option>Choose State</option>
          <option value="true">Verify</option>
          <option value="false">UnVerify</option>
        </Form.Select>
      ),
    },
    {
      name: "Show/Hide Agent",
      selector: (row) => (
        <Form.Select onChange={(e) => handleShowHideOnWebsite(e, row._id)}>
          <option>Choose State</option>
          <option value="false">Show</option>
          <option value="true">Hide</option>
        </Form.Select>
      ),
    },

    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/cragents/${row._id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleShow();
              setAgentId(row._id);
            }}
          >
            Delete
          </Button>

          <Modal
            size="lg"
            show={DeleteModalShow}
            onHide={() => setDeleteModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Are You Sure You Want To Delete This Agent
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                Delete Agent
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
    {
      name: "M-Delete",
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteProducts(e, row._id)}
        />
      ),
    },
  ];
  const [generateExcelFile, setGenerateExecelFile] = useState()
  const { data: Excel } = UnVerifiedAgentsApi.GenerateExelFileQuery()
  const getFileExcel = async () => {
    UnVerifiedAgentsApi.GetFileExcel(generateExcelFile)
    setGenerateExecelFile(undefined)
  };
  return (
    <section>
      <AgentFilesModal
        show={AgentFilesModalShow}
        onHide={() => setAgentFilesModalShow(false)}
      />
      <Row>
        <div className="crud-title">
          <h2>Verified Agents</h2>
        </div>
      </Row>
      <Row>
        <Form className="form-agent">
          {!generateExcelFile ? <Button variant="warning" id="verify-btn" onClick={() => setGenerateExecelFile(Excel)}>
            Export Excel File for UnVerifiedAgent
          </Button> : <Button variant="success" id="verify-btn" onClick={getFileExcel}>
            Download Excel File
          </Button>}
          <Button variant="danger" id="verify-btn" >
            Import Excel
          </Button>
          {verifyAgentInfo && (
            <Button variant="warning" id="verify-btn" onClick={verifyAgents}>
              Verify Agent/s
            </Button>
          )}
          <Button variant="warning" id="verify-btn" onClick={showHideAgents}>
            Show Hide Agent
          </Button>
          {/* <Button disabled={isPending} variant="warning" id="verify-btn" onClick={verifyPrices}>
            Verify Price
          </Button> */}

        </Form>
        <DataTable
          fixedHeader
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={data}
          customStyles={customStyles}
        />
        {deletedProducts.ids.length > 0 ? (
          <>
            <Button
              id="multipleDelete"
              variant="outline-danger"
              onClick={() => handleMultipleShow()}
            >
              Multiple Delete
            </Button>

            <Modal
              size="lg"
              show={MultipleDeleteModalShow}
              onHide={() => setMultipleDeleteModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Are You Sure You Want To Delete All Those Agents
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setMultipleDeleteModal(false)}>
                  Close
                </Button>
                <Button variant="danger" onClick={deleteMultipleِAgents}>
                  Delete Agents
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : null}
      </Row>
    </section>
  );
};

export default VerifiedAgents;
