import axios from "axios";


const ApiInstances = axios.create({
    baseURL: "https://www.api.setalkel.co",
});

ApiInstances.interceptors.request.use((config) => {
    const token = localStorage.getItem("admin-token");
    config.headers.Authorization = token ? ` Bearer ${token} ` : "";
    return config;
});
export default ApiInstances