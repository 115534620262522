import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  shipmentItems: localStorage.getItem("shipmentItems")
    ? JSON.parse(localStorage.getItem("shipmentItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  cartTotalWeight: 0,
  cartTotalVolume: 0,
  // overload: false,
  isEditing: false,
  isVerified: false,
  shipmentInRange: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    checkIsVerified(state, action) {
      state.isVerified = action.payload;
    },
    //==================================================================================================================================
    // checkOverload(state, action) {
    //   state.overload = action.payload;
    // },
    //==================================================================================================================================

    checkIsEditing(state, action) {
      state.isEditing = action.payload;
    },

    //==================================================================================================================================

    shipmentInRange(state, action) {
      state.shipmentInRange = action.payload;
    },

    //==================================================================================================================================

    UpdateSuggestion(state, action) {
      state.cartItems = action.payload;
    },
    //==================================================================================================================================

    UpdateSuggestionShipment(state, action) {
      state.shipmentItems = action.payload;
    },
    //==================================================================================================================================
    //=====================================Add Amount To Cart=======================================================
    addAmountToCart(state, action) {
      // console.log(action.payload);
      //=====================Add To Cart Items================================
      const amount = parseInt(action.payload.amount);
      const existingIndex = state.cartItems.findIndex(
        (item) => item._id === action.payload.cartItem._id
      );

      if (existingIndex >= 0) {
        state.cartItems[existingIndex] = {
          ...state.cartItems[existingIndex],
          cartQuantity: (state.cartItems[existingIndex].cartQuantity = amount),
        };
      } else {
        let tempProductItem = {
          ...action.payload.cartItem,
          cartQuantity: action.payload.amount,
        };
        state.cartItems.push(tempProductItem);
      }
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));

      //=====================Add To Shipment Items================================
      const ShipmentExistingIndex = state.shipmentItems.findIndex(
        (item) => item.product === action.payload.cartItem._id
      );

      if (ShipmentExistingIndex >= 0) {
        state.shipmentItems[ShipmentExistingIndex] = {
          ...state.shipmentItems[ShipmentExistingIndex],
          count: state.shipmentItems[ShipmentExistingIndex].count + amount,
          product: state.shipmentItems[ShipmentExistingIndex].product,
        };
      } else {
        var tempProductItem;
        if (action.payload.cartItem._id) {
          tempProductItem = {
            ...action.payload.cartItem,
            count: amount,
            product: action.payload.cartItem._id,
            note: "",
          };
        } else if (action.payload.cartItem.product) {
          tempProductItem = {
            ...action.payload.cartItem,
            count: amount,
            product: action.payload.cartItem.product,
            note: "",
          };
        }
        console.log(tempProductItem);
        state.shipmentItems.push(tempProductItem);
      }
      localStorage.setItem(
        "shipmentItems",
        JSON.stringify(state.shipmentItems)
      );
    },
    //==================================================================================================================================

    addToCart(state, action) {
      //=====================Add To Cart Items================================
      const existingIndex = state.cartItems.findIndex(
        (item) => item._id === action.payload._id
      );

      if (existingIndex >= 0) {
        state.cartItems[existingIndex] = {
          ...state.cartItems[existingIndex],
          cartQuantity: state.cartItems[existingIndex].cartQuantity + 1,
        };
      } else {
        let tempProductItem = { ...action.payload, cartQuantity: 1 };
        state.cartItems.push(tempProductItem);
      }
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));

      //=====================Add To Shipment Items================================
      const ShipmentExistingIndex = state.shipmentItems.findIndex(
        (item) => item.product === action.payload._id
      );

      if (ShipmentExistingIndex >= 0) {
        state.shipmentItems[ShipmentExistingIndex] = {
          ...state.shipmentItems[ShipmentExistingIndex],
          count: state.shipmentItems[ShipmentExistingIndex].count + 1,
          product: state.shipmentItems[ShipmentExistingIndex].product,
        };
      } else {
        var tempProductItem;
        if (action.payload._id) {
          tempProductItem = {
            ...action.payload,
            count: 1,
            product: action.payload._id,
            note: "",
          };
        } else if (action.payload.product) {
          tempProductItem = {
            ...action.payload,
            count: 1,
            product: action.payload.product,
            note: "",
          };
        }
        state.shipmentItems.push(tempProductItem);
      }
      localStorage.setItem(
        "shipmentItems",
        JSON.stringify(state.shipmentItems)
      );
    },
    //==================================================================================================================================
    decreaseCart(state, action) {
      //======================Delete Form Cart =====================

      const itemIndex = state.cartItems.findIndex(
        (item) => item._id === action.payload._id
      );
      if (state.cartItems[itemIndex]) {
        if (state.cartItems[itemIndex].cartQuantity > 1) {
          state.cartItems[itemIndex].cartQuantity -= 1;
        } else if (state.cartItems[itemIndex].cartQuantity === 1) {
          const nextCartItems = state.cartItems.filter(
            (item) => item._id !== action.payload._id
          );

          state.cartItems = nextCartItems;
        }
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));

      //======================Delete Form Shipment Items =====================

      const ShipmentItemIndex = state.shipmentItems.findIndex(
        (item) => item.product === action.payload.product
      );
      if (state.shipmentItems[ShipmentItemIndex]) {
        if (state.shipmentItems[ShipmentItemIndex].count > 1) {
          state.shipmentItems[ShipmentItemIndex].count -= 1;
        } else if (state.shipmentItems[ShipmentItemIndex].count === 1) {
          const nextShipmentItems = state.shipmentItems.filter(
            (item) => item.product !== action.payload.product
          );

          state.shipmentItems = nextShipmentItems;
        }
      }

      localStorage.setItem(
        "shipmentItems",
        JSON.stringify(state.shipmentItems)
      );
    },
    //==================================================================================================================================

    removeFromCart(state, action) {
      state.cartItems.map((cartItem) => {
        if (cartItem._id === action.payload._id) {
          const nextCartItems = state.cartItems.filter(
            (item) => item._id !== cartItem._id
          );

          state.cartItems = nextCartItems;
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));

        //======================================================

        state.shipmentItems.forEach((shipmentItem) => {
          if (shipmentItem.product === action.payload.product) {
            const nextShipmentItems = state.shipmentItems.filter(
              (item) => item.product !== shipmentItem.product
            );

            state.shipmentItems = nextShipmentItems;
          }
          localStorage.setItem(
            "shipmentItems",
            JSON.stringify(state.shipmentItems)
          );
        });

        return state;
      });
    },
    //==================================================================================================================================

    getTotals(state, action) {
      let { totalWeight, totalVolume, totalPrice, totalQuantity } =
        state.cartItems.reduce(
          (cartTotal, cartItem) => {
            const { productPrice, cartQuantity, packageGrossWeight, volume } =
              cartItem;
            const itemTotalPrice = cartQuantity * productPrice;
            const itemTotalVolume = cartQuantity * volume;
            const itemTotalWeight = cartQuantity * packageGrossWeight;
            cartTotal.totalPrice += itemTotalPrice;
            cartTotal.totalQuantity += cartQuantity;
            cartTotal.totalWeight += itemTotalWeight;
            cartTotal.totalVolume += itemTotalVolume;
            return cartTotal;
          },
          {
            totalWeight: 0,
            totalVolume: 0,
            totalPrice: 0,
            totalQuantity: 0,
          }
        );
      totalPrice = parseFloat(totalPrice.toFixed(2));
      totalWeight = parseFloat(totalWeight.toFixed(2));
      totalVolume = parseFloat(totalVolume.toFixed(3));
      state.cartTotalQuantity = totalQuantity;
      state.cartTotalAmount = totalPrice;
      state.cartTotalWeight = totalWeight;
      state.cartTotalVolume = totalVolume;
    },
    clearCart(state, action) {
      state.cartItems = [];
      state.shipmentItems = [];
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      localStorage.setItem(
        "shipmentItems",
        JSON.stringify(state.shipmentItems)
      );
    },
  },
});

export const {
  UpdateSuggestionShipment,
  checkIsEditing,
  shipmentInRange,
  UpdateSuggestion,
  // checkOverload,
  checkIsVerified,
  addAmountToCart,
  addToCart,
  decreaseCart,
  removeFromCart,
  getTotals,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
