import React, { useEffect, useState } from "react";
//css
import "./RatingComponent.css";
//components
import { Rating } from "react-simple-star-rating";
//bootstrap
import { Button } from "react-bootstrap";
//images
import LocationImg from "../../assets/Payments/Location.png";
import ManImg from "../../assets/Payments/man.png";
import Star from "../../assets/Payments/Star.svg";
import Star2 from "../../assets/Payments/Star2.svg";
import { useTranslation } from "react-i18next";
const RatingComponent = ({ agentId, productId }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [agent, setAgent] = useState([]);
  const { t } = useTranslation();
  const [productRatingByAgent, setProductRatingByAgent] = useState(0);
  //===================navigate==========================

  const handleRating = (rate) => {
    const actualRate = rate / 20;
    setRating(actualRate);
  };
  //=======================get Agent By Id =====================

  useEffect(() => {
    const getAgentById = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/agent/${agentId}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setAgent([]);
        console.log(body);
      } else {
        setAgent(body);
      }
    };
    getAgentById();
  }, [agentId]);
  //========================Post Rating======================
  const postRate = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      value: rating,
      productId: productId,
      agentId: agentId,
      note: comment,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/rating-product/",
      requestOptions
    );
    if (!response.ok) {
      alert("Rating Not Added");
      console.log(response);
    } else {
      alert(t(`Components.Rating.TnxMessage`));
      window.location.reload();
    }
  };

  //============================get Agent Rating For Product=================
  useEffect(() => {
    const getAgentRatingForProduct = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/rating-product/agent?agentId=${agentId}&productId=${productId}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setProductRatingByAgent(0);
        console.log(body);
      } else {
        setProductRatingByAgent(body.value);
      }
    };
    getAgentRatingForProduct();
  }, [agentId, productId]);

  return (
    <article className="rating">
      <div className="rating-title">
        <h3>{t(`Components.Rating.Title`)}</h3>
        <p>{t(`Components.Rating.Desc`)}</p>
      </div>
      <div className="rating-container-card">
        <div className="rating-container-card-header">
          <div className="rating-container-card-header-img">
            <img src={ManImg} alt="pic" className="agent-img" />
          </div>
          <div className="rating-container-card-header-info">
            <h2>{agent.name}</h2>
            <p>
              <img src={LocationImg} alt="location" />
              <span>{agent.address}</span>
            </p>
          </div>
        </div>
        <div className="rating-container-card-body">
          <div className="rating-container-card-stars">
            <Rating
              onClick={handleRating}
              ratingValue={productRatingByAgent * 20}
              fullIcon={<img src={Star} alt="fillStart" />}
              emptyIcon={<img src={Star2} alt="emptyStar" />}
              allowHalfIcon={true}
              fullClassName="fillStar"
              emptyClassName="emptyStar"
            />
          </div>
          <textarea
            onChange={(e) => setComment(e.target.value)}
            placeholder={t(`Components.Rating.TextAreaPlaceholder`)}
          />
        </div>
        <div className="rating-container-card-btns">
          <Button className="primary-btn" onClick={postRate}>
            {t(`Components.Rating.Btn`)}
          </Button>
        </div>
      </div>
    </article>
  );
};

export default RatingComponent;
