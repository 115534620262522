import React from "react";
import { useNavigate } from "react-router-dom";
//css
import "./ShipmentDocuments.css";
//image
import DocsImg from "../../../../../assets/Payments/docs.png";
import DownloadFileImg from ".././../../../../assets/Payments/download.png";
//bootstrap
import { Button } from "react-bootstrap";
//translation
import { useTranslation } from "react-i18next";
const ShipmentDocuments = () => {
  const shipmentFiles = JSON.parse(localStorage.getItem("shipmentFiles"));
  const navigate = useNavigate();
  const { t } = useTranslation();
  //=========================================download File ===============================================
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <section className="shipment-docs">
      <div className="docs-title">
        <h3>{t(`Pages.ShipmentProcess.Shipment.ShipmentDocuments.Title`)}</h3>
      </div>
      <div className="docs-container">
        <div className="docs-container-img">
          <img src={DocsImg} alt="documentsImg" />
        </div>
        <div className="docs-container-files">
          {shipmentFiles &&
            Object.entries(shipmentFiles).map((key, index) => {
              return (
                <div
                  key={index}
                  className="docs-container-file"
                  onClick={() => getFile(key[1])}
                >
                  {key[0]}
                  <img src={DownloadFileImg} alt="download" />
                </div>
              );
            })}
        </div>
        <div className="docs-container-btn">
          <Button>
            <a
              href="https://www.searates.com/ar/container/tracking/?{%22event%22:%22search%20complete%22,%22container%22:%22DFSU7125808%22,%22searchType%22:%22auto%22,%22result%22:%22not-found%22}"
              target="_blank"
              rel="noreferrer"
            >
              Track Shipment
            </a>
          </Button>

          <Button
            variant="warning"
            onClick={() => navigate("/shipmentProcess/payment")}
          >
            {t(`Pages.ShipmentProcess.Shipment.ShipmentDocuments.BackBtn`)}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ShipmentDocuments;
