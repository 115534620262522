import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import ApiInstances from '../../Api/axiso';

const TableRow = ({ item, index, moveRow }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'row',
        item: { index },
    });

    const [, dropRef] = useDrop({
        accept: 'row',
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveRow(draggedItem.index, index);
                draggedItem.index = index; // تحديث الفهرس المسحوب ليتماشى مع الموضع الجديد
            }
        },
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
        <tr ref={(node) => dragRef(dropRef(node))} style={{ opacity }}>
            <td style={{ width: "70px" }}>
                <img
                    src={`https://www.api.setalkel.co${item}`}
                    style={{ cursor: 'pointer', height: "150px", width: "150px" }}
                    alt={`image-${index}`}
                />
            </td>
        </tr>
    );
};

const CardItem = ({ item, index, moveRow }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'row',
        item: { index },
    });

    const [, dropRef] = useDrop({
        accept: 'row',
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveRow(draggedItem.index, index);
                draggedItem.index = index; // تحديث الفهرس المسحوب ليتماشى مع الموضع الجديد
            }
        },
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
        <div ref={(node) => dragRef(dropRef(node))} className="card-item-baner" style={{ opacity }}>
            <img src={`https://www.api.setalkel.co${item}`} alt={`image-${index}`} style={{ width: "200px", height: "200px" }} />
        </div>
    );
};

const BannerDrag = () => {
    const [banner, setBanner] = useState([]);
    const [viewType, setViewType] = useState("grid");

    const fetchBanner = async () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `bearer ${localStorage.getItem("admin-token")}`
        );
        var requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: myHeaders,
        };

        const response = await fetch(
            "https://www.api.setalkel.co/banner/images",
            requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
            toast.error("There is an Error");
            setBanner([]);
        } else {
            setBanner(body.images);
        }
    };

    useEffect(() => {
        fetchBanner();
    }, []);

    const moveRow = (draggedIndex, hoverIndex) => {
        const newBanner = [...banner];
        const [draggedItem] = newBanner.splice(draggedIndex, 1);
        newBanner.splice(hoverIndex, 0, draggedItem);
        setBanner(newBanner);
    };

    const { mutate } = useMutation({
        mutationFn: async (res) => {
            const { data } = await ApiInstances.post(`/product/cat/update-order`, res);
            return data;
        },
        onSuccess: () => {
            toast.success("Sorting Successfully");
            fetchBanner();
        },
    });

    const handleSort = () => {
        const sortedImages = banner.map((item, index) => ({ item, index }));
        mutate({ sortedImages });
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px" }}>

                <Button
                    variant="warning"
                    style={{ height: "fit-content", margin: "0", marginTop: "50px" }}
                    onClick={handleSort}
                >
                    Sorting
                </Button>
            </div>
            {banner.length === 0 ? (
                <div style={{ display: "flex", width: "100%", justifyContent: "center", color: "black", fontSize: "16px" }}>
                    <p>Please Select Category</p>
                </div>
            ) : <div className="card-grid">
                {banner.map((item, index) => (
                    <CardItem key={index} item={item} index={index} moveRow={moveRow} />
                ))}
            </div>}
        </DndProvider>
    );
};

export default BannerDrag;
