import React, { useState, useEffect, useRef } from "react";

import Spreadsheet, {
  createFormulaParser,
  Matrix,
  CellBase,
} from "react-spreadsheet";

import { Link, useNavigate } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from 'react-draft-wysiwyg';

//boostrap
import { Row, Button, Form, Modal } from "react-bootstrap";
//css
import "./CrudProducts.css";
//semantic ui
import { EditorState } from 'draft-js';
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { ARRAYOFCountries, ARRAYOFCountriesAR, ARRAYOFCountriesDE, ARRAYOFCountriesNL } from "./CountriesArray";
import { toast } from "react-hot-toast";
import UnVerifiedAgentsApi from "../../Api/UnVerifiedAgentsApi";
import QRCode from "react-qr-code";
import { FaTrashAlt } from "react-icons/fa";
const AddProduct = () => {

  const [categories, setCategories] = useState([]);
  const [counrtyBarcode, setCounryBarcode] = useState([])
  const [imgBackGounde, setImgBackGounde] = useState(null)


  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const [ImgfileComoanyInfo, setImgfileCompanyInfo] = useState({
    Imgfile: null,
  });
  const [ImgfileFrontLabel, setImgfileFrontLabel] = useState({
    Imgfile: null,
  });
  const [ImgfileBackLabel, setImgfileBackLabel] = useState({
    Imgfile: null,
  });
  const [ImgGallery, setImgGallery] = useState({
    ImgGallery: [],
  });
  const [values, setValues] = useState({
    cat: "",
    subCat: {
      en: "",
      ar: "",
      fr: "",
      ru: "",
      tur: "",
      cn: "",
      de: "",
      ir: "",
      dk: "",
    },
    countryOrigin: "",
    agents: "",
    materialProperty: "",
    peiceQty: {
      grossWeightForPeice: "",
      drainedWeightForPeice: "",
      netWeightForPeice: "",
      packageForPeice: "",
      freeOffer: "",
      usualWeight: "",
      unit: "",
    },
    supplierName: "",
    packageGrossWeight: "",
    unitForPackage: "",
    volume: "",
    width: "",
    height: "",
    tall: "",
    countInBox: "",
    stockOff: false,
    hidden: false,
    is_new: false,
    boxBarcode: {
      barcode: "",
    },
    pieceBarcode: {
      barcode: "",
    },
    canBarcode: {
      barcode: "",
    },

    barcodeCountry: "",
    palletCapacity: "",
    coverSticker: {
      count: null,
      type: ""
    },
    cartonSticker: "",
    productSticker: "",
    stickerLength: "",
    stickerWidth: "",
    components: {
      ar: "",
      en: "",
      de: "",
      fr: "",
      dk: "",
      nl: "",
      nor: "",
      tur: "",
      swe: ""
    },
    storageConditions: {
      ar: "",
      en: "",
      de: ""
    },
    notes: {
      ar: "",
      en: "",
      nl: ""
    },
    producingCompanyInfo: "",
    changeRate: "",
    madeIn: {
      ar: "",
      en: "",
      de: ""
    },
    productStatus: "",
    weightInPounds: "",
    productContainerCapacity: "",
    internationalNumber: "",
    room: "",
    weightCode: "",
    BrandCode: "",
    Brand: "",
    categoryCode: "",
    category: "",
    Weight: "",
    fill: "",
    fillCode: "",
    HsCode: "",
    agents: ""
  });
  const [nutritionalValueEn, setNutritionalValueEn] = useState(
    () => EditorState.createEmpty(),
  );
  const [nutritionalValueFr, setNutritionalValueFr] = useState(
    () => EditorState.createEmpty(),
  );
  const [nutritionalValueAl, setNutritionalValueAl] = useState(
    () => EditorState.createEmpty(),
  );
  const [nutritionalValueHl, setNutritionalValueHl] = useState(
    () => EditorState.createEmpty(),
  );

  //==================================Countries Drop Down Handling=================================
  const [value, setvalue] = useState("");
  const [value2, setvalue2] = useState("");
  const [images, setImages] = useState({
    boxImage: null,
    packageImage: null,
    itemImage: null
  });
  const handlePdfChange = async (event) => {

  }
  // console.log(editorState)
  const handleImageChangeBarcode = async (event, imageKey) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);

    const formData = new FormData();
    formData.append('image', file);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin-token")
    );
    try {
      const response = await fetch('https://www.api.setalkel.co/product/barcode-image', {
        method: 'POST',
        body: formData,
        headers: myHeaders,
      });

      if (response.ok) {

        const { image } = await response.json();
        // console.log(image)
        setImages((prevImages) => ({
          ...prevImages,
          [imageKey]: image
        }));
      } else {
        console.error('Failed to upload the image');
        // Handle error case
      }
    } catch (error) {
      console.error('Error occurred while uploading the image', error);
      // Handle error case
    }
  };
  // console.log(images)

  const handleOnchange = (val) => {
    setvalue(val);
    setValues({ ...values, countryOrigin: val.split(",") });
  };
  const handleOnchangeAgents = (val) => {
    setvalue(val);
    setValues({ ...values, agents: val });
  };
  const handleOnchange2 = (val) => {
    setvalue2(val);
    setValues({ ...values, materialProperty: val.split(",") });
  };
  //==================Image Gallery Handeling===============
  const handleGalleryChange = (e) => {
    const newImages = Array.from(e.target.files);

    setImgGallery(prevState => {
      const allImages = [...prevState.ImgGallery, ...newImages];

      if (allImages.length > 4) {
        return {
          ImgGallery: allImages.slice(0, 4)
        };
      }
      return {
        ImgGallery: allImages
      };
    });
  };


  //=======================Ingredients In  Input Handeling===============
  const productNameInputHandle = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      subCat: { ...values.subCat, [name]: value.toLowerCase() },
    });
  };

  const IngredientsInputHandle = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      components: { ...values.components, [name]: value.toLowerCase() },
    });
  };
  const StorageConditionsInputHandle = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      storageConditions: { ...values.storageConditions, [name]: value.toLowerCase() },
    });
  };
  const NotesInputHandle = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      notes: { ...values.notes, [name]: value.toLowerCase() },
    });
  };
  //=======================Peice Quantity Input Handeling===============
  const peiceQuantityInputHandle = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      peiceQty: { ...values.peiceQty, [name]: value },
    });
  };
  const handelBrcodeBox = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      boxBarcode: { ...values.boxBarcode, [name]: value },
    });
  };
  const handelBrcodeCan = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      canBarcode: { ...values.canBarcode, [name]: value },
    });
  };
  const handelBrcodePiece = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      pieceBarcode: { ...values.pieceBarcode, [name]: value },
    });
  };
  const handelCoverSticker = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      coverSticker: { ...values.coverSticker, [name]: value },
    });
  };
  const MadeinInputHandle = (e) => {
    var { name, value } = e.target;

    setValues({
      ...values,
      madeIn: { ...values.madeIn, [name]: value },
    });
  };
  const [calculatedValue, setCalculatedValue] = useState()
  //=======================Input Handeling==============
  const handleInfoInputChange = (event) => {
    const { name, value } = event.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (name === "width" || name === "height" || name === "tall") {
      const width = name === "width" ? value : values.width || 0;
      const height = name === "height" ? value : values.height || 0;
      const tall = name === "tall" ? value : values.tall || 0;

      const calculatedValue = (width * height * tall) / 1000000;

      setCalculatedValue(calculatedValue);
    }
  };

  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  const handleImageChangeProductInfo = (e) => {
    setImgfileCompanyInfo({
      Imgfile: e.target.files[0],
    });
  };
  const handleImageChangeFrontLabel = (e) => {
    setImgfileFrontLabel({
      Imgfile: e.target.files[0],
    });
  };
  const handleImageChangeBackLabel = (e) => {
    setImgfileBackLabel({
      Imgfile: e.target.files[0],
    });
  };
  const handleImageChangeBack = (e) => {
    setImgBackGounde({
      Imgfile: e.target.files[0],
    });
  };
  const fileInputRef = useRef(null);
  const fileInputRefCompany = useRef(null);
  const fileInputFrontLabel = useRef(null);
  const fileInputBaclLabel = useRef(null);



  // console.log(ImgGallery.ImgGallery[0])
  //===================================Posting Image=====================
  const PostProductImg = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );
      const fd = new FormData();
      fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/product/image/${id}`,
        requestOptions
      );
      const body = await ImgResponse.json();
      // console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  const { data: VerifiedAgents } = UnVerifiedAgentsApi.VerifiedAgentquery()
  const { data: UnVerifiedAgentquery } = UnVerifiedAgentsApi.UnVerifiedAgentquery()


  const [spreadsheetData, setSpreadsheetData] = useState(
    Array.from({ length: 6 }, () => Array.from({ length: 2 }, () => ({ value: "" })))
  );
  const [spreadsheetData2, setSpreadsheetData2] = useState(
    Array.from({ length: 6 }, () => Array.from({ length: 2 }, () => ({ value: "" })))
  );
  const [array, setArray] = useState([""])
  const handleSpreadsheetChange = (data) => {
    setSpreadsheetData(data);
    const valuesArray = spreadsheetData.map((d) => d.map((d2) => d2.value))
    setArray(spreadsheetData.map((d) => d.map((d2) => d2.value).join("")))
  };
  const handleChange = (event) => {
    const { value, checked } = event.target;
    let newValues;
    if (checked) {
      newValues = [...values.agents, value];
    } else {
      newValues = values.agents.filter(agent => agent !== value);
    }
    handleOnchangeAgents(newValues);
  };
  //=====================================Post Kitchen Gallery====================
  const PostProductGallery = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );
      if (ImgGallery.ImgGallery.length < 4) {
        const fd = new FormData();
        for (let i = 0; i < ImgGallery.ImgGallery.length; i++) {
          fd.append(
            "images",
            ImgGallery.ImgGallery[i],
            ImgGallery.ImgGallery[i].name
          );
        }
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: fd,
          redirect: "follow",
        };

        const ImgResponse = await fetch(
          `https://www.api.setalkel.co/product/${id}/gallery`,
          requestOptions
        );
        const body = await ImgResponse.json();
        // console.log(body);
      } else {
        alert(
          "Gallery Images Should Be 4 Image"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  //===========================Post Product==============================


  const navigate = useNavigate()
  // console.log(array)
  const PostProduct = async (e) => {

    console.log(values.madeIn)
    e.preventDefault();
    // console.log(values.Notes)
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = {

        cat: values.cat,
        subCat: values.subCat,
        countryOrigin: values.countryOrigin,
        materialProperty: values.materialProperty,
        peiceQty: values.peiceQty,
        supplierName: values.supplierName,
        packageGrossWeight: values.packageGrossWeight,
        unitForPackage: "kg",
        volume: values.volume,
        countInBox: values.countInBox,
        stockOff: values.stockOff,
        hidden: values.hidden,
        is_new: values.is_new,
        barcodeCountry: values.barcodeCountry,
        palletCapacity: values.palletCapacity,
        barcodeCountry: values.barcodeCountry,
        nutritionalValue: array.filter(value => value !== ""),
        components: values.components,
        notes: values.notes,
        storageConditions: values.storageConditions,
        producingCompanyInfo: values.producingCompanyInfo || "",
        changeRate: values.changeRate || "",
        productStatus: values.productStatus || "",
        weightInPounds: values.weightInPounds,
        madeIn: values.madeIn
      };

      Object.keys(raw.madeIn).forEach(key => {
        if (raw.madeIn[key] === "") delete raw.madeIn[key];
      });
      // Object.keys(raw.nutritionalValue).forEach(key => {
      //   if (raw.nutritionalValue[key] === "") delete raw.nutritionalValue[key];
      // });
      if (raw.nutritionalValue.length === 0) {
        delete raw.nutritionalValue
      }
      Object.keys(raw.components).forEach(key => {
        if (raw.components[key] === "") delete raw.components[key];
      });
      Object.keys(raw.notes).forEach(key => {
        if (raw.notes[key] === "") delete raw.notes[key];
      });
      Object.keys(raw.storageConditions).forEach(key => {
        if (raw.storageConditions[key] === "") delete raw.storageConditions[key];
      });
      // console.log(raw)
      if (values.stickerWidth) {
        raw.stickerWidth = values.stickerWidth
      }
      if (values.stickerLength) {
        raw.stickerLength = values.stickerLength
      }
      if (values.productSticker) {
        raw.productSticker = values.productSticker
      }
      if (values.cartonSticker) {
        raw.cartonSticker = values.cartonSticker
      }
      if (values.pieceBarcode.barcode || images.packageImage) {
        raw.pieceBarcode = {
          barcode: values.pieceBarcode.barcode,
          image: images.packageImage ? images.packageImage : undefined
        };
      }
      if (values.boxBarcode.barcode || images.boxImage) {
        raw.boxBarcode = {
          barcode: values.boxBarcode.barcode,
          image: images.boxImage ? images.boxImage : undefined
        };
      }
      if (values.canBarcode.barcode || images.itemImage) {
        raw.canBarcode = {
          barcode: values.canBarcode.barcode,
          image: images.itemImage ? images.itemImage : undefined
        };
      }
      if (values.coverSticker.type !== "" || values.coverSticker.count) {
        raw.coverSticker = {
          count: values.coverSticker.count,
          type: values.coverSticker.type
        };
      }
      let NewSubCat = raw.subCat;
      let NewComponents = raw.components
      let NewPeiceQty = raw.peiceQty;
      let NewRaw = raw;

      Object.keys(NewComponents).forEach(key => {
        if (NewComponents[key] === "") delete NewComponents[key];
      });
      Object.keys(NewSubCat).forEach((key) => {
        if (NewSubCat[key] === "") {
          delete NewSubCat[key];
        }
      });

      Object.keys(NewPeiceQty).forEach((key) => {
        if (NewPeiceQty[key] === "") {
          delete NewPeiceQty[key];
        }
      });

      Object.keys(NewRaw).forEach((key) => {
        if (NewRaw[key] === "") {
          delete NewRaw[key];
        }
      });

      if (NewComponents) {
        NewRaw.Components = NewComponents
      }
      console.log(JSON.stringify(NewRaw))
      var raw2 = JSON.stringify(NewRaw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(NewRaw),
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        toast.error("Product Not Added !!!!");
        // console.log(response);
        // console.log(body);
      } else {
        if (Imgfile.Imgfile) {
          await PostProductImg(body._id);
        }
        if (ImgGallery.ImgGallery.length > 0) {
          await PostProductGallery(body._id);
        }
        toast.success("Product Added Successfully");
        navigate("/dashboard/crproducts")

      }
    } catch (error) {
      console.log(error);
    }


  };

  useEffect(() => {
    const fetchCategories = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/cat/",
        requestOptions
      );
      const body = await response.json();
      // console.log(body);

      if (!response.ok) {
        setCategories([]);
        // console.log(body);
      } else {
        setCategories(body);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetcCountryforBarcode = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/export-country/info",
        requestOptions
      );
      const body = await response.json();
      // console.log(body);

      if (!response.ok) {
        setCounryBarcode([]);
        // console.log(body);
      } else {
        setCounryBarcode(body);
        // console.log(body)
      }
    };
    fetcCountryforBarcode();
  }, []);


  const customCreateFormulaParser = (data) =>
    createFormulaParser(data, { SUM: undefined });
  const customCreateFormulaParser2 = (data) =>
    createFormulaParser(data, { SUM: undefined });
  const [showModal, setShowModal] = useState(false);
  const [newUnit, setNewUnit] = useState("");
  const [unitOptions, setUnitOptions] = useState([
    "kg",
    "Liter",
    "gram",
    "ml",
    "m",
    "cm",
    "libra"
  ]);
  const [showModalPackage, setShowModalPackage] = useState(false);
  const [newPackage, setPackage] = useState("");
  const [PackageOptions, setPackageOptions] = useState([
    "plastic",
    "glass",
    "carton",
    "canned",
    "vacuum",
    "wood",
    "bag"
  ]);

  useEffect(() => {
    const savedUnits = localStorage.getItem("unitOptions");
    if (savedUnits) {
      setUnitOptions(JSON.parse(savedUnits));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("unitOptions", JSON.stringify(unitOptions));
  }, [unitOptions]);


  useEffect(() => {
    const savedPackage = localStorage.getItem("PackageOp");
    if (savedPackage) {
      setPackageOptions(JSON.parse(savedPackage));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("PackageOp", JSON.stringify(PackageOptions));
  }, [PackageOptions]);

  const handleAddUnit = () => {
    if (newUnit && !unitOptions.includes(newUnit)) {
      setUnitOptions([...unitOptions, newUnit]);
    }
    setShowModal(false);
    setNewUnit("");
  };
  const handleAddPackage = () => {
    if (newPackage && !PackageOptions.includes(newUnit)) {
      setPackageOptions([...PackageOptions, newPackage]);
    }
    setShowModalPackage(false);
    setPackage("");
  };

  return (
    <section className="cms-create-products">
      <Row>
        <div className="crud-products">
          <div className="crud-title">
            <h1 >AddProduct</h1>
          </div>
          {/* ======================== Start Ingredients In  In 7 Langs================================= */}
          <div className="crud-product">
            <h2>Products Names</h2>

            <Form onSubmit={PostProduct} className="products-form">

              <Form.Label htmlFor="ProductNameInEn" style={{ color: "red" }}>
                Arabic & English R Required
              </Form.Label>
              <div className="product-langs">
                <Form.Group>
                  <Form.Label htmlFor={values.subCat.en}>
                    Product Name In  In English /
                    <span style={{ color: "red" }}>Required</span>
                  </Form.Label>
                  <Form.Control
                    name="en"
                    label="Product"
                    value={values.subCat.en}
                    onChange={productNameInputHandle}
                    placeholder="Product Name  In  in english"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor={values.subCat.ar}>
                    Product Name   In Arabic /
                    <span style={{ color: "red" }}>Required</span>
                  </Form.Label>

                  <Form.Control
                    name="ar"
                    label="product"
                    value={values.subCat.ar}
                    onChange={productNameInputHandle}
                    placeholder="Product Name  In   arabic"
                    required
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label htmlFor={values.subCat.fr}>
                    Product Name   In French
                  </Form.Label>

                  <Form.Control
                    name="fr"
                    label="product"
                    value={values.subCat.fr}
                    onChange={productNameInputHandle}
                    placeholder="Product Name  In   french"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor={values.subCat.ru}>
                    Product Name   In Russian
                  </Form.Label>

                  <Form.Control
                    name="ru"
                    label="product"
                    value={values.subCat.ru}
                    onChange={productNameInputHandle}
                    placeholder="Product Name  In   russian"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor={values.subCat.de}>
                    Product Name In German
                  </Form.Label>

                  <Form.Control
                    name="de"
                    label="product"
                    value={values.subCat.de}
                    onChange={productNameInputHandle}
                    placeholder="Product Name  In   german"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor={values.subCat.cn}>
                    Product Name    In China
                  </Form.Label>

                  <Form.Control
                    name="cn"
                    label="product"
                    value={values.subCat.cn}
                    onChange={productNameInputHandle}
                    placeholder="Product Name  In   chineese"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor={values.subCat.tur}>
                    Product Name   In Turkish
                  </Form.Label>
                  <Form.Control
                    name="tur"
                    label="product"
                    value={values.subCat.tur}
                    onChange={productNameInputHandle}
                    placeholder="Product Name  In   turkish"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor={values.subCat.ir}>
                    Product Name In Persian
                  </Form.Label>
                  <Form.Control
                    name="ir"
                    label="product"
                    value={values.subCat.ir}
                    onChange={productNameInputHandle}
                    placeholder="Product Name  In   Persian"
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label htmlFor={values.subCat.dk}>
                    Product Name  In Danish
                  </Form.Label>
                  <Form.Control
                    name="dk"
                    label="product"
                    value={values.subCat.dk}
                    onChange={productNameInputHandle}
                    placeholder="Product Name  In   Danish"
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label htmlFor={values.subCat.dk}>
                    Product Name  In Finnish
                  </Form.Label>
                  <Form.Control
                    name="dk"
                    label="product"
                    style={{
                      border: "1px solid red"
                    }}
                    placeholder="Product Name  In   Finnish"
                  />
                </Form.Group>

                {/* ======================== End Material Property Name In 7 Langs================================= */}

                {/* ===============================Product Rest INFO======================================= */}
              </div>

              <hr />
              <h2>One Peice Info</h2>
              <div className="d-flex gap-5 justify-content-start">
                <Form.Group>
                  <h5>Country Origin</h5>
                  <div className="preview-values">{value}</div>

                  <MultiSelect
                    onChange={handleOnchange}
                    options={ARRAYOFCountries}
                    defaultValue={values.countryOrigin}
                  />
                </Form.Group>
                <div>
                  <h5>Material Property</h5>
                  <Form.Group>
                    <div className="preview-values">{value2}</div>
                    <MultiSelect
                      onChange={handleOnchange2}
                      options={[
                        { label: "Dried", value: "dried" },
                        { label: "Frozen", value: "frozen" },
                        { label: "Mix", value: "mix" },
                        { label: "Concert", value: "concert" },

                      ]}
                      defaultValue={values.materialProperty}
                    />
                  </Form.Group>
                </div>
              </div>

              {/* ======================== Start materialProperty Name  In 7 Langs================================= */}

              <div className="product-info">

                <Form.Group>
                  <Form.Label>
                    Product Category WEPSITE /
                    <span style={{ color: "red" }}>Required</span>
                  </Form.Label>
                  <select
                    id="category-list"
                    name="cat"
                    className="m-0"
                    value={values.cat}
                    onChange={handleInfoInputChange}
                    required
                  >
                    <option>Please Choose A Category</option>
                    {categories.map((category, index) => {
                      return (
                        <option key={index} value={category._id}>
                          {category.en}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{
                    color: "red"
                  }}>
                    Product Category AlAmeen
                  </Form.Label>
                  <select
                    style={{
                      border: "1px solid red"
                    }}
                    id="category-list"
                    name="cat"
                    className="m-0"
                  >
                    <option>Please Choose A Category</option>

                  </select>
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{
                    color: "red"
                  }}>
                    Product Category WEPSITE
                  </Form.Label>
                  <select
                    style={{
                      border: "1px solid red"
                    }}
                    id="category-list"
                    name="cat"
                    className="m-0"
                    value={values.cat}
                    onChange={handleInfoInputChange}
                    required
                  >
                    <option>Please Choose A Category</option>

                  </select>
                </Form.Group>
                <Form.Group>

                  <Form.Label>
                    Gross Weight For Peice /
                    <span style={{ color: "red" }}>Required</span>
                  </Form.Label>

                  <Form.Control
                    type="number"
                    className="m-0"
                    name="grossWeightForPeice"
                    label="grossWeightForPeice"
                    value={values.peiceQty.grossWeightForPeice}
                    onChange={peiceQuantityInputHandle}
                    placeholder="Gross Weight For Peice"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Drained Weight For Peice /
                    <span style={{ color: "red" }}>Required</span>
                  </Form.Label>

                  <Form.Control
                    type="number"
                    name="drainedWeightForPeice"
                    label="drainedWeightForPeice"
                    value={values.peiceQty.drainedWeightForPeice}
                    onChange={peiceQuantityInputHandle}
                    placeholder="Drained Weight For Peice"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Net Weight For Peice/
                    <span style={{ color: "red" }}>Required</span>
                  </Form.Label>

                  <Form.Control
                    type="number"
                    name="netWeightForPeice"
                    label="netWeightForPeice"
                    value={values.peiceQty.netWeightForPeice}
                    onChange={peiceQuantityInputHandle}
                    placeholder="Net Weight For Peice"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Usual Weight

                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="usualWeight"
                    label="usualWeight"
                    value={values.peiceQty.usualWeight}
                    onChange={peiceQuantityInputHandle}


                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Free quantity included in the product, if any</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    type="number"
                    name="freeOffer"
                    label="freeOffer"
                    value={values.peiceQty.freeOffer}
                    onChange={peiceQuantityInputHandle}

                  />
                </Form.Group>


                <Form.Group>
                  <Form.Label>Peice Unit  / <span style={{ color: "red" }}>Required</span></Form.Label>
                  <div className="d-flex align-items-center gap-2">
                    <select
                      id="category-list"
                      name="unit"
                      label="unit"
                      value={values.peiceQty.unit}
                      onChange={peiceQuantityInputHandle}
                      required
                    >
                      <option>Please Choose A Product Unit</option>
                      {unitOptions.map((unit, index) => (
                        <option key={index} value={unit}>
                          {unit}
                        </option>
                      ))}
                    </select>
                    <Button
                      variant="outline-danger"

                      style={{ marginLeft: "10px" }}
                      onClick={() => setShowModal(true)}
                    >
                      +
                    </Button>
                  </div>
                </Form.Group>
                <Form.Group className="">
                  <Form.Label>WeightIn Pounds</Form.Label>
                  <Form.Control
                    type="text"
                    name="weightInPounds"
                    label="weightInPounds"
                    value={values.weightInPounds}
                    onChange={handleInfoInputChange}
                    placeholder="weightInPounds"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Product Package </Form.Label>
                  <div className="d-flex align-items-center gap-2">
                    <select
                      id="category-list"
                      name="packageForPeice"
                      label="packageForPeice"
                      value={values.peiceQty.packageForPeice}
                      onChange={peiceQuantityInputHandle}
                    >
                      <option>Please Choose A Product Package</option>
                      {PackageOptions.map((Package, index) => (
                        <option key={index} value={Package}>
                          {Package}
                        </option>
                      ))}
                    </select>
                    <Button
                      variant="outline-danger"

                      style={{ marginLeft: "10px" }}
                      onClick={() => setShowModalPackage(true)}
                    >
                      +
                    </Button>
                  </div>
                </Form.Group>

                <Form.Group className="mt-3 ">
                  <Form.Label>Permissible Percentage of Weight Difference</Form.Label>
                  <Form.Control
                    name="changeRate"
                    label="changeRate"
                    value={values.changeRate}
                    onChange={handleInfoInputChange}


                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Product Status </Form.Label>
                  <select
                    id="category-list"
                    name="productStatus"
                    label="productStatus"
                    value={values.productStatus}
                    onChange={handleInfoInputChange}
                  >
                    <option>Please Product Status</option>
                    <option>hold</option>
                    <option>seasonal</option>
                    <option>limited-quantity</option>
                  </select>
                </Form.Group>
                {/* ========================  Supplier Name  ================================ */}
                <Form.Group>
                  <Form.Label >Product Company Info</Form.Label>
                  <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    <Form.Control
                      type="file"

                      style={{ height: "fit-content", border: "1px solid red" }}
                      name="image"
                      id="productImg"
                      onChange={handleImageChangeProductInfo}
                      ref={fileInputRefCompany}
                    />
                    {ImgfileComoanyInfo.Imgfile && (
                      <div className="card-item-about position-relative">
                        <img
                          style={{ width: "100px", height: "100px" }}
                          src={URL.createObjectURL(ImgfileComoanyInfo.Imgfile)}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setImgfileCompanyInfo({
                              Imgfile: null,
                            });
                            if (fileInputRefCompany.current) {
                              fileInputRefCompany.current.value = "";
                            }

                          }}
                          style={{
                            position: "absolute",
                            top: "3px",
                            right: "30px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>Pallet Capacity</Form.Label>
                  <Form.Control
                    type="number"
                    name="palletCapacity"
                    label="Barcode Country"
                    value={values.palletCapacity}
                    onChange={handleInfoInputChange}
                    placeholder="Pallet Capacity"
                  />
                </Form.Group>



                <Form.Group>
                  <Form.Label style={{
                    color: "red"
                  }}>Product Number From the Manufacturer</Form.Label>
                  <Form.Control
                    type="number"
                    name="palletCapacity"
                    label="Barcode Country"
                    style={{
                      border: "1px solid red"
                    }}
                    placeholder="Recipe number"
                  />
                </Form.Group>


                {/* ======================== End supplierName================================= */}
                {/* ====================================================================Package Data============================================================ */}
              </div>

              <div className="d-flex flex-column">
                <Form.Group style={{
                  width: "fit-content"
                }}>
                  <Form.Label style={{
                    color: "red"
                  }}>  Capicity Without Pallet </Form.Label>
                  <Form.Control
                    type="number"
                    name="palletCapacity"
                    label="Barcode Country"
                    style={{
                      border: "1px solid red"
                    }}
                    placeholder="Recipe number"
                  />
                </Form.Group>
                <div className="d-flex gap-2 flex-wrap">
                  <Form.Group style={{
                    width: "fit-content"
                  }}>
                    <Form.Label style={{
                      color: "red"
                    }}> Container 20 feet, 10 pallets </Form.Label>
                    <Form.Control
                      type="number"
                      name="palletCapacity"
                      label="Barcode Country"
                      style={{
                        border: "1px solid red"
                      }}
                      placeholder="Container 20 feet, 10 pallets"
                    />
                  </Form.Group>
                  <Form.Group style={{
                    width: "fit-content"
                  }}>
                    <Form.Label style={{
                      color: "red"
                    }}> Container 20 feet, 11 pallets </Form.Label>
                    <Form.Control
                      type="number"
                      name="palletCapacity"
                      label="Barcode Country"
                      style={{
                        border: "1px solid red"
                      }}
                      placeholder="Container 20 feet, 11 pallets"
                    />
                  </Form.Group>
                  <Form.Group style={{
                    width: "fit-content"
                  }}>
                    <Form.Label style={{
                      color: "red"
                    }}> Container 40 feet, 20 pallets </Form.Label>
                    <Form.Control
                      type="number"
                      name="palletCapacity"
                      label="Barcode Country"
                      style={{
                        border: "1px solid red"
                      }}
                      placeholder="Container 20 feet, 11 pallets"
                    />
                  </Form.Group>
                  <Form.Group style={{
                    width: "fit-content"
                  }}>
                    <Form.Label style={{
                      color: "red"
                    }}> Container 40 feet, 21 pallets </Form.Label>
                    <Form.Control
                      type="number"
                      name="palletCapacity"
                      label="Barcode Country"
                      style={{
                        border: "1px solid red"
                      }}
                      placeholder="Container 20 feet, 11 pallets"
                    />
                  </Form.Group>
                </div>
              </div>
              <hr />
              <h4>Product Image <span style={{
                fontSize: "14px"
              }}>With Background White</span></h4>
              <div className="product-info">
                <Form.Group>
                  <Form.Label htmlFor="ProductImg">Product Image</Form.Label>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Form.Control
                      type="file"
                      style={{ height: "fit-content" }}
                      name="image"
                      id="productImg"
                      onChange={handleImageChange}
                      ref={fileInputRef}
                    />
                    {Imgfile.Imgfile && (
                      <div className="card-item-about position-relative">
                        <img
                          style={{ width: "100px", height: "100px" }}
                          src={URL.createObjectURL(Imgfile.Imgfile)}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setImgfile({
                              Imgfile: null,
                            });
                            if (fileInputRef.current) {
                              fileInputRef.current.value = "";
                            }
                          }}
                          style={{
                            position: "absolute",
                            top: "3px",
                            right: "30px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    )}
                  </div>
                </Form.Group>


                <Form.Group>
                  <Form.Label htmlFor="productImg">
                    Product Image Gallery/
                    <span style={{ color: "red" }}>
                      Excatly 4 No More No Less
                    </span>
                  </Form.Label>
                  <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    <Form.Control
                      type="file"
                      name="images"
                      multiple
                      id="productImg"
                      onChange={handleGalleryChange}
                    />
                    <div style={{ display: "flex", gap: "5px" }}>
                      {ImgGallery.ImgGallery.map((image, index) => (
                        <div key={index} style={{ position: "relative", display: "inline-block" }}>
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover"
                            }}
                            src={URL.createObjectURL(image)}
                            alt={`Gallery Img ${index + 1}`}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setImgGallery(prevState => ({
                                ImgGallery: prevState.ImgGallery.filter((_, indexIM) => indexIM !== index)
                              }));
                            }}
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "-11px",
                              backgroundColor: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer"
                            }}
                          >
                            &times;
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>

                </Form.Group>


              </div>
              <hr />
              <h2>Package Info</h2>
              <div className="Package">
                <Form.Group>
                  <Form.Label>Package Gross Weight</Form.Label>

                  <Form.Control
                    name="packageGrossWeight"
                    label="packageGrossWeight"
                    value={values.packageGrossWeight}
                    onChange={handleInfoInputChange}
                    placeholder="Package Gross Weight"
                    required
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Product Box CBM / Volume</Form.Label>

                  <Form.Control
                    name="volume"
                    label="volume"
                    value={values.volume}
                    onChange={handleInfoInputChange}
                    placeholder="Product Box CBM / Volume"
                    required
                  />
                </Form.Group>
                <p style={{
                  color: "1px solid black",
                  fontSize: "16px"
                }}>Product Box CBM / Volume :{calculatedValue}</p>
                <Form.Group>
                  <Form.Label style={{ color: "red" }}>Product Box Width</Form.Label>

                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="width"
                    type="number"
                    min={0}
                    label="width"
                    value={values.width}
                    onChange={handleInfoInputChange}
                    placeholder="Product Box CBM / width"

                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{ color: "red" }}>Product Box height</Form.Label>

                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="height"
                    type="number"
                    min={0}
                    label="height"
                    value={values.height}
                    onChange={handleInfoInputChange}
                    placeholder="Product Box CBM / height"

                  />
                </Form.Group>


                <Form.Group>
                  <Form.Label style={{ color: "red" }}>Product Box Tall</Form.Label>

                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="tall"
                    type="number"
                    min={0}
                    label="tall"
                    value={values.tall}
                    onChange={handleInfoInputChange}
                    placeholder="Product Box CBM / height"

                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Product Count In Box</Form.Label>
                  <Form.Control
                    name="countInBox"
                    label="category"
                    value={values.countInBox}
                    onChange={handleInfoInputChange}
                    placeholder="Product Count In Box"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Product Stock Off ? </Form.Label>

                  <select
                    id="category-list"
                    name="stockOff"
                    label="category"
                    value={values.stockOff}
                    onChange={handleInfoInputChange}
                  >
                    <option>Please Choose A Product Stock Off</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Stock quantity</Form.Label>
                  <Form.Control
                    name="countInBox"
                    label="category"
                    style={{
                      border: "1px solid red"
                    }}
                    placeholder="Stock quantity"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Product Hidden ? </Form.Label>

                  <select
                    id="category-list"
                    name="hidden"
                    label="category"
                    value={values.hidden}
                    onChange={handleInfoInputChange}
                  >
                    <option>Please Choose If Product Hidden</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>IS New Product</Form.Label>

                  <select
                    id="category-list"
                    name="is_new"
                    label="category"
                    value={values.is_new}
                    onChange={handleInfoInputChange}
                  >
                    <option>Please Choose If Product Hidden</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </Form.Group>
              </div>
              <h2>
                Barcode Info
              </h2>

              <div className="box-barcode">
                <Form.Group>
                  <Form.Label style={{ color: "red" }}>Brand Code</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="BrandCode"
                    label="BrandCode"
                    value={values.BrandCode}
                    onChange={handleInfoInputChange}
                    placeholder="BrandCode"

                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{ color: "red" }}>Brand</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="Brand"
                    label="Brand"
                    value={values.Brand}
                    onChange={handleInfoInputChange}
                    placeholder="Brand"

                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label style={{ color: "red" }}>Category Code</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="categoryCode"
                    label="categoryCode"
                    value={values.categoryCode}
                    onChange={handleInfoInputChange}
                    placeholder="categoryCode"

                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{ color: "red" }}>Category</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="category"
                    label="category"
                    value={values.category}
                    onChange={handleInfoInputChange}
                    placeholder="category"

                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{ color: "red" }}>Weight Code</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="weightCode"
                    label="weightCode"
                    value={values.weightCode}
                    onChange={handleInfoInputChange}
                    placeholder="weightCode"

                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{ color: "red" }}>Weight</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="Weight"
                    label="Weight"
                    value={values.Weight}
                    onChange={handleInfoInputChange}
                    placeholder="Weight"

                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{ color: "red" }}>Package</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="fill"
                    label="fill"
                    value={values.fill}
                    onChange={handleInfoInputChange}
                    placeholder="Package"

                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{ color: "red" }}>International Number</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="internationalNumber"
                    label="HsCode"
                    value={values.internationalNumber}
                    onChange={handleInfoInputChange}
                    placeholder="internationalNumber"

                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{ color: "red" }}>Product Category GSI</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="room"
                    label="room"
                    value={values.room}
                    onChange={handleInfoInputChange}
                    placeholder="Packing"

                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Box Barcode</Form.Label>
                  <Form.Control
                    name="barcode"
                    label="Box Barcode"
                    value={values.boxBarcode.barcode}
                    onChange={handelBrcodeBox}
                    placeholder="Box Barcode"
                  />
                </Form.Group>
                {values.boxBarcode.barcode != "" && <Form.Group>
                  <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "100%", marginTop: "10px" }}
                      value={values.boxBarcode.barcode}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </Form.Group>}

              </div>
              <div className="box-barcode">
                <Form.Group>
                  <Form.Label>can Barcode</Form.Label>
                  <Form.Control
                    name="barcode"
                    label="Box Barcode"
                    value={values.canBarcode.barcode}
                    onChange={handelBrcodeCan}
                    placeholder="canBarcode  barcode"
                  />
                </Form.Group>
                {values.canBarcode.barcode && <Form.Group>
                  <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "100%", marginTop: "10px" }}
                      value={values.canBarcode.barcode}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </Form.Group>}

              </div>
              <div className="box-barcode">

                <Form.Group>
                  <Form.Label>Piece Barcode</Form.Label>
                  <Form.Control
                    name="barcode"
                    label="Box Barcode"
                    value={values.pieceBarcode.barcode}
                    onChange={handelBrcodePiece}
                    placeholder="Box Barcode"
                  />
                </Form.Group>
                {values.pieceBarcode.barcode && <Form.Group>

                  <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "100%", marginTop: "10px" }}
                      value={values.pieceBarcode.barcode}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </Form.Group>}

                <Form.Group>
                  <Form.Label>
                    Barcode Country
                  </Form.Label>
                  <select
                    id="category-list"
                    name="barcodeCountry"
                    className="m-0"
                    value={values.barcodeCountry}
                    onChange={handleInfoInputChange}

                  >
                    <option>Please Choose A Barcode Country</option>
                    {ARRAYOFCountries.map((d, index) => {
                      return (
                        <option key={index} value={d.label}>
                          {d.label}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>
                <Form.Group>
                  <Form.Label style={{ color: "red" }}>HsCode</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    name="HsCode"
                    label="HsCode"
                    value={values.HsCode}
                    onChange={handleInfoInputChange}
                    placeholder="HsCode"

                  />
                </Form.Group>







              </div>
              <h2 style={{ marginTop: "20px" }}>
                Sticker Info
              </h2>
              <div className="product">
                <Form.Group>
                  <Form.Label>Carton Sticker Count </Form.Label>
                  <Form.Control
                    type="number"
                    name="cartonSticker"
                    label="Barcode Country"
                    value={values.cartonSticker}
                    onChange={handleInfoInputChange}
                    placeholder="Carton Sticker"
                  />
                </Form.Group>


                <Form.Group>
                  <Form.Label>Cover Sticker Count</Form.Label>
                  <Form.Control
                    type="number"
                    name="count"
                    label="Barcode Country"
                    value={values.coverSticker.count}
                    onChange={handelCoverSticker}
                    placeholder="Cover Sticker Count"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Cover Sticker type </Form.Label>
                  <select
                    id="category-list"
                    name="type"
                    label="type"
                    value={values.coverSticker.type}
                    onChange={handelCoverSticker}
                  >
                    <option>Please Cover Sticker type</option>
                    <option>small</option>
                    <option>big</option>
                  </select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Front label length</Form.Label>
                  <Form.Control
                    type="number"
                    name="stickerLength"
                    label="Barcode Country"
                    value={values.stickerLength}
                    onChange={handleInfoInputChange}
                    placeholder="Front label length"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Front label  Width</Form.Label>
                  <Form.Control
                    name="stickerWidth"
                    type="number"
                    label="Barcode Country"
                    value={values.stickerWidth}
                    onChange={handleInfoInputChange}
                    placeholder="Front label  Width"
                  />
                </Form.Group>
              </div>
              <div className="product">
                <Form.Group>
                  <Form.Label>Back label length</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    type="number"
                    name="productSticker"
                    label="Barcode Country"

                    placeholder="Back  label"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Back label width</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    type="number"
                    name="productSticker"
                    label="Barcode Country"

                    placeholder="Back label width"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Cover sticker number</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid red"
                    }}
                    type="number"
                    name="productSticker"
                    label="Barcode Country"

                    placeholder="Cover sticker number"
                  />
                </Form.Group>
                {/* <Form.Group>
                  <Form.Label>Product Sticker</Form.Label>
                  <Form.Control
                    type="number"
                    name="productSticker"
                    label="Barcode Country"
                    value={values.productSticker}
                    onChange={handleInfoInputChange}
                    placeholder="Product Sticker"
                  />
                </Form.Group>
                 */}

                <Form.Group>
                  <Form.Label>Lid label type </Form.Label>
                  <select
                    id="category-list"
                    name="type"
                    label="type"
                    style={{
                      border: "1px solid red"
                    }}

                  >
                    <option>Please Lid label type</option>
                    <option>small</option>
                    <option>middle</option>
                    <option>big</option>
                  </select>
                </Form.Group>

                <Form.Group>
                  <Form.Label htmlFor="ProductImg" style={{
                    color: "red"
                  }}>Front label Image</Form.Label>
                  <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    <Form.Control
                      type="file"

                      style={{ height: "fit-content", border: "1px solid red" }}
                      name="image"
                      id="productImg"
                      onChange={handleImageChangeFrontLabel}
                      ref={fileInputFrontLabel}
                    />
                    {ImgfileFrontLabel.Imgfile && (
                      <div className="card-item-about position-relative">
                        <img
                          style={{ width: "100px", height: "100px" }}
                          src={URL.createObjectURL(ImgfileFrontLabel.Imgfile)}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setImgfileFrontLabel({
                              Imgfile: null,
                            });
                            if (fileInputFrontLabel.current) {
                              fileInputFrontLabel.current.value = "";
                            }
                          }}
                          style={{
                            position: "absolute",
                            top: "3px",
                            right: "30px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductImg" style={{
                    color: "red"
                  }}>Back label Image</Form.Label>
                  <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    <Form.Control
                      type="file"

                      style={{ height: "fit-content", border: "1px solid red" }}
                      name="image"
                      id="productImg"
                      onChange={handleImageChangeBackLabel}
                      ref={fileInputBaclLabel}
                    />
                    {ImgfileBackLabel.Imgfile && (
                      <div className="card-item-about position-relative">
                        <img
                          style={{ width: "100px", height: "100px" }}
                          src={URL.createObjectURL(ImgfileBackLabel.Imgfile)}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setImgfileBackLabel({
                              Imgfile: null,
                            });
                            if (fileInputBaclLabel.current) {
                              fileInputBaclLabel.current.value = "";
                            }
                          }}
                          style={{
                            position: "absolute",
                            top: "3px",
                            right: "30px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>
              <h4 style={{
                marginTop: "20px"
              }}>
                NutritionalValues Info
              </h4>
              <div className="d-flex gap-4">
                <div style={{ border: '2px solid red', padding: "5px" }} className="d-flex flex-column">
                  <Form.Label>Nutritional values ​​for America/Australia</Form.Label>
                  <Spreadsheet
                    data={spreadsheetData}
                    createFormulaParser={customCreateFormulaParser}
                    onChange={handleSpreadsheetChange}
                    style={{ border: '2px solid red' }}
                  />
                </div>
                <div className="d-flex flex-column" style={{
                  border: "1px solid red",
                  padding: "5px"
                }}>
                  <Form.Label>Nutritional values ​​Europe</Form.Label>
                  <Spreadsheet
                    data={spreadsheetData2}
                    createFormulaParser={customCreateFormulaParser2}
                    onChange={handleSpreadsheetChange}
                    style={{ border: '2px solid red' }}
                  />
                </div>


              </div>
              <h4 style={{
                marginTop: "20px"
              }}>
                Ingredients Info
              </h4>
              <div className="groub-of-editor">

                <Form.Group>
                  <Form.Label>Ingredients In English</Form.Label>
                  <Form.Control
                    name="en"
                    label="Ingredients In English"
                    value={values.components.en}
                    onChange={IngredientsInputHandle}
                    placeholder="Ingredients In English"
                    style={{ textRendering: 'auto' }}
                    as="textarea"
                    row={3}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Ingredients In Arabic</Form.Label>
                  <Form.Control
                    name="ar"
                    label="Ingredients In English"
                    value={values.components.ar}
                    onChange={IngredientsInputHandle}
                    placeholder="Ingredients In Arabic "
                    as="textarea"
                    row={3}
                  />

                </Form.Group>
                <Form.Group>
                  <Form.Label>Ingredients In French</Form.Label>
                  <Form.Control
                    name="fr"
                    label="Ingredients In English"
                    value={values.components.fr}
                    onChange={IngredientsInputHandle}
                    placeholder="Ingredients In  French"
                    as="textarea"
                    row={3}
                  />

                </Form.Group>
                <Form.Group>
                  <Form.Label>Ingredients In German</Form.Label>
                  <Form.Control
                    name="de"
                    label="Ingredients In German"
                    value={values.components.de}
                    onChange={IngredientsInputHandle}
                    placeholder="Ingredients In   German"
                    as="textarea"
                    row={3}
                  />

                </Form.Group>

                <Form.Group>
                  <Form.Label>Ingredients In Danish</Form.Label>
                  <Form.Control
                    name="dk"
                    label="Ingredients In Danish"
                    value={values.components.dk}
                    onChange={IngredientsInputHandle}
                    placeholder="Ingredients In   Danish"
                    as="textarea"
                    row={3}
                  />

                </Form.Group>

                <Form.Group>
                  <Form.Label>Ingredients In Dutch</Form.Label>
                  <Form.Control
                    name="nl"
                    label="Ingredients In Dutch"
                    value={values.components.nl}
                    onChange={IngredientsInputHandle}
                    placeholder="Ingredients In   Dutch"
                    as="textarea"
                    row={3}
                  />

                </Form.Group>
                <Form.Group>
                  <Form.Label>Ingredients In Norwegian</Form.Label>
                  <Form.Control
                    name="nor"
                    label="Ingredients In Norwegian"
                    value={values.components.nor}
                    onChange={IngredientsInputHandle}
                    placeholder="Ingredients In   Norwegian"
                    as="textarea"
                    row={3}
                  />

                </Form.Group>
                <Form.Group>
                  <Form.Label>Ingredients In Swedish</Form.Label>
                  <Form.Control
                    name="swe"
                    label="Ingredients In Swedish"
                    value={values.components.swe}
                    onChange={IngredientsInputHandle}
                    placeholder="Ingredients In   Swedish"
                    as="textarea"
                    row={3}
                  />

                </Form.Group>

                <Form.Group>
                  <Form.Label>Ingredients In Turkish</Form.Label>
                  <Form.Control
                    name="tur"
                    label="Ingredients In Turkish"
                    value={values.components.tur}
                    onChange={IngredientsInputHandle}
                    placeholder="Ingredients In   Turkish"
                    as="textarea"
                    row={3}
                  />

                </Form.Group>
                <Form.Group>
                  <Form.Label>Ingredients In Finnish</Form.Label>
                  <Form.Control
                    name="tur"
                    label="Ingredients In Finnish"
                    style={{
                      border: "1px solid red"
                    }}
                    placeholder="Ingredients In   Finnish"
                    as="textarea"
                    row={3}
                  />

                </Form.Group>
              </div>
              <h4 style={{
                marginTop: "20px"
              }}>
                StorageConditions Info
              </h4>
              <div className="product">
                <Form.Group>
                  <Form.Label>StorageConditions In English</Form.Label>
                  <Form.Control
                    name="en"
                    label="StorageConditions In English"
                    value={values.storageConditions.en}
                    onChange={StorageConditionsInputHandle}
                    placeholder="StorageConditions In English"
                    as="textarea"
                    row={3}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>StorageConditions In Arabic</Form.Label>
                  <Form.Control
                    name="ar"
                    label="StorageConditions In Arabic"
                    value={values.storageConditions.ar}
                    onChange={StorageConditionsInputHandle}
                    placeholder="StorageConditions In Arabic"
                    as="textarea"
                    row={3}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>StorageConditions In German</Form.Label>
                  <Form.Control
                    name="de"
                    label="StorageConditions In German"
                    value={values.storageConditions.de}
                    onChange={StorageConditionsInputHandle}
                    placeholder="StorageConditions In German"
                    as="textarea"
                    row={3}
                  />
                </Form.Group>
              </div>
              <h4 style={{
                marginTop: "20px"
              }}>
                Notes <span style={{
                  fontSize: "12px"
                }}>if any</span>
              </h4>
              <div className="product">
                <Form.Group>
                  <Form.Label>Notes In English</Form.Label>
                  <Form.Control
                    name="en"
                    label="StorageConditions In German"
                    value={values.notes.en}
                    onChange={NotesInputHandle}
                    placeholder="Notes In English"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Notes In Arabic</Form.Label>
                  <Form.Control
                    name="ar"
                    label="StorageConditions In German"
                    value={values.notes.ar}
                    onChange={NotesInputHandle}
                    placeholder="Notes In Arabic"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Notes In Dutch</Form.Label>
                  <Form.Control
                    name="nl"
                    label="StorageConditions In German"
                    value={values.notes.nl}
                    onChange={NotesInputHandle}
                    placeholder="Notes In Dutch"
                  />
                </Form.Group>
              </div>
              <hr />
              <h4>Made In</h4>
              <div className="product" style={{
                padding: "5px",
              }}>
                <Form.Group style={{
                  display: "flex",
                  flexDirection: "column"
                }}>
                  <Form.Label>
                    Made In  English
                  </Form.Label>
                  <select
                    name="en"
                    className="p-2"
                    label="StorageConditions In German"
                    value={values.madeIn.en}
                    onChange={MadeinInputHandle}
                    placeholder="Made In Arabic"
                    style={{
                      border: "1px solid #9ca3af",
                      borderRadius: "8px"
                    }}
                  >
                    <option>Please Choose A  Country</option>
                    {ARRAYOFCountries.map((d, index) => {
                      return (
                        <option key={index} value={d.label}>
                          {d.label}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>

                <Form.Group style={{
                  display: "flex",
                  flexDirection: "column"
                }}>
                  <Form.Label>
                    Made In  Arabic
                  </Form.Label>
                  <select
                    name="ar"
                    className="p-2"
                    label="StorageConditions In German"
                    value={values.madeIn.ar}
                    onChange={MadeinInputHandle}
                    placeholder="Made In Arabic"
                    style={{
                      border: "1px solid #9ca3af",
                      borderRadius: "8px"
                    }}
                  >
                    <option>Please Choose a Country</option>
                    {ARRAYOFCountriesAR.map((category, index) => {
                      return (
                        <option key={index} value={category.value}>
                          {category.value}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    Made In  Dutch
                  </Form.Label>
                  <select
                    name="de"
                    className="p-2"
                    label="StorageConditions In German"
                    value={values.madeIn.de}
                    onChange={MadeinInputHandle}
                    placeholder="Made In Dutch"
                    style={{
                      border: "1px solid #9ca3af",
                      borderRadius: "8px"
                    }}
                  >
                    <option>Please Choose a Country</option>
                    {ARRAYOFCountriesNL.map((category, index) => {
                      return (
                        <option key={index} value={category.label}>
                          {category.label}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>
                <Form.Group style={{
                  display: "flex",
                  flexDirection: "column"
                }}>
                  <Form.Label>
                    Made In  German
                  </Form.Label>
                  <select
                    name="de"
                    className="p-2"
                    label="StorageConditions In German"
                    style={{
                      border: "1px solid red",
                      borderRadius: "8px"
                    }}
                    placeholder="Made In German"
                  >
                    <option>Please Choose a Country</option>
                    {ARRAYOFCountriesDE.map((category, index) => {
                      return (
                        <option key={index} value={category.label}>
                          {category.label}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>

              </div>
              <Form.Group style={{
                width: "fit-content"
              }}>
                <Form.Label htmlFor="ProductImg" style={{ color: "red" }}>Print files</Form.Label>
                <Form.Control
                  style={{ border: "1px solid red" }}
                  type="file"
                  name="image"
                  id="productImg"
                  onChange={(event) => handlePdfChange(event)}
                />
              </Form.Group>



              <Form.Group>
                <Form.Label style={{ color: 'red' }}>The Name of the Agent is On The Product Cove</Form.Label>
                <div>
                  <h4>Verified Agents</h4>
                  <div className="agent-prod">
                    {VerifiedAgents?.map((agent) => (
                      <Form.Check
                        key={agent.value}
                        type="checkbox"
                        label={agent.name}
                        value={agent.value}
                        checked={values.agents.includes(agent.value)}
                        onChange={handleChange}
                      />

                    ))}
                  </div>
                  <hr />
                  <h4> un Verified Agents</h4>
                  <div className="agent-prod">
                    {UnVerifiedAgentquery?.map((agent) => (

                      <Form.Check
                        key={agent.value}
                        type="checkbox"
                        label={agent.name}
                        value={agent.value}
                        checked={values.agents.includes(agent.value)}
                        onChange={handleChange}
                      />

                    ))}
                  </div>
                </div>
              </Form.Group>
              <hr />
              <div style={{
                width: "100%",
                height: "200px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center"
              }}>
                <h4>Prices</h4>
              </div>
              <Button type="submit" variant="warning">
                Add New Product
              </Button>
            </Form>
          </div>
        </div>
        <hr />

      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>New Unit</Form.Label>
            <Form.Control
              type="text"
              value={newUnit}
              onChange={(e) => setNewUnit(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="warning" onClick={handleAddUnit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showModalPackage} onHide={() => setShowModalPackage(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Product Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>New Package</Form.Label>
            <Form.Control
              type="text"
              value={newPackage}
              onChange={(e) => setPackage(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalPackage(false)}>
            Close
          </Button>
          <Button variant="warning" onClick={handleAddPackage}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default AddProduct;
