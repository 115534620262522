import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { customStyles } from '../../constants/DataTableCustomeStyles';
import { useNavigate } from 'react-router-dom';

const AppVeriosn = () => {
  const [appversions, setAppversions] = useState([])
  const navigate = useNavigate()
  const fetchApp = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    const response = await fetch(
      "https://www.api.setalkel.co/app-version/all",
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      setAppversions([]);

    } else {
      setAppversions(body);
      console.log(body);
    }
  };
  useEffect(() => {
    fetchApp();
  }, []);

  const columns = [
    {
      name: "Platform	",
      selector: (row) => <span>{row.platform}</span>,
    },
    {
      name: "Current Version",
      selector: (row) => <span>{row.current_version}</span>,
    },
    {
      name: "Minimum Required Version	",
      selector: (row) => <span>{row.minimum_required_version}</span>,
    },
    {
      name: "Update Required",
      selector: (row) => <span>{row.update_required ? "Yes" : "No"}</span>,
    },
    {
      name: "Update Url	",
      selector: (row) => <span>{row.update_url}</span>,
    },
    {
      name: "Update",
      selector: (row) => (
          <div>
              <Button
                  variant="warning"
                  onClick={() => {
                      navigate(`/dashboard/app-version/${row.platform}`)
                  }}
              >
                  Update
              </Button>


          </div>
      ),
  },
  ]
  const naviaget = useNavigate()
  return (
    <>
      <Row>
        <h1>App Version</h1>
        <Button variant='warning' style={{
          width: "fit-content"
        }} onClick={() => {
          naviaget("/dashboard/add-app-version")
        }}>
          Add App Version
        </Button>
      </Row>
      <Row>
        <div className="list-category">
          <DataTable
            fixedHeaderScrollHeight="300px"
            responsive
            subHeaderWrap
            columns={columns}
            data={appversions}
            customStyles={customStyles}
          />
        </div>
      </Row>

    </>
  )
}

export default AppVeriosn