import React, { useEffect, useState } from "react";
//translation
import { useTranslation } from "react-i18next";
//redux
import { useDispatch } from "react-redux";
import { addExportCountryId } from "../../../../redux/shipmentStepperSlice";
//css
import "./ExportCountryCard.css";
//img
import SoonImg from "../../../../assets/soonCat.jpg";
//scroll
// import { scroller } from "react-scroll";
const ExportCountryCard = ({ country, scroll, setScroll }) => {
  const [docLang, setDocLang] = useState(document.querySelector("html").lang);
  const { t } = useTranslation();
  useEffect(() => {
    setDocLang(document.querySelector("html").lang);
  }, [t]);
  const dispatch = useDispatch();

  //===========================================Get Export Country Id==========================
  const getExportCountryID = () => {
    if (country.categories.length > 0) {
      dispatch(addExportCountryId(country._id));
      alert(
        t(
          `Components.ShipmentComponents.ShipmentStepper.ExportCountryCard.CountryChosenAlert`
        )
      );
    } else {
      alert("Sorry You Cant Choose This Country Duo To It's Not Ready");
    }
    setScroll((prev) => ({ firstTime: true, click: !prev.click }));
  };
  return (
    <article
      id="export-country-card"
      className="export-country-card"
      onClick={getExportCountryID}
    >
      <div className="export-country-card-header">
        <img src={"https://www.api.setalkel.co" + country.img} alt="" />
        <span>
          {country.countryName[docLang]
            ? country.countryName[docLang]
            : country.countryName.en}
        </span>
      </div>
      <div className="export-country-card-body">
        {country.categories.length > 0 ? (
          country.categories.map((category, index) => {
            return (
              <div className="export-card-body-category" key={index}>
                <img
                  src={"https://www.api.setalkel.co" + category.cat.img}
                  alt="category"
                />
                <span>
                  {category.cat[docLang]
                    ? category.cat[docLang]
                    : category.cat.en}
                </span>
              </div>
            );
          })
        ) : (
          <img
            src={SoonImg}
            alt="soonImg"
            id="soongIMg"
            style={{ borderRadius: "100px" }}
          />
        )}
      </div>
    </article>
  );
};

export default ExportCountryCard;
