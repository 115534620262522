import React, { useRef } from "react";
//css
import "./AdminShipmentProductCard.css";
//bootstrap
import { Col, Row } from "react-bootstrap";
//icons
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
//images
import CartBag from "../../../../../../assets/Payments/cartbag.png";
import TrashImg from "../../../../../../assets/Payments/trash.png";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  adminAddAmountToCart,
  adminAddToCart,
  adminDecreaseCart,
  adminRemoveFromCart,
} from "../../../../../../redux/Admin/adminCartSlice";
//URL
const BaseURL = "https://www.api.setalkel.co";
const AdminShipmentProductCard = ({ product }) => {
  const QuantityRef = useRef();
  const dispatch = useDispatch();
  const adminCartItems = useSelector((state) => state.adminCart.adminCartItems);
  //===============================Add To Cart========================
  const handleAddToCart = (product) => {
    dispatch(adminAddToCart(product));
  };
  //======================Decrease Cart Amount========================

  const handleDecreaseCart = (product) => {
    dispatch(adminDecreaseCart(product));
  };
  //=======================add Amount Handle============================
  const handleAmountChange = (e, cartItem) => {
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== null &&
      e.target.value > 0
    ) {
      dispatch(
        adminAddAmountToCart({
          amount: parseInt(e.target.value),
          cartItem: cartItem,
        })
      );
    } else {
      QuantityRef.current.value = "";
    }
  };
  //==========================stockOffProduct===============================

  const stockOffProduct = () => {
    alert(
      "This Product Is Out Of Stock Please Contact Us On The Contact Page To Discuss About It "
    );
  };
  return (
    <article className="admin-shipment-product-card" key={product._id}>
      <div className="admin-shipment-product-card-h1">
        <div className="admin-shipment-product-card-title">
          <div className="admin-shipment-product-card-title-info">
            <h3>{product.subCat.en}</h3>
            <p>{product.subCat.en}</p>
          </div>
          <div className="admin-shipment-product-card-title-btn">
            {product.stockOff === true ? (
              <button
                className="stockOff-btn"
                onClick={() => stockOffProduct()}
              >
                Stock Off Product
              </button>
            ) : (
              <button onClick={() => handleAddToCart(product)}>
                <img src={CartBag} alt="addToCardBtn" />
                Add To Cart
              </button>
            )}
          </div>
        </div>
        <hr />
        <div className="admin-shipment-product-card-details">
          <div className="admin-info-data">
            <div>
              <Row>
                <Col>Piece Gross Weight</Col>
                <Col>{`${product.peiceQty.grossWeightForPeice} ${product.peiceQty.unit}`}</Col>
              </Row>
              <Row>
                <Col>Package Gross Weight</Col>
                <Col>{`${
                  product.packageGrossWeight
                    ? product.packageGrossWeight
                    : (product.packageGrossWeight = 0)
                }`}</Col>
              </Row>
              <Row>
                <Col>Box Volume</Col>
                <Col>
                  {product.volume ? product.volume : (product.volume = 0)}
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>Box Price</Col>
                <Col>
                  {product.productPrice
                    ? product.productPrice
                    : (product.productPrice = 0)}
                </Col>
              </Row>
              <Row>
                <Col>Count In Box</Col>
                <Col>
                  {product.countInBox
                    ? product.countInBox
                    : (product.countInBox = 0)}
                </Col>
              </Row>
            </div>
          </div>
          <div className="admin-shipment-product-card-qty">
            <p>Package Quantity</p>
            <div className="btn-group">
              <button onClick={() => handleAddToCart(product)}>
                <AiOutlinePlus />
              </button>
              {adminCartItems.map((item) => {
                return item._id === product._id ? (
                  <input
                    ref={QuantityRef}
                    type="text"
                    min={0}
                    pattern="\d*"
                    maxlength="4"
                    id={product._id}
                    value={item.cartQuantity}
                    onChange={(e) => handleAmountChange(e, product)}
                  />
                ) : null;
              })}

              <button onClick={() => handleDecreaseCart(product)}>
                <AiOutlineMinus />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="admin-shipment-product-card-h2">
        {/* {adminCartItems.map((adminCartItem) => {
          return adminCartItem._id === product._id &&
            adminCartItem.cartQuantity > 0 ? (
            <img
              src={TrashImg}
              alt="TrashImg"
              onClick={() => adminRemoveFromCart(adminCartItem)}
              id="deleteImg"
            />
          ) : null;
        })} */}
        <img src={BaseURL + product.img} alt="productImg" />
      </div>
    </article>
  );
};

export default AdminShipmentProductCard;
