import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//css
import "./FinalInvoiceCard.css";
//image
import LogoImg from "../../../../assets/Logo.png";
import CalenderImg from "../../../../assets/Payments/Calendar.png";
//bootstrap
import { Button } from "react-bootstrap";
//redux
import { useDispatch } from "react-redux";
//components
import { addFinalInvoice } from "../../../../redux/shipmentStepperSlice";
//translation
import { useTranslation } from "react-i18next";
//table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const FinalInvoiceCard = ({ shipment }) => {
  const dispatch = useDispatch();
  const [docLang, setDocLang] = useState(document.querySelector("html").lang);
  const { t } = useTranslation();
  useEffect(() => {
    setDocLang(document.querySelector("html").lang);
  }, [t]);
  //=====================handle Invoice Card=====================
  const handleInvoiceCard = () => {
    dispatch(addFinalInvoice(shipment._id));
    alert(
      t(
        `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.ShipmentChoosen`
      )
    );
    const nextBtn = document.getElementById("next-button");
    window.scrollTo(nextBtn);
  };
  //==============handleDownloadFiles=================
  var navigate = useNavigate();
  const handleGoToFiles = () => {
    localStorage.setItem(
      "shipmentFiles",
      JSON.stringify(shipment.shipmentFiles)
    );
    navigate("/shipmentProcess/documents");
  };
  //=========================================download File ===============================================
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  //============================NoFilesPOPUP=======================
  return (
    <article className="final-invoice-card" onClick={() => handleInvoiceCard()}>
      <div className="shipmentInvoiceheader">
        <img src={LogoImg} alt="setalkel" />
        <div className="shipmentInvoiceheaderInfo">
          <div>
            <span>
              {t(
                `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.OrderNumber`
              )}
              :
            </span>
            <span>{shipment.orderNumber}</span>
          </div>
          <p>
            <img src={CalenderImg} alt="calenderImg" />
            <span>{shipment.order.createdAt.slice(0, 10)}</span>
          </p>
        </div>
      </div>
      <hr id="invoice-card-hr" />

      <div className="shipmentInvoiceInfo">
        <div>
          <h5>
            {t(
              `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.ShipmentDetails`
            )}
            :
          </h5>
          <div className="shipmentInvoiceInfo-products">
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    {t(
                      `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.ProductName`
                    )}
                  </Th>

                  <Th>
                    {t(
                      `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.ProductWeight`
                    )}
                  </Th>
                  <Th>
                    {t(
                      `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.ProductCount`
                    )}
                  </Th>
                </Tr>
              </Thead>
              <div id="invoice-card-spacer"></div>
              <Tbody className="products-table-body">
                {shipment.order.items.map((item, index) => {
                  return (
                    <>
                      <Tr key={index}>
                        <Td>
                          {item.product.subCat[docLang]
                            ? item.product.subCat[docLang]
                            : item.product.subCat.en}
                        </Td>
                        <Td>{item.product.peiceQty.drainedWeightForPeice}g</Td>
                        <Td>{item.count}</Td>
                      </Tr>
                      <div id="invoice-card-table-spacer"></div>
                    </>
                  );
                })}
              </Tbody>
            </Table>
          </div>
        </div>
        <div>
          <h5>
            {t(
              `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.Count`
            )}
            :
          </h5>
          <div className="shipment-container-info">
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    {t(
                      `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.ContainerDetails.ContainerType`
                    )}
                  </Th>
                  <Th>
                    {t(
                      `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.ContainerDetails.Fridge`
                    )}
                  </Th>
                  <Th>
                    {t(
                      `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.ContainerDetails.MaxVolume`
                    )}
                  </Th>
                  <Th>
                    {t(
                      `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.ContainerDetails.MaxWeight`
                    )}
                  </Th>
                </Tr>
              </Thead>
              <div id="invoice-card-spacer"></div>

              <Tbody>
                <Tr>
                  <Td>{shipment.containerInfo.container.containerType}</Td>
                  <Td>
                    {shipment.containerInfo.container.isFridge ? "Yes" : "No"}
                  </Td>
                  <Td>{shipment.containerInfo.container.maxVolume}</Td>
                  <Td>{shipment.containerInfo.container.maxWeight} Kg</Td>
                </Tr>
              </Tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className="final-invoice-btn-group">
        <Button
          className="primary-btn invoice-btn"
          onClick={() =>
            shipment.shipmentFiles.telexRelease
              ? getFile(shipment.shipmentFiles.telexRelease)
              : alert(
                  t(
                    `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.TelexReleaseNote`
                  )
                )
          }
        >
          {t(
            `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.ContainerDetails.DownloadTelexRelease`
          )}
        </Button>
        <Button
          className="primary-btn invoice-btn secondary-btn"
          onClick={() => handleGoToFiles()}
        >
          {t(
            `Components.PaymentComponents.FinalInvoice.FinalInvoiceCard.DownloadShipmentDocs`
          )}
        </Button>
      </div>
    </article>
  );
};

export default FinalInvoiceCard;
