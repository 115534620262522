import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
//css
import "./ScrollToTop.css";
//icons
import { FaAngleUp } from "react-icons/fa";
import { Plus } from "react-iconly";
//images
import CartImg from "../../assets/Payments/cart.png";
//redux
import { useSelector } from "react-redux";
import { t } from "i18next";
const ScrollToTop = () => {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const navigate = useNavigate();
  const location = useLocation();
  window.addEventListener("scroll", (e) => {
    const el = document.getElementById("jsScroll");
    if (window.scrollY > 200) {
      el.classList.add("visible");
    } else {
      el.classList.remove("visible");
    }
  });

  //=====================Scroll To Top=====================
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToDown = () => {
    document.getElementById("our-activities-container").scrollIntoView();
  };

  const NavigateToOrder = () => {
    navigate("/shipmentProcess/shipment");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <button id="jsScroll" className="scroll" onClick={scrollToTop}>
        <FaAngleUp />
      </button>
      {cartItems.length > 0 && (
        <button
          id="jsScroll2"
          className="scroll2"
          onClick={() => navigate("/shipmentProcess/cart")}
        >
          <img src={CartImg} alt="cart" />
        </button>
      )}
      {location.pathname === "/aboutUs"  && (
        <button id="jsScroll3" className="scroll3" onClick={scrollToDown}>
          <span
            style={{
              fontSize: "10px",
            }}
          >
              {t(`Pages.OurActivities.BannerTitle`)}
          </span>
        </button>
      )}
      {location.pathname === "/shipmentProcess/myorders" && (
        <button id="jsScroll4" className="scroll4" onClick={NavigateToOrder}>
          <Plus set="broken" />
          <span
            style={{
              fontSize: "10px",
            }}
          >
            Make Order
          </span>
        </button>
      )}
    </>
  );
};

export default ScrollToTop;
