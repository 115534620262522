import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import Logo from "../../assets/img/Pages/Agent/Logo.png"; //logo
import CircleImg from "../../assets/img/Pages/Agent/Shape.png"; //shape
import Leaf from "../../assets/img/Pages/Agent/Icons/Group 54.png"; //group 54
import "./Login.css";
import { FaEye, FaEyeSlash, FaEnvelope } from "react-icons/fa";
import { requestForToken } from "../../firebase";
import toast from "react-hot-toast";

const Login = () => {
  requestForToken();

  const [user, setUser] = useState({
    email: "",
    password: "",
    fcm: "",
  });

  const Navigate = useNavigate();

  const loginUser = async (e) => {
    e.preventDefault();
    if (user.email && user.password) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: user.email,
        password: user.password,
        fcmToken: localStorage.getItem("admin-fcm"),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/admin/login",
        requestOptions
      );

      const body = await response.json();
      if (!response.ok) {
        toast.error("Email Or Password Are Wrong");
      } else {
        localStorage.setItem("admin-token", body.token);
        localStorage.setItem("user-data", body.name);
        Navigate("/dashboard");
        toast.success("Welcome in Dashboard")
      }
    }
  };

  const handleUser = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  //=================toggle show/hide passwords====================
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const togglePasswordVisibilty = () => {
    setIsPasswordShown(!isPasswordShown);
  };
  return (
    <div className="admin-bg">
      <Row>
        <Col sm={12} md={6} lg={6} className="admin-login-FH">
          <div className="admin-login-FH-form">
            <form className="admin-loginform" onSubmit={loginUser}>
              <img src={Leaf} alt="" />
              <h4>Admin Login</h4>

              <div className="admin-formComponents">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    value={user.email}
                    onChange={handleUser}
                  />
                  <FaEnvelope className="email-icon" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type={isPasswordShown ? "text" : "password"}
                    placeholder="********"
                    name="password"
                    value={user.password}
                    onChange={handleUser}
                  />
                  {isPasswordShown ? (
                    <FaEyeSlash
                      className="password-eye"
                      onClick={togglePasswordVisibilty}
                    />
                  ) : (
                    <FaEye
                      className="password-eye"
                      onClick={togglePasswordVisibilty}
                    />
                  )}
                </Form.Group>
              </div>
              <Button variant="primary" type="submit" className="loginBtn">
                Login
              </Button>
            </form>
          </div>
        </Col>
        <Col sm={12} md={6} lg={6}>
          <div className="SH">
            <img src={Logo} alt="setalkelLogo" />
            <img src={CircleImg} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
