import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  exportCountryId: localStorage.getItem("exportCountryId")
    ? localStorage.getItem("exportCountryId")
    : "",
  importCountryId: localStorage.getItem("importCountryId")
    ? localStorage.getItem("importCountryId")
    : "",
  importStateId: localStorage.getItem("importStateId")
    ? localStorage.getItem("importStateId")
    : "",
  deliveryMethod: localStorage.getItem("deliveryMethod")
    ? localStorage.getItem("deliveryMethod")
    : "",
  containerId: localStorage.getItem("containerId")
    ? localStorage.getItem("containerId")
    : "",
  container: JSON.parse(localStorage.getItem("container"))
    ? JSON.parse(localStorage.getItem("container"))
    : {},
  categoryId: localStorage.getItem("categoryId")
    ? localStorage.getItem("categoryId")
    : "",
};

const adminShipmentStepperSlice = createSlice({
  name: "shipmentStepper",
  initialState,
  reducers: {
    adminAddExportCountryId: (state, action) => {
      state.exportCountryId = action.payload;
      localStorage.setItem("exportCountryId", action.payload);
    },
    adminAddImportCountryId: (state, action) => {
      state.importCountryId = action.payload;
      localStorage.setItem("importCountryId", action.payload);
    },
    adminAddImportStateId: (state, action) => {
      state.importStateId = action.payload;
      localStorage.setItem("importStateId", action.payload);
    },
    adminAddDeliveryMethod: (state, action) => {
      state.deliveryMethod = action.payload;
      localStorage.setItem("deliveryMethod", action.payload);
    },
    adminAddContainerId: (state, action) => {
      state.containerId = action.payload;
      localStorage.setItem("containerId", action.payload);
    },
    adminAddContainer: (state, action) => {
      state.container = action.payload;
      localStorage.setItem("container", JSON.stringify(action.payload));
    },
    adminAddCategoryId: (state, action) => {
      state.categoryId = action.payload;
      localStorage.setItem("categoryId", action.payload);
    },
  },
});
export const {
  adminAddExportCountryId,
  adminAddImportCountryId,
  adminAddImportStateId,
  adminAddDeliveryMethod,
  adminAddContainerId,
  adminAddContainer,
  adminAddCategoryId,
} = adminShipmentStepperSlice.actions;

export default adminShipmentStepperSlice.reducer;
