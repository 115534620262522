import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminCartItems: localStorage.getItem("adminCartItems")
    ? JSON.parse(localStorage.getItem("adminCartItems"))
    : [],
  adminShipmentItems: localStorage.getItem("adminShipmentItems")
    ? JSON.parse(localStorage.getItem("adminShipmentItems"))
    : [],
  adminCartTotalQuantity: 0,
  adminCartTotalAmount: 0,
  adminCartTotalWeight: 0,
  adminCartTotalVolume: 0,
  adminIsEditing: false,
  shipmentAgentId: "",
  adminShipmentInRange: false,
};

const adminCartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    shipmentAgentId(state, action) {
      state.shipmentAgentId = action.payload;
    },

    adminShipmentInRange(state, action) {
      state.adminShipmentInRange = action.payload;
    },
    //==================================================================================================================================

    adminCheckIsEditing(state, action) {
      state.adminIsEditing = action.payload;
    },

    //==================================================================================================================================

    adminUpdateSuggestion(state, action) {
      state.adminCartItems = action.payload;
    },
    //==================================================================================================================================

    adminUpdateSuggestionShipment(state, action) {
      state.adminShipmentItems = action.payload;
    },
    //==================================================================================================================================
    //=====================================Add Amount To Cart=======================================================
    adminAddAmountToCart(state, action) {
      //=====================Add To Cart Items================================
      const amount = parseInt(action.payload.amount);
      const existingIndex = state.adminCartItems.findIndex(
        (item) => item._id === action.payload.cartItem._id
      );

      if (existingIndex >= 0) {
        state.adminCartItems[existingIndex] = {
          ...state.adminCartItems[existingIndex],
          cartQuantity: (state.adminCartItems[existingIndex].cartQuantity =
            amount),
        };
      } else {
        let tempProductItem = {
          ...action.payload.cartItem,
          cartQuantity: action.payload.amount,
        };
        state.adminCartItems.push(tempProductItem);
      }
      localStorage.setItem(
        "UpdatedCartItems",
        JSON.stringify(state.adminCartItems)
      );

      //=====================Add To Shipment Items================================
      const ShipmentExistingIndex = state.adminShipmentItems.findIndex(
        (item) => item.product === action.payload.cartItem._id
      );
      if (ShipmentExistingIndex >= 0) {
        state.adminShipmentItems[ShipmentExistingIndex] = {
          ...state.adminShipmentItems[ShipmentExistingIndex],
          count: (state.adminShipmentItems[ShipmentExistingIndex].count =
            amount),
          product: state.adminShipmentItems[ShipmentExistingIndex].product,
        };
      } else {
        var tempProductItem;
        if (action.payload.cartItem._id) {
          tempProductItem = {
            ...action.payload.cartItem,
            count: amount,
            product: action.payload.cartItem._id,
            note: "",
          };
        } else if (action.payload.cartItem.product) {
          tempProductItem = {
            ...action.payload.cartItem,
            count: amount,
            product: action.payload.cartItem.product,
            note: "",
          };
        }
        console.log(tempProductItem);
        state.adminShipmentItems.push(tempProductItem);
      }
      localStorage.setItem(
        "adminShipmentItems",
        JSON.stringify(state.adminShipmentItems)
      );
    },
    //==================================================================================================================================

    adminAddToCart(state, action) {
      //=====================Add To Cart Items================================
      const existingIndex = state.adminCartItems.findIndex(
        (item) => item._id === action.payload._id
      );

      if (existingIndex >= 0) {
        state.adminCartItems[existingIndex] = {
          ...state.adminCartItems[existingIndex],
          cartQuantity: state.adminCartItems[existingIndex].cartQuantity + 1,
        };
      } else {
        let tempProductItem = { ...action.payload, cartQuantity: 1 };
        state.adminCartItems.push(tempProductItem);
      }
      localStorage.setItem(
        "adminCartItems",
        JSON.stringify(state.adminCartItems)
      );

      //=====================Add To Shipment Items================================

      const ShipmentExistingIndex = state.adminShipmentItems.findIndex(
        (item) => item.product === action.payload._id
      );

      if (ShipmentExistingIndex >= 0) {
        state.adminShipmentItems[ShipmentExistingIndex] = {
          ...state.adminShipmentItems[ShipmentExistingIndex],
          count: state.adminShipmentItems[ShipmentExistingIndex].count + 1,
          product: state.adminShipmentItems[ShipmentExistingIndex].product,
        };
      } else {
        var tempProductItem;
        if (action.payload._id) {
          tempProductItem = {
            ...action.payload,
            count: 1,
            product: action.payload._id,
            note: "",
          };
        } else if (action.payload.product) {
          tempProductItem = {
            ...action.payload,
            count: 1,
            product: action.payload.product,
            note: "",
          };
        }
        state.adminShipmentItems.push(tempProductItem);
      }
      localStorage.setItem(
        "adminShipmentItems",
        JSON.stringify(state.adminShipmentItems)
      );
    },
    //==================================================================================================================================
    adminDecreaseCart(state, action) {
      //======================Delete Form Cart =====================

      const itemIndex = state.adminCartItems.findIndex(
        (item) => item._id === action.payload._id
      );
      if (state.adminCartItems[itemIndex]) {
        if (state.adminCartItems[itemIndex].cartQuantity > 1) {
          state.adminCartItems[itemIndex].cartQuantity -= 1;
        } else if (state.adminCartItems[itemIndex].cartQuantity === 1) {
          const nextCartItems = state.adminCartItems.filter(
            (item) => item._id !== action.payload._id
          );

          state.adminCartItems = nextCartItems;
        }
      }

      localStorage.setItem(
        "adminCartItems",
        JSON.stringify(state.adminCartItems)
      );

      //======================Delete Form Shipment Items =====================

      const ShipmentItemIndex = state.adminShipmentItems.findIndex(
        (item) => item.product === action.payload.product
      );
      if (state.adminShipmentItems[ShipmentItemIndex]) {
        if (state.adminShipmentItems[ShipmentItemIndex].count > 1) {
          state.adminShipmentItems[ShipmentItemIndex].count -= 1;
        } else if (state.adminShipmentItems[ShipmentItemIndex].count === 1) {
          const nextShipmentItems = state.adminShipmentItems.filter(
            (item) => item.product !== action.payload.product
          );

          state.adminShipmentItems = nextShipmentItems;
        }
      }

      localStorage.setItem(
        "adminShipmentItems",
        JSON.stringify(state.adminShipmentItems)
      );
    },
    //==================================================================================================================================

    adminRemoveFromCart(state, action) {
      console.log(action.payload);
      state.adminCartItems.map((cartItem) => {
        if (cartItem._id === action.payload._id) {
          const nextCartItems = state.adminCartItems.filter(
            (item) => item._id !== cartItem._id
          );

          state.adminCartItems = nextCartItems;
        }
        localStorage.setItem(
          "adminCartItems",
          JSON.stringify(state.adminCartItems)
        );

        //======================================================

        state.adminShipmentItems.forEach((shipmentItem) => {
          if (shipmentItem.product === action.payload.product) {
            const nextShipmentItems = state.adminShipmentItems.filter(
              (item) => item.product !== shipmentItem.product
            );

            state.adminShipmentItems = nextShipmentItems;
          }
          localStorage.setItem(
            "adminShipmentItems",
            JSON.stringify(state.adminShipmentItems)
          );
        });

        return state;
      });
    },
    //==================================================================================================================================

    adminGetTotals(state, action) {
      let { totalWeight, totalVolume, totalPrice, totalQuantity } =
        state.adminCartItems.reduce(
          (cartTotal, cartItem) => {
            const { productPrice, cartQuantity, packageGrossWeight, volume } =
              cartItem;
            const itemTotalPrice = productPrice * cartQuantity;
            const itemTotalVolume = cartQuantity * volume;
            const itemTotalWeight = cartQuantity * packageGrossWeight;
            cartTotal.totalPrice += itemTotalPrice;
            cartTotal.totalQuantity += cartQuantity;
            cartTotal.totalWeight += itemTotalWeight;
            cartTotal.totalVolume += itemTotalVolume;
            return cartTotal;
          },
          {
            totalWeight: 0,
            totalVolume: 0,
            totalPrice: 0,
            totalQuantity: 0,
          }
        );
      totalPrice = parseFloat(totalPrice.toFixed(2));
      totalWeight = parseFloat(totalWeight.toFixed(2));
      totalVolume = parseFloat(totalVolume.toFixed(2));
      state.adminCartTotalQuantity = totalQuantity;
      state.adminCartTotalAmount = totalPrice;
      state.adminCartTotalWeight = totalWeight;
      state.adminCartTotalVolume = totalVolume;
    },
    adminClearCart(state, action) {
      state.adminCartItems = [];
      state.adminShipmentItems = [];
      localStorage.setItem(
        "adminCartItems",
        JSON.stringify(state.adminCartItems)
      );
      localStorage.setItem(
        "adminShipmentItems",
        JSON.stringify(state.adminShipmentItems)
      );
    },
  },
});

export const {
  adminAddAmountToCart,
  adminAddToCart,
  adminCheckIsEditing,
  adminClearCart,
  adminDecreaseCart,
  adminGetTotals,
  adminRemoveFromCart,
  adminUpdateSuggestion,
  adminUpdateSuggestionShipment,
  shipmentAgentId,
  adminShipmentInRange,
} = adminCartSlice.actions;

export default adminCartSlice.reducer;
