import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import { customStyles } from "../../../../constants/DataTableCustomeStyles";
import "./CategoriesAndProducts.css";


const CategoriesAndProducts = () => {

  const params = useParams();

  const [countriesData, setCountriesData] = useState({});

  const [specificCategoryProducts, setSpecificCategoryProducts] = useState([]);

  const [categoryProductsId, setCategoryProductsId] = useState("");

  const [exportCountriesCategory, setExportCountriesCategory] = useState([]);

  //=================================== Delete Product====================================
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalShowClose = () => setDeleteModalShow(false);
  const handleDeleteModalShowShow = () => setDeleteModalShow(true);
  const [deleteProductId, setDeleteProductId] = useState("");
  //===================================Mutliple Delete Products====================================
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);
  const [deletedProducts, setDeletedProducts] = useState([]);
  //===================================Delete Category Modal States========================
  const [DeleteCategoryModalShow, setDeleteCategoryModalShow] = useState(false);
  const handleDeleteCategoryModalClose = () => setDeleteCategoryModalShow(false);
  const handleDeleteCategoryModalShow = () => setDeleteCategoryModalShow(true);
  //======================================handle Check=========================
  // const handleCheckAll = (e) => {
  //   if (e.target.checked) {
  //     const ProductsChecks = document.querySelectorAll("#productCheck");
  //     const ProductsChecksArray = Array.from(ProductsChecks);
  //     var prodsId = [];
  //     for (let i = 0; i < ProductsChecksArray.length; i++) {
  //       const element = ProductsChecksArray[i];
  //       element.setAttribute("checked", true);
  //       prodsId.push(element.value);
  //     }
  //     setDeletedProducts([...deletedProducts, ...prodsId]);
  //   } else {
  //     const ProductsChecks = document.querySelectorAll("#productCheck");
  //     const ProductsChecksArray = Array.from(ProductsChecks);
  //     for (let i = 0; i < ProductsChecksArray.length; i++) {
  //       const element = ProductsChecksArray[i];
  //       element.removeAttribute("checked");
  //     }
  //     setDeletedProducts([]);
  //   }
  // };
  //================================get export country categories and products==================
  useEffect(() => {
    const getExportCountryCategoriesAndProduct = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/export-country/${params.Id}`,
        requestOptions
      );

      const body = await response.json();

      if (!response.ok) {
        console.log(body);
        setCountriesData({});
      } else {
        setCountriesData(body);
        setExportCountriesCategory(body?.categories);
      }
    };
    getExportCountryCategoriesAndProduct();
  }, [params.Id]);

  //=================Get Specific Categorey products============
  useEffect(() => {
    const getSpecificCategoryProducts = async () => {
      if (categoryProductsId) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `https://www.api.setalkel.co/export-country/admin/products?idc=${params.Id}&idcat=${categoryProductsId}`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          setSpecificCategoryProducts([]);
          console.log(body);
        } else {
          setSpecificCategoryProducts(body.prods);
        }
      }
    };
    getSpecificCategoryProducts();
  }, [categoryProductsId, params.Id]);

  //==========================Delete Product==============================
  const onDelete = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/export-country/product?idc=${params.Id}&idcat=${categoryProductsId}&idp=${deleteProductId}`,
      requestOptions
    );
    if (!response.ok) {
      alert("product Not Deleted");
    } else {
      alert("Product Has Been Deleted");
      handleDeleteCategoryModalClose();
      setSpecificCategoryProducts(specificCategoryProducts.filter(product => product._id !== deleteProductId));
    }
  };

  //============================Delete Mutliple Product===================
  const deleteProducts = (e, productId) => {
    if (e.target.checked) {
      if (!deletedProducts.includes(productId)) {
        setDeletedProducts([...deletedProducts, productId]);
      }
    } else {
      setDeletedProducts(
        deletedProducts.filter(item => item !== productId)
      );
    }
  };

  //=======================delete Multiple Products========================
  const deleteMultipleProducts = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);
    
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/export-country/product-collection?idc=${params.Id}&idcat=${categoryProductsId}`,
      requestOptions
    );
    if (!response.ok) {
      alert("There Is An Error");
      console.log(response);
    } else {
      alert("Products Have Been Deleted !!");
    }
  };

  //=================================Delete Category From Export Country===================
  const deleteCategoryExportCountry = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/export-country/category/?idc=${params.Id}&idcat=${categoryProductsId}`,
      requestOptions
    );

    if (!response.ok) {
      alert("Category Not Deleted");
      console.log(response);
    } else {
      alert("Category Deleted !!");
      handleDeleteCategoryModalClose();
      setExportCountriesCategory(exportCountriesCategory.filter(category => category._id !== categoryProductsId));
    }
  };

  const columns2 = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Image",
      selector: (row) => (
        <span>
          <img
            src={"https://www.api.setalkel.co" + row?.cat?.img}
            alt="productImg"
            width="100px"
            height="100px"
          />
        </span>
      ),
    },
    {
      name: "Category Name",
      selector: (row) => <span>{row?.cat?.en}</span>,
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteCategoryModalShow();
              setCategoryProductsId(row._id);
            }}
          >
            Delete
          </Button>
          <Modal
            show={DeleteCategoryModalShow}
            onHide={handleDeleteCategoryModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete This Category
              </Modal.Title>
            </Modal.Header>

            <Modal.Footer>
              <Button variant="dark" onClick={handleDeleteCategoryModalClose}>
                Close
              </Button>
              <Button variant="danger" onClick={deleteCategoryExportCountry}>
                Delete Category
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];

  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Image",
      selector: (row) => (
        <span>
          <img
            src={"https://www.api.setalkel.co" + row?.img}
            alt="productImg"
            width="100px"
            height="100px"
          />
        </span>
      ),
    },
    {
      name: "Category",
      selector: (row) => <span>{row?.cat?.en}</span>,
    },
    {
      name: "English Name",
      selector: (row) => <span>{row?.subCat?.en}</span>,
    },
    {
      name: "Arabic Name",
      selector: (row) => <span>{row?.subCat?.ar}</span>,
    },
    {
      name: "Piece Gross Weight",
      selector: (row) => <span>{row?.peiceQty?.grossWeightForPeice}</span>,
    },
    {
      name: "Package Gross Weight",
      selector: (row) => <span>{row?.packageGrossWeight}</span>,
    },
    {
      name: "Volume",
      selector: (row) => <span>{row?.volume}</span>,
    },
    {
      name: "Count In Box",
      selector: (row) => <span>{row?.countInBox}</span>,
    },
    {
      name: "Package",
      selector: (row) => <span>{row?.peiceQty?.packageForPeice}</span>,
    },
    {
      name: "Stock Off",
      selector: (row) => <span>{row?.stockOff ? "Yes" : "No Stock Off"}</span>,
    },
    {
      name: "Hidden",
      selector: (row) => <span>{row?.hidden ? "Yes" : "No"}</span>,
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button
            onClick={() => {
              handleDeleteModalShowShow();
              setDeleteProductId(row._id);
            }}
            variant="danger"
          >
            Delete
          </Button>
          <Modal show={DeleteModalShow} onHide={handleDeleteModalShowClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete This Product
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteModalShowClose}>
                Close
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                Delete Product
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
    {
      name: "M-Delete",
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="productCheck"
          value={row._id}
          onChange={(e) => deleteProducts(e, row._id)}
        />
      ),
    },
  ];

  return (
    <section className="show-categories-products">
      <Row>
        <div className="crud-countries">
          <div className="title">
            <h1>Export Countries Categories And Products</h1>
          </div>
        </div>
      </Row>
      <Row>
        <Form.Group>
          <Form.Label>Filter Category</Form.Label>
          <Form.Select
            id="category-list"
            name="catFilterId"
            value={categoryProductsId}
            onChange={(e) => setCategoryProductsId(e.target.value)}
          >
            <option>Please Choose A Category</option>
            {exportCountriesCategory.map((category) => {
              return <option value={category._id}>{category?.cat?.en}</option>;
            })}
          </Form.Select>
        </Form.Group>
        {/* ===================================================Delete Multiple Products==================== */}
        {deletedProducts.length > 0 ? (
          <div>
            <Button
              id="multipleDelete"
              variant="outline-danger"
              onClick={() => handleMultipleShow()}
            >
              Multiple Delete
            </Button>

            <Modal
              size="lg"
              show={MultipleDeleteModalShow}
              onHide={() => setMultipleDeleteModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Are You Sure You Want To Delete All Those Products
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleMultipleClose}>
                  Close
                </Button>
                <Button variant="danger" onClick={deleteMultipleProducts}>
                  Delete Products
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : null}
        <hr />
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns2}
          data={exportCountriesCategory}
          customStyles={customStyles}
        />
        <hr />
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={specificCategoryProducts}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
};

export default CategoriesAndProducts;
