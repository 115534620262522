import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//boostrap
import { Col, Form, Button, Row } from "react-bootstrap";

//css
import "./Profile.css";
//images
import Leaf from "../../../assets/img/Pages/Agent/Icons/Group 54.png"; //leaf
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [userInfo] = useState(JSON.parse(localStorage.getItem("user-info")));
  const [user, setUser] = useState({
    name: "",
    mobilePhone: "",
    phone: "",
    port: "",
    companyName: "",
    country: "",
    password: "",
    currentPassword: "",
  });
  //=========================Handle Input==============================
  const handleUser = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  //=========================get Agent Info By Id =========================
  useEffect(() => {
    const getAgentInfoById = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/agent/${userInfo._id}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setUser({});
        console.log(body);
      } else {
        console.log(body);
        setUser({
          ...user,
          name: body.name,
          mobilePhone: body.mobilePhone,
          phone: body.phone,
          port: body.port,
          companyName: body.companyName,
          country: body.country,
        });
      }
    };
    getAgentInfoById();
  }, [userInfo._id]);
  //=========================Update Agent Info==============================
  const updateUser = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: user.name,
      port: user.port,
      companyName: user.companyName,
      mobilePhone: user.mobilePhone,
      phone: user.phone,
      country: user.country,
      isOnline: true,
    });
    console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/agent/update-info",
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("There is an Error");
      console.log(body);
    } else {
      user.password && updatePassword();
      alert("Agent Info Updated");
      navigate("/");
      window.location.reload();
    }
  };
  //===========================Update Password===================
  const updatePassword = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    if (user.currentPassword && user.password) {
      var raw = JSON.stringify({
        currentPassword: user.currentPassword,
        password: user.password,
      });
      console.log(raw);
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/agent/reset-password/",
        requestOptions
      );
      if (!response.ok) {
        alert("Password Not Updated");
        console.log(response);
      } else {
        alert("Password  Updated");
        window.location.reload();
      }
    }
  };
  return (
    <Row>
      <Col md={12} className="P-Register-FH">
        <div className="P-Register-FH-form">
          <Form>
            <img src={Leaf} alt="leaf" />
            <h2>{t(`Pages.Auth.Profile.Title`)}</h2>
            <div className="formGroup1">
              <Row className="mb-3">
                <Form.Group controlId="formGridUserName">
                  <Form.Label>{t(`Pages.Auth.Profile.username`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder={t(`Pages.Auth.Profile.username`)}
                    onChange={handleUser}
                    value={user.name}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group controlId="formGridSeaPort">
                  <Form.Label>
                    {t(`Pages.Auth.Profile.MobilePhoneNumber`)}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="mobilePhone"
                    placeholder={t(`Pages.Auth.Profile.MobilePhoneNumber`)}
                    onChange={handleUser}
                    value={user.mobilePhone}
                  />
                </Form.Group>

                <Form.Group controlId="formGridCompanyName">
                  <Form.Label>{t(`Pages.Auth.Profile.PhoneNumber`)}</Form.Label>
                  <Form.Control
                    type="number"
                    name="phone"
                    placeholder={t(`Pages.Auth.Profile.PhoneNumber`)}
                    onChange={handleUser}
                    value={user.phone}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group controlId="formGridSeaPort">
                  <Form.Label>{t(`Pages.Auth.Profile.Country`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    placeholder={t(`Pages.Auth.Profile.Country`)}
                    onChange={handleUser}
                    value={user.country}
                  />
                </Form.Group>

                {/* <Form.Group controlId="formGridCompanyName">
                  <Form.Label>{t(`Pages.Auth.Profile.Address`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder={t(`Pages.Auth.Profile.Address`)}
                    onChange={handleUser}
                    value={user.address}
                  />
                </Form.Group> */}
              </Row>
              <Row className="mb-3">
                <Form.Group controlId="formGridSeaPort">
                  <Form.Label>{t(`Pages.Auth.Profile.SeaPort`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="port"
                    placeholder={t(`Pages.Auth.Profile.SeaPort`)}
                    onChange={handleUser}
                    value={user.port}
                  />
                </Form.Group>

                <Form.Group controlId="formGridCompanyName">
                  <Form.Label>{t(`Pages.Auth.Profile.CompanyName`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyName"
                    placeholder={t(`Pages.Auth.Profile.CompanyName`)}
                    onChange={handleUser}
                    value={user.companyName}
                  />
                </Form.Group>
              </Row>
              <hr />
              <Row className="mb-3">
                <h6>Do You Want To Change Your Password ? </h6>
                <Form.Group className="mb-3" controlId="formGridSeaPort">
                  <Form.Label>{t(`Pages.Auth.Profile.Password`)}</Form.Label>
                  <Form.Control
                    type="text"
                    name="currentPassword"
                    placeholder={`Current ` + t(`Pages.Auth.Profile.Password`)}
                    onChange={handleUser}
                  />
                </Form.Group>
                <Form.Group controlId="formGridSeaPort">
                  <Form.Label>
                    New {t(`Pages.Auth.Profile.Password`)}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="password"
                    placeholder={`New ` + t(`Pages.Auth.Profile.Password`)}
                    onChange={handleUser}
                  />
                </Form.Group>
              </Row>
            </div>
            <div className="formGroup2">
              <Button
                variant="warning"
                type="button"
                className="updateBtn m-0"
                onClick={updateUser}
              >
                {t(`Pages.Auth.Profile.UpdateBtn`)}
              </Button>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Profile;
