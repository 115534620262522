export const customStyles = {
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      width: "200px",
      display:"flex",
      justifyContent: "center"
      
   
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        color:"red",
        display:"flex",
        justifyContent: "center"
        

      },
    }
  }
};
