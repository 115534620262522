import React, { useRef, useState } from 'react'
import { Row, Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const AddCatgories = () => {
    const [Imgfile, setImgfile] = useState({
        Imgfile: null,
    });
    const [values, setValues] = useState({
        cat: "",
        en_name: "",
        ar_name: "",
        fr_name: "",
        ru_name: "",
        de_name: "",
        cn_name: "",
        tur_name: "",
        ir_name: "",
        dk_name: "",
    });
    const handleImageChange = (e) => {
        setImgfile({
            Imgfile: e.target.files[0],
        });
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    //===================================================Posting Category==================================================================
    const PostCategoryImg = async (id) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Bearer " + localStorage.getItem("admin-token")
            );
            const fd = new FormData();
            fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: fd,
                redirect: "follow",
            };

            const ImgResponse = await fetch(
                `https://www.api.setalkel.co/product/cat/image/${id}`,
                requestOptions
            );
            const body = await ImgResponse.json();
            console.log(body);
        } catch (error) {
            console.log(error);
        }
    };
    const fileInputRef = useRef(null);
    const navigate = useNavigate()
    //==============================================================================================================
    const PostCategory = async () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `bearer ${localStorage.getItem("admin-token")}`
        );
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        if (values.ar_name && values.en_name) {
            var urlencoded = new URLSearchParams();
            urlencoded.append("ar", values.ar_name);
            urlencoded.append("en", values.en_name);
            urlencoded.append("de", values.de_name);
            urlencoded.append("fr", values.fr_name);
            urlencoded.append("cn", values.cn_name);
            urlencoded.append("ru", values.ru_name);
            urlencoded.append("tur", values.tur_name);
            urlencoded.append("ir", values.ir_name);
            urlencoded.append("dk", values.dk_name);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };
            const response = await fetch(
                "https://www.api.setalkel.co/product/cat",
                requestOptions
            );
            const body = await response.json();
            if (!response.ok) {
                alert(body.message);
                console.log(body);
            } else {
                if (Imgfile.Imgfile) {
                    await PostCategoryImg(body._id);
                }
                navigate(-1)
                toast.success("Category has been Added :)");
                setValues({
                    cat: "",
                    en_name: "",
                    ar_name: "",
                    fr_name: "",
                    ru_name: "",
                    de_name: "",
                    cn_name: "",
                    tur_name: "",
                    ir_name: "",
                    dk_name: "",
                });
            }
        } else {
            toast.error("Cannot Add New Category Without Arabic And English Name");
        }
    };
    return (
        <div>
            <div className="crud-category">
                <Form className="categories-form">
                    <div className="category-langs">
                        <Form.Group>
                            <Form.Label htmlFor="ProductNameInEn">
                                Category Name In English
                            </Form.Label>
                            <Form.Control
                                name="en_name"
                                label="category"
                                value={values.en_name}
                                onChange={handleInputChange}
                                placeholder="name in en"
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="CategoryNameInEn">
                                Category Name In Arabic
                            </Form.Label>

                            <Form.Control
                                name="ar_name"
                                label="category"
                                value={values.ar_name}
                                onChange={handleInputChange}
                                placeholder="name in ar"
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="CategoryNameInEn">
                                Category Name In French
                            </Form.Label>
                            <Form.Control
                                name="fr_name"
                                label="category"
                                value={values.fr_name}
                                onChange={handleInputChange}
                                placeholder="name in fr"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="CategoryNameInEn">
                                Category Name In Russian
                            </Form.Label>

                            <Form.Control
                                name="ru_name"
                                label="category"
                                value={values.ru_name}
                                onChange={handleInputChange}
                                placeholder="name in ru"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="CategoryNameInEn">
                                Category Name In German
                            </Form.Label>

                            <Form.Control
                                name="de_name"
                                label="category"
                                value={values.de_name}
                                onChange={handleInputChange}
                                placeholder="name in de"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="CategoryNameInEn">
                                Category Name In China
                            </Form.Label>

                            <Form.Control
                                name="cn_name"
                                label="category"
                                value={values.cn_name}
                                onChange={handleInputChange}
                                placeholder="name in cn"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="CategoryNameInEn">
                                Category Name In Turkish
                            </Form.Label>
                            <Form.Control
                                name="tur_name"
                                label="category"
                                value={values.tur_name}
                                onChange={handleInputChange}
                                placeholder="name in tur"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="CategoryNameInEn">
                                Category Name In Persian
                            </Form.Label>
                            <Form.Control
                                name="ir_name"
                                label="category"
                                value={values.ir_name}
                                onChange={handleInputChange}
                                placeholder="name in ir"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="CategoryNameInEn">
                                Category Name In Danish
                            </Form.Label>
                            <Form.Control
                                name="dk_name"
                                label="category"
                                value={values.dk_name}
                                onChange={handleInputChange}
                                placeholder="name in dk"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="CategoryImg">Category Image</Form.Label>
                            <div className="d-flex flex-column ">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    id="productImg"
                                    ref={fileInputRef}
                                    onChange={handleImageChange}
                                    style={{
                                        height: "fit-content"
                                    }}
                                />
                                {Imgfile.Imgfile && (
                                    <div className="position-relative" style={{
                                        width: "fit-content"
                                    }}>
                                        <img
                                            style={{ width: "100px", height: "100px" }}
                                            src={URL.createObjectURL(Imgfile.Imgfile)}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setImgfile({
                                                    Imgfile: null,
                                                });
                                                if (fileInputRef.current) {
                                                    fileInputRef.current.value = ""; // إفراغ الحقل باستخدام useRef
                                                }
                                            }}
                                            style={{
                                                position: "absolute",
                                                top: "-18px",
                                                right: "-14px",
                                                backgroundColor: "red",
                                                color: "white",
                                                border: "none",
                                                borderRadius: "50%",
                                                width: "20px",
                                                height: "20px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer"
                                            }}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                )}
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="CategoryImg" >Category Media</Form.Label>
                            <div className="d-flex flex-column ">
                                <Form.Control
                                    type="file"
                                    name="image"
                                    id="productImg"
                                    style={{
                                        height: "fit-content",
                                        border: "1px solid red"
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </Form>
                <Button type="button" onClick={PostCategory} variant="warning">
                    Add Category
                </Button>
            </div>
        </div>
    )
}

export default AddCatgories