import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
//bootstrap
import {
  Button,
  Form,
  Modal,
  Row,
} from "react-bootstrap";

//css
import "./CRImportCountry.css";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../constants/DataTableCustomeStyles";
import toast from "react-hot-toast";
import UnVerifiedAgentsApi from "../../../Api/UnVerifiedAgentsApi";
const ImportCountry = () => {
  const [importCountries, setImportCountries] = useState([]);
  const [countryId, setCountryId] = useState("");

  const [deletedImportCountries, setDeletedImportCountries] = useState({
    countries: [],
  });

  const [multipleDeleteCountriesShow, setMultipleDeleteCountriesShow] =
    useState(false);
  const handleMultipleDeleteCountriesClose = () =>
    setMultipleDeleteCountriesShow(false);
  const handleMultipleDeleteCountriesShow = () =>
    setMultipleDeleteCountriesShow(true);
  //===============================Delete Modal======================================
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  //=======================Image Handeling===============



  useEffect(() => {
    const getAllImportCountriesInfo = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/import-country/admin",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setImportCountries([]);
        console.log(body);
      } else {
        setImportCountries(body);
      }
    };
    getAllImportCountriesInfo();
  }, []);
  //=====================Add Import Country Image================


  //==========================Delete Country=====================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/import-country/${countryId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          toast.success("Country deleted");
          handleDeleteModalClose()
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  //============================Delete Mutliple Product===================

  const deleteImportCountires = (e, CountryId) => {
    if (e.target.checked) {
      if (!deletedImportCountries.countries.includes(CountryId)) {
        setDeletedImportCountries({
          ...deletedImportCountries,
          countries: [...deletedImportCountries.countries, CountryId],
        });
      }
    } else {
      setDeletedImportCountries({
        countries: deletedImportCountries.countries.filter(
          (item) => item !== CountryId
        ),
      });
    }
  };
  //=======================delete Multiple Products========================
  const deleteMultipleImportStates = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedImportCountries);
    console.log(raw);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/import-country/",
      requestOptions
    );
    if (!response.ok) {
      toast.error("There Is An Error");
      console.log(response);
    } else {
      toast.success("Countries Have Been Deleted !!");
      handleMultipleDeleteCountriesClose()
    }
  };
  //=====================================================================
  const columns = [
    {
      name: "ID",
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: "Image",
      selector: (row) => (
        <span>
          <img
            src={`https://www.api.setalkel.co${row.img}`}
            alt=""
            width="70px"
            height="70px"
          />
        </span>
      ),
    },
    {
      name: "Country Code",
      selector: (row) => <span>{row.countryCode}</span>,
    },
    {
      name: "English Country Name",
      selector: (row) => <span>{row.countryName.en}</span>,
    },
    {
      name: "Arabic Country Name",
      selector: (row) => <span>{row.countryName.ar}</span>,
    },
    {
      name: "States",
      selector: (row) => (
        <ul className="p-0">
          {row.states.length > 0 ? (
            row.states.map((state, index) => {
              return <li key={index}>{state.stateName.en}</li>;
            })
          ) : (
            <span>No States</span>
          )}
        </ul>
      ),
    },
    {
      name: "State Shipping Containers",
      selector: (row) => (
        <ul className="p-0">
          {row.states.length > 0 ? (
            <li>
              {row.states.map((state, index) => {
                return state.shippingContainers.length > 0 ? (
                  <ol className="p-0" key={index}>
                    <h6>{state.stateName.en}</h6>
                    {state.shippingContainers.map((container, index) => {
                      return container.container !== null ? (
                        <li key={index}>
                          {container.container.isFridge
                            ? "Fridge"
                            : "Not Fridge"}{" "}
                          {` /// `} {container.container.usualName}
                        </li>
                      ) : (
                        <div>NO Containers 1</div>
                      );
                    })}
                  </ol>
                ) : (
                  <span>Those States Dosn't Have Any Containers</span>
                );
              })}
            </li>
          ) : (
            <span>There Is No States So There Is No Containers</span>
          )}
        </ul>
      ),
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/crimportcountries/${row._id}`}>
          <Button variant="dark">Update Country</Button>
        </Link>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <span>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteModalShow();
              setCountryId(row._id);
            }}
          >
            Delete
          </Button>

        </span>
      ),
    },
    {
      name: "Multiple Delete",
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deleteImportCountries"
          id="deleteImportCountries"
          onChange={(e) => deleteImportCountires(e, row._id)}
        />
      ),
    },
  ];
  const navigate = useNavigate()
  const [generateExcelFile, setGenerateExecelFile] = useState()
  const { data: Excel } = UnVerifiedAgentsApi.GenerateExelFileQueryForImportCountry()
  const getFileExcel = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co${generateExcelFile.pathFile}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${generateExcelFile.pathFile}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  return (
    <section className="cms-create-import-country">
      <Row>
        <div className="crud-countries">
          <div className="title">
            <h1>Import Country</h1>
          </div>
        </div>
        <div className="d-flex">
          <Button
            variant="warning"
            onClick={() => navigate("/dashboard/crimportcountries/add-import-country")}
            className="add-import"
          >
            Add Import Country
          </Button>
          {!generateExcelFile ? <Button variant="warning" id="verify-btn" onClick={() => setGenerateExecelFile(Excel)}>
            Export Excel  Import Countries
          </Button> : <Button variant="success" id="verify-btn" onClick={getFileExcel}>
            Download Excel File
          </Button>}
          <Button variant="danger" id="verify-btn" >
            Import Excel
          </Button>
        </div>

        {deletedImportCountries.countries.length > 0 ? (
          <>
            <Button
              variant="danger"
              onClick={handleMultipleDeleteCountriesShow}
            >
              Multiple Delete Countries
            </Button>

            <Modal
              show={multipleDeleteCountriesShow}
              onHide={handleMultipleDeleteCountriesClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Are You Sure You Want To Delete All Those Countries
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleMultipleDeleteCountriesClose}
                >
                  Close
                </Button>
                <Button
                  variant="danger"
                  onClick={deleteMultipleImportStates}
                >
                  Delete Countries
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : null}
      </Row>
      <hr />
      <Row>
        <div className="import-country-table">
          <DataTable
            fixedHeader
            pagination
            responsive
            subHeaderWrap
            columns={columns}
            data={importCountries}
            customStyles={customStyles}
          />
        </div>
      </Row>
      <Modal show={DeleteModalShow} onHide={handleDeleteModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are You Sure You Want To Delete This Container
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="dark" onClick={handleDeleteModalClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => onDelete()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default ImportCountry;
