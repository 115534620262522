import React, { useEffect, useState } from "react";
import { Button, Container, Form, Row, Modal } from "react-bootstrap";
import "../ExportCountryCategoriesAndProducts/ExportCountryCategoriesAndProducts"
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../../constants/DataTableCustomeStyles";
import axios from "axios";
import { Link } from "react-router-dom";
const ExportContainers = () => {
  const [exportCountries, setExportCountries] = useState([]);
  const [exportCountryId, setExportCountryId] = useState("");
  const [containers, setContainers] = useState([]);
  const [containersByCountries, setContainersByCountries] = useState([]);
  const [containerId, setContainerId] = useState("");
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);

  const [choosenContainers] = useState([]);
  //========================handle Export Country=========================
  const handleExportCountryContainers = (e, container) => {
    if (e.target.checked) {
      if (choosenContainers.includes(container._id)) {
        return;
      } else {
        choosenContainers.push(container._id);
      }
      console.log(choosenContainers);
    } else {
      for (let i = 0; i < choosenContainers.length; i++) {
        const element = choosenContainers[i];
        if (element === container._id) {
          choosenContainers.splice(i, 1);
        }
      }
    }
  };
  //========================get All Export Countries=========================
  useEffect(() => {
    const getAllExportCountries = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/export-country/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setExportCountries([]);
        console.log(body);
      } else {
        setExportCountries(body);
      }
    };
    getAllExportCountries();
  }, []);
  useEffect(() => {

    const getAllCountainers = async () => {
      if (exportCountryId) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `https://www.api.setalkel.co/export-country/${exportCountryId}/containers`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          console.log(body);
          setContainersByCountries([])
        } else {
          console.log(body.containerInCountry)
          setContainersByCountries(body.containerInCountry)
        }
      }

    };
    exportCountryId && getAllCountainers();
  }, [exportCountryId]);

  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/container/${containerId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          toast.success("item deleted");
          handleDeleteModalClose()
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  //====================================containers=====================
  useEffect(() => {
    const getContainers = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/container/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setContainers([]);
        console.log(body);
      } else {
        setContainers(body);
      }
    };
    getContainers();
  }, []);
  //=========================================Add Container To Export Country=================
  const AddContainerToExportCountry = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      exportCountryId: exportCountryId,
      containers: choosenContainers,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    if (choosenContainers.length > 0) {
      const response = await fetch(
        "https://www.api.setalkel.co/export-country/container",
        requestOptions
      );
      if (!response.ok) {
        toast.error("Containers Not Added");
        console.log(response);
      } else {
        console.log(response);
        toast.success("Containers Added Successfully !!!");
      }
    } else {
      toast.success("Please Choose At Least One Container");
    }
  };
  const columns = [
    {
      name: "Max Weight",
      selector: (row) => <span>{row.maxWeight}</span>,
    },
    {
      name: "Max Volume",
      selector: (row) => <span>{row.maxVolume}</span>,
    },
    {
      name: "HighCube",
      selector: (row) => <span> {row.isHighcube ? "yes" : "no"}</span>,
    },
    {
      name: "Fridge",
      selector: (row) => <span>{row.isFridge ? "yes" : "no"}</span>,
    },
    {
      name: "Container Type",
      selector: (row) => (
        <span>{row.containerType ? row.containerType : "No Type"}</span>
      ),
    },
    {
      name: "Stored Matrial",
      selector: (row) => (
        <span>
          {" "}
          {row.storedMaterialProperty.map((prop) => {
            return <span>{prop}</span>;
          })}
        </span>
      ),
    },
    {
      name: "Usual Name",
      selector: (row) => (
        <span>{row.usualName ? row.usualName : "No usualName"}</span>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <div>
          <Button
            onClick={() => {
              handleDeleteModalShow();
              setContainerId(row._id);
            }}
            variant="danger"
          >
            Delete
          </Button>

        </div>
      ),
    },
    {
      name: "Update",
      selector: (row) => (
        <Link to={`/dashboard/crcontainers/${row._id}`}>
          <Button variant="dark">Update</Button>
        </Link>
      ),
    },
  ];
  return (
    <section>
      <Modal show={DeleteModalShow} onHide={handleDeleteModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are You Sure You Want To Delete This Container
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="dark" onClick={handleDeleteModalClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => onDelete()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        <Row>
          <div className="crud-countries">
            <div className="title">
              <h1>Export Country Containers</h1>
            </div>
          </div>
        </Row>
        <div className="add-categories-and-products">
          <Form.Label>Select Export Country</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setExportCountryId(e.target.value)}
          >
            <option>Open this select menu</option>
            {exportCountries.map((country, index) => (
              <option key={index} value={country._id}>
                {country.countryName.en}
              </option>
            ))}
          </Form.Select>
        </div>
        <br />
        <Row>
          <Form.Label>Please Choose Containers To Be Added</Form.Label>
          <div className="check">
            {containers.map((container, index) => (
              <Form.Check
                type="checkbox"
                key={index}
                label={`${index + 1} ||| ${container.usualName} ||| ${container.isFridge ? "Fronzen" : "Not Frozen"
                  }`}
                value={container._id}
                onChange={(e) => handleExportCountryContainers(e, container)}
              />
            ))}
          </div>
          <Button className="add-containers" variant="warning" onClick={AddContainerToExportCountry}>
            Add Containers To Export Country
          </Button>
        </Row>
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={containersByCountries}
          customStyles={customStyles}
          noDataComponent={<p>Please Select Country </p>}
        />
      </Container>
    </section>
  );
};

export default ExportContainers;
