import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//css
import "./ShipmentOrder.css";
//bootstrap
import { Button } from "react-bootstrap";
//images
import DocsImg from "../../../../../../assets/Payments/docs.png";
import FollowImg from "../../../../../../assets/Payments/followOrder.png";
import DownloadImg from "../../../../../../assets/Payments/download.png";
import { useTranslation } from "react-i18next";
const ShipmentOrder = () => {
  const [orderFiles, setOrderFiles] = useState({});
  const param = useParams();
  var orderId = param.orderId;
  const { t } = useTranslation();
  //=============================get Shipment Files=====================

  useEffect(() => {
    const getShipmentFiles = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/shipment/files/${orderId}`,
        requestOptions
      );
      const body = await response.json();
      console.log(body);
      if (!response.ok) {
        setOrderFiles({});
        console.log(body);
      } else {
        setOrderFiles(body.shipmentFiles);
      }
    };
    getShipmentFiles();
  }, [orderId]);
  //=============================download File ===============================================
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <section className="shipment-docs">
      <div className="docs-title">
        <h3>
          {t(
            `Pages.ShipmentProcess.Shipment.ShipmentOrders.ShipmentOrder.Title`
          )}
        </h3>
      </div>
      <div className="docs-container">
        <div className="docs-container-img">
          <img src={DocsImg} alt="documentsImg" />
        </div>
        <div className="docs-container-files">
          {Object.entries(orderFiles).length > 0 ? (
            Object.entries(orderFiles).map((key, index) => {
              return (
                <div
                  className="docs-container-file"
                  key={index}
                  onClick={() => getFile(key[1])}
                >
                  <div>{key[0]}</div>
                  <img src={DownloadImg} alt="download" />
                </div>
              );
            })
          ) : (
            <p>
              {t(
                `Pages.ShipmentProcess.Shipment.ShipmentOrders.ShipmentOrder.NoFilesNote`
              )}
            </p>
          )}
        </div>
        <div className="docs-container-btn">
          <Button>
            {t(
              `Pages.ShipmentProcess.Shipment.ShipmentOrders.ShipmentOrder.ShipmentTracking`
            )}
            <img src={FollowImg} alt="follow" />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ShipmentOrder;
