import React, { useCallback, useEffect } from 'react'
import { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap'
import MultiSelect from "react-multiple-select-dropdown-lite";
import { ARRAYOFCountries } from './Products/CountriesArray';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { FaDownload, FaTrashAlt } from 'react-icons/fa';
const AddAddress = () => {
  const [values, setValues] = useState({
    country: {
      en: "",
      ar: "",
      de: "",
      fr: "",
      ru: "",
      tur: "",
      cn: "",

      ir: "",
      dk: "",
    },
    state: {
      en: "",
      ar: "",
      fr: "",
      ru: "",
      tur: "",
      cn: "",
      de: "",
      ir: "",
      dk: "",
    },
    address: {
      en: "",
      ar: "",
      fr: "",
      ru: "",
      tur: "",
      cn: "",
      de: "",
      ir: "",
      dk: "",
    },
    email1: "",
    email2: "",
    phone1: "",
    phone2: "",
    hidden: "",
    img: ""

  });
  const { id } = useParams()
  const getAddressById = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    const response = await fetch(
      `https://www.api.setalkel.co/address/${id}`,
      requestOptions
    );
    const body = await response.json();
    console.log(body);
    setValues({
      state: {
        en: body?.state?.en,
        ar: body?.state?.ar,
        fr: body?.state?.fr,
        cn: body?.state?.cn,
        de: body?.state?.de,
        dk: body?.state?.dk,
        ir: body?.state?.ir,
        ru: body?.state?.ru,
        tur: body?.state?.tur


      },
      country: {
        en: body?.country?.en,
        ar: body?.country?.ar,
        fr: body?.country?.fr,
        cn: body?.country?.cn,
        de: body?.country?.de,
        dk: body?.country?.dk,
        ir: body?.country?.ir,
        ru: body?.country?.ru,
        tur: body?.country?.tur
      },
      address: {
        en: body?.address?.en,
        ar: body?.address?.ar,
        fr: body?.address?.fr,
        cn: body?.address?.cn,
        de: body?.address?.de,
        dk: body?.address?.dk,
        ir: body?.address?.ir,
        ru: body?.address?.ru,
        tur: body?.address?.tur
      },
      email1: body.email1,
      email2: body.email2,
      phone1: body.phone1,
      phone2: body.phone2,
      hidden: body.hidden,
      img: body.img
    })
    setImgfile(body.img)
    // console.log(ImgGallery.ImgGallery)
  }, [id]);
  useEffect(() => {
    id && getAddressById();
  }, [getAddressById]);
  const [imgFile, setImgfile] = useState("")
  const StateInputHandle = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      state: { ...values.state, [name]: value.toLowerCase() },
    });
  };
  const CountryInputHandle = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      country: { ...values.country, [name]: value.toLowerCase() },
    });
  };
  const AddressInputHandle = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      address: { ...values.address, [name]: value.toLowerCase() },
    });
  };

  const handleInfoInputChange = (event) => {
    const { name, value } = event.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  const handleImageChange = async (e) => {
    setImgfile(e.target.files[0]);
      PostImg(e.target.files[0])
  };
  const PostImg = async (file) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    const formImg = new FormData()
    formImg.append("image", file)
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formImg,
      redirect: "follow",
    };
    const response = await fetch("https://www.api.setalkel.co/address/image", requestOptions)
    const body = await response.json();
    if (!response.ok) {
      console.log(body);
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        img: body._id,
      }));
    }
  }
  // useEffect(() => {
  //   if (imgFile) {
  //     PostImg()
  //   }
  // }, [imgFile])
  const navigate = useNavigate()
  const PostAddress = async () => {


    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append(
      "Content-Type",
      `application/json`
    );
    const data = {
      ...values,
      img: values.img != "" ? id ? values.img.split('/').pop() : values.img : undefined
    }
    var requestOptions = {
      method: id ? "PUT" : "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };
    const response = await fetch(`https://www.api.setalkel.co/address/${id ? id : ""}`, requestOptions)
    const body = await response.json();
    if (!response.ok) {
      console.log(body);
    } else {
      navigate("/dashboard/address")
      toast.success("Address has been Added :)");
    }
  }
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('.').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <section className="cms-create-products">
      <Row>
        <div className="crud-products">
          <div className="crud-title">
            <h1 >Add Address</h1>
          </div>
        </div>
        <Form onSubmit={() => { }} className="products-form">
          <Form.Group>
            <h4>Country</h4>
            <div className="product-langs">

              <Form.Group>
                <Form.Label >
                  Country Name In English
                </Form.Label>
                <Form.Control
                  name="en"
                  label="en"
                  value={values.country.en}
                  onChange={CountryInputHandle}
                  placeholder="Country Name In English"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label >
                  Country Name In Arabic
                </Form.Label>
                <Form.Control
                  name="ar"
                  label="ar"
                  value={values.country.ar}
                  onChange={CountryInputHandle}
                  placeholder="Country Name In Arabic"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label >
                  Country Name In French
                </Form.Label>
                <Form.Control
                  name="fr"
                  label="fr"
                  value={values.country.fr}
                  onChange={CountryInputHandle}
                  placeholder="Country Name In French"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label >
                  Country Name In Russian
                </Form.Label>
                <Form.Control
                  name="ru"
                  label="ru"
                  value={values.country.ru}
                  onChange={CountryInputHandle}
                  placeholder="Country Name In Russian"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label >
                  Country Name In German
                </Form.Label>
                <Form.Control
                  name="de"
                  label="de"
                  value={values.country.de}
                  onChange={CountryInputHandle}
                  placeholder="Country Name In german"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label >
                  Country Name In China
                </Form.Label>
                <Form.Control
                  name="cn"
                  label="cn"
                  value={values.country.cn}
                  onChange={CountryInputHandle}
                  placeholder="Country Name In China"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label >
                  Country Name In Turkish
                </Form.Label>
                <Form.Control
                  name="tur"
                  label="tur"
                  value={values.country.tur}
                  onChange={CountryInputHandle}
                  placeholder="Country Name In Turkish"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label >
                  State Name In Persian
                </Form.Label>
                <Form.Control
                  name="ir"
                  label="ir"
                  value={values.country.ir}
                  onChange={CountryInputHandle}
                  placeholder="Country Name In Persian"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label >
                  Country Name In Danish
                </Form.Label>
                <Form.Control
                  name="dk"
                  label="dk"
                  value={values.country.dk}
                  onChange={CountryInputHandle}
                  placeholder="Country Name In Danish"
                />
              </Form.Group>
            </div>
          </Form.Group>
          <hr />
          <h4>State</h4>
          <div className="product-langs">

            <Form.Group>
              <Form.Label >
                State Name In English
              </Form.Label>
              <Form.Control
                name="en"
                label="en"
                value={values.state.en}
                onChange={StateInputHandle}
                placeholder="State Name In English"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label >
                State Name In Arabic
              </Form.Label>
              <Form.Control
                name="ar"
                label="ar"
                value={values.state.ar}
                onChange={StateInputHandle}
                placeholder="State Name In Arabic"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label >
                State Name In French
              </Form.Label>
              <Form.Control
                name="fr"
                label="fr"
                value={values.state.fr}
                onChange={StateInputHandle}
                placeholder="State Name In French"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label >
                State Name In Russian
              </Form.Label>
              <Form.Control
                name="ru"
                label="ru"
                value={values.state.ru}
                onChange={StateInputHandle}
                placeholder="State Name In Russian"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label >
                State Name In German
              </Form.Label>
              <Form.Control
                name="de"
                label="de"
                value={values.state.de}
                onChange={StateInputHandle}
                placeholder="State Name In german"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label >
                State Name In China
              </Form.Label>
              <Form.Control
                name="cn"
                label="cn"
                value={values.state.cn}
                onChange={StateInputHandle}
                placeholder="State Name In China"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label >
                State Name In Turkish
              </Form.Label>
              <Form.Control
                name="tur"
                label="tur"
                value={values.state.tur}
                onChange={StateInputHandle}
                placeholder="State Name In Turkish"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label >
                State Name In Persian
              </Form.Label>
              <Form.Control
                name="ir"
                label="ir"
                value={values.state.ir}
                onChange={StateInputHandle}
                placeholder="State Name In Persian"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label >
                State Name In Danish
              </Form.Label>
              <Form.Control
                name="dk"
                label="dk"
                value={values.state.dk}
                onChange={StateInputHandle}
                placeholder="State Name In Danish"
              />
            </Form.Group>
          </div>
          <hr />
          <h4>Address</h4>
          <div className="product-langs">
            <Form.Group>
              <Form.Label >
                Address Name In English
              </Form.Label>
              <Form.Control
                name="en"
                label="en"
                value={values.address.en}
                onChange={AddressInputHandle}
                placeholder="Address Name In English"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label >
                Address Name In Arabic
              </Form.Label>
              <Form.Control
                name="ar"
                label="ar"
                value={values.address.ar}
                onChange={AddressInputHandle}
                placeholder="Address Name In Arabic"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label >
                Address Name In french
              </Form.Label>
              <Form.Control
                name="fr"
                label="fr"
                value={values.address.fr}
                onChange={AddressInputHandle}
                placeholder="Address Name In french"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label >
                Address Name In Russian
              </Form.Label>
              <Form.Control
                name="ru"
                label="ru"
                value={values.address.ru}
                onChange={AddressInputHandle}
                placeholder="Address Name In Russian"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label >
                Address Name In German
              </Form.Label>
              <Form.Control
                name="de"
                label="de"
                value={values.address.de}
                onChange={AddressInputHandle}
                placeholder="Address Name In German"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label >
                Address Name In China
              </Form.Label>
              <Form.Control
                name="cn"
                label="cn"
                value={values.address.cn}
                onChange={AddressInputHandle}
                placeholder="Address Name In China"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label >
                Address Name In Turkish
              </Form.Label>
              <Form.Control
                name="tur"
                label="tur"
                value={values.address.tur}
                onChange={AddressInputHandle}
                placeholder="Address Name In Turkish"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label >
                Address Name In Persian
              </Form.Label>
              <Form.Control
                name="ir"
                label="ir"
                value={values.address.ir}
                onChange={AddressInputHandle}
                placeholder="Address Name In Persian"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label >
                Address Name In Danish
              </Form.Label>
              <Form.Control
                name="dk"
                label="dk"
                value={values.address.dk}
                onChange={AddressInputHandle}
                placeholder="Address Name In Danish"
              />
            </Form.Group>
          </div>
          <hr />
          <h4>Contact Information</h4>
          <div className="product-langs">

            <Form.Group>
              <Form.Label >
                Email1 1
              </Form.Label>
              <Form.Control
                name="email1"
                label="email1"
                value={values.email1}
                onChange={handleInfoInputChange}
                placeholder="  Email1 1"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label >
                Email1 2
              </Form.Label>
              <Form.Control
                name="email2"
                label="email2"
                value={values.email2}
                onChange={handleInfoInputChange}
                placeholder="  Email1 2"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label >
                Phone 1
              </Form.Label>
              <Form.Control
                name="phone1"
                label="phone1"
                value={values.phone1}
                onChange={handleInfoInputChange}
                placeholder="  Phone 1"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label >
                Phone 2
              </Form.Label>
              <Form.Control
                name="phone2"
                label="phone2"
                value={values.phone2}
                onChange={handleInfoInputChange}
                placeholder="  Phone 2"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Show Or Hide
              </Form.Label>
              <Form.Select
                name="hidden"
                value={values.hidden}
                onChange={handleInfoInputChange}
                style={{ height: "fit-content" }}
              >
                <option value="">Choose State</option>
                <option value="false">Show</option>
                <option value="true">Hide</option>
              </Form.Select>
            </Form.Group>


            <Form.Group>
              <Form.Label htmlFor="ProductImg">Address Image</Form.Label>
              <div className="d-flex flex-column">
                <Form.Control
                  type="file"
                  name="image"
                  id="productImg"
                  onChange={handleImageChange}
                />
                {imgFile && <div className="position-relative" style={{
                  width: "fit-content"
                }}>
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                    }}
                    src={id ? `https://www.api.setalkel.co${imgFile}` : URL.createObjectURL(imgFile)}
                  />
                  {id && <FaDownload onClick={() => getFile(imgFile)} style={{
                    color: "red",
                    fontSize: "15px",
                    position: "absolute",
                    top: "0",
                    right: "20px"

                  }} />}
                  <FaTrashAlt onClick={() => setImgfile(null)} style={{
                    color: "red",
                    fontSize: "15px",
                    position: "absolute",
                    top: "0",
                    right: "0"

                  }} />

                </div>
                }
              </div>
            </Form.Group>
          </div>
          <Button onClick={PostAddress} variant='warning'>{id ? "Update" : "Add"} Address</Button>
        </Form>
      </Row>

    </section>
  )
}

export default AddAddress